import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { CaptionMedium } from 'shared_components';
import { Popover, Position } from '@blueprintjs/core';
import FaClose from 'react-icons/lib/fa/close';

import { media, colors } from 'shared_components';
import { isNullOrUndefined } from 'services/utils';
import shoe from 'assets/images/shapes/shoe.png';
import bottom from 'assets/images/shapes/bottom.png';
import top from 'assets/images/shapes/top.png';
import full from 'assets/images/shapes/full.png';
import { MEASUREMENTS_IN_COMFORT_SVGS, extractMeasurements } from 'shared_components';

import SizeInput from './inputs/SizeInput';
import QuantityInput from './inputs/QuantityInput';
import { Validation, NoRecoCallout, SizeAdvice, PositionItemInfo } from './common';

const SHAPES_MAPPING = {
  top: top,
  bottom: bottom,
  full: full,
  feet: shoe
};
const CloseIcon = styled(FaClose)`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  &:hover {
    filter: brightness(50%);
  }
`;
const Container = styled.div`
  width: 50rem;
  border-radius: 3px;
  border: solid 0.05em ${colors.lightGrey};
  margin-bottom: 1.5em;
  ${media.phone`
    width: 100%;
    background-color: ${colors.coal};
  `}
`;

const ImageContainer = styled.div`
  flex: 0 1 15%;
  margin-right: 1em;

  ${media.phone`
    flex: 0 1 20%;
    margin-left: 5%;
  `}
  ${media.phoneLarge`
    margin-left: 5%;
  `}
`;

const ProductImage = styled.img`
  cursor: pointer;
  object-fit: contain;
  height: ${props => (props.height ? props.height : '2.5rem')};
  width: ${props => (props.width ? props.width : '2.5rem')};
  background-color: white;
  vertical-align: middle;
  ${media.phone`
    background-color: ${props => (props.backgroudColor ? props.backgroudColor : colors.mainBlue)};
  `}
`;

const DesktopContentContainer = styled.div`
  flex: 1;
  border-left: solid 0.05em ${colors.lightGrey};
`;

const Header = styled.div`
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  align-items: center;
  min-height: 3rem;
  background-color: ${colors.mainBlue};
  padding: 0.5em 1em;
  ${media.phone`
    height: 3rem;
    padding: 0em 0em;
    background-color: ${colors.mainBlue};
  `}
  ${media.phoneLarge`
    height: 3rem;
    padding: 0em 0em;
    background-color: ${colors.mainBlue};
  `}
`;

const Inner = styled.div`
  background-color: rgba(246, 247, 250, 0.8);
  padding: 1em;
  display: flex;
  flex-wrap: wrap;

  ${media.phone`
    flex-direction: column;
    background-color: ${colors.coal};
  `}
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  order: 1;

  ${media.phone`
    flex-direction: column;
    justify-content: flex-end;
  `}
`;

const ShoeForm = ({ shoeResult, language, updateResult }) => {
  const {
    product_name,
    reco,
    cloth_type,
    fixed_quantity,
    sizing_chart_sizes,
    sizing_chart_info,
    recommended_size_id,
    size_advices,
    quantity,
    chosen_size_id,
    product_image_url,
    validated
  } = shoeResult;

  let validationTooltipState = 'OK';
  if (!shoeResult.fixed_quantity && isNullOrUndefined(quantity)) {
    validationTooltipState = 'SELECT_QUANTITY';
  }
  const [imagePopoverOpened, setImagePopoverOpened] = useState(false);
  // This is a HACK to support "accessories" (products where we cannot do a recommendation. Ex: hats, gloves, ...).
  // The product is an accessory if ALL the measurements, in its sizing chart, are NOT in the comfort SVGs.
  const isAccessory = !extractMeasurements(sizing_chart_info).some(measurement =>
    MEASUREMENTS_IN_COMFORT_SVGS.includes(measurement)
  );

  let productImage = product_image_url;
  // We don't have shapes for accessories so if there is no product_image_url we don't show any.
  if (!productImage && !isAccessory) {
    productImage = SHAPES_MAPPING[cloth_type];
  }

  const setQuantity = quantity => updateResult({ quantity });
  const setChosenSizeId = chosen_size_id => updateResult({ chosen_size_id });
  const toggleValidated = () => updateResult({ validated: !validated });
  const togglePopover = () => setImagePopoverOpened(prev => !prev);
  return (
    <Container>
      <DesktopContentContainer>
        <Header>
          {productImage && (
            <Popover
              position={Position.TOP_LEFT}
              isOpen={imagePopoverOpened}
              onClose={() => setImagePopoverOpened(false)}
              content={
                <div>
                  <CloseIcon
                    onClick={() => setImagePopoverOpened(false)}
                    color={colors.grey}
                  ></CloseIcon>
                  <ProductImage
                    src={productImage}
                    alt={cloth_type}
                    width="95vw"
                    height="95vw"
                    backgroudColor="white"
                  />
                </div>
              }
            >
              <ImageContainer onClick={togglePopover}>
                <ProductImage src={productImage} alt={cloth_type} />
              </ImageContainer>
            </Popover>
          )}
          <CaptionMedium text={product_name} color="white" />
        </Header>
        <PositionItemInfo>
          {!validated && (
            <SizeAdvice language={language} itemSizeAdvices={size_advices} hasMarginBottom={true} />
          )}
        </PositionItemInfo>
        <Inner>
          <FlexRow>
            <SizeInput
              sizes={sizing_chart_sizes}
              selectedSizeId={chosen_size_id}
              recommendedSizeId={recommended_size_id}
              onChange={setChosenSizeId}
              validated={validated}
            />
            <QuantityInput
              fixed={fixed_quantity}
              quantity={quantity}
              disabled={validated}
              onChange={setQuantity}
            />
          </FlexRow>
          <Validation
            validated={validated}
            validationTooltip={validationTooltipState}
            onToggle={toggleValidated}
          />
          {!reco[0].found && <NoRecoCallout />}
        </Inner>
      </DesktopContentContainer>
    </Container>
  );
};

const languageMapper = state => ({ language: state.settings.language });

export default connect(languageMapper)(ShoeForm);
