import store from 'store';
import { history } from 'navigation';

import { raiseSentryError } from 'services/sentry';
import { localizeStringToLanguage } from 'shared_components';

import UK from 'assets/images/flags/gb.png';
import FR from 'assets/images/flags/fr.png';
import US from 'assets/images/flags/us.png';
import DE from 'assets/images/flags/de.png';
import ES from 'assets/images/flags/es.png';
import IT from 'assets/images/flags/it.png';
import PT from 'assets/images/flags/pt.png';
import EU from 'assets/images/flags/eu.png';
import NL from 'assets/images/flags/nl.png';

const DEFAULT_LOCALE = 'FR';

// A whitespace Unicode character with a height (contrary to the usual whitespace) and no width
// It allows us to preserve the layout when we have nothing to show (key === null or temporarily no info)
// Copied from https://beautifuldingbats.com/punctuation/whitespace/
const ZERO_WIDTH_SPACE = '​';

const languageMatching = {
  FR: 'FR',
  EN: 'EN',
  NL: 'NL'
};

export const languages = Object.keys(languageMatching);

const flagMap = {
  EU: EU,
  US: US,
  GB: UK,
  EN: UK,
  UK: UK,
  FR: FR,
  DE: DE,
  ES: ES,
  IT: IT,
  PT: PT,
  NL: NL
};

function getLocale(language) {
  if (language === undefined) {
    return DEFAULT_LOCALE;
  } else {
    return languageMatching[language];
  }
}

export const getFlag = language => flagMap[language];

export function getLocaleString(language, key, values) {
  const locale = getLocale(language);
  if (!locale) {
    raiseSentryError(`Unknown language ${language}`);
    return 'UNKNOWN LOCALE';
  }
  try {
    return localizeStringToLanguage(locale, key, values);
  } catch (error) {
    raiseSentryError(error);
    return ZERO_WIDTH_SPACE;
  }
}

export const localizedString = (name, values) => {
  const language = store.getState().settings.language;
  return getLocaleString(language, name, values);
};

export function setLanguageFromUrl() {
  // URLSearchParams would require a weighty polyfill
  const language = (history.location.search.match(/[?&]language=(.*?)(?=$|&)/) || [])[1];
  if (language) {
    const languageCode = language.toUpperCase();
    const currentLanguage = store.getState().settings.language;
    if (languages.indexOf(languageCode) > -1 && languageCode !== currentLanguage) {
      store.dispatch({ type: 'CHANGE_LANGUAGE', language: languageCode });
    }
  }
}
