import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Caption, CaptionMedium } from 'shared_components';

import api from 'api';
import { localizedString } from 'services/i18n';
import Async from 'library/Async';

import ItemsList from './ItemsList';
import HelpDialog from './HelpDialog';
import { Pane, BottomRowButtons, ExplanationCallout } from '../common';

const ModalLink = styled(Caption)`
  cursor: pointer;
  background-color: transparent;
  margin-left: 0.2rem;
  font-size: 0.75rem;
  font-style: italic;
  text-decoration: underline;
`;

const InfoExplanation = ({ openSizeDialog, openComfortDialog }) => (
  <ExplanationCallout intent="primary">
    <div style={{ marginBottom: '1rem' }}>
      <CaptionMedium text={localizedString('POSITION_INFO_SIZE_ADVICE') + ' : '} color="mainBlue" />
      <Caption name="POSITION_INFO_TEXT_1" />
      <br />
      <Caption name="TO_KNOW_MORE" />
      <ModalLink as="button" onClick={openSizeDialog}>
        {localizedString('SIZE_ADVICE_HELP')}
      </ModalLink>
    </div>
    <div>
      <CaptionMedium
        text={localizedString('POSITION_INFO_CONFORT_CHOICE') + ' : '}
        color="mainBlue"
      />
      <Caption name="POSITION_INFO_TEXT_2" />
      <br />
      <Caption name="TO_KNOW_MORE" />
      <ModalLink as="button" onClick={openComfortDialog}>
        {localizedString('CONFORT_CHOICE_HELP')}
      </ModalLink>
    </div>
  </ExplanationCallout>
);

const Info = ({ submitPosition, updatePosition, maleItems, femaleItems }) => {
  const location = useLocation();
  const canOnlyEditInfo = location.state && location.state.onlyEditInfo;
  const [isSizeDialogOpen, setIsSizeDialogOpen] = useState(false);
  const [isComfortDialogOpen, setIsComfortDialogOpen] = useState(false);

  const fetchProducts = async () => await api.products.list();

  // onInputChange("s", 2, size_advice_fr)
  const onInputChange = gender => (value, index, input) => {
    const newItems = {
      maleItems: [...maleItems],
      femaleItems: [...femaleItems]
    };
    newItems[`${gender}Items`][index][input] = value;
    updatePosition(newItems);
  };

  const openSizeDialog = () => setIsSizeDialogOpen(true);
  const openComfortDialog = () => setIsComfortDialogOpen(true);

  return (
    <>
      <InfoExplanation openSizeDialog={openSizeDialog} openComfortDialog={openComfortDialog} />
      <Async fetchProps={fetchProducts}>
        {products => (
          <>
            {femaleItems.length > 0 && (
              <Pane title="FEMALE">
                <ItemsList
                  items={femaleItems}
                  onInputChange={onInputChange('female')}
                  products={products}
                  openSizeDialog={openSizeDialog}
                  openComfortDialog={openComfortDialog}
                />
              </Pane>
            )}
            {maleItems.length > 0 && (
              <Pane title="MALE">
                <ItemsList
                  items={maleItems}
                  onInputChange={onInputChange('male')}
                  products={products}
                  openSizeDialog={openSizeDialog}
                  openComfortDialog={openComfortDialog}
                />
              </Pane>
            )}
            <BottomRowButtons backDisabled={canOnlyEditInfo} submit={submitPosition} />
          </>
        )}
      </Async>
      <HelpDialog
        about="SIZE_ADVICE"
        isOpen={isSizeDialogOpen}
        onClose={() => setIsSizeDialogOpen(false)}
      />
      <HelpDialog
        about="COMFORT"
        isOpen={isComfortDialogOpen}
        onClose={() => setIsComfortDialogOpen(false)}
      />
    </>
  );
};

const mapper = state => ({
  femaleItems: state.position.femaleItems,
  maleItems: state.position.maleItems
});

const dispatcher = dispatch => ({
  updatePosition: items => dispatch({ type: 'UPDATE_POSITION', ...items })
});

export default connect(mapper, dispatcher)(Info);
