import React from 'react';
import { media } from 'shared_components';
import styled from 'styled-components';
import { BodyShapeFemaleFirstStep, BodyShapeFemaleSecondStep } from 'shared_components';

export const FitleComponentContainer = styled.div`
  height: 25rem;
  width: 20rem;
  margin-bottom: 2rem;
  ${media.phone`
    width: 90%;
    height:50vh;
    min-height:15rem;
    margin-bottom: 0;
  `}
  ${media.phoneSmall`
    width: 90%;
    height:50vh;
    min-height:15rem;
    margin-bottom: 0;
  `}
  ${media.phoneMedium`
    margin: 0rem 0rem 0rem 0rem;
    width: 90%;
  `}
  ${media.phoneLarge`
    margin: 0rem 0rem 0rem 0rem;
    width: 90%;
  `}
`;

const InputBodyShapeFemale = ({
  theme,
  isFirstStep,
  selectedFirstStepChoice,
  selectFirstStepChoice,
  selectShape,
  selectedShape
}) => {
  return (
    <FitleComponentContainer>
      {isFirstStep ? (
        <BodyShapeFemaleFirstStep
          theme={theme}
          selectShape={selectFirstStepChoice}
          selectedShape={selectedFirstStepChoice}
        />
      ) : (
        <BodyShapeFemaleSecondStep
          theme={theme}
          userBodyShape={selectedShape}
          submitBodyShape={selectShape}
          firstStepChoice={selectedFirstStepChoice}
        />
      )}
    </FitleComponentContainer>
  );
};

export default InputBodyShapeFemale;
