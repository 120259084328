import React from 'react';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import Trash from 'react-icons/lib/io/trash-b';
import MdCreate from 'react-icons/lib/md/create';
import MdAddCircle from 'react-icons/lib/md/add-circle';

import { SLIDE_LEFT_TOTAL_MS, SLIDE_FADE_TOTAL_MS } from './Animations';
import { Wrapper, Header, EditButton, ITEMS_HEIGHT, LogoContainer, Logo } from './common';
import { Caption } from '../text';
import { localizedString, capitalize } from '../services/i18n/translations';
import { colors } from 'shared_components/styles';

const ReferenceContainer = styled.div`
  height: ${ITEMS_HEIGHT};
  display: flex;
  align-items: center;
  padding: 0.5em;
  width: 100%;
  border: 1px solid ${colors.coal};
  border-radius: 5px;
  margin-bottom: 1em;
  background-color: ${colors.white};
`;

const BrandName = styled(Caption)`
  max-width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DeleteButton = styled(EditButton)`
  margin-left: 0.5em;
  :hover {
    color: ${colors.red};
  }
`;

const FlexedCaption = styled(Caption)`
  flex: 1;
  vertical-align: bottom;
`;

const StyledAddCircle = styled(MdAddCircle)`
  margin: 0 0.5rem;
  vertical-align: bottom;
`;

const AddNewReference = styled.button`
  display: inline-block;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: ${ITEMS_HEIGHT};
  border-radius: 0.25rem;
  transition: box-shadow 450ms cubic-bezier(0.23, 1, 0.32, 1);
  cursor: pointer;

  &:hover,
  &:active {
    box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.1);
  }

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primaryColor};
  }

  ${props =>
    props.disabled &&
    css`
      background-color: rgba(0, 0, 0, 0.2);
      color: white;
      cursor: not-allowed;
    `};
`;

const SelectedReferences = ({
  selectedReferences,
  editReference,
  deleteReference,
  dispatch,
  maxReferencesAllowed,
  skipSection
}) => (
  <>
    <Header text="THANKS_FOR_CLARIFICATION" />
    <Wrapper>
      <CSSTransition
        classNames="reference-slide-left"
        timeout={SLIDE_LEFT_TOTAL_MS}
        in={true}
        appear={true}
        unmountOnExit={true}
      >
        <div>
          {selectedReferences.map(reference => {
            const text = localizedString('SELECTED_REFERENCE_SUBTITLE', {
              category: capitalize(localizedString(reference.category.translation_key)),
              size: reference.size_name
            });
            return (
              <ReferenceContainer key={reference.id + reference.category.slug}>
                {reference.brand_logo_url && reference.brand_logo_url.length > 0 && (
                  <LogoContainer>
                    <Logo src={reference.brand_logo_url} alt={reference.name} />
                  </LogoContainer>
                )}
                <BrandName text={reference.name} color="coal" />
                <skipSection />
                <Caption text={text} color="grey" style={{ flex: 1, marginLeft: '0.25rem' }} />
                <EditButton
                  type="button"
                  aria-label={`edit reference : ${reference.name} ${text}`}
                  onClick={() => editReference(reference)}
                >
                  <MdCreate />
                </EditButton>
                <DeleteButton
                  type="button"
                  aria-label={`delete reference : ${reference.name} ${text}`}
                  onClick={() => deleteReference(reference)}
                >
                  <Trash />
                </DeleteButton>
              </ReferenceContainer>
            );
          })}
        </div>
      </CSSTransition>
      {selectedReferences.length < maxReferencesAllowed && (
        <CSSTransition
          classNames="reference-fade-in"
          timeout={SLIDE_FADE_TOTAL_MS}
          in={true}
          appear={true}
          unmountOnExit={true}
        >
          <div>
            <AddNewReference
              aria-label="select new reference"
              onClick={() => dispatch({ type: 'SHOW_BRANDS' })}
            >
              <StyledAddCircle color={colors.coal} size={14} />
              <FlexedCaption
                color="coal"
                name={selectedReferences.length ? 'SELECT_ANOTHER_REFERENCE' : 'SELECT_REFERENCE'}
              />
            </AddNewReference>
          </div>
        </CSSTransition>
      )}
    </Wrapper>
  </>
);

export default SelectedReferences;
