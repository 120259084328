import axios from 'axios';
import { matchPath } from 'react-router';
import store from 'store';
import { logout } from 'navigation';

export const API_URL = process.env.REACT_APP_API_URL || 'https://preprod.fitle.com';

const api = axios.create({
  baseURL: API_URL
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (error.response.status === 401 && process.env.REACT_APP_DEV !== '1') {
        logout();
      }
    }
    return Promise.reject(error);
  }
);

export const get = (url, params = {}, requiresAuth = false, withCredentials = false) => {
  return api.get(url, { params, requiresAuth, withCredentials });
};

export const post = (url, data = {}, requiresAuth = false, withCredentials = false) => {
  return api.post(url, data, { requiresAuth, withCredentials });
};

export const patch = (url, data = {}, requiresAuth = false, withCredentials = false) => {
  return api.patch(url, data, { requiresAuth, withCredentials });
};

export const put = (url, data = {}, requiresAuth = false, withCredentials = false) => {
  return api.put(url, data, { requiresAuth, withCredentials });
};

export const _delete = (
  url,
  params = {},
  requiresAuth = false,
  withCredentials = false,
  data = {}
) => {
  return api.delete(url, { params, requiresAuth, withCredentials, data });
};

// fixme: for simplicity reasons, we still send the token as a query parameter
// for downloads (cannot add an Authorization header). A better way would be
// to ask the API for a short-lived download link.
export const download = (path, params, requiresAuth = false) => {
  if (requiresAuth) params = { ...params, token: getToken() };
  const queryString = Object.entries(params)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');
  const url = `${API_URL}${path}?${queryString}`;
  document.getElementById('downloader-frame').src = url;
  return Promise.resolve();
};

const getToken = () => {
  const match = matchPath(window.location.pathname, {
    path: '/clothes_attribution/:token',
    exact: false,
    strict: false
  });
  const campaignEmployeeToken = match && match.params && match.params.token;
  if (campaignEmployeeToken) return campaignEmployeeToken;
  const operatorToken = store.getState().auth.token;
  if (operatorToken) return operatorToken;
  throw new Error('No token');
};

const authenticate = config => {
  try {
    config.headers.common['Authorization'] = `Bearer ${getToken()}`;
    return config;
  } catch (e) {
    console.warn('Error getting token', e);
    return config;
  }
};

api.interceptors.request.use(
  config => {
    if (config.requiresAuth) return authenticate(config);
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  ({ data }) => {
    return Promise.resolve(data);
  },
  error => {
    return Promise.reject(error.response);
  }
);
