import { css } from 'styled-components/macro';

const emSize = 16;
const smallMaxWidth = 420;
const screenMediumMaxWidth = 730;
const verySmallMaxHeight = 520;
const smallMaxHeight = 660;
const MediumMaxHeight = 780;
const media = {
  bigScreen: (...args) => css`
    @media screen and (min-width: ${(smallMaxWidth + 1) / emSize}em) {
      ${css(...args)};
    }
  `,
  screenLarge: (...args) => css`
    @media screen and (min-width: ${screenMediumMaxWidth / emSize}em) {
      ${css(...args)};
    }
  `,
  screenMedium: (...args) => css`
    @media screen and (max-width: ${screenMediumMaxWidth / emSize}em) {
      ${css(...args)};
    }
  `,
  phone: (...args) => css`
    @media screen and (max-width: ${smallMaxWidth / emSize}em) {
      ${css(...args)};
    }
  `,
  phoneVerySmall: (...args) => css`
    @media screen and (max-width: ${smallMaxWidth /
      emSize}em) and (max-height: ${verySmallMaxHeight / emSize}em) {
      ${css(...args)};
    }
  `,
  phoneSmall: (...args) => css`
    @media screen and (max-width: ${smallMaxWidth /
      emSize}em) and (min-height: ${verySmallMaxHeight /
      emSize}em) and (max-height: ${smallMaxHeight / emSize}em) {
      ${css(...args)};
    }
  `,
  phoneMedium: (...args) => css`
    @media screen and (max-width: ${smallMaxWidth / emSize}em) and (min-height: ${smallMaxHeight /
      emSize}em) and (max-height: ${MediumMaxHeight / emSize}em) {
      ${css(...args)};
    }
  `,
  phoneLarge: (...args) => css`
    @media screen and (max-width: ${smallMaxWidth / emSize}em) and (min-height: ${MediumMaxHeight /
      emSize}em) {
      ${css(...args)};
    }
  `
};

export const isPhoneSmall = () => {
  return window.innerWidth < smallMaxWidth && window.innerHeight < smallMaxWidth;
};

export const isPhoneMedium = () => {
  return (
    window.innerWidth < smallMaxWidth &&
    window.innerHeight > smallMaxWidth &&
    window.innerHeight < MediumMaxHeight
  );
};

export default media;
