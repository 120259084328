import React from 'react';
import styled from 'styled-components/macro';
import { Intent } from '@blueprintjs/core';
import { colors, Title, Body, SmallText, Caption, CaptionMedium } from 'shared_components';

import api from 'api';
import Callout from 'library/Callout';
import MediaUpload from 'library/MediaUpload';
import { localizedString } from 'services/i18n';
import { showToast, AppToaster } from 'services/toaster';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ExplanationContainer = styled.div`
  margin: 1rem 0;
`;

const ExampleTable = styled.table`
  margin-bottom: 1rem;
  th,
  td {
    padding: 0.5rem;
    border: 1px solid ${colors.grey};
  }
  th {
    background-color: ${colors.lightBlueGrey};
    vertical-align: middle;
  }
`;

const getErrorMessage = errorResponse => {
  // Some errors (500) dont send responses
  const code = errorResponse?.data?.payload?.code;
  switch (code) {
    case 0:
      return { name: 'NO_FILE_PROVIDED' };
    case 1:
      return { name: 'INVALID_FILE_EXTENSION' };
    case 2:
      return { name: 'MISSING_COLUMNS' };
    case 3: {
      const id = errorResponse.data.payload.conflicting_identifier;
      return { name: 'EMPLOYEE_ID_CONFLICT_WITH_ID', values: { id } };
    }
    case 4:
      return { name: 'EMPTY_EMPLOYEES_LIST' };
    case 5: {
      const incorrectPositions = errorResponse.data.payload.incorrect_positions;
      return {
        name: 'WRONG_POSITION_ID',
        values: { incorrectPositions: incorrectPositions.join(', ') }
      };
    }
    case 6: {
      const invalidEmails = errorResponse.data.payload.invalid_emails;
      return {
        name: 'IMPORT_INVALID_EMAILS',
        values: { invalidEmails: invalidEmails.join(', ') }
      };
    }
    default:
      return { name: 'TRY_AGAIN_LATER' };
  }
};

const handleError = errorResponse => {
  const error = getErrorMessage(errorResponse);
  showToast({
    message: (
      <div>
        <Body name="CANNOT_UPLOAD_EMPLOYEES" color="white" />
        <p>
          <SmallText {...error} color="white" />
        </p>
      </div>
    ),
    intent: Intent.DANGER,
    timeout: 0
  });
};

const EmployeesImportForm = ({
  onFileSelected,
  onSuccess,
  companyId = null,
  campaignId = null
}) => {
  const handleFile = async formData => {
    onFileSelected();
    const key = showToast({
      message: <Body name="UPLOADING_EMPLOYEES" />,
      intent: Intent.PRIMARY,
      timeout: 0
    });
    try {
      if (campaignId) {
        await api.campaignEmployees.uploadCSV({
          campaign_id: campaignId,
          formData
        });
      }
      if (companyId) {
        await api.employees.uploadCSV({
          company_id: companyId,
          formData
        });
      }
      showToast({
        message: <Body name="EMPLOYEES_UPLOAD_SUCCESS" />,
        intent: Intent.SUCCESS
      });
      onSuccess();
    } catch (e) {
      handleError(e);
    } finally {
      AppToaster.dismiss(key);
    }
  };

  const mandatoryColumns = campaignId ? 3 : 2;

  return (
    <Container>
      <Title name="EMPLOYEES_IMPORT" style={{ marginBottom: 20 }} />
      <Container>
        <Container style={{ width: 500 }}>
          <Caption name="EMPLOYEES_IMPORT_EXPLANATION" style={{ marginBottom: 10 }} />
          <Callout intent="primary" style={{ marginBottom: 10 }}>
            <ExplanationContainer>
              <Caption name="CSV_EXPLANATION_INTRO" values={{ mandatoryColumns }} />
              <span
                role="img"
                aria-label="warning-emoji"
                style={{ marginRight: '0.625rem', marginLeft: '0.5rem' }}
              >
                ⚠️
              </span>
              <Caption name="CSV_EXPLANATION_WARNING" />
            </ExplanationContainer>
            <ExampleTable>
              <thead>
                <tr>
                  <th>
                    <CaptionMedium name="CSV_HEADER_EXAMPLE" />
                  </th>
                  <th>
                    <CaptionMedium text="first_name" />
                  </th>
                  <th>
                    <CaptionMedium text="last_name" />
                  </th>
                  {campaignId && (
                    <th>
                      <CaptionMedium text={`position_id`} />
                    </th>
                  )}
                  <th>
                    <CaptionMedium text={`identifier (${localizedString('OPTIONAL')})`} />
                  </th>
                  <th>
                    <CaptionMedium text={`email (${localizedString('OPTIONAL')})`} />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Caption name="CONTENT" />
                  </td>
                  <td>
                    <Caption name="CSV_TABLE_FIRST_NAME" />
                  </td>
                  <td>
                    <Caption name="CSV_TABLE_LAST_NAME" />
                  </td>
                  {campaignId && (
                    <td>
                      <Caption name="CSV_TABLE_POSITION" />
                    </td>
                  )}
                  <td>
                    <Caption name="CSV_TABLE_IDENTIFIER" />
                  </td>
                  <td>
                    <Caption name="CSV_TABLE_EMAIL" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Caption name="EXAMPLE" />
                  </td>
                  <td>
                    <Caption text="ex : Paul" />
                  </td>
                  <td>
                    <Caption text="ex : Dupont" />
                  </td>
                  {campaignId && (
                    <td>
                      <Caption text="ex : CAPPATISSERIE_LV1" />
                    </td>
                  )}
                  <td>
                    <Caption text="ex : 1231YYKNJ8912R" />
                  </td>
                  <td>
                    <Caption text="ex : paul.dupont@company.com" />
                  </td>
                </tr>
              </tbody>
            </ExampleTable>
          </Callout>
          <MediaUpload handleFile={handleFile} />
        </Container>
      </Container>
    </Container>
  );
};

export default EmployeesImportForm;
