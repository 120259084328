import styled from 'styled-components/macro';
import { media, Caption, SmallText } from 'shared_components';

export const Container = styled.div`
  width: 31.25rem;
  flex: 0 1 60vh;
  display: flex;
  flex-direction: column;

  ${media.phone`
    width: 100%;
  `}
  ${media.phoneVerySmall`
    width:100%
    margin-top: 0em;
  `}
  ${media.phoneSmall`
    width:100%
    margin-top: 0em;
  `}
  ${media.phoneMedium`
    width:100%
    margin-top: 0em;
  `}
`;

export const ReferenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin-top: 0em;
  background-color: white;

  ${media.phone`
    order: 3;
    margin-top: 0em;
  `}
`;

export const ReferencesExplanation = styled(Caption)`
  margin: 0 2em;
  line-height: 1.5;
  text-align: center;

  ${media.phone`
    order: 1;
  `}
  ${media.phoneLarge`
    border: solid orange ;
    width: 80%;
    font-size: 1.12rem;
  `}
`;

export const ReferenceContent = styled.div`
  max-height: 500px;
  height: 65vh;
  display: flex;
  flex: 0 1 50vh;
  flex-direction: column;
  padding: 1em;
  background-color: white;
  ${media.phone`
    flex: 0 1 70vh;
    margin: 0rem 0.5rem;
    padding: 0em;
  `}
  ${media.phoneVerySmall`
    flex: 0 1 50vh;
    margin: 0rem 0.5rem;
    padding: 0em;
  `}
  ${media.phoneSmall`
    flex: 0 1 50vh;
    margin: 0rem 0.5rem;
    padding: 0em;
  `}
  ${media.phoneMedium`
    flex: 0 1 70vh;
    margin: 0rem 0.5rem;
    padding: 0em;
  `}
  ${media.phoneLarge`
    flex: 0 1 70vh;
    margin: 0rem 0.5rem;
    padding: 0em;
  `}
`;

export const SkipReferencesContainer = styled.div`
  visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;

  ${props =>
    props.hidden &&
    media.phone`
    display: none;
  `}

  ${media.phone`
    margin-top: 1rem;
    margin-bottom: 0.4em;
  `}
  ${media.phoneVerySmall`
    margin-top: 2rem;
    margin-bottom: 0.4em;
  `}
  ${media.phoneSmall`
    margin-top: 2rem;
    margin-bottom: 0.4em;
  `}
  ${media.phoneLarge`
    margin-top: 0em;
    margin-bottom: 2em;
  `}
`;

export const SkipButton = styled.button`
  background-color: white;
  display: flex;
  align-items: center;

  ${media.phone`
    margin-top: 0.25em;
  `}
`;

export const SkipReferencesLink = styled(SmallText)`
  text-decoration: underline;
  margin: 0 0.5em;
  font-size: 12px;
`;

export const SkipReferencesText = styled(SmallText)`
  font-size: 12px;
  margin: 0 0.5em;
  text-align: center;
`;
