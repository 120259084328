import React from 'react';
import { Form as FormikForm, Field as FormikField } from 'formik';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Caption, Body } from 'shared_components';
import { Tooltip, NumericInput, Classes } from '@blueprintjs/core';
import { media } from 'shared_components';

import { colors } from 'shared_components';
import Select from 'library/forms/select';
import { isNullOrUndefined, normalize } from 'services/utils';

const StyledForm = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
`;

const Label = styled.label`
  margin-right: 1rem;
`;

const StyledField = styled(FormikField)`
  &:focus {
    outline: 0;
  }
  margin-left: 15px;
`;

const BLUEPRINT_FIELDS = ['text', 'textarea', 'number', 'email', 'password'];

export class Field extends React.Component {
  static contextTypes = {
    errors: PropTypes.object,
    touched: PropTypes.object
  };
  render() {
    const {
      name,
      label,
      required,
      containerStyle,
      labelValues,
      labelStyle,
      children,
      position = 'right',
      usePortal = true,
      ...rest
    } = this.props;
    const propsToPass = { name, label, required, ...rest };
    const { errors, touched } = this.context;
    const showError = !!(touched && touched[name] && errors && errors[name]);
    return (
      <FieldContainer style={containerStyle}>
        {label && (
          <Label>
            <Caption name={label} values={labelValues} style={labelStyle} />
            {required && <Body color={colors.blue} text="*" style={{ marginLeft: 4 }} />}
          </Label>
        )}
        <Tooltip
          usePortal={usePortal}
          isOpen={showError}
          intent="danger"
          position={position}
          // When the error disappears, the tooltip stays in the DOM for a short time
          // with the undefined key.
          // So, we use the null key instead to avoid sending an error to Sentry
          content={<Caption name={(errors && errors[name]) || null} color="white" />}
        >
          <StyledField
            className={BLUEPRINT_FIELDS.includes(this.props.type) && Classes.INPUT}
            {...propsToPass}
          />
        </Tooltip>
        {children}
      </FieldContainer>
    );
  }
}

export class CheckBox extends React.Component {
  static contextTypes = {
    values: PropTypes.object
  };
  render() {
    const values = this.context.values;
    if (!values) {
      throw new Error('Must pass the values object to the form when using checkboxes');
    }
    return <Field type="checkbox" checked={values[this.props.name]} {...this.props} />;
  }
}

export class NumericField extends React.Component {
  static contextTypes = {
    errors: PropTypes.object,
    touched: PropTypes.object
  };
  render() {
    const { name, label, required, onBlur, position = 'right' } = this.props;
    const { errors, touched } = this.context;
    const showError = !!(touched && touched[name] && errors && errors[name]);
    return (
      <FieldContainer onBlur={onBlur}>
        <Label>
          <Caption name={label} />
          {required && <Body color={colors.blue} text="*" style={{ marginLeft: 4 }} />}
        </Label>
        <Tooltip
          isOpen={showError}
          intent="danger"
          position={position}
          content={<Caption name={(errors && errors[name]) || null} color="white" />}
        >
          <NumericInput name={name} min={0} {...this.props} />
        </Tooltip>
      </FieldContainer>
    );
  }
}

export class SelectField extends React.Component {
  static contextTypes = {
    errors: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func
  };
  render() {
    const {
      name,
      label,
      required,
      items,
      valueKey,
      labelKey,
      placeholderName,
      placeholderText,
      usePortal,
      position = 'right'
    } = this.props;
    const { errors, touched, values, setFieldTouched, setFieldValue } = this.context;
    const renderItem = item => {
      if (!isNullOrUndefined(item)) return <Caption text={item[labelKey]} />;
      if (!isNullOrUndefined(placeholderName)) return <Caption name={placeholderName} />;
      if (!isNullOrUndefined(placeholderText)) return <Caption name={placeholderText} />;
      return '';
    };
    const showError = !!(touched && touched[name] && errors && errors[name]);
    return (
      <FieldContainer>
        <Label>
          <Caption name={label} />
          {required && <Body color={colors.blue} text="*" style={{ marginLeft: 4 }} />}
        </Label>
        <Tooltip
          isOpen={showError}
          intent="danger"
          position={position}
          content={<Caption name={(errors && errors[name]) || null} color="white" />}
        >
          <Select
            items={items}
            value={items.find(i => i[valueKey] === values[name])}
            onChange={item => setFieldValue(name, item[valueKey])}
            renderItem={renderItem}
            onClose={() => setFieldTouched(name, true)}
            usePortal={usePortal || false}
            itemPredicate={(query, item) =>
              normalize(item[labelKey]).indexOf(normalize(query)) >= 0
            }
          />
        </Tooltip>
      </FieldContainer>
    );
  }
}

export class Form extends React.Component {
  static childContextTypes = {
    errors: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func
  };
  getChildContext() {
    const { errors, touched, values, setFieldTouched, setFieldValue } = this.props;
    return { errors, touched, values, setFieldTouched, setFieldValue };
  }
  render() {
    const { setFieldTouched, setFieldValue, ...rest } = this.props;
    return <StyledForm {...rest} />;
  }
}

const IntegerInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px;
  background-color: ${colors.lightBlue};
  position: relative;
  margin-left: 10px;
  padding: 5px 10px;
  height: 2.5em;

  &:before {
    content: '';
    position: absolute;
    left: -5px;
    top: 50%;
    margin-top: -5px;
    height: 10px;
    width: 10px;
    background-color: ${colors.lightBlue};
    transform: rotate(45deg);
  }
`;

const IconCircleContainer = styled.div`
  cursor: pointer;
  display: inline-block;
  padding: 0.625rem;
  ${media.phone`
    padding: 0rem;
  `}
`;

const Symbol = styled.span`
  ${media.phone`
    font-size: 1.2rem;
  `}
`;
const IconCircle = styled.button`
  cursor: pointer;
  vertical-align: bottom;
  line-height: 1.125rem;
  height: 1.125em;
  width: 1.125em;
  border-radius: 50%;
  color: white;
  background-color: ${props => (props.disabled ? colors.grey : colors.blue)};
  /* hidden seems to be a reserved key word */
  visibility: ${props => (props._hidden ? 'hidden' : 'visible')};
`;

export class IntegerInput extends React.Component {
  min = this.props.min === undefined ? -Infinity : this.props.min;
  max = this.props.max === undefined ? Infinity : this.props.max;
  state = {
    valueAsNumber: isNullOrUndefined(this.props.initValue) ? null : Number(this.props.initValue),
    valueAsString: isNullOrUndefined(this.props.initValue) ? '' : String(this.props.initValue)
  };

  clamp = v => {
    if (isNullOrUndefined(v)) return v;
    return Math.min(this.max, Math.max(this.min, v));
  };

  minus = () => {
    const { valueAsNumber } = this.state;
    if (valueAsNumber >= this.min + 1) {
      this.handleValueChange(valueAsNumber - 1, String(valueAsNumber - 1));
    }
  };

  plus = () => {
    const { valueAsNumber } = this.state;
    if (valueAsNumber <= this.max - 1)
      this.handleValueChange(valueAsNumber + 1, String(valueAsNumber + 1));
  };

  handleValueChange = (valueAsNumber, valueAsString) => {
    if (valueAsString === '') valueAsNumber = null;
    this.setState({ valueAsNumber, valueAsString });
    this.props.onValueChange(valueAsNumber);
  };

  render() {
    const { className, disabled } = this.props;
    const { valueAsString, valueAsNumber } = this.state;
    return (
      <IntegerInputContainer className={className}>
        <IconCircleContainer onClick={this.minus}>
          <IconCircle
            type="button"
            onClick={this.minus}
            _hidden={disabled}
            disabled={valueAsNumber < this.min + 1}
          >
            <Symbol> - </Symbol>
          </IconCircle>
        </IconCircleContainer>
        <NumericInput
          style={{
            textAlign: 'center',
            backgroundColor: disabled ? colors.lightBlue : 'white',
            width: '1.8rem',
            height: '1.4rem',
            marginLeft: '5px',
            marginRight: '5px'
          }}
          disabled={disabled}
          min={this.min}
          max={this.max}
          buttonPosition="none"
          value={valueAsString}
          onValueChange={this.handleValueChange}
          clampValueOnBlur
        />
        <IconCircleContainer onClick={this.plus}>
          <IconCircle
            type="button"
            onClick={this.plus}
            _hidden={disabled}
            disabled={valueAsNumber > this.max - 1}
          >
            <Symbol> + </Symbol>
          </IconCircle>
        </IconCircleContainer>
      </IntegerInputContainer>
    );
  }
}
