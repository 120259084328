import React from 'react';
import styled from 'styled-components/macro';
import { Body, Caption, Title, CaptionMedium } from 'shared_components';
import { Tooltip } from '@blueprintjs/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ColoredButton } from 'library/buttons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InvitationLink = styled(Body)`
  width: 300px;
  word-wrap: break-word;
  display: inline-block;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

class OperatorDetails extends React.Component {
  state = {
    showCopiedTooltip: false
  };
  onCopy = () => {
    this.setState({ showCopiedTooltip: true }, () => {
      setTimeout(() => this.setState({ showCopiedTooltip: false }), 1000);
    });
  };
  render() {
    const { email, first_name, last_name, registered, is_admin, invitation_token } = this.props;
    const invitation_link = `${window.origin}/signup/${invitation_token}`;

    return (
      <Container>
        <div>
          <Title text={first_name} style={{ marginRight: 4 }} />
          <Title text={last_name} />
        </div>
        <Body text={email} />
        {is_admin && <Caption name="ADMIN" />}

        {!registered && (
          <Container style={{ marginTop: 15 }}>
            <Row>
              <CaptionMedium name="INVITATION_LINK" />
              <Tooltip
                isOpen={this.state.showCopiedTooltip}
                content={<Caption name="COPIED" color="white" />}
              >
                <CopyToClipboard text={invitation_link} onCopy={this.onCopy}>
                  <ColoredButton style={{ marginLeft: 10, lineHeight: '1rem' }}>
                    <Caption name="COPY_TO_CLIPBOARD" color="white" />
                  </ColoredButton>
                </CopyToClipboard>
              </Tooltip>
            </Row>
            <div style={{ marginTop: 10 }}>
              <InvitationLink text={invitation_link} />
            </div>
          </Container>
        )}
      </Container>
    );
  }
}

export default OperatorDetails;
