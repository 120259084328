import React from 'react';
import ReactDOM from 'react-dom';
import { Body } from 'shared_components';
import { Intent } from '@blueprintjs/core';

import App from './App';
import { register } from './serviceWorkerRegistration';
import { initializeSentry } from 'services/sentry';
import { showToast } from 'services/toaster';
import { CookiesProvider } from "react-cookie";

import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import 'styles/blueprint.css'; // see comment on top of blueprint.css for explanation about this file

initializeSentry();

ReactDOM.render( 
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  document.getElementById('root'));
register({
  onUpdate: () =>
    showToast({
      message: <Body name="UPDATE_AVAILABLE" />,
      intent: Intent.PRIMARY
    })
});
