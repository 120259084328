import React from 'react';
import { Body } from 'shared_components';

import api from 'api';
import AuthContainer from './AuthContainer';
import ForgotPasswordResetForm from './ForgotPasswordResetForm';
import { authenticate } from 'services/auth';

class ForgotPasswordReset extends React.Component {
  state = {
    done: false
  };

  getToken = () => {
    const path = this.props.location.pathname;
    return path.split('/forgot_password_reset/')[1];
  };
  submitForm = async ({ new_password }) => {
    const auth_info = await api.operators.forgotPasswordReset({
      new_password,
      token: this.getToken()
    });
    authenticate(auth_info);
    this.props.history.replace('/');
  };
  render() {
    return (
      <AuthContainer title="RESET_PASSWORD">
        <Body name="RESET_PASSWORD_EXPLANATION" />
        <div style={{ marginTop: 20 }}>
          <ForgotPasswordResetForm onSubmit={this.submitForm} />
        </div>
      </AuthContainer>
    );
  }
}

export default ForgotPasswordReset;
