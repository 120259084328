import React from 'react';
import styled from 'styled-components/macro';
import { Caption, Body } from 'shared_components';
import { Dialog as BpDialog, Overlay, Classes } from '@blueprintjs/core';

import { media } from 'shared_components';
import { ColoredButton, SubmitButton, CancelButton, ButtonRow } from 'library/buttons';

const DialogContainer = styled(BpDialog)`
  max-width: ${props => props.width || '50rem'};
  && {
    background-color: white;
    width: ${props => props.width || 'fit-content'};

    ${media.phone`
      width:100%;
      margin: 10px;
      padding: 0;
    `}
  }
`;

const DialogBody = styled.div`
  padding: 50px;
  padding-bottom: 20px;

  ${media.phone`
    padding: 20px 10px;
  `}
`;

export const Dialog = ({ children, ...rest }) => (
  <DialogContainer isOpen usePortal {...rest}>
    <DialogBody>{children}</DialogBody>
  </DialogContainer>
);

const DialogContent = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`;

export const NotificationDialog = ({ onClose, children, ...rest }) => (
  <Dialog onClose={onClose} {...rest}>
    <DialogContent>{children}</DialogContent>
    <ColoredButton style={{ width: '100%' }} onClick={onClose}>
      <Caption name="OK" color="white" s />
    </ColoredButton>
  </Dialog>
);

const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    margin-bottom: 10px;
  }
`;

export class CancellableDialog extends React.Component {
  state = {
    confirmationString: ''
  };
  disabled = () => {
    const { disabled, confirmationString } = this.props;
    return disabled || (confirmationString && confirmationString !== this.state.confirmationString);
  };
  render = () => {
    const {
      onClose,
      onSubmit,
      children,
      cancelOnRight,
      danger,
      disabled,
      confirmationString,
      ...rest
    } = this.props;
    const cancel = <CancelButton onClick={onClose} />;
    const submit = <SubmitButton onClick={onSubmit} danger={danger} disabled={this.disabled()} />;
    const shouldSwitch = cancelOnRight || danger;
    const left = shouldSwitch ? submit : cancel;
    const right = shouldSwitch ? cancel : submit;
    return (
      <Dialog onClose={onClose} {...rest}>
        <DialogContent>{children}</DialogContent>
        {confirmationString && (
          <ConfirmationContainer>
            <div>
              <Body name="TYPE_TO_CONFIRM" />
            </div>
            <div>
              <Body text={confirmationString} style={{ fontWeight: '600' }} color="darkRed" />
            </div>
            <input
              className={Classes.INPUT}
              type="text"
              value={this.state.confirmationString}
              onChange={e => this.setState({ confirmationString: e.target.value })}
            />
          </ConfirmationContainer>
        )}
        <ButtonRow style={{ marginTop: 20 }}>
          {left}
          {right}
        </ButtonRow>
      </Dialog>
    );
  };
}

// the bp3-overlay-backdrop covers the scrollbar, "pointer-events: none" let clicks throught the div
// and the user can use the scrollbar
const StyledOverlay = styled(Overlay)`
  .bp3-overlay-backdrop {
    pointer-events: none;
  }
`;

const FullScreenStyled = styled.div`
  min-height: 100%;
  width: 100%;
  border-radius: none;
  background-color: white;
`;

export const FullScreenDialog = props => {
  const { className, children, ...rest } = props;
  // bp3-overlay-scroll-container adds the scrollbar
  return (
    <StyledOverlay
      usePortal
      isOpen
      className={className + ' bp3-overlay-scroll-container'}
      {...rest}
    >
      <FullScreenStyled>{children}</FullScreenStyled>
    </StyledOverlay>
  );
};
