import React, { useState } from 'react';

import { Intent } from '@blueprintjs/core';
import { SmallText, Caption } from 'shared_components';
import { showToast } from 'services/toaster';
import { ArrowForward } from 'library/icons';
import { isNullOrUndefined } from 'services/utils';
import DefaultStepReferences from './DefaultStepReferences';
import ShoesStepReferences from './ShoesStepReferences';
import { colors } from 'shared_components';
import { referencesTheme } from 'styles/themes';
import { BackButton } from './utils';

import {
  LightPage,
  Header,
  BottomButtonsRow,
  BottomTooltip,
  ColoredAnchorButton
} from '../common/styles';
import {
  SkipReferencesContainer,
  SkipReferencesLink,
  SkipReferencesText,
  SkipButton,
} from './styles';
import api from 'api';




const getTitleTranslationKey = (details, isFirstStep) => {
  if (details.client_id !== 545 && !details.has_shoes_female && !details.has_shoes_male) {
    return 'BRANDS_YOU_ALREADY_WEAR';
  }
  else if (details.client_id === 545 && !details.has_shoes_female && !details.has_shoes_male) {
    return 'BRANDS_YOU_ALREADY_WEAR';
  }
  else if (isFirstStep) {
    return 'SHOES_BRANDS_YOU_ALREADY_WEAR';
  }
  else{
    return 'CLOTHES_BRANDS_YOU_ALREADY_WEAR';
  }
};


const ReferencesScreen= ({ details, match, language, history, location }) => {
  const token = match.params.campaign_employee_token;
  const campaign_employee_id = details.id;
  // selectedReferences is null while we wait for the saved references to be fetched
  const [selectedReferences, setSelectedReferences] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false)
  const [submitting, setSubmitting] = useState(false);
  const [firstStepChoice, setFirstStepChoice] = useState(null);
  const [isReferenceFirstStep, setIsReferenceFirstStep] = useState(() => {
    if (details.client_id === 545 && ((details.has_shoes_female && details.gender_name === "female") || (details.has_shoes_male && details.gender_name === 'male'))) {
      return true;
    }
    else{
      return false;
    }
  })
  // const { references, mostUsed, savedSelectedReferences, isLoading, isError } =
  //   useSizerproReferences(details.gender_name, details.client_id, campaign_employee_id, token);
  // useEffect(() => {
  //   setSelectedReferences(savedSelectedReferences);
  // }, [savedSelectedReferences]);

  const submitReferences = async selectedReferences => {
    try {
      if(isReferenceFirstStep){
        setFirstStepChoice(selectedReferences)
        setIsReferenceFirstStep(false)
      }
      else{
        setSubmitting(true);
        let references = [];
        if(firstStepChoice){
          references = selectedReferences.concat(firstStepChoice).map(ref => ({
            sizing_chart_slug: ref.sizing_chart_slug,
            size_name: ref.size_name
          }));

        }
        else{
          references = selectedReferences.map(ref => ({
            sizing_chart_slug: ref.sizing_chart_slug,
            size_name: ref.size_name
          }));
        }
        const res = await api.campaignEmployees.updateReferences({
          campaign_employee_id,
          token,
          references
        });
        if (res !== 'OK') throw new Error(`Issue with request: ${res}`);
        history.push({ ...location, pathname: `/clothes_attribution/${token}/clothes` });
      }
    } catch (error) {
      setSubmitting(false);
      showToast({
        message: <Caption name="ERROR" color="white" />,
        intent: Intent.DANGER
      });
    }
  };

  const areReferencesValid = refs => {
    return refs && refs.length > 0 && refs.every(ref => !isNullOrUndefined(ref.size_name));
  };

  const addReference = newRef => setSelectedReferences(selectedReferences.concat(newRef));

  const updateReference = (newRef, updatedRef) => {
    const updatedRefIndex = selectedReferences.findIndex(
      r =>
        r.sizing_chart_slug === updatedRef.sizing_chart_slug &&
        r.category.slug === updatedRef.category.slug
    );
    setSelectedReferences([
      ...selectedReferences.slice(0, updatedRefIndex),
      newRef,
      ...selectedReferences.slice(updatedRefIndex + 1)
    ]);
  };

  const deleteReference = deletedRef => {
    const newSelectedReferences = selectedReferences.filter(
      r => !(r.name === deletedRef.name && r.category.slug === deletedRef.category.slug)
    );
    setSelectedReferences(newSelectedReferences);
  };

  const skipStep = () => {
    if (!submitting) submitReferences([]);
  };
  const SkipComponent = () => {
    if(isReferenceFirstStep){
      return(<></>)
    }
    else{
      return (
        <SkipReferencesContainer hidden={submitting || areReferencesValid(selectedReferences)}>
          <SkipReferencesText name="SKIP_REFERENCES_TEXT" />
          <SkipButton onClick={skipStep}>
            <SkipReferencesLink name="SKIP_REFERENCES_LINK" />
            <ArrowForward color={colors.blue} />
          </SkipButton>
        </SkipReferencesContainer>
      );
    }
    
  };

  return (
    <LightPage currentProgress={2}>
      <Header titleName={getTitleTranslationKey(details, isReferenceFirstStep)} details={details} hidePersonalDetails={true} />
      <>
      {isReferenceFirstStep ? 
          (<ShoesStepReferences
            details={details}
            match={match}
            theme={referencesTheme}
            selectedReferences={selectedReferences}
            selectReferences={setSelectedReferences}
            addReference={addReference}
            updateReference={updateReference}
            deleteReference={deleteReference}
            language={language}
            setIsloading={setIsLoading}
            setIsError={setIsError}
            skipSection={<SkipComponent />}
            />)
          :
          (<DefaultStepReferences 
            details={details}
            match={match}
            theme={referencesTheme}
            selectedReferences={selectedReferences}
            selectReferences={setSelectedReferences}
            addReference={addReference}
            updateReference={updateReference}
            deleteReference={deleteReference}
            language={language}
            setIsloading={setIsLoading}
            setIsError={setIsError}
            skipSection={<SkipComponent />}
            />
          )}
      </>
      <BottomButtonsRow>
        <BackButton
          isFirstStep={isReferenceFirstStep || details.has_shoes_female === false || details.has_shoes_male === false }
          token={token}
          returnFirstChoice={() => setIsReferenceFirstStep(true)}
        />
        <BottomTooltip
          disabled={areReferencesValid(selectedReferences)}
          usePortal={false}
          content={
            <SmallText
              name={
                selectedReferences && selectedReferences.length === 0
                  ? 'ADD_REFERENCE_TO_VALIDATE'
                  : 'MISSING_REFERENCE_SIZE'
              }
              color="white"
            />
          }
        >
          <ColoredAnchorButton
            disabled={!areReferencesValid(selectedReferences) || isLoading || isError || submitting}
            onClick={() => submitReferences(selectedReferences)}
          >
            <Caption name="VALIDATE_REFERENCES" color="white" />
          </ColoredAnchorButton>
        </BottomTooltip>
      </BottomButtonsRow>
    </LightPage>
  );
};

export default ReferencesScreen
