import React from 'react';
import styled from 'styled-components';
import { colors, Caption } from 'shared_components';
import MdClose from 'react-icons/lib/md/close';

const TooltipContainer = styled.div`
  max-width: 240px;
  display: flex;
  align-items: flex-start;
  text-align: left;
  span {
    line-height: 1.5;
  }
`;

const TooltipClose = styled.button.attrs(() => ({
  type: 'button',
  'aria-label': 'Close tooltip'
}))`
  margin-left: 0.2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${colors.white};
`;

export const TooltipContent = ({ onClose }) => (
  <TooltipContainer>
    <Caption style={{ flex: 1 }} name="SIZING_CHART_TOOLTIP" color="white" />
    <TooltipClose onClick={onClose}>
      <MdClose />
    </TooltipClose>
  </TooltipContainer>
);
