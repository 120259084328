// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing (https://davidwalsh.name/javascript-debounce-function).
export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const hexToRgb = hex => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

export const findYPosition = obj => {
  var curLeft = 0;
  if (obj.offsetParent) {
    do {
      curLeft += obj.offsetLeft;
    } while ((obj = obj.offsetParent));
    return curLeft;
  }
};

export const findXPosition = obj => {
  var curTop = 0;
  if (obj.offsetParent) {
    do {
      curTop += obj.offsetTop;
    } while ((obj = obj.offsetParent));
    return curTop;
  }
};

export const BODY_SHAPES = [
  {
    gender: 'female',
    gender_id: 1,
    id: 1,
    slug: 'A',
    group: 'average-waist',
    localeHelper: 'WAIST_BIGGER_CHEST',
    localesSVG: ['A_SVG_TITLE', 'YOU_ARE_A'],
    hasBoldText: true,
    localeHelperBold: 'WAIST_BIGGER_CHEST_BOLD',
    localeHelperBefore: 'WAIST_BIGGER_CHEST_1',
    localeHelperAfter: 'WAIST_BIGGER_CHEST_2'
  },
  {
    gender: 'female',
    gender_id: 1,
    id: 2,
    slug: 'XA',
    group: 'narrow-waist',
    localeHelper: 'WAIST_BIGGER_CHEST',
    localesSVG: ['XA_SVG_TITLE', 'YOU_ARE_XA'],
    hasBoldText: true,
    localeHelperBold: 'WAIST_BIGGER_CHEST_BOLD',
    localeHelperBefore: 'WAIST_BIGGER_CHEST_1',
    localeHelperAfter: 'WAIST_BIGGER_CHEST_2'
  },
  {
    gender: 'female',
    gender_id: 1,
    id: 3,
    slug: 'H',
    group: 'average-waist',
    localeHelper: 'WAIST_ALIGN_CHEST',
    localesSVG: ['H_SVG_TITLE', 'YOU_ARE_H'],
    hasBoldText: true,
    localeHelperBold: 'WAIST_ALIGN_CHEST_BOLD',
    localeHelperBefore: 'WAIST_ALIGN_CHEST_1',
    localeHelperAfter: 'WAIST_ALIGN_CHEST_2'
  },
  {
    gender: 'female',
    gender_id: 1,
    id: 4,
    slug: 'X',
    group: 'narrow-waist',
    localeHelper: 'WAIST_ALIGN_CHEST',
    localesSVG: ['X_SVG_TITLE', 'YOU_ARE_X'],
    hasBoldText: true,
    localeHelperBold: 'WAIST_ALIGN_CHEST_BOLD',
    localeHelperBefore: 'WAIST_ALIGN_CHEST_1',
    localeHelperAfter: 'WAIST_ALIGN_CHEST_2'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 15,
    slug: 'regular-round-wide',
    group: 'regular',
    localeHelper: 'TOOLTIP_SHAPE_ROUND_WIDE'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 16,
    slug: 'regular-round-narrow',
    group: 'regular',
    localeHelper: 'TOOLTIP_SHAPE_ROUND_NARROW'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 17,
    slug: 'regular-standard',
    group: 'regular',
    localeHelper: 'TOOLTIP_SHAPE_STANDARD'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 18,
    slug: 'regular-athletic-narrow',
    group: 'regular',
    localeHelper: 'TOOLTIP_SHAPE_ATHLETIC_NARROW'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 19,
    slug: 'regular-athletic-wide',
    group: 'regular',
    localeHelper: 'TOOLTIP_SHAPE_ATHLETIC_WIDE'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 20,
    slug: 'overweight-round-wide',
    group: 'overweight',
    localeHelper: 'TOOLTIP_SHAPE_ROUND_WIDE'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 21,
    slug: 'overweight-round-narrow',
    group: 'overweight',
    localeHelper: 'TOOLTIP_SHAPE_ROUND_NARROW'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 22,
    slug: 'overweight-standard',
    group: 'overweight',
    localeHelper: 'TOOLTIP_SHAPE_STANDARD'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 23,
    slug: 'overweight-athletic-narrow',
    group: 'overweight',
    localeHelper: 'TOOLTIP_SHAPE_ATHLETIC_NARROW'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 24,
    slug: 'overweight-athletic-wide',
    group: 'overweight',
    localeHelper: 'TOOLTIP_SHAPE_ATHLETIC_WIDE'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 25,
    slug: 'moderate-round-wide',
    group: 'moderate',
    localeHelper: 'TOOLTIP_SHAPE_OBESE_ROUND_WIDE'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 26,
    slug: 'moderate-round-narrow',
    group: 'moderate',
    localeHelper: 'TOOLTIP_SHAPE_OBESE_ROUND_NARROW'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 27,
    slug: 'moderate-standard',
    group: 'moderate',
    localeHelper: 'TOOLTIP_SHAPE_OBESE_STANDARD'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 28,
    slug: 'moderate-athletic-narrow',
    group: 'moderate',
    localeHelper: 'TOOLTIP_SHAPE_OBESE_ATHLETIC_NARROW'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 29,
    slug: 'moderate-athletic-wide',
    group: 'moderate',
    localeHelper: 'TOOLTIP_SHAPE_OBESE_ATHLETIC_WIDE'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 30,
    slug: 'severe-round',
    group: 'severe',
    localeHelper: 'TOOLTIP_SHAPE_OBESE_ROUND_WIDE'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 31,
    slug: 'severe-standard',
    group: 'severe',
    localeHelper: 'TOOLTIP_SHAPE_OBESE_STANDARD'
  },
  {
    gender: 'male',
    gender_id: 2,
    id: 32,
    slug: 'severe-athletic',
    group: 'severe',
    localeHelper: 'TOOLTIP_SHAPE_OBESE_ATHLETIC_WIDE'
  }
];

const getIMC = (weight, height) => {
  const meterHeight = height / 100;
  return weight / (meterHeight * meterHeight);
};

export const getBodyShapes = (gender, weight = 0, height = 0) => {
  if (gender === 'female') {
    return BODY_SHAPES.filter(shape => shape.gender === gender);
  } else if (gender === 'male') {
    if (!weight || !height) {
      return BODY_SHAPES.filter(shape => shape.gender === gender);
    } else {
      const imc = getIMC(weight, height);
      if (imc <= 25) {
        return BODY_SHAPES.filter(shape => shape.group === 'regular');
      } else if (imc <= 30) {
        return BODY_SHAPES.filter(shape => shape.group === 'overweight');
      } else if (imc <= 35) {
        return BODY_SHAPES.filter(shape => shape.group === 'moderate');
      } else if (imc > 35) {
        return BODY_SHAPES.filter(shape => shape.group === 'severe');
      }
    }
  }
};

export const GENDERS = [
  { id: 1, name: 'female', male: false, female: true },
  { id: 2, name: 'male', male: true, female: false },
  { id: 3, name: 'unisex', male: true, female: true },
  { id: 4, name: 'babies', male: true, female: true },
  { id: 5, name: 'boys', male: true, female: false },
  { id: 6, name: 'girls', male: false, female: true }
];

export const GENDER_NAMES = GENDERS.reduce((acc, gender) => {
  acc[gender.name] = gender.name.charAt(0).toUpperCase() + gender.name.slice(1);
  return acc;
}, {});

export const ADULT_GENDERS = ['male', 'female', 'unisex'];
export const CHILD_GENDERS = ['boys', 'girls', 'babies'];
