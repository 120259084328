import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { media, colors, CaptionMedium, Body, Caption } from 'shared_components';
import FaEnvelope from 'react-icons/lib/fa/envelope';
import { Intent } from '@blueprintjs/core';

import api from 'api';
import { showToast } from 'services/toaster';
import { localizedString } from 'services/i18n';
import { BottomSubmitButton } from './styles';

const OPENED_WIDTH = '18rem';

const Container = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  width: ${OPENED_WIDTH};
  background-color: ${colors.white};
  border: 1px solid ${colors.mainBlue};
  border-radius: 5px 0 0 5px;
  position: absolute;
  box-shadow: 0 5px 10px 0 rgba(234, 239, 242, 1);
  cursor: pointer;
  right: 0;
  top: 6rem;

  ${media.phone`
    padding: 0.5rem 1rem;
    width: ${props => (props.isOpen ? OPENED_WIDTH : 'min(16vw,3rem)')};
    max-width: ${OPENED_WIDTH};
    min-width: 2rem;
    `}
  ${media.phoneVerySmall`
    top: 3.5rem;
  `}
  ${media.phoneSmall`
    top: 3.5rem;
  `}
  ${media.phoneMedium`
    top: 3.9rem;
  `}
  ${media.phoneLarge`
    top: 5.4rem;
  `}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Envelope = styled(FaEnvelope)`
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.5rem;

  ${media.phone`
    margin-right: 0;
  `}
`;

const Text = styled(Caption)`
  ${media.phone`
    display: none;
  `}
`;

const FeedbackInput = styled.textarea`
  margin: 1rem 0;
  width: 100%;
  min-height: 3rem;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid ${colors.grey};
  font-size: 0.625rem;
  resize: vertical;

  :focus {
    border-color: ${colors.mainBlue};
  }
  ::placeholder {
    color: ${colors.grey};
  }
`;

const Thanks = styled.div`
  margin: 1.5rem auto;
  span {
    margin: 0.25rem auto;
    display: block;
    text-align: center;
  }
`;

export const useFeedback = () => {
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const handleFeedbackChange = e => setFeedback(e.target.value);

  const sendFeedback = async e => {
    e.stopPropagation();
    try {
      await api.campaignEmployees.postFeedback({
        rating,
        content: feedback
      });
    } catch (e) {
      showToast({
        message: <Body name="UNKNOWN_ERROR" />,
        intent: Intent.DANGER
      });
    }
    setSubmitted(true);
  };

  return {
    rating,
    setRating,
    feedback,
    handleFeedbackChange,
    sendFeedback,
    submitted
  };
};

const Feedback = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { feedback, handleFeedbackChange, submitted, sendFeedback } = useFeedback();
  const ref = useRef(null);
  const feedbackInput = useRef(null);
  useEffect(() => {
    if (isOpen && !submitted) {
      feedbackInput && feedbackInput.current.focus();
    }
  }, [isOpen, submitted]);

  useEffect(() => {
    if (isOpen) {
      const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
          document.removeEventListener('click', handleClickOutside, true);
        }
      };
      document.addEventListener('click', handleClickOutside, true);
    }
  }, [isOpen]);

  return (
    <Container ref={ref} isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
      <Row>
        <Envelope color={colors.mainBlue} />
        <Text name="TROUBLE_IN_FUNNEL" />
      </Row>
      {isOpen && (
        <>
          {submitted ? (
            <Thanks>
              <CaptionMedium name="THANKS_FEEDBACK" />
              <Caption name="HELP_IMPROVE_SIZERPRO" />
            </Thanks>
          ) : (
            <>
              <FeedbackInput
                ref={feedbackInput}
                value={feedback}
                onClick={e => e.stopPropagation()}
                onChange={handleFeedbackChange}
                placeholder={localizedString('TROUBLE_PRECISION')}
              />
              <BottomSubmitButton
                disabled={!feedback}
                style={{ width: '100%', fontSize: '0.625rem', height: 'auto' }}
                onClick={sendFeedback}
              >
                {localizedString('SEND')}
              </BottomSubmitButton>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Feedback;
