import React from 'react';
import FaPlus from 'react-icons/lib/fa/plus';
import { Caption } from 'shared_components';

import api from 'api';
import { Dialog } from 'library/dialogs';
import { Content, Card, OptionsRow } from 'library/layout';
import { ColoredButton, HugeBottomButton } from 'library/buttons';

import CompanyListing from './CompanyListing';
import CompanyCreationForm from './CompanyCreationForm';

class CompaniesScreen extends React.Component {
  state = {
    showCreationModal: false,
    createError: false
  };

  toggleCreateModal = () => {
    this.setState({ showCreationModal: !this.state.showCreationModal });
  };

  createCompany = async values => {
    try {
      await api.companies.create(values);
      this.props.refresh();
      this.toggleCreateModal();
    } catch (e) {
      this.setState({ createError: true });
    }
  };
  renderCreateRow = () => {
    return (
      <OptionsRow>
        <ColoredButton onClick={this.toggleCreateModal}>
          <FaPlus color="white" />
          <Caption name="COMPANY_CREATION" color="white" />
        </ColoredButton>
      </OptionsRow>
    );
  };

  renderCreationModal = companies => {
    return (
      <Dialog onClose={this.toggleCreateModal} isOpen={this.state.showCreationModal}>
        <CompanyCreationForm
          onSubmit={this.createCompany}
          error={this.state.createError}
          companies={companies}
        />
      </Dialog>
    );
  };

  render() {
    const companies = this.props.companies;
    const hasCompanies = companies.length > 0;
    return (
      <Content>
        {hasCompanies && this.renderCreateRow()}
        <Card>
          {hasCompanies ? (
            <CompanyListing data={companies} />
          ) : (
            <HugeBottomButton
              Icon={FaPlus}
              name="COMPANY_CREATION"
              onClick={this.toggleCreateModal}
            />
          )}
        </Card>
        {this.state.showCreationModal && this.renderCreationModal(companies)}
      </Content>
    );
  }
}

export default CompaniesScreen;
