const INITIALIZE_SENTRY =
  window.Sentry && // Sentry is now blocked by uBlock Origin
  (process.env.REACT_APP_ENVIRONMENT === 'production' ||
    process.env.REACT_APP_ENVIRONMENT === 'staging');

// enable Sentry only for production and staging mode
export const initializeSentry = () => {
  if (INITIALIZE_SENTRY) {
    window.Sentry.init({
      dsn: 'https://2812ccbffe5d440fa0187d273f63f981@sentry.io/1192872',
      release: process.env.REACT_APP_COMMIT_HASH,
      environment: process.env.REACT_APP_ENVIRONMENT,
      // Those are all errors due to a network problem on the user side. We cannot do much about them and know that we handle them gracefully.
      ignoreErrors: [
        /^Loading( CSS)? chunk/,
        'Network Error',
        'Error: Network Error',
        'Error: timeout of 0ms exceeded'
      ]
    });
  }
};

export const raiseSentryError = (error, errorInfo) => {
  if (INITIALIZE_SENTRY) {
    window.Sentry.withScope(scope => {
      if (errorInfo) scope.setExtras(errorInfo);
      window.Sentry.captureException(new Error(error));
    });
  } else {
    console.error('Did not sent error to Sentry: ', error);
  }
};

export const addSentryBreadcrumb = (category, message, data, severityLevel) => {
  // category: a string to label the event under (ex: 'auth', ...).
  // message: a string describing the event.
  // data: a mapping (str => str) of metadata around the event.
  if (INITIALIZE_SENTRY) {
    let level = severityLevel;
    if (!level) level = window.Sentry.Severity.Info;
    window.Sentry.addBreadcrumb({
      category,
      message,
      data,
      level
    });
  }
};

export const configureSentryScope = (extras, tags) => {
  if (INITIALIZE_SENTRY) {
    window.Sentry.configureScope(scope => {
      if (extras) scope.setExtras(extras);
      if (tags) Object.keys(tags).map(tagKey => scope.setTag(tagKey, tags[tagKey]));
    });
  }
};
