import { createGlobalStyle } from 'styled-components/macro';

const GlobalFixesBlueprintStyle = createGlobalStyle`
  /* Ugly but necessary for Blueprint selects with a lot of items  */
  .bp3-popover-content .bp3-menu {
    max-height: 300px;
    overflow: auto;
  }

  /* By default this element is a span and doesn't fill the width of it's parent */
  span.bp3-popover-wrapper {
    span.bp3-popover-target {
      display: block;
    }
  }
`;

export default GlobalFixesBlueprintStyle;
