// Contains position data for the PositionFunnel
// It allows the user to navigate between screens and keep the position data

const DEFAULT_STATE = {
  positionId: null,
  clientPositionId: null,
  name: null,
  femaleItems: [],
  maleItems: []
};

export default function reducer(state = DEFAULT_STATE, action) {
  const { type, ...data } = action;
  switch (type) {
    case 'UPDATE_POSITION':
      return { ...state, ...data };
    case 'RESET_POSITION':
      return DEFAULT_STATE;
    default:
      return state;
  }
}
