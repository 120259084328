const MAX_CHARACTERS = 14;

// put the words from text into chunks <= at maxCharacters
const splitTextInChunks = (text, maxCharacters) => {
  if (!text.includes(' ') || text.length < maxCharacters) {
    return [text];
  }
  const words = text.split(' ');
  const chunks = [];

  while (words.length > 0) {
    let chunk = words.shift();
    if (chunk.length > maxCharacters) {
      chunks.push(chunk);
    } else {
      while (chunk.length < maxCharacters && words.length > 0) {
        if (chunk.length + words[0].length + 1 > maxCharacters) break;
        chunk = `${chunk} ${words.shift()}`;
      }
      chunks.push(chunk);
    }
  }
  return chunks;
};

export const setText = (
  text,
  parent,
  originalElement,
  heightOffset,
  isInseamOrHeight,
  isTitle,
  position
) => {
  if (originalElement && parent) {
    // first, we clear all children nodes (only "clean" way to do this for svg)
    while (parent.hasChildNodes()) {
      parent.removeChild(parent.lastChild);
    }

    let y = parseFloat(originalElement.getAttribute('y'));
    // for height and inseam the text is too close to the title
    if (isInseamOrHeight) y = y + 5;
    const splittedText = splitTextInChunks(text, MAX_CHARACTERS);

    splittedText.forEach((elementText, index) => {
      const elementToAppend = originalElement.cloneNode();
      elementToAppend.textContent = elementText;
      // for the title the text needs to expand upwards. Hence the negative multiplier.
      const deltaMultiplier = isTitle ? -(splittedText.length - (index + 1)) : index;
      elementToAppend.setAttribute('y', y + heightOffset * deltaMultiplier);
      if (position === 'left') {
        elementToAppend.setAttribute('text-anchor', 'end');
      }
      parent.appendChild(elementToAppend);
    });
  }
};
