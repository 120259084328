import React from 'react';
import { Caption, CaptionMedium } from '../../text';
import { PhysicalInput } from '../base';
import { getPounds, getKgs, InputSelector } from '../utils';

const WeightInput = ({ unitType, weight, limits, onChangeUnitType, defaultValue, onChange }) => {
  const isCurrentlyMetric = unitType === 'metric';

  const switchUnitType = () => {
    const unitType = isCurrentlyMetric ? 'imperial' : 'metric';
    // If still at default value, we set the value upon unit change
    // Otherwise the slider will rerender weirdly
    if (!weight) onChange(defaultValue);
    onChangeUnitType(unitType);
  };
  const convertedWeightLimits = isCurrentlyMetric
    ? limits
    : { min: getPounds(limits.min), max: getPounds(limits.max) };
  const convertedWeightValue =
    weight === undefined ? weight : Math.round(isCurrentlyMetric ? weight : getPounds(weight));

  // Apply non linearity between weight and % range for the 'normale' weight range (50 - 80)
  // to be approximately in the middle of the slider
  const smoothFactor = isCurrentlyMetric ? 15 : 15 * getPounds(1, 2); // We need to adjust the smoothFactor in pounds to get the same sloap
  const yIntercept = convertedWeightLimits.min / smoothFactor - 1;
  const sloap = convertedWeightLimits.max / smoothFactor - yIntercept;
  const weightToPercentage = weight => {
    if (!weight) return undefined;
    return Math.round((Math.log(weight / smoothFactor - yIntercept) / Math.log(sloap)) * 100);
  };
  const percentageToWeight = percentage => {
    return Math.round((Math.exp((percentage / 100) * Math.log(sloap)) + yIntercept) * smoothFactor);
  };

  const displayedDefaultValue = weightToPercentage(
    isCurrentlyMetric ? defaultValue : getPounds(defaultValue)
  );

  const onInputChange = value => {
    const metricValue = isCurrentlyMetric ? value : getKgs(value, 2);
    onChange(metricValue, true);
  };

  const onSliderChange = percentage => {
    const newWeight = percentageToWeight(percentage);
    onInputChange(newWeight);
  };

  // Custom increase and decrease function for the slider so we increment/decrement by 1 in the weight space and not in the % space.
  const increaseByOne = () => {
    const newWeight = Math.min(
      convertedWeightLimits.max,
      Math.round(isCurrentlyMetric ? weight || defaultValue : getPounds(weight || defaultValue)) + 1
    );
    onInputChange(newWeight);
  };
  const decreaseByOne = () => {
    const newWeight = Math.max(
      convertedWeightLimits.min,
      Math.round(isCurrentlyMetric ? weight || defaultValue : getPounds(weight || defaultValue)) - 1
    );
    onInputChange(newWeight);
  };

  const displayValue = weight ? (
    <>
      <CaptionMedium text={convertedWeightValue} />
      <Caption text={isCurrentlyMetric ? ' kg' : ' lb'} />
    </>
  ) : (
    <Caption text="-" style={{ marginRight: '0.650rem' }} />
  );

  return (
    <PhysicalInput
      inputId="weight-slider"
      name="WEIGHT"
      required={true}
      value={weightToPercentage(convertedWeightValue)}
      onChange={onSliderChange}
      limits={{ min: 0, max: 100 }}
      displayValue={displayValue}
      defaultValue={displayedDefaultValue}
      settings={
        <InputSelector left="kg" right="lb" isLeft={isCurrentlyMetric} onSwitch={switchUnitType} />
      }
      increaseInput={increaseByOne}
      decreaseInput={decreaseByOne}
    />
  );
};

export default WeightInput;
