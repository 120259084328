// All colors must be in rgba with the 1 at the end.
// The 1 at the end is for the opacity, we need it there to be able to modify it (for the linear-gradients for exemple).
const colors = {
  purple: '#6e5ef3',
  deepBlue: '#333645',
  blue_v2: '#34A5F8',
  darkRed: '#ad0000',
  darkGreen: '#7ED321',
  lightBlack: '#E5E5E5',

  // blue
  blue: 'rgba(52,165,248,1)',
  mainBlue: 'rgba(52,165,248,1)',
  lightBlue: 'rgba(232,247,255,1)',
  darkBlue: 'rgba(10,54,107,1)',
  lightBlueGrey: 'rgba(234,239,242,1)',
  // green
  green: 'rgba(109,204,115,1)',
  lightGreen: 'rgba(198,218,54,1)',
  // red
  red: 'rgba(200,62,62,1)',
  lightRed: 'rgba(255,216,214,1)',
  // text colors
  black: 'rgba(61,61,61,1)',
  darkBlack: 'rgba(0,0,0,1)',
  coal: 'rgba(79,79,79,1)',
  grey: 'rgba(200,200,200,1)',
  lightGrey: 'rgba(246,246,246,1)',
  lighterGrey: 'rgba(248,250,251,1)',
  darkGrey: 'rgba(129,129,129,1)',
  white: 'rgba(255,255,255,1)',
  // other
  yellow: 'rgba(217,130,43,1)',
  lightYellow: 'rgba(217,130,43,0.25)',
  // fitle
  fitle: 'rgba(0,161,171,1)',
  fitleCoral: 'rgba(238,112,128,1)',
  fitleOrange: 'rgba(248,146,84,1)'
};

export default colors;
