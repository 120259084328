import React from 'react';
import styled from 'styled-components/macro';
import { FootLengthMeasurementExplanation, FootLengthMeasurement } from 'shared_components';
import { Title } from 'shared_components';

import { media } from 'shared_components';

const HeaderText = styled(Title)`
  text-align: center;
  max-width: 100%;
  ${media.phone`
    font-size:1rem;
    font-weight: 400;
    margin-top: 1rem;
  `}
  ${media.phoneVerySmall`
    margin-top: 0.1rem;
  `}
  ${media.phoneSmall`
    margin-top: 0.1rem;
  `}
  ${media.phoneMedium`
    font-size:1rem;
    font-weight: 400;
    margin-top: 0.8rem;
  `}
`;

const Container = styled.div`
  width: 21em;
  padding-bottom: 1rem;

  ${media.phone`
    width: 100%; 
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 0rem;
  `}
  ${media.phoneVerySmall`
    width: 100%; 
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 0rem;
  `}
  ${media.phoneSmall`
    width: 100%; 
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 0rem;
  `}
  ${media.phoneMedium`
    width: 100%; 
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 0rem;
  `}
  ${media.phoneLarge`
    width: 100%; 
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 0rem;
  `}
`;

const Spacer = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  ${media.phone`
    margin-bottom: 0.2rem;
  `}
`;

const FootLengthMeasurementInput = ({
  theme,
  footLength,
  onChange,
  lengthUnitType,
  switchLenghtUnitType
}) => (
  <Container>
    <HeaderText forwardedAs="h2" name="MEASURE_FOOT_LENGTH_TITLE" />
    <Spacer />
    <FootLengthMeasurementExplanation theme={theme} />
    <Spacer />
    <FootLengthMeasurement
      theme={theme}
      footLength={footLength}
      onInputChange={onChange}
      lengthUnitType={lengthUnitType}
      switchLenghtUnitType={switchLenghtUnitType}
    />
  </Container>
);

export default FootLengthMeasurementInput;
