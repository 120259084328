import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'store';
import { Router } from 'react-router-dom';
import { history } from 'navigation';
import Routes from './routes';
import AuthGuard from './screens/auth/AuthGuard';

import GlobalResetStyle from './styles/GlobalResetStyle';
import GlobalFontStyle from './styles/GlobalFontStyle';
import GlobalFixesBlueprintStyle from './styles/GlobalFixesBlueprintStyle';

import i18n from 'i18n';
import { setLanguageData } from 'shared_components';

import { raiseSentryError } from 'services/sentry';
import { FocusStyleManager } from '@blueprintjs/core';

FocusStyleManager.onlyShowFocusOnTabs();

class App extends Component {
  componentDidMount() {
    // Load all languages to shared_components
    Object.keys(i18n).forEach(language => setLanguageData(language, i18n[language]));
  }

  componentDidCatch(error, errorInfo) {
    raiseSentryError(error, errorInfo);
  }

  render() {
    return (
      <>
        <GlobalResetStyle />
        <GlobalFontStyle />
        <GlobalFixesBlueprintStyle />
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            {/* Used to download exports */}
            <iframe id="downloader-frame" title="downloader-frame" style={{ display: 'none' }} />
            <Router history={history}>
              <>
                <AuthGuard />
                <Routes />
              </>
            </Router>
          </PersistGate>
        </Provider>
      </>
    );
  }
}

export default App;
