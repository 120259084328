import React from 'react';
import styled from 'styled-components/macro';
import { media, Rating, colors, BodyMedium, Caption, CaptionMedium } from 'shared_components';

import { localizedString } from 'services/i18n';
import { Card, BottomSubmitButton } from '../common/styles';
import { useFeedback } from '../common/Feedback';
import { ratingTheme } from 'styles/themes';

const Text = styled(BodyMedium)`
  width: 100%;
  text-align: center;
  margin-bottom: 1.25rem;
  ${media.phone`
    margin-bottom: 0rem;
  `}
  ${media.phoneLarge`
    margin-bottom: 1.25rem;
  `}
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 0rem auto 1.25rem auto;
  background-color: ${colors.lightBlueGrey};
  ${media.phone`
  margin: 0rem auto 0rem auto;
  `}
  ${media.phoneLarge`
  margin: 0rem auto 1.25rem auto;
  `}
`;

const TextInput = styled.textarea`
  width: 100%;
  min-height: 5rem;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid ${colors.grey};
  resize: vertical;

  :focus {
    border-color: ${colors.mainBlue};
  }
  ::placeholder {
    color: ${colors.grey};
  }
  :disabled {
    cursor: not-allowed;
  }
`;

const Optional = styled(Caption)`
  font-style: italic;
  align-self: flex-start;
`;

const Thanks = styled.div`
  margin: 1rem 2rem 0 2rem;
  span {
    display: block;
    text-align: center;
  }
`;

const SubmitButton = styled(BottomSubmitButton)`
  width: 100%;
  margin-top: 1.25rem;
  font-size: 0.75rem;
  ${media.phoneVerySmall`
    margin:0;
  `}
  ${media.phoneSmall`
    margin:0;
  `}
  ${media.phoneMedium`
    margin-top: 0.2rem;
  `}
`;
const CardEnd = styled(Card)`
  ${media.phoneVerySmall`
    padding:0;
  `}
  ${media.phoneSmall`
    padding:0;
  `}
  ${media.phoneMedium`
    margin-bottom:0.3rem;
  `}
`;

const FeedbackForm = () => {
  const { rating, setRating, feedback, handleFeedbackChange, sendFeedback, submitted } =
    useFeedback();

  const selectRating = rating => {
    if (submitted) {
      return null;
    }
    setRating(rating);
  };

  return (
    <CardEnd>
      <Text name="IS_FUNNEL_USEFUL" />
      <Rating selectedRating={rating} selectRating={selectRating} theme={ratingTheme} />
      <Divider />
      <Text name="GOT_TROUBLE_WITH_FUNNEL" />
      <TextInput
        disabled={submitted}
        placeholder={localizedString('TELL_US_PROBLEMS')}
        value={feedback}
        onChange={handleFeedbackChange}
      />
      <Optional text={`(${localizedString('OPTIONAL')})`} />
      {submitted ? (
        <Thanks>
          <CaptionMedium name="THANKS_FEEDBACK" />
          <Caption name="HELP_IMPROVE_SIZERPRO" />
        </Thanks>
      ) : (
        <SubmitButton disabled={!rating} onClick={sendFeedback}>
          {localizedString('SEND')}
        </SubmitButton>
      )}
    </CardEnd>
  );
};

export default FeedbackForm;
