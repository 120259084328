import React, { useState, useEffect } from 'react';
import api from 'api';
import { Formik } from 'formik';
import styled from 'styled-components/macro';
import { colors, Body, Caption, Title } from 'shared_components';
import { Suggest } from '@blueprintjs/select';
import { Intent, MenuItem, Tooltip } from '@blueprintjs/core';

import Async from 'library/Async';
import { Form } from 'library/forms';
import { SubmitButton } from 'library/buttons';
import { Field, SelectField } from 'library/forms';
import { isNullOrUndefined, normalize } from 'services/utils';
import CampaignEmployeeToggle from './CampaignEmployeeToggle';
import { ToggleContainer } from 'library/toggle';
import { Dialog } from 'library/dialogs';
import { showToast } from 'services/toaster';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormContainer = styled.div``;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
`;

const EmployeeSelectionCard = ({ touched, errors, employees, setFieldValue, setFieldTouched }) => (
  <FieldContainer
    onKeyPress={e => {
      // Hack to prevent Enter press to trigger a Submit
      if (e.key === 'Enter') e.preventDefault();
    }}
  >
    <div>
      <Caption name="EMPLOYEE" />
      <Body color={colors.blue} text="*" style={{ marginLeft: 4 }} />
    </div>
    <Tooltip
      isOpen={!!(touched && touched.employee_id && errors && errors.employee_id)}
      intent="danger"
      position="right"
      content={<Caption name={errors && errors.position_id} color="white" />}
    >
      <Suggest
        items={employees}
        itemRenderer={(employee, { handleClick, modifiers }) => (
          <MenuItem
            active={modifiers.active}
            label={employee.client_employee_id}
            key={employee.id}
            text={`${employee.last_name} ${employee.first_name}`}
            onClick={handleClick}
          />
        )}
        inputValueRenderer={employee => `${employee.last_name} ${employee.first_name}`}
        onItemSelect={employee => {
          setFieldValue('employee_id', employee.id);
        }}
        itemPredicate={(query, employee) =>
          `${normalize(employee.last_name)}. ${normalize(employee.first_name)} ${normalize(
            employee.client_employee_id
          )}`.indexOf(normalize(query)) >= 0
        }
        inputProps={{
          onBlur: () =>
            // Avoid flash from the tooltip upon selecting an item
            setTimeout(() => setFieldTouched('employee_id', true), 500)
        }}
        popoverProps={{
          minimal: true,
          usePortal: false
        }}
      />
    </Tooltip>
  </FieldContainer>
);

const CampaignEmployeeForm = ({
  employees,
  positions,
  values,
  errors,
  touched,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
  isValid
}) => (
  <Form
    values={values}
    errors={errors}
    touched={touched}
    setFieldTouched={setFieldTouched}
    setFieldValue={setFieldValue}
  >
    <FormContainer>
      <ToggleContainer>
        <CampaignEmployeeToggle
          activeIndex={!values.creating ? 0 : 1}
          onToggle={() => setFieldValue('creating', !values.creating)}
        />
      </ToggleContainer>
      {values.creating ? (
        <>
          <Field name="client_employee_id" type="text" label="IDENTIFIER" required />
          <Field name="last_name" type="text" label="LAST_NAME" required />
          <Field name="first_name" type="text" label="FIRST_NAME" required />
          <Field name="email" type="email" label="EMAIL" />
        </>
      ) : (
        <EmployeeSelectionCard
          touched={touched}
          errors={errors}
          employees={employees}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
      )}
      <SelectContainer>
        <SelectField
          required
          style={{ margin: '0 0 10px 15px' }}
          name="position_id"
          valueKey="id"
          labelKey="name"
          label="POSITION"
          items={positions}
          placeholderName="SELECT_POSITION"
          usePortal={false}
        />
      </SelectContainer>
    </FormContainer>
    <SubmitButton disabled={isSubmitting || !isValid} />
  </Form>
);

const AddEmployeeDialog = ({ campaignId, positions, isOpen, onClose }) => {
  const [companyId, setCompanyId] = useState(null);
  const [allEmployees, setAllEmployees] = useState([]);

  useEffect(() => {
    const getCompanyId = async () => {
      const campaignInfo = await api.campaigns.get({
        id: campaignId
      });
      setCompanyId(campaignInfo.company_id);
    };
    getCompanyId();
  }, [campaignId]);

  useEffect(() => {
    const getAllEmployees = async () => {
      const employees = await api.employees.list({ company_id: companyId });
      setAllEmployees(employees);
    };
    if (companyId) {
      getAllEmployees();
    }
  }, [companyId]);

  const fetchAvailableEmployees = async () => {
    const employees = await api.campaigns.availableEmployees({ campaign_id: campaignId });
    return employees;
  };

  const validate = ({
    employee_id,
    position_id,
    email,
    last_name,
    first_name,
    client_employee_id,
    creating
  }) => {
    const errors = {};
    if (creating) {
      if (email.length > 0 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        errors.email = 'INVALID_EMAIL';
      }
      if (!(last_name && last_name.length)) {
        errors.last_name = 'MISSING_FIELD';
      }
      if (!(first_name && first_name.length)) {
        errors.first_name = 'MISSING_FIELD';
      }
      if (!(client_employee_id && client_employee_id.length)) {
        errors.client_employee_id = 'MISSING_FIELD';
      } else {
        try {
          if (
            allEmployees
              .map(e => normalize(e.client_employee_id))
              .includes(normalize(client_employee_id))
          )
            errors.client_employee_id = 'EMPLOYEE_ID_CONFLICT';
        } catch (e) {
          // we do this to handle the case where allEmployees are not loaded yet
          errors.client_employee_id = 'CANNOT_CHECK_EMPLOYEE';
        }
      }
    } else {
      if (isNullOrUndefined(employee_id)) {
        errors.employee_id = 'MISSING_FIELD';
      }
    }
    if (isNullOrUndefined(position_id)) {
      errors.position_id = 'MISSING_FIELD';
    }
    return errors;
  };

  const onSubmit = async ({
    employee_id,
    position_id,
    creating,
    first_name,
    last_name,
    email,
    client_employee_id
  }) => {
    const employee_ids_to_add = [];
    if (creating) {
      try {
        const employee = await api.employees.create({
          company_id: companyId,
          first_name,
          last_name,
          client_employee_id,
          email
        });
        employee_ids_to_add.push(employee.id);
      } catch (e) {
        if (e.status === 409) {
          showToast({
            message: <Body name="EMPLOYEE_CONFLICT" />,
            intent: Intent.DANGER
          });
        } else {
          showToast({
            message: <Body name="UNKNOWN_ERROR" />,
            intent: Intent.DANGER
          });
        }
      }
    } else {
      employee_ids_to_add.push(employee_id);
    }
    await api.campaigns.updateEmployees({
      campaign_id: campaignId,
      position_id,
      employee_ids_to_add
    });
    onClose(true);
  };

  return (
    <Dialog isOpen={isOpen} onClose={() => onClose(false)}>
      <Container>
        <Title name="NEW_CAMPAIGN_EMPLOYEE" />
        <Async fetchProps={fetchAvailableEmployees}>
          {employees => (
            <Formik
              initialValues={{
                position_id: null,
                employee_id: null,
                creating: false,
                first_name: '',
                last_name: '',
                email: '',
                client_employee_id: ''
              }}
              validate={validate}
              onSubmit={onSubmit}
            >
              {props => (
                <CampaignEmployeeForm employees={employees} positions={positions} {...props} />
              )}
            </Formik>
          )}
        </Async>
      </Container>
    </Dialog>
  );
};

export default AddEmployeeDialog;
