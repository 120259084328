import React from 'react';
import Async from 'library/Async';
import api from 'api';
import styled from 'styled-components/macro';
import CampaignManagement from './CampaignManagement';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

class CompanyCampaigns extends React.Component {
  state = {
    showDeleteModal: false,
    pendingDelete: null,
    showCreationModal: false
  };

  getCompanyId = () => this.props.match.params.company_id;

  fetchCampaigns = () => api.campaigns.list({ company_id: this.getCompanyId() });

  render() {
    return (
      <Async fetchProps={this.fetchCampaigns} ref={async => (this.async = async)}>
        {campaigns => (
          <Container>
            <CampaignManagement
              refresh={this.async.refresh}
              company_id={this.getCompanyId()}
              campaigns={campaigns}
            />
          </Container>
        )}
      </Async>
    );
  }
}

export default CompanyCampaigns;
