import React from 'react';
import styled from 'styled-components/macro';
import { MainTitle, Title } from 'shared_components';

import { TitleSection, Content, Card } from 'library/layout';
import api from 'api';
import Async from 'library/Async';
import ProductListing from './ProductListing';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

class CollectionDetails extends React.Component {
  getCollectionId = () => this.props.match.params.collection_id;
  refresh = async () => {
    const collection_id = this.getCollectionId();
    const [collection, products] = await Promise.all([
      api.collections.get({ collection_id }),
      api.collections.listProducts({
        collection_id
      })
    ]);
    return { collection, products };
  };
  render() {
    return (
      <Container>
        <Async fetchProps={this.refresh}>
          {({ collection, products }) => (
            <React.Fragment>
              <TitleSection>
                <MainTitle text={collection.name} />
                <Title text=" - " color="grey" />
                <Title name="CLOTHES" color="grey" />
              </TitleSection>
              <Content>
                <Card>
                  <ProductListing products={products} />
                </Card>
              </Content>
            </React.Fragment>
          )}
        </Async>
      </Container>
    );
  }
}

export default CollectionDetails;
