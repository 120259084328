const loadedLanguages = { defaultStrings: {}, customStrings: {} };
let selectedLanguage;
const LATIN_LANGUAGES = ['FR', 'PT', 'ES', 'IT'];

export const setLanguageData = (language, data) => {
  loadedLanguages.defaultStrings[language] = data;
};

export const setCustomLanguages = data => {
  loadedLanguages.customStrings = data;
};

export const selectLanguage = language => {
  selectedLanguage = language;
};

export const isLanguageLatin = language => LATIN_LANGUAGES.indexOf(language) !== -1;

function format(text, values = {}) {
  const keys = Object.keys(values);
  let res;
  if (!keys.length) return text;
  res = text;
  for (let v of keys) {
    const interpol = '$' + v;
    res = res.replace(interpol, values[v] || ''); //todo : optimize
  }
  return res;
}

// A whitespace Unicode character with a height (contrary to the usual whitespace) and no width
// It allows us to preserve the layout when we have nothing to show (key === null or temporarily no info)
// Copied from https://beautifuldingbats.com/punctuation/whitespace/
const ZERO_WIDTH_SPACE = '​';

export function localizeStringToLanguage(language, key, values) {
  if (!language) {
    throw new Error(`Language cannot be undefined`);
  }
  const { defaultStrings, customStrings } = loadedLanguages;

  // If the key is null, it's to intentionally return a whitespace.
  // We don't send an error to Sentry.
  // And we use a zero width space so that the (empty) string still has a height
  if (key === null) return ZERO_WIDTH_SPACE;

  let raw = undefined;

  // first try in custom
  if (language in customStrings) {
    raw = customStrings[language][key];
  }
  // then try in default
  if (!raw) {
    if (language in defaultStrings) {
      raw = defaultStrings[language][key];
      if (!raw) {
        throw new Error(`Unknown key ${key} for language ${language}`);
      }
    } else {
      return ZERO_WIDTH_SPACE; // not loaded yet
    }
  }
  return format(raw, values);
}

export const localizedString = (key, values) => {
  if (!selectedLanguage) throw new Error('No language selected');
  return localizeStringToLanguage(selectedLanguage, key, values);
};

export const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
