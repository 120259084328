import React from 'react';
import { Formik } from 'formik';
import { Form, Field } from 'library/forms';
import { SubmitButton } from 'library/buttons';

const ForgotPasswordResetFormRender = ({ errors, touched, isSubmitting }) => {
  return (
    <Form errors={errors} touched={touched} style={{ display: 'flex', flexDirection: 'column' }}>
      <Field type="password" name="new_password" label="NEW_PASSWORD" />
      <Field type="password" name="confirm" label="CONFIRM_PASSWORD" />
      <SubmitButton disabled={isSubmitting}>Submit</SubmitButton>
    </Form>
  );
};

const validate = ({ new_password, confirm }) => {
  const errors = {};
  if (!new_password || new_password.length < 6) {
    errors.new_password = 'INVALID_PASSWORD';
  }
  if (new_password !== confirm) {
    errors.confirm = 'INVALID_PASSWORD_CONFIRM';
  }
  return errors;
};

const ForgotPasswordResetForm = ({ onSubmit, onResetHook }) => {
  return (
    <Formik
      initialValues={{ new_password: '', confirm: '' }}
      onSubmit={async (values, actions) => {
        await onSubmit(values);
        actions.setSubmitting(false);
      }}
      validate={values => validate(values)}
      render={p => <ForgotPasswordResetFormRender {...p} />}
    />
  );
};

export default ForgotPasswordResetForm;
