import React from 'react';
import { withRouter } from 'react-router';

import { colors, Caption } from 'shared_components';
import { BottomBackButton } from '../common/styles';
export const BackButton = withRouter(({ history, isFirstStep, returnFirstChoice }) => {
    if (isFirstStep) {
      return (
        <BottomBackButton onClick={() => history.goBack()}>
          <Caption name="BACK" color={colors.darkGrey} />
        </BottomBackButton>
      );
    } else {
      return (
        <BottomBackButton onClick={returnFirstChoice}>
          <Caption name="BACK" color={colors.darkGrey} />
        </BottomBackButton>
      );
    }
  });