import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Body } from 'shared_components';

import api from 'api';
import Async from 'library/Async';
import { Campaign, Company } from 'library/icons';

import NameForm from './NameForm';

const CampaignInfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.8rem;
`;

const CampaignInfo = ({ campaign }) => (
  <CampaignInfoContainer>
    <Campaign size={18} style={{ margin: '0 5 5 0' }} />
    <Body text={campaign.name} />
    <Company size={18} style={{ margin: '0 5 5 10' }} />
    <Body text={campaign.company_name} />
  </CampaignInfoContainer>
);

const Name = ({ match, history, name, clientPositionId, updatePosition }) => {
  const campaignId = match.params.campaign_id;
  const fetchCampaignAndPositions = async () =>
    await Promise.all([
      api.campaigns.get({ id: campaignId }),
      api.positions.list({ campaign_id: campaignId })
    ]);

  return (
    <Async fetchProps={fetchCampaignAndPositions}>
      {([campaign, positions]) => (
        <>
          <CampaignInfo campaign={campaign} />
          <NameForm
            name={name}
            clientPositionId={clientPositionId}
            positions={positions}
            onSubmit={(name, clientPositionId) => {
              updatePosition(name, clientPositionId);
              history.push(`/position/${campaignId}/clothes`);
            }}
          />
        </>
      )}
    </Async>
  );
};

const mapper = state => ({
  name: state.position.name,
  clientPositionId: state.position.clientPositionId
});

const dispatcher = dispatch => ({
  updatePosition: (name, clientPositionId) =>
    dispatch({ type: 'UPDATE_POSITION', name, clientPositionId })
});

export default connect(mapper, dispatcher)(Name);
