import React from 'react';
import { Intent } from '@blueprintjs/core';
import { Body } from 'shared_components';

import api from 'api';
import store from 'store';
import { history } from 'navigation';
import { showToast } from 'services/toaster';

const authErrors = error => {
  switch (error.status) {
    case 409:
      showToast({
        message: <Body name="OPERATOR_CONFLICT" />,
        intent: Intent.DANGER
      });
      break;
    case 400:
      showToast({
        message: <Body name="ERROR_INPUT_EMAIL" />,
        intent: Intent.DANGER
      });
      break;
    case 401:
      showToast({
        message: <Body name="ERROR_INPUT_AUTH" />,
        intent: Intent.DANGER
      });
      break;
    default:
      showToast({
        message: <Body name="UNKNOWN_ERROR" />,
        intent: Intent.DANGER
      });
      break;
  }
};

export function authenticate(res) {
  store.dispatch({
    type: 'LOGIN',
    token: res.token,
    isAdmin: res.is_admin,
    clientName: res.client_name,
    firstName: res.first_name,
    lastName: res.last_name,
    email: res.email,
    id: res.id
  });
  history.push('/');
}

export async function login(params) {
  try {
    const res = await api.operators.login(params);
    authenticate(res);
  } catch (error) {
    authErrors(error);
  }
}

export function logout(redirect = true) {
  store.dispatch({ type: 'LOGOUT' });
  redirect && history.push('/');
}

export async function signup(params) {
  try {
    const res = await api.operators.signup(params);
    authenticate(res);
  } catch (error) {
    authErrors(error);
  }
}
