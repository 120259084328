import React from 'react';
import { colors, CaptionMedium } from 'shared_components';
import styled from 'styled-components/macro';
import FaEye from 'react-icons/lib/fa/eye';
import { Link, withRouter } from 'react-router-dom';

import { StandardTable, TextCell, TextCellMedium } from 'library/tables';
import { Company } from 'library/icons';

const ActionContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  & svg {
    cursor: pointer;
  }
  color: ${colors.blue};
`;
const Actions = ({ original }) => (
  <ActionContainer>
    <Link to={`/companies/${original.id}/campaigns`}>
      <FaEye />
    </Link>
  </ActionContainer>
);

const CompanyListing = ({ data, loading, history }) => {
  const COMPANY_COLUMNS = [
    {
      Cell: () => <Company color="grey" />,
      width: 30,
      resizable: false,
      sortable: false,
      filterable: false
    },
    {
      Header: <CaptionMedium color="grey" name="COMPANY_NAME" />,
      accessor: 'name',
      Cell: TextCellMedium
    },
    {
      Header: <CaptionMedium color="grey" name="IDENTIFIER" />,
      accessor: 'client_company_id',
      Cell: TextCell
    },
    {
      Header: <CaptionMedium color="grey" name="DESCRIPTION" />,
      accessor: 'description',
      Cell: TextCell
    },
    {
      Header: <CaptionMedium color="grey" name="ACTIONS" />,
      Cell: Actions,
      filterable: false,
      width: 100
    }
  ];
  return (
    <StandardTable
      shrinkable
      data={data}
      columns={COMPANY_COLUMNS}
      loading={loading}
      onRowClick={rowInfo => history.push(`/companies/${rowInfo.original.id}/campaigns`)}
    />
  );
};

export default withRouter(CompanyListing);
