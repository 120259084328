import React from 'react';
import { connect } from 'react-redux';

class Splash extends React.Component {
  componentDidMount = () => {
    let route;
    if (this.props.connected) {
      route = 'campaigns';
    } else {
      route = 'auth';
    }
    this.props.history.replace(route);
  };
  render() {
    return <div />;
  }
}

const mapper = state => ({
  connected: !!state.auth.token
});

export default connect(mapper)(Splash);
