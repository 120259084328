import React from 'react';
import styled from 'styled-components';
import ArrowLeft from 'react-icons/lib/go/triangle-left';
import ArrowRight from 'react-icons/lib/go/triangle-right';

import ProductBadge from '../../../library/ProductBadge';
import ComfortRenderer from './ComfortRenderer';
import { colors, media } from 'shared_components/styles';
import { useComparisonLogic, getHeaderTextLineTwo } from './logic';
import { isMobile } from '../../../services/device';
import { localizedString } from '../../../services/i18n/translations';
import Title from './Title';

const Container = styled.div`
  position: relative;
  padding: 0 1.25em;
  width: 100%;

  ${media.bigScreen`
    padding-top: 0;
    flex: 1;
  `}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: ${props => props.buttonPosition};
  padding: 1em 0 1em 0;
  width: 100%;
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
`;

const ChangeSizeButton = styled.button`
  min-width: 6em;
  border-radius: 1em;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightBlueGrey};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  padding: 0.5em 1.2em;
  cursor: pointer;
  transition: box-shadow 100ms ease-out;
  font-size: 0.75rem;

  ${({ arrowDirection }) => arrowDirection && `padding-${arrowDirection}: 0.7em;`}

  svg {
    font-size: 0.75em;
  }

  :hover {
    box-shadow: none;
  }

  :focus {
    outline: none;
    border-color: ${props => props.theme.primaryColor};
  }
`;

const Comparison = ({
  sizes,
  clothType,
  gender,
  language,
  selectSize,
  selectedSize,
  photoUrl,
  showSizingChart,
  sendTooltipDisplayedEvent
}) => {
  const { comparedSize, leftSize, rightSize, hiddenSize, buttonInfo, handleSizeButtonClick } =
    useComparisonLogic(sizes);
  const headerTextLineTwo = getHeaderTextLineTwo(comparedSize);

  return (
    <Container>
      {isMobile() && <ProductBadge photoUrl={photoUrl} />}
      <Title
        recommendedSizeName={sizes[0].name}
        comparedSizeName={comparedSize.name}
        headerTextLineTwo={headerTextLineTwo}
        showSizingChart={showSizingChart}
        sendTooltipDisplayedEvent={sendTooltipDisplayedEvent}
      />
      <ComfortRenderer
        shapeFileName={`${clothType}-${gender}`}
        rightSize={rightSize}
        leftSize={leftSize}
        language={language}
        selectSize={selectSize}
        selectedSize={selectedSize.name}
        recommendedSize={sizes[0].name}
        clothType={clothType}
      />
      <ButtonContainer buttonPosition={buttonInfo.position} isVisible={buttonInfo.show}>
        <ChangeSizeButton
          onClick={handleSizeButtonClick}
          arrowDirection={buttonInfo.arrowDirection}
        >
          <>
            {buttonInfo.arrowDirection === 'left' && <ArrowLeft style={{ marginRight: 5 }} />}
            {localizedString(buttonInfo.text || null, { size: hiddenSize && hiddenSize.name })}
            {buttonInfo.arrowDirection === 'right' && <ArrowRight style={{ marginLeft: 5 }} />}
          </>
        </ChangeSizeButton>
      </ButtonContainer>
    </Container>
  );
};

export default Comparison;
