import { makeBrands, makeCategories } from './utils';

export const initialState = {
  showSelectedReferences: true,
  showBrands: false,
  showCategories: false,
  showReferences: false,
  availableReferences: {}, // { brandName: { categorySlug: [{ reference }, ...], ... }, ...}
  brands: [], // [{ name, logoUrl }, ...]
  categories: [], // [{ slug, tranlation_key }, ...]
  references: [], // [{ reference }, ...]
  selectedBrand: {}, // { name, logoUrl }
  selectedCategory: {}, // { slug, translation_key }
  referenceBeingEdited: undefined // { reference }
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'MAKE_BRANDS':
      return {
        ...state,
        brands: makeBrands(action.availableReferences),
        availableReferences: action.availableReferences
      };
    case 'SHOW_BRANDS':
      return {
        ...state,
        showSelectedReferences: false,
        showBrands: true
      };
    case 'SELECT_BRAND':
      return {
        ...state,
        showBrands: false,
        showCategories: true,
        categories: makeCategories(state.availableReferences, action.selectedBrand.name),
        references: null,
        selectedBrand: action.selectedBrand
      };
    case 'EDIT_BRAND':
      return {
        ...state,
        showBrands: true,
        showCategories: false,
        showReferences: false
      };
    case 'SELECT_CATEGORY':
      return {
        ...state,
        showCategories: false,
        showReferences: true,
        selectedCategory: action.selectedCategory,
        references:
          state.availableReferences[state.selectedBrand.name][action.selectedCategory.slug]
      };
    case 'EDIT_CATEGORY':
      return {
        ...state,
        categories: makeCategories(state.availableReferences, state.selectedBrand.name),
        showCategories: true,
        showReferences: false
      };
    case 'SELECT_REFERENCE':
      return {
        ...state,
        showReferences: false,
        showSelectedReferences: true
      };
    case 'EDIT_REFERENCE':
      return {
        ...state,
        categories: action.categories,
        references: action.references,
        showSelectedReferences: false,
        showReferences: true,
        selectedBrand: action.selectedBrand,
        selectedCategory: action.selectedCategory,
        referenceBeingEdited: action.referenceBeingEdited
      };
    case 'RESET_REFERENCE_BEING_EDITED':
      return {
        ...state,
        referenceBeingEdited: undefined
      };
    case 'RESET':
      return initialState;
    default:
      throw new Error('Unknown action type');
  }
};
