import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'shared_components/styles';
import { getTheme } from '../styles/theme';
import Callout from '../library/Callout';
import { Caption } from '../text';

const _l = (min, max, defaultValue) => ({ min, max, defaultValue });
const INPUT_LIMITS = {
  adults: {
    age: _l(14, 100, 25),
    height: _l(140, 210, 175),
    weight: _l(40, 200, 60),
    footLength: _l(21.6, 33.9, 22.5)
  },
  children: {
    age: _l(4, 16, 8),
    height: _l(80, 170, 120),
    weight: _l(15, 60, 20),
    footLength: _l(10, 33.9, 20)
  }
};

export const getInputLimits = (field, forChild) => {
  const population = forChild ? 'children' : 'adults';
  return INPUT_LIMITS[population][field];
};

export const getInputDefaultValue = (field, forChild) => {
  const population = forChild ? 'children' : 'adults';
  return INPUT_LIMITS[population][field].defaultValue;
};

export const getValue = value => {
  if (value == null || Number.isNaN(value)) return undefined;
  return value;
};

// precision parameters indicate the number of digit you want for the precision of the conversion (float number)
export const getPounds = (value, precision = 0) =>
  getValue(Math.round((value / 0.453592) * Math.pow(10, precision)) / Math.pow(10, precision));

export const getKgs = (value, precision = 0) =>
  getValue(Math.round(value * 0.453592 * Math.pow(10, precision)) / Math.pow(10, precision));

export const getCm = (inputFeet, inputInches, precision = 0) => {
  const feet = getValue(inputFeet);
  const inches = getValue(inputInches);
  if (feet === undefined || inches === undefined) return undefined;
  return (
    Math.round(((12 * feet + inches) / 0.3937) * Math.pow(10, precision)) / Math.pow(10, precision)
  );
};

export const getFeetInches = (inputValue, format = false) => {
  let inches, feet;
  const value = getValue(inputValue);
  if (value === undefined) return { inches: undefined, feet: undefined };
  inches = Math.round(value * 0.3937);
  feet = Math.floor(inches / 12);
  inches = inches % 12;
  if (format) return `${feet}'${inches}"`;
  return { inches, feet };
};

export const getInches = (inputValue, precision = 0) => {
  const value = getValue(inputValue);
  if (value === undefined) return undefined;
  return Math.round(value * 0.3937 * Math.pow(10, precision)) / Math.pow(10, precision);
};

const InputTypeSelector = styled.div`
  margin-left: 1rem;
  min-width: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const InputType = styled.button`
  width: 1.1rem;
  font-size: 0.75em;
  background-color: white;
  color: ${props => (props.disabled ? colors.black : colors.grey)};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

const InputTypeLeft = styled(InputType)`
  text-align: right;
`;

const InputTypeRight = styled(InputType)`
  text-align: left;
`;

const Separator = styled.div`
  height: 1rem;
  width: 0.0625rem;
  margin: 0 0.325rem 0.125rem 0.325rem;
  background-color: ${colors.grey};
`;

export const InputSelector = React.memo(({ left, right, isLeft, onSwitch, className }) => (
  <InputTypeSelector className={'input-type-selector ' + className}>
    <InputTypeLeft
      type="button"
      className="input-type"
      aria-label={'switch-to-' + left}
      disabled={isLeft}
      onClick={onSwitch}
    >
      {left}
    </InputTypeLeft>
    <Separator />
    <InputTypeRight
      type="button"
      className="input-type"
      aria-label={'switch-to-' + right}
      disabled={!isLeft}
      onClick={onSwitch}
    >
      {right}
    </InputTypeRight>
  </InputTypeSelector>
));

InputSelector.propTypes = {
  left: PropTypes.string.isRequired,
  right: PropTypes.string.isRequired,
  isLeft: PropTypes.bool.isRequired,
  onSwitch: PropTypes.func.isRequired,
  labelFor: PropTypes.string
};

const ErrorCallout = styled(Callout)`
  padding: 0.75em;
  ${props => getTheme(props, 'PhysicalInputError')}
`;

export const PhysicalInputError = React.memo(({ name, values }) => (
  <ErrorCallout intent="danger">
    <Caption name={name} color={colors.red} values={{ ...values }} />
  </ErrorCallout>
));

PhysicalInputError.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.object
};
