import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { media, colors, Caption, BodyMedium, CaptionMedium } from 'shared_components';

import { Check } from 'library/icons';
import Callout from 'library/Callout';
import { DarkPage, CompanyName, Card, SizerproLogo, IconCheckContainer } from '../common/styles';

import FeedbackForm from './FeedbackForm';

const CenteredCard = styled(Card)`
  align-items: center;
  margin-bottom: 0rem;
  ${media.phoneMedium`
    padding: 0rem 0rem 0.5rem 0rem;
    margin: 0rem;
  `}
  ${media.phoneVerySmall`
    padding: 0rem 0rem 0rem 0rem;
    margin: 0rem;
  `}
  ${media.phoneSmall`
    padding: 0rem 0rem 0rem 0rem;
    margin: 0rem;
  `}
`;

const Row = styled.div`
  margin-bottom: 1.2rem;
  display: flex;
  justify-content: center;
  flex: 1;
  ${media.phone`
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  `}
  ${media.phoneVerySmall`
    margin-top: 0rem;
    margin-bottom: 0rem;
  `}
  ${media.phoneSmall`
    margin-top: 0rem;
    margin-bottom: 0rem;
  `}
`;

const Text = styled(Caption)`
  margin-top: 0.5em;
  text-align: center;
  margin-bottom: 0.5em;
`;
const InformText = styled(Caption)`
  margin-top: 1em;
  text-align: center;
  margin-bottom: 0.5em;
  width: 90%;
`;
const Bottom = styled.div`
  margin-top: 1em;
  ${media.phone`
    margin-top: 0em;
  `}
`;

const ModifyLink = styled(CaptionMedium)`
  color: ${colors.darkGrey};
  text-decoration: underline;
  :hover {
    text-decoration: none;
  }
`;
const CompanyTitle = styled(CompanyName)`
  color: ${colors.white};
  ${media.phone`
    margin: 0rem;
    padding: 0rem;
    height: max(8vh, 1.3rem);
    font-size: max(4vh, 1rem);
  `}
  ${media.phoneVerySmall`
    height: 2rem;
    font-size: 1.1rem;
  `}
  ${media.phoneSmall`
    height: 2rem;
    font-size: 1.1rem;
  `}
  ${media.phoneMedium`
    height: 3rem;
    font-size: 1.3rem;
  `}
  ${media.phoneLarge`
    height: 3rem;
    font-size: 1.3rem;
  `}
`;
const End = ({ details, location, match }) => {
  const mailSent = location.state && location.state.mailSent;
  const { company_name, email } = details;
  return (
    <DarkPage>
      <CompanyTitle text={company_name} />
      <CenteredCard>
        <Row>
          <IconCheckContainer backgroundColor={colors.blue}>
            <Check size={'80%'} />
          </IconCheckContainer>
        </Row>
        <BodyMedium name="CHOICES_SAVED" />
        <InformText name="INFORM_BEFORE_SEND_MAIL" />
        {email &&
          (mailSent ? (
            <Text name="CONFIRMATION_MAIL_SENT" />
          ) : (
            <Callout intent="warning">
              <Caption name="COULDNT_SEND_CONFIRMATION_MAIL" />
            </Callout>
          ))}
        <Bottom>
          <Link to={`/clothes_attribution/${match.params.campaign_employee_token}/clothes`}>
            <ModifyLink name="MODIFY_MY_CHOICES" style={{ fontWeight: '600', color: 'black' }} />
          </Link>
        </Bottom>
      </CenteredCard>
      <FeedbackForm />
      <SizerproLogo />
    </DarkPage>
  );
};

export default End;
