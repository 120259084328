import React from 'react';
import styled from 'styled-components';
import ArrowLeft from 'react-icons/lib/go/triangle-left';
import ArrowRight from 'react-icons/lib/go/triangle-right';
import { ComfortRenderer, useComparisonLogic } from 'shared_components';
import { media, colors, BodyMedium } from 'shared_components';

import { localizedString } from 'services/i18n';
import RecommendedTooltip from './RecommendedTooltip';

const ChangeSizeButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  z-index: 1;
  min-width: 6rem;
  border-radius: 1rem;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightBlueGrey};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  padding: 0.6rem 1.2rem 0.45rem 1.2rem;
  cursor: pointer;
  transition: box-shadow 100ms ease-out;
  font-size: 0.75rem;

  ${({ arrowDirection }) => arrowDirection && `padding-${arrowDirection}: 0.7rem;`}

  svg {
    margin-bottom: 2px;
    font-size: 0.75rem;
  }

  :hover {
    box-shadow: none;
    border-color: ${props => props.theme.primaryColor};
  }

  :focus {
    outline: none;
  }

  ${media.bigScreen`
    position: absolute;
    top: 45%;
    ${props => (props.desktopLeft ? 'right: -15%;' : 'left: -15%;')}
  `}
`;

const SizeButton = ({ desktopLeft, onClick, buttonInfo, hiddenSize }) => (
  <ChangeSizeButton
    desktopLeft={desktopLeft}
    onClick={onClick}
    arrowDirection={buttonInfo.arrowDirection}
  >
    <>
      {buttonInfo.arrowDirection === 'left' && <ArrowLeft style={{ marginRight: 5 }} />}
      {localizedString('SEE_SIZE', { size: hiddenSize && hiddenSize.name })}
      {buttonInfo.arrowDirection === 'right' && <ArrowRight style={{ marginLeft: 5 }} />}
    </>
  </ChangeSizeButton>
);

const ComfortContainer = styled.div`
  ${props => (props.hasThirdSize ? 'flex: 3' : 'width: 60%')};
  margin: 0 2rem;
  ${media.phone`
    margin: 0;
    width: 100%;
  `}
`;

const RecommendedContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: -1.5rem;
  justify-content: ${props => (props.tooltipLeft ? 'flex-start' : 'flex-end')};
`;

const DesktopButtonContainer = styled.div`
  position: relative;
  padding: 1em;
  border-radius: 6px;
  flex: 1;
  align-self: flex-end;
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  border: 1px solid ${colors.lightBlueGrey};
  display: flex;
  flex-direction: column;
  height: 320px;
  ${media.phone`
    display: none;
  `}
`;

const Gradient = styled.div`
  top: -5px;
  left: -2px;
  position: absolute;
  height: 103%;
  width: 103%;
  background: linear-gradient(
    ${props => props.gradientDirection},
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 60%
  );
`;

const MobileButtonContainer = styled.div`
  display: ${props => (props.isDisplayed ? 'flex' : 'none')};
  justify-content: ${props => props.buttonPosition};
  margin-top: 1rem;
  width: 100%;

  ${media.bigScreen`
    display: none;
  `}
`;

const DESKTOP_BUTTON_POSITION = {
  'flex-end': 'right',
  'flex-start': 'left',
  center: 'right'
};

const Comparison = ({
  recoUnchanged,
  selectSize,
  recoSizeName,
  chosenSizeName,
  activeFit,
  language,
  gender,
  sizes,
  clothType,
  showSizingChart
}) => {
  const { leftSize, rightSize, hiddenSize, buttonInfo, handleSizeButtonClick } =
    useComparisonLogic(sizes);

  const showButton = hiddenSize && buttonInfo.show;

  return (
    <>
      {showButton && (
        <DesktopButtonContainer
          recoUnchanged={recoUnchanged}
          isVisible={DESKTOP_BUTTON_POSITION[buttonInfo.position] === 'left'}
        >
          <BodyMedium text={hiddenSize.name} style={{ textAlign: 'center' }} />
          <SizeButton
            desktopLeft={true}
            onClick={handleSizeButtonClick}
            buttonInfo={buttonInfo}
            hiddenSize={hiddenSize}
          />
          <Gradient gradientDirection={'to left'} />
        </DesktopButtonContainer>
      )}
      <ComfortContainer hasThirdSize={showButton}>
        <RecommendedContainer tooltipLeft={leftSize.name === recoSizeName}>
          <RecommendedTooltip sizes={sizes} activeFit={activeFit} recoUnchanged={recoUnchanged} />
        </RecommendedContainer>
        <ComfortRenderer
          shapeFileName={`${clothType}-${gender}`}
          rightSize={rightSize}
          leftSize={leftSize}
          language={language}
          selectSize={size => selectSize(size.name)}
          selectedSize={chosenSizeName}
          clothType={clothType}
        />
      </ComfortContainer>
      {showButton && (
        <DesktopButtonContainer
          recoUnchanged={recoUnchanged}
          isVisible={DESKTOP_BUTTON_POSITION[buttonInfo.position] === 'right'}
        >
          <BodyMedium text={hiddenSize.name} style={{ textAlign: 'center' }} />
          <SizeButton
            onClick={handleSizeButtonClick}
            buttonInfo={buttonInfo}
            hiddenSize={hiddenSize}
          />
          <Gradient gradientDirection={'to right'} />
        </DesktopButtonContainer>
      )}
      {showButton && (
        <MobileButtonContainer buttonPosition={buttonInfo.position} isDisplayed={buttonInfo.show}>
          <SizeButton
            onClick={handleSizeButtonClick}
            buttonInfo={buttonInfo}
            hiddenSize={hiddenSize}
          />
        </MobileButtonContainer>
      )}
    </>
  );
};

export default Comparison;
