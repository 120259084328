import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from 'shared_components/styles';
import { Caption, CaptionMedium } from '../text';

const splitArray = array => {
  const res = [];
  for (let i = 0; i < array.length; i += 4) {
    res.push(array.slice(i, i + 4));
  }
  return res;
};

const Table = styled.table`
  ${props => props.disabled && 'opacity: 0.3; pointer-events: none;'}
  width: 100%;
  border: 1px ${colors.lightBlueGrey} solid;
  margin-bottom: 1em;

  & th {
    padding: 10px;
    border: 1px ${colors.lightBlueGrey} solid;
    background-color: ${colors.lightgrey};
  }
  & td > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
  }
`;

const EmptyCell = styled.td``;

const CellStyle = styled.td`
  border: 1px ${colors.lightBlueGrey} solid;
  height: 2em;
  width: 5.5em;
`;

const CellButton = styled.button`
  cursor: pointer;
  background-color: ${props =>
    props.selected
      ? props.theme.primaryColor
      : props.preselected
      ? colors.lightGrey
      : colors.white};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: ${props => (props.selected ? props.theme.primaryColor : colors.lighterGrey)};
  }
  :focus {
    outline: none;
    background-color: ${props => (props.selected ? props.theme.primaryColor : colors.lighterGrey)};
  }
`;

const Cell = ({ disabled, item, selected, preselected, onClick }) => (
  <CellStyle>
    <CellButton
      type="button"
      disabled={disabled}
      onClick={onClick}
      selected={selected}
      preselected={preselected}
    >
      <Caption text={item} color={selected ? colors.white : colors.coal} />
    </CellButton>
  </CellStyle>
);

const TableList = ({ items, name, previousItem, disabled, onSelect }) => {
  const [selected, setSelected] = useState();

  let cells = splitArray(items).map(subarray =>
    subarray.map((item, i) => (
      <Cell
        key={i}
        disabled={disabled}
        item={item}
        selected={selected === item}
        preselected={item === previousItem}
        onClick={() => {
          setSelected(item);
          onSelect(item);
        }}
      />
    ))
  );

  // Add 1, 2 or 3 EmptyCells on the last row of the Table if it has less than 4 Cells for aesthetic reasons
  let numberOfCellsToAdd = items.length % 4 === 0 ? 0 : 4 - (items.length % 4);
  for (numberOfCellsToAdd; numberOfCellsToAdd > 0; numberOfCellsToAdd--) {
    cells[cells.length - 1].push(<EmptyCell key={'empty_' + numberOfCellsToAdd} />);
  }

  return (
    <Table className="bra-table-list" disabled={disabled}>
      {name && (
        <thead className="bra-table-head">
          <tr className="bra-table-head-row">
            <th colSpan="4">
              <CaptionMedium name={name} color="coal" />
            </th>
          </tr>
        </thead>
      )}
      <tbody className="bra-table-body">
        {cells.map((c, i) => (
          <tr className="bra-table-row" key={i}>
            {c}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TableList;
