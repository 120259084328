import React from 'react';
import styled from 'styled-components';

import { colors, media } from 'shared_components/styles';
import { localizedString } from '../services/i18n/translations';

const getColor = ({ color }) => {
  return colors[color] || color || colors.black;
};

const Text = styled.span`
  color: ${getColor};

  &.heading {
    font-weight: 600;
  }
  &.link {
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &.link-inverted {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

const StandardText = ({ text, name, values, ...rest }) => (
  // The name dangerouslySetInnerHTML is frightening just to remind developers of the potential XSS risk.
  // The fact that you use it doesn't automatically render your code "smelly" or otherwise "bad".
  // In other words, if you are certain that the code you fetch is static, it's a pretty safe bet.
  <Text
    {...rest}
    dangerouslySetInnerHTML={{ __html: text === undefined ? localizedString(name, values) : text }}
  />
);

export const SmallText = styled(StandardText)`
  font-size: 0.625rem;
`;

export const Caption = styled(StandardText)`
  font-size: 0.75rem;
`;

export const Body = styled(StandardText)`
  font-size: 1rem;
  line-height: 1.5;
`;

export const Title = styled(StandardText)`
  font-size: 1.125rem;
  line-height: 1.6;
`;

export const SmallTextMedium = styled(SmallText)`
  font-weight: 600;
`;

export const CaptionMedium = styled(Caption)`
  font-weight: 600;
`;

export const BodyMedium = styled(Body)`
  font-weight: 600;
`;

export const TitleMedium = styled(Title)`
  font-weight: 600;
`;

export const MainTitle = styled(StandardText)`
  font-size: 1.375rem;
  line-height: 2;
  font-weight: 600;
  ${media.phoneLarge`
    font-size: 1.5rem;
  `}
`;

export const ErrorText = p => <Caption color="red" {...p} />;
