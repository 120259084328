import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import FaTrash from 'react-icons/lib/fa/trash';
import FaEdit from 'react-icons/lib/fa/edit';
import { colors, SmallTextMedium, Caption } from 'shared_components';

import { StandardTable } from 'library/tables';
import { Employee } from 'library/icons';

const TextCell = ({ value }) => <Caption text={value} />;

const ActionContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  & svg {
    cursor: pointer;
  }
  color: ${colors.blue};
`;
const Actions =
  ({ onDelete, onEdit }) =>
  ({ original }) =>
    (
      <ActionContainer>
        <FaTrash onClick={() => onDelete(original)} color={colors.darkRed} />
        <FaEdit onClick={() => onEdit(original)} />
      </ActionContainer>
    );

const EmployeeListing = ({ data, onDelete, onEdit, checkboxesColumn }) => {
  const reactTable = useRef(null);
  const OPERATOR_COLUMNS = [
    { ...checkboxesColumn(reactTable) },
    {
      Cell: () => <Employee color="grey" />,
      width: 30,
      resizable: false,
      sortable: false,
      filterable: false
    },
    {
      Header: <SmallTextMedium color="grey" name="IDENTIFIER" />,
      accessor: 'client_employee_id',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="LAST_NAME" />,
      accessor: 'last_name',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="FIRST_NAME" />,
      accessor: 'first_name',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="EMAIL" />,
      accessor: 'email',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="ACTIONS" />,
      Cell: Actions({ onDelete, onEdit }),
      filterable: false,
      resizable: false,
      sortable: false
    }
  ];
  return <StandardTable ref={reactTable} data={data} columns={OPERATOR_COLUMNS} shrinkable />;
};

export default EmployeeListing;
