import React from 'react';

import PhysicalInfoForm from './PhysicalInfoForm';
import { LightPage, Header } from '../common/styles';

const PhysicalInfoFormScreen = ({ details, match, onSubmitted, language }) => (
  <LightPage currentProgress={0}>
    <Header titleName="PERSONAL_INFO" details={details} />
    <PhysicalInfoForm
      details={details}
      language={language}
      employee={details}
      token={match.params.campaign_employee_token}
      onSubmitted={onSubmitted}
    />
  </LightPage>
);

export default PhysicalInfoFormScreen;
