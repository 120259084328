import React, { useReducer, useEffect, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import { localizedString } from '../services/i18n/translations';

import { makeCategories } from './utils';
import { initialState, reducer } from './store';
import { colors, media } from 'shared_components/styles';

import { Animations } from './Animations';
import SelectedReferences from './SelectedReferences';
import BrandsList from './BrandsList';
import CategoriesList from './CategoriesList';
import SizesTable from './SizesTable';
import ErrorMessage from '../library/ErrorMessage';
import Spinner from '../library/Spinner';
import { Caption } from '../text';
import { Header } from './common';

const Container = styled.section`
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  ${media.phoneVerySmall`
    height: 65vh;
  `};
  ${media.phoneSmall`
    height: 65vh;
  `};
`;

const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoReferencesContainer = styled(SpinnerContainer)`
  flex-direction: column;
`;

const NoReferences = () => (
  <NoReferencesContainer>
    <Caption name="SORRY" color="coal" style={{ marginBottom: '1em' }} />
    <Caption name="NO_AVAILABLE_REFERENCES" color="coal" />
  </NoReferencesContainer>
);

const References = React.forwardRef(
  (
    {
      theme,
      availableReferences,
      mostUsedBrandsNames,
      focusedBrandName,
      selectedReferences,
      addReference,
      deleteReference,
      updateReference,
      maxReferencesAllowed,
      isLoading,
      isError,
      skipSection
    },
    ref
  ) => {
    const [
      {
        showSelectedReferences,
        showBrands,
        showCategories,
        showReferences,
        brands,
        categories,
        references,
        selectedBrand,
        selectedCategory,
        referenceBeingEdited
      },
      dispatch
    ] = useReducer(reducer, initialState);

    const container = useRef(null);
    // We are getting the container height to be able to give a height to this element and thus use "overflow: scroll".
    let containerHeight;
    if (container.current) {
      containerHeight = container.current.parentElement.offsetHeight;
    }

    useEffect(() => {
      dispatch({ type: 'MAKE_BRANDS', availableReferences });
    }, [availableReferences]);

    // If there are no selectedReferences show the BrandList.
    useEffect(() => {
      if (showSelectedReferences && !isLoading && selectedReferences.length === 0) {
        dispatch({ type: 'SHOW_BRANDS' });
      }
    }, [showSelectedReferences, selectedReferences, isLoading]);

    const editReference = reference => {
      const selectedBrand = { name: reference.name, logoUrl: reference.brand_logo_url };
      const categories = makeCategories(availableReferences, selectedBrand.name);
      const selectedCategory = reference.category;
      const references = availableReferences[selectedBrand.name][selectedCategory.slug];

      return dispatch({
        type: 'EDIT_REFERENCE',
        categories,
        references,
        selectedBrand,
        selectedCategory,
        referenceBeingEdited: reference
      });
    };

    const submitEditedReference = reference => {
      updateReference(reference, referenceBeingEdited);
      return dispatch({ type: 'RESET_REFERENCE_BEING_EDITED' });
    };

    // Used by plugin tracking.
    useImperativeHandle(ref, () => {
      return {
        getActiveScreen: () => {
          if (showBrands) return 'Brand Selection';
          if (showCategories) return 'Category Selection';
          if (showReferences) return 'Size Selection';
        }
      };
    });

    return (
      <>
        <Animations />
        <ThemeProvider theme={theme}>
          <Container className="fitle-references" ref={container} parentHeight={containerHeight}>
            {isLoading ? (
              <SpinnerContainer>
                <Spinner size={96} />
              </SpinnerContainer>
            ) : isError ? (
              <ErrorMessage style={{ marginTop: '3em' }} />
            ) : Object.keys(availableReferences).length === 0 ? (
              <NoReferences />
            ) : (
              <>
                {showSelectedReferences && (
                  <SelectedReferences
                    selectedReferences={selectedReferences}
                    deleteReference={deleteReference}
                    editReference={editReference}
                    dispatch={dispatch}
                    maxReferencesAllowed={maxReferencesAllowed}
                  />
                )}
                {showBrands && (
                  <>
                    <Header text="CHOOSE_BRAND" showSeparator={false} />
                    {skipSection}
                    <BrandsList
                      brands={brands}
                      dispatch={dispatch}
                      mostUsedBrandsNames={mostUsedBrandsNames}
                      focusedBrandName={focusedBrandName}
                    />
                  </>
                )}
                {showCategories && (
                  <>
                    <Header
                      text="REFERENCE_CATEGORY_TITLE"
                      values={{ brand: selectedBrand.name }}
                      showSeparator={false}
                    />
                    {skipSection}
                    <CategoriesList
                      categories={categories}
                      dispatch={dispatch}
                      selectedBrand={selectedBrand}
                      selectedReferences={selectedReferences}
                      referenceBeingEdited={referenceBeingEdited}
                    />
                  </>
                )}
                {showReferences && (
                  <>
                    <Header
                      text="WHAT_IS_THE_SIZE_OF"
                      values={{
                        brand: selectedBrand.name,
                        category: localizedString(selectedCategory.translation_key)
                      }}
                      showSeparator={false}
                    />
                    {skipSection}
                    <SizesTable
                      references={references}
                      dispatch={dispatch}
                      submitNewReference={addReference}
                      submitEditedReference={submitEditedReference}
                      selectedBrand={selectedBrand}
                      selectedCategory={selectedCategory}
                      referenceBeingEdited={referenceBeingEdited}
                    />
                  </>
                )}
              </>
            )}
          </Container>
        </ThemeProvider>
      </>
    );
  }
);

References.defaultProps = {
  maxReferencesAllowed: 100,
  isLoading: false,
  isError: false,
  theme: {
    primaryColor: colors.black
  },
  mostUsedBrandsNames: []
};

References.propTypes = {
  theme: PropTypes.object,
  mostUsedBrandsNames: PropTypes.array,
  availableReferences: PropTypes.object.isRequired,
  selectedReferences: PropTypes.array.isRequired,
  addReference: PropTypes.func.isRequired,
  deleteReference: PropTypes.func.isRequired,
  maxReferencesAllowed: PropTypes.number,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool
};

export default References;
