import styled from 'styled-components';
import { media } from 'shared_components/styles';

// In Safari height: 100% does not work if the element is a child of a flexbox. But flex: 1 does !
// I originaly used only height: 100% because we cannot be sure that the parent of this component is a flexbox.
// With both flex: 1 and height: 100% : if the parent is a flexbox flex: 1 fixes the bug in Safari.
// And if the parent is not a flexbox, flex: 1 is useless but height: 100% works.
// https://www.labsrc.com/safari-full-height-flexbox-children/
export const Container = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
`;

export const Shape = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ShapeImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30vh;

  ${media.bigScreen`
    flex: 1;
    min-height: auto;
  `}
`;

export const ShapeCheckbox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
  }
`;

export const MaleSecondStepShapeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.bigScreen`
    flex: 1;
    svg {
      height: 9.5em;
    }
  `}
  ${media.phoneVerySmall`
    svg {
      height: 10em;
    }
  `}

  ${media.phoneSmall`
    svg {
      height: 10em;
    }
  `}

  ${media.phone`
    svg {
      height: 25vh;
    }
  `}
`;
