import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components/macro';
import { ErrorText, Title, Caption } from 'shared_components';
import { withRouter } from 'react-router';

import { Form, Field } from 'library/forms';
import { SubmitButton, OutlineButton } from 'library/buttons';
import { alphanum, normalize } from 'services/utils';

const GenerateButton = styled(OutlineButton)`
  align-self: flex-end;
  margin-top: -10px;
  margin-bottom: 20px;
`;

const generateId = (first_name, last_name, client_employee_ids) => {
  const id = alphanum(last_name + first_name).toUpperCase();
  let result = id;
  let count = 0;
  while (client_employee_ids.includes(result)) {
    count += 1;
    result = `${id}(${count})`;
  }
  return result;
};

const EmployeeCreationFormRender = ({
  errors,
  touched,
  isSubmitting,
  values,
  client_employee_ids,
  setFieldValue
}) => (
  <Form
    errors={errors}
    touched={touched}
    values={values}
    style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
  >
    <Field type="text" name="last_name" label="LAST_NAME" required />
    <Field type="text" name="first_name" label="FIRST_NAME" required />
    <Field type="email" name="email" label="EMAIL" />
    <Field type="text" name="client_employee_id" label="CLIENT_EMPLOYEE_ID" required />
    <GenerateButton
      disabled={
        !(
          values.first_name &&
          values.first_name.length > 0 &&
          values.last_name &&
          values.last_name.length > 0
        )
      }
      onClick={() =>
        setFieldValue(
          'client_employee_id',
          generateId(values.first_name, values.last_name, client_employee_ids)
        )
      }
    >
      <Caption name="GENERATE" />
    </GenerateButton>
    <SubmitButton disabled={isSubmitting} />
  </Form>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

class EmployeeCreationForm extends React.Component {
  getCompanyId = () => this.props.match.params.company_id;

  validate = ({ first_name, last_name, client_employee_id }) => {
    const errors = {};
    if (!first_name || first_name.length === 0) {
      errors.first_name = 'MISSING_FIELD';
    }
    if (!last_name || last_name.length === 0) {
      errors.last_name = 'MISSING_FIELD';
    }
    if (!client_employee_id || client_employee_id.length === 0) {
      errors.client_employee_id = 'MISSING_FIELD';
    }
    if (
      this.props.client_employee_ids
        .map(id => normalize(id))
        .includes(normalize(client_employee_id))
    ) {
      errors.client_employee_id = 'EMPLOYEE_ID_CONFLICT';
    }
    return errors;
  };

  render() {
    const { onSubmit, error, client_employee_ids } = this.props;
    return (
      <Container>
        <Title name="EMPLOYEE_CREATION" style={{ marginBottom: 30 }} />
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            email: '',
            client_employee_id: ''
          }}
          onSubmit={async (values, actions) => {
            await onSubmit(values);
            actions.setSubmitting(false);
          }}
          validate={this.validate}
          render={p => (
            <EmployeeCreationFormRender client_employee_ids={client_employee_ids} {...p} />
          )}
        />
        {error && <ErrorText name="ERROR_OCURRED" style={{ marginTop: 10 }} />}
      </Container>
    );
  }
}
export default withRouter(EmployeeCreationForm);
