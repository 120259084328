import React from 'react';
import { connect } from 'react-redux';

import api from 'api';
import Async from 'library/Async';

import ProductsPane from './ProductsPane';

const itemToProduct = item => ({
  id: item.product_id,
  name: item.product_name,
  client_product_id: item.client_product_id,
  gender_name: item.gender_id === 1 ? 'female' : 'male'
});

const productToItem = (product, genderId) => ({
  client_product_id: product.client_product_id,
  enable_preferred_fit: null,
  gender_id: genderId,
  product_id: product.id,
  product_name: product.name,
  quantity: null,
  size_advices: product.size_advices
});

const mergeItems = (products, items, genderId) => {
  const keyedItems = items.reduce((acc, i) => {
    acc[i.product_id] = i;
    return acc;
  }, {});
  return products.map(p => {
    const item = productToItem(p, genderId);
    const oldItem = keyedItems[item.product_id];
    if (oldItem) {
      item.id = oldItem.id;
      item.quantity = oldItem.quantity;
      item.enable_preferred_fit = oldItem.enable_preferred_fit;
      item.size_advices = oldItem.size_advices || item.size_advices;
    }
    return item;
  });
};

const Products = ({ match, history, maleItems, femaleItems, updatePosition }) => {
  const fetchProducts = async () => await api.products.list();

  const onSubmit = selectedProducts => {
    updatePosition({
      maleItems: mergeItems(selectedProducts.maleProducts, maleItems, 2),
      femaleItems: mergeItems(selectedProducts.femaleProducts, femaleItems, 1)
    });
    history.push(`/position/${match.params.campaign_id}/quantity`);
  };

  return (
    <Async fetchProps={fetchProducts}>
      {products => (
        <ProductsPane
          products={products}
          initValues={{
            maleProducts: maleItems.map(itemToProduct),
            femaleProducts: femaleItems.map(itemToProduct)
          }}
          onSubmit={onSubmit}
        />
      )}
    </Async>
  );
};

const mapper = state => ({
  femaleItems: state.position.femaleItems,
  maleItems: state.position.maleItems
});

const dispatcher = dispatch => ({
  updatePosition: items => dispatch({ type: 'UPDATE_POSITION', ...items })
});

export default connect(mapper, dispatcher)(Products);
