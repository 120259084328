import React from 'react';
import { PhysicalInput } from '../base';
import { Caption, CaptionMedium } from '../../text';

const AgeInput = ({ limits, age, defaultValue, onChange }) => {
  const displayValue = () =>
    age ? (
      <>
        <CaptionMedium text={age} />
        <Caption name="YEARS" style={{ marginLeft: '0.325rem' }} />
      </>
    ) : (
      <>
        <Caption text="-" style={{ marginRight: '0.650rem' }} />
      </>
    );
  const handleChange = value => {
    onChange(value, true);
  };

  return (
    <PhysicalInput
      inputId="age-slider"
      name="AGE"
      required={true}
      value={age}
      onChange={handleChange}
      limits={limits}
      displayValue={displayValue()}
      defaultValue={defaultValue}
    />
  );
};

export default AgeInput;
