import React from 'react';
import Position from './Position';

const PositionListing = ({ positions, onDelete, onClickEdit }) => (
  <>
    {positions.map((position, i) => (
      <Position
        key={i}
        position={position}
        onDelete={() => onDelete(position)}
        onClickEdit={() => onClickEdit(position)}
      />
    ))}
  </>
);

export default PositionListing;
