import React from 'react';
import colors from 'shared_components/styles/colors';
import FaRocket from 'react-icons/lib/fa/rocket';
import FaBuildingO from 'react-icons/lib/fa/building-o';
import IoAndroidPerson from 'react-icons/lib/io/android-person';
import IoAndroidPeople from 'react-icons/lib/io/android-people';
import FaSuitcase from 'react-icons/lib/fa/suitcase';
import IoShirt from 'react-icons/lib/io/tshirt-outline';
import FaCogs from 'react-icons/lib/fa/cogs';
import FaPlus from 'react-icons/lib/fa/plus';
import FaTrash from 'react-icons/lib/fa/trash';
import FaClose from 'react-icons/lib/fa/close';
import MdFingerprint from 'react-icons/lib/md/fingerprint';
import IoMoreV from 'react-icons/lib/io/android-more-vertical';
import MdSend from 'react-icons/lib/md/send';
import MdPersonAdd from 'react-icons/lib/md/person-add';
import MdGroupAdd from 'react-icons/lib/md/group-add';
import MdInfoOutline from 'react-icons/lib/md/info-outline';
import MdWork from 'react-icons/lib/md/work';
import MdArrowForward from 'react-icons/lib/md/arrow-forward';
import MdCheck from 'react-icons/lib/md/check';

export const Campaign = FaRocket;
export const Company = FaBuildingO;
export const Employee = IoAndroidPerson;
export const Employees = IoAndroidPeople;
export const Position = FaSuitcase;
export const Product = IoShirt;
export const Operation = FaCogs;
export const Plus = FaPlus;
export const Trash = p => <FaTrash color={colors.darkRed} {...p} />;
export const Check = MdCheck;
export const Close = FaClose;
export const Admin = MdFingerprint;
export const KebabMenu = IoMoreV;
export const Send = MdSend;
export const AddPerson = MdPersonAdd;
export const AddGroup = MdGroupAdd;
export const Info = MdInfoOutline;
export const ArrowForward = MdArrowForward;
export const Work = MdWork;
