import React from 'react';
import { signup } from 'services/auth';
import { Formik } from 'formik';
import { Form, Field } from 'library/forms';
import { SubmitButton } from 'library/buttons';
import AuthContainer from './AuthContainer';

//todo: move somewhere else, this is useless
const AuthFormRender = ({ errors, touched, isSubmitting }) => (
  <Form errors={errors} touched={touched} style={{ display: 'flex', flexDirection: 'column' }}>
    <Field type="password" autoComplete="new-password" name="password" label="PASSWORD" />
    <Field
      type="password"
      autoComplete="new-password"
      name="confirm"
      position="bottom"
      label="CONFIRM_PASSWORD"
    />
    <SubmitButton disabled={isSubmitting}>Submit</SubmitButton>
  </Form>
);

const validate = ({ password, confirm }) => {
  const errors = {};
  if (!password || password.length < 6) {
    errors.password = 'INVALID_PASSWORD';
  }
  if (password !== confirm) {
    errors.confirm = 'INVALID_PASSWORD_CONFIRM';
  }
  return errors;
};

const AuthForm = ({ onSubmit }) => {
  return (
    <Formik
      initialValues={{ password: '', confirm: '' }}
      onSubmit={async (values, actions) => {
        await onSubmit(values);
        actions.setSubmitting(false);
      }}
      validate={values => validate(values)}
      render={AuthFormRender}
    />
  );
};

class Signup extends React.Component {
  signup = ({ password }) => {
    const invitation_token = this.props.match.params.token;
    signup({ invitation_token, password });
  };
  render() {
    return (
      <AuthContainer title="SIGNUP">
        <AuthForm onSubmit={this.signup} />
      </AuthContainer>
    );
  }
}

export default Signup;
