import React from 'react';
import { Dialog } from 'library/dialogs';
import { Formik } from 'formik';
import { Title } from 'shared_components';
import { withRouter } from 'react-router';
import styled from 'styled-components/macro';

import { Form, Field } from 'library/forms';
import api from 'api';
import { SubmitButton, CancelButton, ButtonRow } from 'library/buttons';
import Async from 'library/Async';
import { normalize } from 'services/utils';

const EditDetailsFormRender =
  ({ onClose, employees }) =>
  ({ errors, touched, isSubmitting, values }) =>
    (
      <Form
        errors={errors}
        touched={touched}
        values={values}
        style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
      >
        <Field type="text" name="client_employee_id" label="IDENTIFIER" required />
        <Field type="text" name="last_name" label="LAST_NAME" required />
        <Field type="text" name="first_name" label="FIRST_NAME" required />
        <Field type="email" name="email" label="EMAIL" />
        <ButtonRow>
          <SubmitButton disabled={isSubmitting} />
          <CancelButton onClick={onClose} />
        </ButtonRow>
      </Form>
    );

const validate = ({ first_name, last_name, client_employee_id }, clientEmployeeIds) => {
  const errors = {};

  if (!first_name || first_name.length === 0) {
    errors.first_name = 'MISSING_FIELD';
  }
  if (!last_name || last_name.length === 0) {
    errors.last_name = 'MISSING_FIELD';
  }
  if (!client_employee_id || client_employee_id.length === 0) {
    errors.client_employee_id = 'MISSING_FIELD';
  } else if (clientEmployeeIds.map(id => normalize(id)).includes(normalize(client_employee_id))) {
    errors.client_employee_id = 'EMPLOYEE_ID_CONFLICT';
  }

  return errors;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

class EditDetailsForm extends React.Component {
  getCompanyId = () => this.props.match.params.company_id;

  fetchOtherEmployeesIds = async () => {
    const employees = await api.employees.list({
      company_id: this.getCompanyId()
    });
    return employees
      .map(e => e.client_employee_id)
      .filter(id => id !== this.props.employee.client_employee_id);
  };

  render() {
    const { employee, onClose, onSubmit } = this.props;
    if (employee) {
      return (
        <Dialog isOpen onClose={onClose} usePortal={false}>
          <Container>
            <Title name="EMPLOYEE_EDIT" style={{ marginBottom: 30 }} />
            <Async
              fetchProps={this.fetchOtherEmployeesIds}
              render={clientEmployeeIds => (
                <Formik
                  initialValues={employee}
                  onSubmit={async (values, actions) => {
                    await onSubmit(values);
                    actions.setSubmitting(false);
                  }}
                  validate={values => validate(values, clientEmployeeIds)}
                  render={EditDetailsFormRender({ onClose })}
                />
              )}
            />
          </Container>
        </Dialog>
      );
    } else {
      return null;
    }
  }
}

export default withRouter(EditDetailsForm);
