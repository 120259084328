import React from 'react';
import styled from 'styled-components/macro';
import { MenuItem, AnchorButton, Tooltip } from '@blueprintjs/core';
import { media, colors, MainTitle, TitleMedium, Body } from 'shared_components';

import { isNullOrUndefined } from 'services/utils';
import logo from 'assets/images/sizerpro-logo-white-v2.svg';
import { Employee, Work, Info } from 'library/icons';
import { StandardButton } from 'library/buttons';

import Feedback from './Feedback';
import ProgressRow from './ProgressRow';

export const InfoIcon = styled(Info)`
  font-size: 4em;
  color: ${colors.lightGrey};
  margin: 2.5rem 0;

  ${media.phone`
    margin-top: 0;
  `}
`;

export const IconCheckContainer = styled.div`
  height: 2em;
  width: 2em;
  border-radius: 50%;
  color: white;
  background-color: ${props => props.backgroundColor};
  margin-left: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.phone`
    margin-left: 0em;
  `}
`;

const Page = styled.div`
  min-height: 100vh;

  /* only applied to IE 11 */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 100vh;
  }
`;

export const DarkPage = styled(Page)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(59, 68, 75);
  padding: 2rem 1rem;
  max-width: 50rem;
  margin: 0 auto;
  ${media.phone`
    padding: 0.2rem 0.5rem;
  `}
  ${media.phoneMedium`
    padding: 0rem 0.5rem;
  `}
`;

export const CompanyName = styled(MainTitle)`
  margin: 1rem;
  color: ${colors.white};
`;

export const Card = styled.div`
  background-color: ${colors.white};
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 95%;
  margin: 1rem 0.625em;
  border-radius: 0.2em;
  line-height: 1.5;

  ${media.phone`
    padding: 1rem;
    width: 100%;
  `}
`;

export const CardTitle = styled(TitleMedium)`
  margin-top: 1em;
  margin-bottom: 1em;
  ${media.phone`
    margin-top: 1vh;
    font-size: max(1rem, 2vh);
  `}
  ${media.phoneSmall`
    margin-top: 1vh;
    font-size: max(1rem, 2vh);
  `}
  ${media.phoneLarge`
    margin-top: 0.5em;
    font-size: 1.3rem;
  `}
`;

const SizedImg = styled.img`
  margin: 1rem 1rem;
  width: 6em;
  ${media.phone`
    margin: 0.4rem 1rem;
  `}
`;
export const SizerproLogo = () => <SizedImg src={logo} alt="sizerpro-logo" />;
const StyledLightPage = styled(Page)`
  margin: 0 auto;
  max-width: 55rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.white};

  ${media.phone`
    flex: 1;
  `}
  ${media.phoneLarge`
    width: 100%;
    height: 100%;
  `}
  /*
  This is to force components repainting on Safari Mobile. There is an issue
  with components that have 'position: relative' inside a scrollable parent. Components are rendered correctly, but not painted by Safari and therefore
  appear blank on the page. Interacting with them will trigger a new painting and a correct display of the components.
  Forcing the hardware acceleration is a workaround. More details here https://github.com/janpaepke/ScrollMagic/issues/467
  On Safari Mobile children components with 'position: relative' should not 'disappear' on scroll anymore.
  */
  -webkit-transform: translate3d(0, 0, 0);
`;

// flex-grow, flex-basis instead of flex: 1 for IE 11
const LightPageBody = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100vw;
  max-width: 50rem;
  margin-top: 5rem;

  ${media.phone`
    margin-top:0;
    flex: 1;
    width: 100%;
    max-height:100%;
    padding: 1.25em 0.625em;
  `}
  ${media.phoneVerySmall`
    padding: 0em 0em;
    width: 100%;
    max-height:100%;
    justify-content: space-between;
  `}
  ${media.phoneSmall`
    padding: 0em 0em;
    width: 100%;
    max-height:100%;
    justify-content: space-between;
  `}
  ${media.phoneMedium`
    padding: 0em 0em;
    width: 100%;
    max-height:100%;
    justify-content: space-between;
  `}
  ${media.phoneLarge`
    flex: 1;
    width: 100%;
    max-height:100%;
    padding: 0;
    justify-content: space-between;
  `}
`;

export const LightPage = ({ currentProgress, children }) => (
  <StyledLightPage>
    {!isNullOrUndefined(currentProgress) && (
      <>
        <Feedback />
        <ProgressRow currentProgress={currentProgress} />
      </>
    )}
    <LightPageBody>{children}</LightPageBody>
  </StyledLightPage>
);

const PageTitle = styled(MainTitle)`
  text-align: center;
  ${media.phoneVerySmall`
    line-height: 1;
    font-size: max(6vw, 1rem);
    margin: min(0.5em,1vh) 0em 0.2em 0em;
  `}
  ${media.phoneSmall`
    line-height: 1;
    font-size: max(6vw, 1rem);
    margin: min(0.5em,1vh) 0em 0.2em 0em;
  `}
  ${media.phoneMedium`
    line-height: 1;
    font-size: max(6vw, 1rem);
    margin: min(0.5em,1vh) 0em 0.2em 0em;
  `}
  ${media.phoneLarge`
    line-height: 1;
    font-size: max(6vw, 1rem);
    margin: 1em 0em 0.2em 0em;
  `}
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.7em;
`;
const PersonalDetailsRow = styled(FlexRow)`
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  ${media.phoneVerySmall`
    justify-content: center;
    width:70%;
    margin: 0.1em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `}
  ${media.phoneSmall`
    justify-content: center;
    width:70%;
    margin: 0.1em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `}
  ${media.phoneMedium`
    width:70%;
    margin: 0.1em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `}
  ${media.phoneLarge`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0.15em 0.5em;
  `}
`;

const RowItem = styled.div`
  margin: 0.25em 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.grey};
  line-height: 1em;

  ${media.phone`
    margin: 0.25rem 1rem;
  `}
  ${media.phoneVerySmall`
    font-size: 1.1rem;
    margin: 0.1em 0;
  `}
  ${media.phoneSmall`
    font-size: 1.1rem;
    margin: 0.1em 0;
  `}
  ${media.phoneMedium`
    font-size: 1.1rem;
    margin: 0.1em 0;
  `}
  ${media.phoneLarge`
    margin: 0.1em 2em;
    line-height: 1rem;
  `}
`;

const RowIcon = styled.div`
  color: ${colors.mainBlue};
  margin-right: 0.5rem;
  ${media.phoneLarge`
    font-size: 1.25rem;
    margin-right: 0.2rem;
  `}
`;

const RowText = styled(Body)`
  max-width: 15.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${media.phone`
    max-width: 12.5rem;
  `}
  ${media.phoneVerySmall`
    font-size: 0.8rem;
    margin: 0em;
  `}
  ${media.phoneSmall`
    font-size: 0.8rem;
    margin: 0em;
  `}
  ${media.phoneMedium`
    font-size: 0.8rem;
    margin: 0em;
  `}
  ${media.phoneLarge`
    font-size: 0.8rem;
  `}
`;

const PersonalDetails = ({ firstName, lastName, positionName }) => (
  <PersonalDetailsRow>
    <RowItem>
      <RowIcon>
        <Employee />
      </RowIcon>
      <RowText text={`${firstName} ${lastName}`} />
    </RowItem>
    <RowItem>
      <RowIcon>
        <Work />
      </RowIcon>
      <RowText text={positionName} />
    </RowItem>
  </PersonalDetailsRow>
);

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 0 0em 0;
  min-height: 5em;
  ${media.phone`
    width: 100vw;
    margin-top: 0;
    min-height: 3em;
  `}
  ${media.phoneVerySmall`
    margin: 0em 0;
    min-height: 0em;
  `}
  ${media.phoneSmall`
    margin: 0em 0;
    min-height: 0em;
  `}
  ${media.phoneMedium`
    margin: 0em 0;
    min-height: 0em;
  `}
  ${media.phoneLarge`
    margin: 0.2em 0;
    width:95%;
  `}
`;

export const Header = ({ titleName, details, hidePersonalDetails }) => {
  const { first_name, last_name, position_name } = details;
  return (
    <HeaderContainer>
      <PageTitle name={titleName} />
      {!hidePersonalDetails && (
        <PersonalDetails firstName={first_name} lastName={last_name} positionName={position_name} />
      )}
    </HeaderContainer>
  );
};

export const BottomButtonsRow = styled.div`
  width: 90%;
  padding: 0.5em 0 2.5em 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  ${media.phone`
    padding:0;
    margin: 2.5em 0 0 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
  ${media.phoneVerySmall`
    margin: 0.15rem auto 0.8rem auto;
    width: 90%;
    height: 15vh;
    max-height: 5.6em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.1em;
  `};
  ${media.phoneSmall`
    margin: 0.15rem auto 0.8rem auto;
    width: 90%;
    height: 15vh;
    max-height: 5.6em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.1em;
  `};
  ${media.phoneMedium`
    margin: 0.15rem auto 1em auto;
    width: 90%;
    height: 15vh;
    max-height: 5.6em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.1em;
  `};
  ${media.phoneLarge`
    margin: 0.15em auto 0em auto;
    width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 1em;
  `}
`;

export const BottomBackButton = styled(StandardButton)`
  box-shadow: none;
  border-radius: 5px;
  padding: 0.5rem 2rem;
  height: 2.25rem;
  border: 1px solid ${colors.grey};
  background-color: ${colors.white};
  :hover {
    background-color: ${colors.lightGrey};
    filter: brightness(1);
  }
  ${media.phone`
    order: 2;
    width: 100%;
    margin-top: 1.5em;
  `}
  ${media.phoneVerySmall`
      order: 1;
      width: 100%;
      margin-top: 0.4rem;
  `}
  ${media.phoneSmall`
      order: 1;
      width: 100%;
      margin-top: 0.4rem;
  `}
  ${media.phoneMedium`
      order: 1;
      width: 100%;
      margin-top: 1em;
  `}
`;

export const BottomTooltip = styled(Tooltip)`
  ${media.phone`
    width: 100%;
  `}
`;

export const ColoredAnchorButton = styled(AnchorButton)`
  &&&& {
    height: 2.25rem;
    box-shadow: none;
    border: none;
    border-radius: 0.3125em;
    padding: 0.5rem 2rem;
    color: white;
    background-color: ${props => (props.disabled ? colors.grey : colors.mainBlue)};
    background-image: none;
    :hover {
      ${props => (props.disabled ? null : `filter: brightness(105%)`)};
    }

    ${media.phone`
      order: 1;
      width: 100%;
    `}
  }
`;

export const BottomSubmitButton = styled(StandardButton)`
  height: 2.25rem;
  box-shadow: none;
  border: none;
  border-radius: 0.3125em;
  padding: 0.5rem 2rem;
  color: white;
  background-color: ${props => (props.disabled ? colors.grey : colors.mainBlue)};
  background-image: none;
  :hover {
    ${props => (props.disabled ? null : `filter: brightness(105%)`)};
  }

  ${media.phone`
    order: 1;
    width: 90%;
  `}
`;

export const StyledMenuItem = styled(MenuItem)`
  &&&& {
    color: black;
    background-color: ${props => (props.active ? colors.lightBlue : colors.white)};
    :hover {
      background-color: ${props => (props.active ? colors.lightBlue : colors.lightGrey)};
    }
  }
`;
