import { post, get, patch, _delete, put, download } from './util';

const API = {
  operators: {
    login: params => post('/v1/sizerpro/operators/login', params),
    signup: params => post('/v1/sizerpro/operators/signup', params),
    create: params => post('/v1/sizerpro/operators', params, true),
    update: params => patch('/v1/sizerpro/operators', params, true),
    delete: params => _delete('/v1/sizerpro/operators', params, true),
    forgotPassword: params => post('/v1/sizerpro/operators/forgot_password', params),
    forgotPasswordReset: params => post('/v1/sizerpro/operators/forgot_password_reset', params),
    resetPassword: params => post('/v1/sizerpro/operators/reset_password', params, true),
    list: params => get('/v1/sizerpro/operators', params, true)
  },
  companies: {
    list: params => get('/v1/sizerpro/companies', params, true),
    create: params => post('/v1/sizerpro/companies', params, true),
    delete: company_id => _delete(`/v1/sizerpro/companies/${company_id}`, undefined, true),
    get: company_id => get('/v1/sizerpro/companies/' + company_id, {}, true),
    exportCampaigns: ({ company_id, ...params }) => {
      return download(`/v1/sizerpro/companies/${company_id}/exports`, params, true);
    }
  },

  campaigns: {
    list: params => {
      if (!params || params.company_id === undefined)
        return get('/v1/sizerpro/campaigns', params, true);
      const { company_id, ...rest } = params;
      return get(`/v1/sizerpro/companies/${company_id}/campaigns`, rest, true);
    },
    create: params => post(`/v1/sizerpro/companies/${params.company_id}/campaigns`, params, true),
    delete: params => {
      const { id, ...rest } = params;
      return _delete(`/v1/sizerpro/campaigns/${params.id}`, rest, true);
    },
    update: params => {
      const { id, ...rest } = params;
      return patch(`/v1/sizerpro/campaigns/${params.id}`, rest, true);
    },
    get: params => get(`/v1/sizerpro/campaigns/${params.id}`, undefined, true),
    listEmployees: params => {
      const { campaign_id, ...rest } = params;
      return get(`/v1/sizerpro/campaigns/${campaign_id}/employees`, rest, true);
    },
    updateEmployees: params => {
      const { campaign_id, ...rest } = params;
      return patch(`/v1/sizerpro/campaigns/${campaign_id}/employees`, rest, true);
    },
    availableEmployees: ({ campaign_id }) => {
      return get(`/v1/sizerpro/campaigns/${campaign_id}/available_employees`, undefined, true);
    },
    availablePositions: ({ campaign_id }) => {
      return get(`/v1/sizerpro/campaigns/${campaign_id}/available_positions`, undefined, true);
    },
    export: ({ campaign_id, ...params }) => {
      return download(`/v1/sizerpro/campaigns/${campaign_id}/exports`, params, true);
    },
    exportRecomputed: ({ campaign_id, ...params }) => {
      return download(`/v1/sizerpro/campaigns/${campaign_id}/exports/recomputed`, params, true);
    },
    exportAll: params => download('/v1/sizerpro/campaigns/exports', params, true),
    mailEmployees: ({ campaign_id, campaign_employee_ids, language }) => {
      return post(
        `/v1/sizerpro/campaigns/${campaign_id}/mail_employees`,
        { campaign_employee_ids, language },
        true
      );
    }
  },
  employees: {
    list: params => {
      const { company_id, ...rest } = params;
      if (!company_id) {
        throw new Error(`company_id must be provided`);
      }
      return get(`/v1/sizerpro/companies/${company_id}/employees`, rest, true);
    },
    create: params => {
      const { company_id, ...rest } = params;
      if (!company_id) {
        throw new Error(`company_id must be provided`);
      }
      return post(`/v1/sizerpro/companies/${company_id}/employees`, rest, true);
    },
    delete: ({ employee_id }) => _delete(`/v1/sizerpro/employees/${employee_id}`, undefined, true),
    massDelete: ({ employee_ids }) =>
      _delete(`/v1/sizerpro/employees`, undefined, true, false, { employee_ids }),
    update: params => {
      const { id: employee_id, ...rest } = params;
      return patch(`/v1/sizerpro/employees/${employee_id}`, rest, true);
    },
    uploadCSV: params => {
      const { company_id, formData } = params;
      return post(`/v1/sizerpro/companies/${company_id}/employees?upload=file`, formData, true);
    }
  },
  positions: {
    list: params => {
      const { campaign_id, ...rest } = params;
      if (!campaign_id) {
        throw new Error(`campaign_id must be provided`);
      }
      return get(`/v2/sizerpro/campaigns/${campaign_id}/positions`, rest, true);
    },
    create: ({ campaign_id, ...rest }) => {
      if (!campaign_id) {
        throw new Error(`campaign_id must be provided`);
      }
      return post(`/v2/sizerpro/campaigns/${campaign_id}/positions`, rest, true);
    },
    edit: ({ position_id, ...data }) => patch(`v2/sizerpro/positions/${position_id}`, data, true),
    delete: ({ id }) => _delete(`/v1/sizerpro/positions/${id}`, undefined, true)
  },
  products: {
    list: () => {
      return get('/v2/sizerpro/products', undefined, true);
    }
  },
  collections: {
    list: () => {
      return get('/v1/sizerpro/collections', undefined, true);
    },
    listProducts: ({ collection_id }) => {
      return get(`/v1/sizerpro/collections/${collection_id}/products`, undefined, true);
    },
    get: ({ collection_id }) => {
      return get(`/v1/sizerpro/collections/${collection_id}`, undefined, true);
    }
  },
  campaignEmployees: {
    get: params => {
      const { campaign_employee_id, ...rest } = params;
      return get(`/v1/sizerpro/campaign_employees/${campaign_employee_id}`, rest, true);
    },
    auth: params => {
      return get(`/v1/sizerpro/campaign_employees/auth`, params, false);
    },
    delete: params => {
      const { campaign_employee_id, ...rest } = params;
      return _delete(`/v1/sizerpro/campaign_employees/${campaign_employee_id}`, rest, true);
    },
    setPosition: params => {
      const { campaign_employee_id, position_id } = params;
      return patch(
        `/v1/sizerpro/campaign_employees/${campaign_employee_id}`,
        { position_id },
        true
      );
    },
    setAbsence: params => {
      const { campaign_employee_id, absent } = params;
      return patch(`/v1/sizerpro/campaign_employees/${campaign_employee_id}`, { absent }, true);
    },
    getPhysicalInfo: params => {
      const { campaign_employee_id, ...rest } = params;
      return get(
        `/v1/sizerpro/campaign_employees/${campaign_employee_id}/physical_info`,
        rest,
        true
      );
    },
    updatePhysicalInfo: params => {
      const { campaign_employee_id, ...rest } = params;
      return put(
        `/v1/sizerpro/campaign_employees/${campaign_employee_id}/physical_info`,
        rest,
        true
      );
    },
    updateBodyShape: params => {
      const { campaign_employee_id, ...rest } = params;
      return put(`/v1/sizerpro/campaign_employees/${campaign_employee_id}/body_shapes`, rest, true);
    },
    postFeedback: data => {
      return post('/v1/sizerpro/user_funnel_feedbacks', data, true);
    },
    getReferences: params => {
      const { campaign_employee_id, ...rest } = params;
      return get(`/v1/sizerpro/campaign_employees/${campaign_employee_id}/references`, rest, true);
    },
    updateReferences: params => {
      const { campaign_employee_id, ...rest } = params;
      return put(`/v1/sizerpro/campaign_employees/${campaign_employee_id}/references`, rest, true);
    },
    getResults: params => {
      const { campaign_employee_id, ...rest } = params;
      return get(`/v4/sizerpro/campaign_employees/${campaign_employee_id}/results`, rest, true);
    },
    updateResults: params => {
      const { campaign_employee_id, ...rest } = params;
      return put(`/v1/sizerpro/campaign_employees/${campaign_employee_id}/results`, rest, true);
    },
    getMeasurementLabels: ({ language }) => {
      return get('/v1/sizerpro/campaigns/exports/measurement_labels', { language }, true);
    },
    uploadCSV: params => {
      const { campaign_id, formData } = params;
      return post(`/v1/sizerpro/campaigns/${campaign_id}/employees?upload=file`, formData, true);
    }
  },
  references: {
    getAllV2: (gender, type_of_references, client_id, numberOfMostUSedWanted) => {
      return get('/v2/plugin_references', {
        gender,
        type_of_references,
        client_id,
        number_of_most_used: numberOfMostUSedWanted
      });
    }
  }
};

export default API;
