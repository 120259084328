import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { media } from 'shared_components';

import { FlagSelector } from 'shared_components';
import { languages } from 'services/i18n';

const FlagContainer = styled.div`
  width: 2.4rem;
  margin: auto;

  button {
    background-color: transparent;
  }
  ${media.phoneLarge`
    margin: 0.3rem;
  `}
`;

const LanguageSelector = ({ currentLanguage, changeLanguage }) => (
  <FlagContainer>
    <FlagSelector
      value={currentLanguage}
      options={languages.map(lang => ({
        label: lang,
        value: lang
      }))}
      selectFlag={option => option.value && changeLanguage(option.value)}
    />
  </FlagContainer>
);

const mapper = state => ({
  currentLanguage: state.settings.language
});

const dispatcher = dispatch => ({
  changeLanguage: language => dispatch({ type: 'CHANGE_LANGUAGE', language })
});

export default connect(mapper, dispatcher)(LanguageSelector);
