import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { media, colors, Caption, SmallText, Callout } from 'shared_components';
import {
  LightPage,
  Header,
  BottomButtonsRow,
  BottomTooltip,
  BottomBackButton,
  ColoredAnchorButton
} from '../common/styles';

import api from 'api';
import { Loading, Error } from 'library/Async';
import ClothForm from './ClothForm';
import ShoeForm from './ShoeForm';
import ConfirmationDialog from './ConfirmationDialog';

const RoburMessage = styled.p`
  background-color: #ffdd9e8a;
  padding: 25px 20px;
  margin: 0 0 25px 0;
  border-radius: 3px;
  color: #ce820e;
  font-size: 14px;
  line-height: 1.5;
  ${media.phoneLarge`
    border: solid green ;
  `}
`;
const CalloutRecoItems = styled(Callout)`
  width: 90%;
  display: flex;
  justify-content: flex-start;
  margin: 2rem auto 2rem auto;
  ${media.phone`
    margin: 2rem auto 0rem auto;
  `}
  ${media.phoneLarge`
    margin: 3rem auto 0rem auto;
  `}
`;
const PositionItemsScreen = ({ details, history, match, language, location }) => {
  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false);
  const [resultsByProduct, setResultsByProduct] = useState([]);
  const [userMeasurements, setUserMeasurements] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [canFinalize, setCanFinalize] = useState(false);

  useEffect(() => {
    const fetchCampaignEmployeeResult = async () => {
      try {
        const res = await api.campaignEmployees.getResults({
          campaign_employee_id: details.id,
          token: match.params.campaign_employee_token
        });
        setResultsByProduct(res.results_by_product);
        setUserMeasurements(res.user_measurements);
        setLoading(res.loading);
      } catch (e) {
        setError(true);
        setLoading(false);
      }
    };
    fetchCampaignEmployeeResult();
  }, [details.id, match.params.campaign_employee_token]);

  const onSubmit = async () => {
    setSubmitting(true);
    const token = match.params.campaign_employee_token;
    const results = resultsByProduct.map(result => ({
      product_id: result.product_id,
      recommended_size_id: result.recommended_size_id,
      quantity: result.quantity,
      chosen_size_id: result.chosen_size_id
    }));
    const message = await api.campaignEmployees.updateResults({
      token,
      campaign_employee_id: details.id,
      results,
      send_confirmation_mail: true,
      language: language.toLowerCase()
    });
    return message;
  };

  const onConfirmation = async () => {
    try {
      const message = await onSubmit();
      const token = match.params.campaign_employee_token;
      history.push(`/clothes_attribution/${token}/end`, {
        mailSent: message === 'MAIL SENT'
      });
    } catch (e) {
      // An error occured, we want to let the user retry another submit
      setSubmitting(false);
    }
  };

  const updateResult = index => result => {
    setResultsByProduct(prevResultsByProduct => {
      const newResultsByProduct = [...prevResultsByProduct];
      newResultsByProduct[index] = { ...newResultsByProduct[index], ...result };
      return newResultsByProduct;
    });
  };

  useEffect(() => {
    setCanFinalize(resultsByProduct.every(r => r.validated));
  }, [resultsByProduct]);

  const handleFinalizeClick = async () => {
    const returnPathname = location.state && location.state.return_pathname;
    if (returnPathname) {
      // We come from the campaign operation screen so we want to be quick
      // => no confirmation dialog, back to campaign operation
      await onSubmit(true);
      history.push(returnPathname);
    } else {
      setConfirmationDialogOpened(true);
    }
  };

  const renderForms = () => {
    let clothCount = 0;
    return resultsByProduct.map((result, index) => {
      if (result.cloth_type !== 'feet') {
        clothCount += 1;
        return (
          <ClothForm
            key={result.client_product_id + index}
            showTooltip={clothCount === 1 && !result.validated}
            clothResult={result}
            userMeasurements={userMeasurements}
            updateResult={updateResult(index)}
            gender={details.gender_name}
          />
        );
      } else {
        return (
          <ShoeForm
            key={result.client_product_id + index}
            shoeResult={result}
            updateResult={updateResult(index)}
          />
        );
      }
    });
  };

  return (
    <LightPage currentProgress={3}>
      <Header titleName="SIZES_CHECK" details={details} hidePersonalDetails={false} />
      {
        /* TODO remove ! Super ugly !! But necessary for Robur right now. To be replace with specific comment for each product */
        details.client_id === 545 && (
          <RoburMessage>
            <span role="img" aria-label="warning-emoji" style={{ marginRight: 5 }}>
              ⚠️
            </span>{' '}
            Il est possible que la taille recommandée soit différente de la taille que vous portez
            habituellement dans le commerce. C'est normal car les vêtements professionnels peuvent
            avoir des taillants spécifiques.
          </RoburMessage>
        )
      }
      <CalloutRecoItems intent="info" backgroundColor={colors.lighterGrey} iconColor={colors.black}>
        <div>
          <Caption name="NUMBER_ITEMS_TO_VALIDATE_CALLOUT_START" />
          <Caption
            name="NUMBER_ITEMS_TO_VALIDATE_CALLOUT_BODY"
            values={{ n_items: resultsByProduct.length }}
            style={{ fontWeight: 600 }}
          />
          <Caption name="NUMBER_ITEMS_TO_VALIDATE_CALLOUT_END" />
        </div>
      </CalloutRecoItems>
      {loading ? <Loading /> : error ? <Error /> : renderForms()}
      <BottomButtonsRow>
        <BottomBackButton onClick={() => history.goBack()}>
          <Caption name="BACK" color={colors.darkGrey} />
        </BottomBackButton>
        <BottomTooltip
          disabled={canFinalize}
          content={<SmallText name="VALIDATE_BEFORE_FINALIZE" color="white" />}
        >
          <ColoredAnchorButton disabled={!canFinalize} onClick={handleFinalizeClick}>
            <Caption name="FINALIZE_CHOICES" color="white" />
          </ColoredAnchorButton>
        </BottomTooltip>
      </BottomButtonsRow>
      <ConfirmationDialog
        isOpen={confirmationDialogOpened}
        onClose={() => setConfirmationDialogOpened(false)}
        campaign_employee_token={match.params.campaign_employee_token}
        onSubmit={onConfirmation}
        submitting={submitting}
      />
    </LightPage>
  );
};

export default connect(state => ({
  language: state.settings.language
}))(PositionItemsScreen);
