import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import Trash from 'react-icons/lib/io/trash-b';
import MdAddCircleOutline from 'react-icons/lib/md/add-circle-outline';

import BoobPickerForm from './BoobPickerForm';
import { getDefaultRegion } from './utils';
import Flag from '../../library/FlagSelector/Flag';
import { Caption } from '../../text';
import { localizedString } from '../../services/i18n/translations';
import { colors, media } from 'shared_components/styles';

import { MobilePhysicalLine, MobilePhysicalLineTexts } from '../styles';
import { SLIDE_VERTICAL_TIMEOUT } from '../../styles/animations/GlobalSlideAnimationsStyle';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;

  width: auto;
  justify-content: flex-end;
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  background-color: white;
  cursor: pointer;
  padding: 0.5em;
  border: none;
  color: ${props => (props.highlight ? props.theme.primaryColor : colors.coal)};
  font-size: 0.75rem;

  :hover {
    opacity: 0.5;
  }

  margin: auto;

  ${media.phone`
    border-radius: 3px;
    border: 0.125rem solid;
    border-color: ${props =>
      props.active || props.highlight ? props.theme.primaryColor : colors.black};
  `}
`;

const AddIcon = styled(MdAddCircleOutline)`
  margin-right: 0.5em;
  height: 1rem;
  width: 1rem;
`;

const INPUTS_WIDTH = '4rem';
const INPUTS_HEIGHT = '2rem';
const BraDisplayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  margin-right: 0.5em;
  outline: none;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
    inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  background: #ffffff;
  height: ${INPUTS_HEIGHT};
  min-width: ${INPUTS_WIDTH};
  padding: 0 10px;
  vertical-align: middle;
  line-height: ${INPUTS_HEIGHT};
  text-align: center;
  color: #182026;
  font-size: 14px;
  font-weight: 400;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  ${media.phone`
    width: auto;
    padding: 0.1em;
    border-radius: 3px;
    border: 0.125rem solid ${props => props.theme.primaryColor};
  `}
`;

const BraFlag = styled(Flag)`
  margin-left: 0.325em;
  vertical-align: baseline;
`;

const Delete = styled.button`
  background-color: transparent;
  margin-right: 0.75em;
  cursor: pointer;
  color: ${colors.grey};

  :hover {
    color: ${colors.red};
  }
`;

const BraDisplay = ({ cup, band, region, onClear, ...rest }) => (
  <>
    <BraDisplayButton type="button" className="bra-display-button" {...rest}>
      {band}
      {cup}
      <BraFlag value={region} />
    </BraDisplayButton>
    <Delete type="button" aria-label="delete bra size" onClick={onClear}>
      <Trash size={'1.5rem'} />
    </Delete>
  </>
);

const BoobContainer = styled(MobilePhysicalLine)`
  margin: 0;
  ${props =>
    props.gender === 'female'
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `};

  ${media.phone`
    margin: 0;
  `}
`;

const BoobPicker = ({
  bra,
  language,
  gender,
  onChange,
  highlightBraButton,
  useFullScreenBoobPicker,
  isBraInputMandatory
}) => {
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (gender === 'male') setShowForm(false);
  }, [gender]);

  const submit = data => {
    setShowForm(false);
    onChange(data, true);
  };

  const handleBraButtonClick = e => {
    e.target.blur();
    setShowForm(!showForm);
  };

  return (
    <BoobContainer gender={gender} className="bra-container">
      <MobilePhysicalLineTexts className="bra-texts">
        <Caption
          text={localizedString('BRA_SIZE') + (isBraInputMandatory ? ' *' : '')}
          style={{ marginRight: '0.6rem', fontWeight: 600 }}
        />
        <ButtonContainer>
          {bra.band && bra.cup && bra.region ? (
            <BraDisplay
              {...bra}
              active={bra.band || showForm}
              onClick={handleBraButtonClick}
              onClear={() => onChange({}, true)}
            />
          ) : (
            <AddButton
              type="button"
              active={bra.band || showForm}
              className="bra-add-button"
              onClick={handleBraButtonClick}
              highlight={highlightBraButton}
            >
              <AddIcon />
              {localizedString('ADD')}
            </AddButton>
          )}
        </ButtonContainer>
      </MobilePhysicalLineTexts>
      <CSSTransition
        in={showForm}
        classNames={`enter-slide-down${useFullScreenBoobPicker ? '-full-screen' : ''}`}
        timeout={SLIDE_VERTICAL_TIMEOUT}
        unmountOnExit
      >
        <BoobPickerForm
          cup={bra.cup}
          band={bra.band}
          region={bra.region || getDefaultRegion(language)}
          onClose={() => setShowForm(false)}
          onSubmit={submit}
          isFullScreen={useFullScreenBoobPicker}
        />
      </CSSTransition>
    </BoobContainer>
  );
};

export default BoobPicker;
