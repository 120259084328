import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { BodyMedium, Caption, CaptionMedium } from 'shared_components';

import { OutlineButton } from 'library/buttons';
import { Dialog } from 'library/dialogs';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.5;
`;

const Row = styled.div`
  display: flex;
`;

const ImageContainer = styled(Container)`
  flex: 1;
`;

const Image = styled.img`
  width: 100%;
  box-shadow: 0 5px 10px 0 #eaeff2;
`;

const HelpDialog = ({ language, about, isOpen, onClose }) => {
  const lowerAbout = about.toLowerCase();
  const lowerLanguage = language === 'FR' ? 'fr' : 'en';
  return (
    <Dialog style={{ maxWidth: 990 }} isOpen={isOpen} onClose={onClose}>
      <Container>
        <BodyMedium
          style={{ marginBottom: 20 }}
          color="mainBlue"
          name={`POSITION_${about}_DIALOG_TITLE`}
        />
        <Caption style={{ marginBottom: 30 }} name={`POSITION_${about}_DIALOG_TEXT`} />
        <CaptionMedium
          style={{ marginBottom: 13 }}
          name={`POSITION_${about}_DIALOG_SUBTITLE`}
          color="mainBlue"
        />
        <Row>
          <ImageContainer style={{ marginRight: 30 }}>
            <CaptionMedium style={{ marginBottom: 10 }} name={`POSITION_${about}_DIALOG_WITH`} />
            <Image
              src={`${process.env.REACT_APP_STATIC_URL}/sizerpro/position/with_${lowerAbout}_${lowerLanguage}.png`}
              alt={`With ${lowerAbout}`}
            />
          </ImageContainer>
          <ImageContainer>
            <CaptionMedium style={{ marginBottom: 10 }} name={`POSITION_${about}_DIALOG_WITHOUT`} />
            <Image
              src={`${process.env.REACT_APP_STATIC_URL}/sizerpro/position/without_${lowerAbout}_${lowerLanguage}.png`}
              alt={`Without ${lowerAbout}`}
            />
          </ImageContainer>
        </Row>
        <OutlineButton style={{ alignSelf: 'flex-end', marginTop: 40 }} onClick={onClose}>
          <Caption name="CLOSE" />
        </OutlineButton>
      </Container>
    </Dialog>
  );
};

const mapper = state => ({ language: state.settings.language });

export default connect(mapper)(HelpDialog);
