// TODO : this function doesn't work in mozilla firefox
export const isMobile = () =>
  // "iPad" has been removed to quickly fix rendering issue on tablets.
  // We temporary display the plugin in "desktop mode".
  // Once we start working on tablets issue break this function into isTablet() isMobile() isDesktop(). (maybe use third party library)
  /Android|webOS|iPhone|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    navigator.userAgent
  );

export const isIE = () => {
  // MSIE used to detect IE10 or older and Trident used to detect IE11
  return navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1;
};
