import React, { useRef, useEffect, useState, useCallback } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

import { Container } from '../styles';
import colors from '../../styles/colors';
import Shape from './Shape';
import Buttons from './Buttons';
import Dots from './Dots';
import { objectFromArray, getObserver } from './utils';
import { getBodyShapes, BODY_SHAPES } from '../../services/utils';

const CarouselContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Carousel = styled(Container)`
  flex: 0 0 75%;
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

const Actions = styled(Container)`
  max-width: 37.5rem;
  flex-direction: column;
  justify-content: space-around;
`;

const EmptyScrollSpace = styled.div`
  flex: 1 0 12.5%;
`;

const BodyShapeMale = ({
  theme,
  selectShape,
  selectedShape,
  weight,
  height,
  sendTooltipDisplayedEvent
}) => {
  const shapes = getBodyShapes('male', weight, height);
  const shapeSlugs = shapes.map(shape => shape.slug);
  const [dots, setDots] = useState(objectFromArray(shapeSlugs, false));

  const carouselRef = useRef(null);
  const observer = useRef(null);
  const watchedRefs = useRef([]); // each bodyshape is a distinct ref being whatched by the observer
  const addToWatchedRefs = useCallback(shapeRef => watchedRefs.current.push(shapeRef), []);

  const handleClickToScroll = back => {
    const currentScrollPosition = carouselRef.current.scrollLeft;
    const scrollDistanceToNextShape = carouselRef.current.children[1].clientWidth;
    const directionModifier = back ? -1 : 1;
    const newScrollPosition = currentScrollPosition + directionModifier * scrollDistanceToNextShape;
    carouselRef.current.scroll({ left: newScrollPosition, behavior: 'smooth' });
  };

  useEffect(() => {
    // When there are 5 shapes, horizontally center the scrollbar to show shapes 2,3 and 4.
    if (shapes.length === 5) {
      const distanceXToCenter = carouselRef.current.children[1].clientWidth;
      carouselRef.current.scroll({ left: distanceXToCenter });
    }
  }, [shapes.length]);

  useEffect(() => {
    // based on this article: https://www.rubensuet.com/intersectionObserver/
    const handler = triggeredShapes => {
      triggeredShapes.forEach(shapeRef => {
        if (shapeRef.intersectionRatio >= 0.9) {
          setDots(dots => ({ ...dots, [shapeRef.target.id]: true }));
        } else {
          setDots(dots => ({ ...dots, [shapeRef.target.id]: false }));
        }
      });
    };
    if (observer.current) {
      observer.current.disconnect();
    }
    const options = {
      threshold: 0.9
    };
    const newObserver = getObserver(observer, options, handler);
    watchedRefs.current.forEach(shapeRef => newObserver.observe(shapeRef));

    return () => newObserver.disconnect();
  }, []);

  const allShapesAreVisible = Object.entries(dots).every(([_, isActive]) => isActive);
  return (
    <ThemeProvider theme={theme}>
      <CarouselContainer>
        <Carousel ref={carouselRef}>
          <EmptyScrollSpace />
          {shapes.map(shape => (
            <Shape
              currentShape={shape}
              key={shape.slug}
              addToWatchedRefs={addToWatchedRefs}
              selectedShape={selectedShape}
              selectShape={selectShape}
              sendTooltipDisplayedEvent={sendTooltipDisplayedEvent}
            />
          ))}
          <EmptyScrollSpace />
        </Carousel>
        <Actions>
          {!allShapesAreVisible && <Dots shapes={shapes} dots={dots} />}
          <Buttons
            disabled={allShapesAreVisible}
            disableLeft={shapes.length === 5 && !allShapesAreVisible && dots[shapes[0]]}
            disableRight={shapes.length === 5 && !allShapesAreVisible && dots[shapes[4]]}
            handleClickToScroll={handleClickToScroll}
          />
        </Actions>
      </CarouselContainer>
    </ThemeProvider>
  );
};

BodyShapeMale.defaultProps = {
  theme: {
    primaryColor: colors.black
  }
};

BodyShapeMale.propTypes = {
  weight: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  theme: PropTypes.object,
  selectShape: PropTypes.func.isRequired,
  selectedShape: PropTypes.oneOf(BODY_SHAPES.filter(b => b.gender === 'male').map(b => b.slug)),
  sendTooltipDisplayedEvent: PropTypes.func
};

export default BodyShapeMale;
