import React from 'react';
import styled from 'styled-components/macro';
import { media, colors, Body, BodyMedium } from 'shared_components';

import { CardTitle, DarkPage, CompanyName, Card, SizerproLogo } from './common/styles';
import { StandardButton } from 'library/buttons';
import LinkButton from 'library/buttons/LinkButton';

const CenteredCard = styled(Card)`
  align-items: center;
`;
const Text = styled(Body)`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;
const BoldText = styled(BodyMedium)`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-self: stretch;
  margin-top: 1em;
  & > button {
    width: 5em;
  }
`;
const CompanyTitle = styled(CompanyName)`
  ${media.phone`
    margin: 0rem;
    padding: 0rem;
    height: max(8vh, 1.3rem);
    font-size: max(4vh, 1rem);
  `}
  ${media.phoneVerySmall`
    height: max(8vh, 1.3rem);
    font-size: max(4vh, 1rem);
  `}
  ${media.phoneSmall`
    height: max(8vh, 1.3rem);
    font-size: max(4vh, 1rem);
  `}
  ${media.phoneMedium`
    height: min(8vh, 1.4rem);
    font-size: min(4vh, 1.3rem);
  `}
  ${media.phoneLarge`
    height: 1.3rem;
    font-size: 1.3rem;
  `}
`;

class PositionConfirmation extends React.Component {
  render() {
    const { details } = this.props;
    const { company_name, position_name } = details;
    return (
      <DarkPage>
        <CompanyTitle text={company_name} />
        <CenteredCard>
          <CardTitle name="FIRST" />
          <BoldText name="IS_YOUR_POSITION" />
          <Text name="YOUR_POSITION" values={{ company_name, position_name }} />
          <ButtonsRow>
            <StandardButton>
              <Body name="NO" color={colors.darkGrey} />
            </StandardButton>
            <LinkButton
              to={`/clothes_attribution/${this.props.match.params.campaign_employee_token}/explanation`}
            >
              <Body name="YES" color="white" />
            </LinkButton>
          </ButtonsRow>
        </CenteredCard>
        <SizerproLogo />
      </DarkPage>
    );
  }
}

export default PositionConfirmation;
