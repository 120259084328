import React from 'react';
import styled from 'styled-components/macro';
import { media } from 'shared_components';

import { colors } from 'shared_components';

import Item from './Item';

const Wrapper = styled.div`
  position: relative;
  height: 30px;
  border: 1px ${props => (props.borderColor ? props.borderColor : colors.darkGrey)} solid;
  border-radius: 50px;
  display: flex;
  align-items: center;
  color: ${colors.darkGrey};
  background-color: white;
  ${media.screenLarge`
    height:40px;
  `}
  ${media.screenMedium`
    flex-direction: column;
    margin: 20 0 20 0;
    height: auto;
    border: none;
    background-color: inherit;
    box-shadow: inherit;
    border-radius: 5px;
  `}
  ${media.phone`
    flex-direction: column;
    margin: 20 0 20 0;
    height: auto;
    border: none;
    background-color: inherit;
    box-shadow: inherit;
    border-radius: 5px;
  `}
  ${media.phoneMedium`
  
    flex-direction: column;
    margin: 20 0 20 0;
    height: auto;
    border: none;
    background-color: inherit;
    box-shadow: inherit;
    border-radius: 5px;
  `}
  ${media.phoneLarge`
    flex-direction: column;
    height: auto;
    background-color: inherit;
    box-shadow: inherit;

  `}
`;

const Toggle = ({ children, activeIndex, onToggle, borderColor, shadowColor, backgroundColor }) => {
  const clickItem = index => () => {
    activeIndex !== index && onToggle(index);
  };

  if (!children || children.length < 2) {
    throw new Error('A <Toggle> should have at least two children');
  }

  return (
    <Wrapper borderColor={borderColor} shadowColor={shadowColor} backgroundColor={backgroundColor}>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          active: index === activeIndex,
          onClick: clickItem(index),
          borderColor: borderColor
        })
      )}
    </Wrapper>
  );
};

export const ToggleContainer = styled.div`
  padding: 20px 0px;
`;

Toggle.Item = Item;

export default Toggle;
