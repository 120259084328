import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from "react-cookie";

const PUBLIC_PATHS = [
  'signup',
  'auth',
  'forgot_password',
  'forgot_password_reset',
  'clothes_attribution',
  'consent'
];

class AuthGuard extends React.Component {
  check = () => {
    const { connected, history } = this.props;
    const topRoute = history.location.pathname.split('/')[1];
    const userConsented = this.props.cookies.get("consent") === 'true'

    if (!userConsented && history.location.pathname!=='/consent') {
      history.push('/consent');
    } else if (!connected && !PUBLIC_PATHS.includes(topRoute)) {
      history.push('/auth');
    }
  };
  componentDidMount = () => {
    this.check();
  };
  componentDidUpdate = () => {
    this.check();
  };

  render() {
    return null;
  }
}
const mapper = state => ({ connected: !!state.auth.token });
export default withRouter(connect(mapper)(withCookies(AuthGuard)));
