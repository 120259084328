import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';

import { Caption, CaptionMedium } from '../../../text';
import { SimpleTextButton, StyledButton } from '../../../library/buttons';
import Callout from '../../../library/Callout';
import Tooltip, { Position, useTooltip } from '../../../library/Tooltip';
import { localizedString } from '../../../services/i18n/translations';
import { colors, media } from 'shared_components/styles';

// We give a fixed height, so if the headerTextLineTwo switch between 1 and 2 lines the design is not jumpy.
const TitleContainer = styled.div`
  height: 5.5em;
  margin: 0.75em 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1em;

  ${media.phoneVerySmall`
    margin-top: 0;
  `}
  ${media.phoneSmall`
    margin-top: 0;
  `}

  ${media.bigScreen`
    margin: 0 1.5em 0 1.5em;
  `}

  height: auto;
`;

const CalloutContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 0.25em;
  margin-bottom: 0.75em;
  width: 100%;
`;

const StyledTooltip = styled(Tooltip)`
  left: 0;
  text-align: left;
  z-index: 3;
`;

const Title = ({
  recommendedSizeName,
  comparedSizeName,
  headerTextLineTwo,
  showSizingChart,
  sendTooltipDisplayedEvent,
  style
}) => {
  const themeContext = useContext(ThemeContext);

  const { showTooltip, setShowTooltip } = useTooltip(sendTooltipDisplayedEvent);

  return (
    <TitleContainer style={style}>
      <CaptionMedium
        className="comfort-clothes-title"
        color="coal"
        name="COMPARISON_HEADER_ONE"
        values={{ size: recommendedSizeName }}
      />
      <Caption
        className="comfort-clothes-title"
        color="coal"
        name={headerTextLineTwo}
        values={{ size: comparedSizeName }}
      />
      <CalloutContainer>
        <Callout
          intent="info"
          backgroundColor={colors.lighterGrey}
          iconColor={themeContext.primaryColor}
        >
          <SimpleTextButton
            onClick={e => {
              e.preventDefault();
              setShowTooltip(!showTooltip);
            }}
            name="HOME_CALLOUT"
            style={{ display: 'flex' }}
            active={showTooltip}
          />
          <StyledTooltip
            opened={showTooltip}
            close={() => setShowTooltip(false)}
            position={Position.BOTTOM}
            borderColor={themeContext.primaryColor}
            globalEventOff={false}
          >
            <CaptionMedium name="HOME_TOOLTIP_START" /> <Caption name="HOME_TOOLTIP_MIDDLE" />
            <div>
              <Caption name="HOME_TOOLTIP_END" />
            </div>
            <Callout
              icon={false}
              backgroundColor={colors.lighterGrey}
              style={{ marginTop: '10px' }}
            >
              <StyledButton as="a" onClick={showSizingChart} style={{ fontSize: '0.75rem' }}>
                {localizedString('HOME_TOOLTIP_LINK')}
              </StyledButton>
            </Callout>
          </StyledTooltip>
        </Callout>
      </CalloutContainer>
    </TitleContainer>
  );
};

Title.propTypes = {
  recommendedSizeName: PropTypes.string.isRequired,
  comparedSizeName: PropTypes.string,
  headerTextLineTwo: PropTypes.string.isRequired,
  showSizingChart: PropTypes.func,
  sendTooltipDisplayedEvent: PropTypes.func,
  style: PropTypes.object
};

export default Title;
