import React from 'react';
import styled from 'styled-components';
import { SvgLoader } from 'react-svgmt';
import { media } from 'shared_components/styles';

import { isIE } from '../../services/device';
import colors from '../../styles/colors';
import { ShapeCheckbox } from '../styles';
import Checkbox from '../../library/Checkbox';
import ShapeTooltip from './ShapeTooltip';

const ShapeCheckboxMan = styled(ShapeCheckbox)`
  height: 30%;
`;

const Container = styled.div`
  flex: 0 0 25%;
  max-width: 7.5rem;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 5px;
  padding: 1rem 0;
  margin-bottom: 1rem;
  :hover {
    background-color: ${colors.lighterGrey};
  }
  border: ${({ isSelected }) => `solid 1px ${isSelected ? colors.black : colors.white}`};
  ${media.phoneVerySmall`
    margin: 0;
    padding: 0rem 0;
  `}
  ${media.phoneSmall`
    margin: 0;
    padding: 0rem 0;
  `}
`;

const ShapeImage = styled.div`
  min-height: 6.25rem;
  height: 70%;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const svgOptions = isIE() ? { width: '100', height: '165' } : { width: '100%', height: '100%' };

const Shape = ({
  currentShape,
  addToWatchedRefs,
  selectedShape,
  selectShape,
  sendTooltipDisplayedEvent
}) => {
  const isSelected = currentShape.slug === selectedShape;
  return (
    <Container id={currentShape.slug} ref={addToWatchedRefs} isSelected={isSelected}>
      <ShapeImage onClick={() => selectShape(currentShape.slug)}>
        <SvgLoader
          width={svgOptions.width}
          height={svgOptions.height}
          style={{ maxHeight: '10.3rem' }}
          path={`${process.env.REACT_APP_STATIC_URL}/plugin/data/body-shapes/${currentShape.slug}.svg`}
        />
      </ShapeImage>
      <ShapeCheckboxMan>
        <ShapeTooltip
          locale={currentShape.localeHelper}
          sendTooltipDisplayedEvent={sendTooltipDisplayedEvent}
        />
        <Checkbox
          value={currentShape.slug}
          name={currentShape.slug}
          labelId={`kindOf${currentShape.slug} labelFor${currentShape.slug}`}
          isChecked={isSelected}
          onChange={() => selectShape(currentShape.slug)}
          size={1.75}
        />
      </ShapeCheckboxMan>
    </Container>
  );
};

export default Shape;
