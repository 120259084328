import React from 'react';
import styled from 'styled-components/macro';
import { MainTitle } from 'shared_components';

import { TitleSection, Card, Content } from 'library/layout';
import api from 'api';
import Async from 'library/Async';
import CollectionListing from './CollectionListing';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

class Collections extends React.Component {
  fetchCollections = () => {
    return api.collections.list();
  };
  render() {
    return (
      <Container>
        <TitleSection>
          <MainTitle name="COLLECTIONS" />
        </TitleSection>
        <Content>
          <Card>
            <Async fetchProps={this.fetchCollections}>
              {collections => <CollectionListing collections={collections} />}
            </Async>
          </Card>
        </Content>
      </Container>
    );
  }
}

export default Collections;
