import React from 'react';
import { Caption, CaptionMedium } from '../../text';
import { PhysicalInput } from '../base';
import { getCm, getFeetInches, getInches, InputSelector } from '../utils';

class HeightInput extends React.PureComponent {
  isCurrentlyMetric = () => this.props.unitType === 'metric';

  switch = () => {
    const unitType = this.isCurrentlyMetric() ? 'imperial' : 'metric';
    // If still at default value, we set the value upon unit change
    // Otherwise the slider will rerender weirdly
    if (!this.props.height) this.onInputChange(this.getDefaultValue());
    this.props.onChangeUnitType(unitType);
  };

  getInputValue = () => {
    const { height } = this.props;
    if (this.isCurrentlyMetric()) {
      return height;
    } else {
      return getInches(height);
    }
  };

  getInputLimits = () => {
    const { limits } = this.props;
    if (this.isCurrentlyMetric()) {
      return limits;
    } else {
      return { min: getInches(limits.min), max: getInches(limits.max) };
    }
  };

  onInputChange = value => {
    const { onChange } = this.props;
    let metricValue;
    if (this.isCurrentlyMetric()) {
      metricValue = value;
    } else {
      const feet = Math.floor(value / 12),
        inches = value % 12;
      metricValue = getCm(feet, inches);
    }
    onChange(metricValue, true);
  };

  getDefaultValue = () => {
    const { defaultValue } = this.props;
    return this.isCurrentlyMetric() ? defaultValue : getInches(defaultValue);
  };

  renderDisplayValue = () => {
    const { height } = this.props;
    if (height) {
      if (this.isCurrentlyMetric()) {
        return (
          <>
            <CaptionMedium text={height} />
            <Caption text=" cm" />
          </>
        );
      } else {
        return (
          <>
            <CaptionMedium text={getFeetInches(height, true)} />
          </>
        );
      }
    } else {
      return (
        <>
          <Caption text="-" style={{ marginRight: '0.650rem' }} />
        </>
      );
    }
  };

  render() {
    return (
      <PhysicalInput
        inputId="height-slider"
        name="HEIGHT"
        required={true}
        value={this.getInputValue()}
        onChange={this.onInputChange}
        limits={this.getInputLimits()}
        displayValue={this.renderDisplayValue()}
        defaultValue={this.getDefaultValue()}
        settings={
          <InputSelector
            left="cm"
            right="in"
            isLeft={this.isCurrentlyMetric()}
            onSwitch={this.switch}
          />
        }
      />
    );
  }
}

export default HeightInput;
