import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Caption, CaptionMedium } from '../text/index';
import {
  MEASUREMENTS_TEXT_MAPPING,
  normalizeData,
  convertTableToUnit,
  convertToInches
} from './util';
import { Cell, FixedCell, CELL_WIDTH_PX } from './styles';
import ConstraintsRow from './ConstraintsRow';
import { colors } from 'shared_components/styles';
import MdArrowDropDown from 'react-icons/lib/md/arrow-drop-down';
import { SmallText } from '../text/index';
import { StyledButton } from '../library/buttons';
import Tooltip, { Position, useTooltip } from '../library/Tooltip';
import MdInfo from 'react-icons/lib/md/info';

const CHART_TABLE_MARGIN_TOP_PX = 40;

const Container = styled.div`
  margin-top: 1.5rem;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Text = styled(Caption)`
  text-align: center;
  line-height: 1.2rem;
`;

const Table = styled.table`
  border-collapse: separate;
  margin-top: 8px;
  margin-bottom: 40px;
  width: 100%;
  tbody {
    width: 100%;
    tr {
      width: 100%;
    }
  }

  /* All of this is to allow the border-radius
     based on this stack overflow answer: https://stackoverflow.com/a/4785917 */
  tr td {
    border-right: 1px solid ${colors.lightBlueGrey};
    border-bottom: 1px solid ${colors.lightBlueGrey};
    padding: 5px 0px;
    height: 1.875rem;
  }
  tr:first-child td {
    border-top: solid 1px ${colors.lightBlueGrey};
  }
  tr td:first-child {
    border-left: 1px solid ${colors.lightBlueGrey};
    padding-right: 5px;
    padding-left: 5px;
  }
  tr td:first-child {
    border-left: 1px solid ${colors.lightBlueGrey};
  }
  /* top-left border-radius */
  tr:first-child td:first-child {
    border-top-left-radius: 5px;
  }
  /* top-right border-radius */
  tr:first-child td:last-child {
    border-top-right-radius: 5px;
  }
  /* bottom-left border-radius */
  tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
  }
  /* bottom-right border-radius */
  tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
  }
`;

const MeasurementCell = styled(Cell)`
  width: ${CELL_WIDTH_PX}px;
`;

const TableContainer = styled.div`
  border-collapse: separate;
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;

  table {
    margin-bottom: 10px;
  }
  /* Force scrollbar to be visible on mac */
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
  }
`;

const SizingChartTable = styled(Table)`
  position: relative;
  margin-top: ${CHART_TABLE_MARGIN_TOP_PX}px;

  tr td {
    height: 2.5rem;
  }

  /* top-right border-radius */
  tr:first-child td:last-child {
    border-top-right-radius: 0px;
  }
  tr th {
    border: 1px solid ${colors.lightBlueGrey};
  }
  tr th:first-child {
    border: none;
  }
  tr th:nth-child(2) {
    border-top-left-radius: 5px;
  }
  tr th:last-child {
    border-top-right-radius: 5px;
  }
`;

const SizeCell = styled.th`
  position: relative;
  padding: 8px 15px;
  background-color: ${colors.lightBlueGrey};
  border: 1px solid ${colors.lightBlueGrey};
  text-align: center;
  color: ${colors.coal};
  min-width: ${CELL_WIDTH_PX}px;
`;

const Recommended = styled(SmallText)`
  text-align: center;
  position: absolute;
  width: 100%;
  border-radius: 6px;
  background-color: ${colors.green};
  color: ${colors.white};
  visibility: ${props => (props.isShown ? 'visible' : 'hidden')};
  top: -0.75rem;
  left: 0;
  line-height: 1rem;

  :after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: ${colors.green};
    border-width: 0.5em;
    margin-left: -0.5em;
  }
`;

const RecommendedCover = styled.div`
  position: absolute;
  left: 0;
  top: -${CHART_TABLE_MARGIN_TOP_PX}px;
  height: ${CHART_TABLE_MARGIN_TOP_PX}px;
  width: 100%;
  z-index: 1;
  background-color: ${colors.white};
`;

const ScrollButtonsContainer = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ScrollButton = styled.button`
  cursor: pointer;
  box-sizing: border-box;
  min-width: 2.5rem;
  border: 1px solid rgba(234, 239, 242, 1);
  border-radius: 17.5px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  :disabled {
    cursor: not-allowed;
    opacity: 0.8;
    box-shadow: none;
  }
`;

const Arrow = styled(MdArrowDropDown)`
  height: 2rem;
  width: 1.5rem;
  color: ${props => (props.disabled ? colors.lightGrey : colors.black)};
`;

const ArrowLeft = styled(Arrow)`
  transform: rotate(90deg);
`;

const ArrowRight = styled(Arrow)`
  transform: rotate(-90deg);
`;

const TooltipContainer = styled.div`
  position: relative;
  text-align: center;
`;

const UserMeasurementsAndSizingChart = ({
  userMeasurements,
  sizingChartInfo,
  recommendedSizeName,
  unitSystem,
  sendTooltipDisplayedEvent,
  theme = {
    primaryColor: colors.black
  }
}) => {
  const sizingChartTable = useRef(null);
  const recommendedSizeCell = useRef(null);
  const { showTooltip, setShowTooltip } = useTooltip(sendTooltipDisplayedEvent);

  const unit = unitSystem === 'metric' ? 'cm' : 'in';
  const isInches = unit === 'in';
  const data = useMemo(() => normalizeData(sizingChartInfo), [sizingChartInfo]);

  // Scroll to recommended size on element first render.
  useEffect(() => {
    if (recommendedSizeName) {
      sizingChartTable.current.scrollLeft =
        recommendedSizeCell.current.offsetParent.offsetLeft - 1.5 * CELL_WIDTH_PX;
    }
  }, [recommendedSizeName]);

  const displayedUserMeasurement = measurement => {
    if (isInches) {
      return +convertToInches(measurement).toFixed(1);
    } else {
      return +measurement.toFixed(1);
    }
  };

  let displayedTable = data.table;
  if (isInches) {
    displayedTable = convertTableToUnit(data.table, 'in');
  }

  // The scroll buttons scroll the size of one cell on each click.
  const scrollLeftButtonClicked = () => (sizingChartTable.current.scrollLeft -= CELL_WIDTH_PX);
  const scrollRightButtonClicked = () => (sizingChartTable.current.scrollLeft += CELL_WIDTH_PX);

  // This is the left position of the scroll, this will trigger the rerendering of children component to
  // recompute the visibility condition of the visual indicators in the constraint cells.
  // const [scrollLeft, setScrollLeft] = useState();

  // Logic to disable scroll buttons if table scroll is at the beginning or the end.
  const [isLeftScrollDisabled, setIsLeftScrollDisabled] = useState(false);
  const [isRightScrollDisabled, setIsRightScrollDisabled] = useState(false);
  const onTableScroll = e => {
    const element = e.target;
    // Maybe add throttle like function to not rerender children on each pixel scrolled
    // setScrollLeft(element.scrollLeft);
    // at beginning of table scroll
    if (element.scrollLeft === 0) {
      setIsLeftScrollDisabled(true);
    } else if (isLeftScrollDisabled) {
      setIsLeftScrollDisabled(false);
    }
    // at end of table scroll
    if (element.scrollWidth - element.scrollLeft === element.clientWidth) {
      setIsRightScrollDisabled(true);
    } else if (isRightScrollDisabled) {
      setIsRightScrollDisabled(false);
    }
  };
  // Use to disable the button before the 1st scroll
  useEffect(() => {
    // at beginning of table scroll
    if (sizingChartTable.current.scrollLeft === 0) {
      setIsLeftScrollDisabled(true);
    } else if (isLeftScrollDisabled) {
      setIsLeftScrollDisabled(false);
    }
    // at end of table scroll
    if (
      sizingChartTable.current.scrollWidth - sizingChartTable.current.scrollLeft ===
      sizingChartTable.current.clientWidth
    ) {
      setIsRightScrollDisabled(true);
    } else if (isRightScrollDisabled) {
      setIsRightScrollDisabled(false);
    }
  }, [isLeftScrollDisabled, isRightScrollDisabled, sizingChartTable]);

  return (
    <Container>
      <TooltipContainer>
        <Text name="YOUR_MEASUREMENTS" />
        <StyledButton
          onClick={() => setShowTooltip(!showTooltip)}
          active={showTooltip}
          style={{ display: 'inline', marginLeft: '0.25em', fontSize: '0.75rem' }}
        >
          <MdInfo color={showTooltip ? colors.darkGrey : theme.primaryColor} />
        </StyledButton>

        <Tooltip
          opened={showTooltip}
          close={() => setShowTooltip(false)}
          style={{ zIndex: 2, textAlign: 'left' }}
          position={Position.BOTTOM}
          borderColor={theme.primaryColor}
        >
          <CaptionMedium name="HOME_TOOLTIP_START" /> <Caption name="HOME_TOOLTIP_MIDDLE" />
          <div>
            <Caption name="HOME_TOOLTIP_END" />
          </div>
        </Tooltip>
      </TooltipContainer>
      <Table>
        <tbody>
          {data.measurements.map((measurement, index) => (
            <tr key={index}>
              <MeasurementCell>
                <CaptionMedium name={MEASUREMENTS_TEXT_MAPPING[measurement]} />
              </MeasurementCell>
              <Cell>
                <Caption
                  text={`${displayedUserMeasurement(userMeasurements[measurement])} ${unit}`}
                />
              </Cell>
            </tr>
          ))}
        </tbody>
      </Table>
      <Text name="SIZING_CHART" />
      <TableContainer ref={sizingChartTable} onScroll={onTableScroll}>
        <SizingChartTable>
          <thead>
            <tr>
              <FixedCell as="th">
                <RecommendedCover />
              </FixedCell>
              {data.sizes.map((size, index) => (
                <SizeCell key={index}>
                  <CaptionMedium text={size} />
                  <Recommended name="RECOMMENDED_FOR_SIZE" isShown={size === recommendedSizeName} />
                  {
                    // if the size is the recommended size, creates a div to have an element to scroll to
                    size === recommendedSizeName && <div ref={recommendedSizeCell} />
                  }
                </SizeCell>
              ))}
            </tr>
          </thead>
          <tbody>
            {displayedTable.map((constraints, rowIndex) => {
              const measurementName = data.measurements[rowIndex];
              return (
                <ConstraintsRow
                  constraints={constraints}
                  rowIndex={rowIndex}
                  key={rowIndex}
                  inputType={data.inputTypes[rowIndex]}
                  measurementName={measurementName}
                  userMeasurement={displayedUserMeasurement(userMeasurements[measurementName])}
                  recommendedSizeIndex={data.sizes.findIndex(size => size === recommendedSizeName)}
                />
              );
            })}
          </tbody>
        </SizingChartTable>
      </TableContainer>
      <ScrollButtonsContainer>
        <ScrollButton
          type="button"
          aria-label="scroll sizing chart table to the left"
          onClick={scrollLeftButtonClicked}
          disabled={isLeftScrollDisabled}
        >
          <ArrowLeft disabled={isLeftScrollDisabled} />
        </ScrollButton>
        <ScrollButton
          type="button"
          aria-label="scroll sizing chart table to the right"
          onClick={scrollRightButtonClicked}
          disabled={isRightScrollDisabled}
        >
          <ArrowRight disabled={isRightScrollDisabled} />
        </ScrollButton>
      </ScrollButtonsContainer>
    </Container>
  );
};

UserMeasurementsAndSizingChart.propTypes = {
  userMeasurements: PropTypes.object.isRequired,
  sizingChartInfo: PropTypes.object.isRequired,
  recommendedSizeName: PropTypes.string,
  unitSystem: PropTypes.oneOf(['metric', 'imperial']),
  sendTooltipDisplayedEvent: PropTypes.func,
  theme: PropTypes.object
};

export default UserMeasurementsAndSizingChart;
