import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { Popover, Menu, MenuItem } from '@blueprintjs/core';
import { colors, SmallTextMedium, Caption } from 'shared_components';

import { KebabMenu, Send } from 'library/icons';
import { StandardButton, ColoredButton } from 'library/buttons';
import {
  StandardTable,
  TextCell,
  booleanFiltering,
  YesNoCell,
  CenteredCell,
  CheckboxCell
} from 'library/tables';

const MailSent = ({ value }) => (
  <CenteredCell>{value && <Send size={18} color={colors.blue} />}</CenteredCell>
);

const AbsentCell = React.memo(({ original, onChange, value }) => (
  <CenteredCell>
    <CheckboxCell
      disabled={original.has_results}
      checked={value}
      onChange={() => onChange(original)}
    />
  </CenteredCell>
));

const ActionContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  & svg {
    cursor: pointer;
  }
  color: ${colors.darkGrey};
  padding-left: 10px;
  padding-right: 10px;
`;

const CampaignEmployeeMenu = ({ giveAccess, copyAccessLink, onRemove, onEdit }) => (
  <Menu>
    <MenuItem
      icon="envelope"
      text={<Caption name="GIVE_ACCESS_TO_CAMPAIGN_EMPLOYEE" />}
      onClick={giveAccess}
    />
    <MenuItem
      icon="link"
      text={<Caption name="COPY_ACCESS_LINK_CAMPAING_EMPLOYEE" />}
      onClick={copyAccessLink}
    />
    <MenuItem
      icon="exclude-row"
      text={<Caption name="CAMPAIGN_EMPLOYEE_REMOVE" />}
      onClick={onRemove}
    />
    <MenuItem icon="edit" text={<Caption name="MODIFY_POSITION" />} onClick={onEdit} />
  </Menu>
);

const Actions =
  ({
    giveAccessToEmployee,
    copyEmployeeAccessLink,
    onAttributeClothes,
    onRemoveEmployee,
    onEditEmployee
  }) =>
  ({ original }) => {
    const giveAccess = () => giveAccessToEmployee(original);
    const copyAccessLink = () => copyEmployeeAccessLink(original);
    const onRemove = () => onRemoveEmployee(original);
    const onEdit = () => onEditEmployee(original);
    const button = original.has_results ? (
      <StandardButton
        style={{ flex: 1, marginRight: 10 }}
        onClick={() => onAttributeClothes(original)}
      >
        <Caption name="SEE_ATTRIBUTION" />
      </StandardButton>
    ) : (
      <ColoredButton
        style={{ flex: 1, marginRight: 10 }}
        onClick={() => onAttributeClothes(original)}
      >
        <Caption color="white" name="START_ATTRIBUTION" />
      </ColoredButton>
    );
    return (
      <ActionContainer>
        {button}
        <Popover content={CampaignEmployeeMenu({ giveAccess, copyAccessLink, onEdit, onRemove })}>
          <KebabMenu />
        </Popover>
      </ActionContainer>
    );
  };

const CampaignEmployeeListing = ({
  checkboxesColumn,
  giveAccessToEmployee,
  copyEmployeeAccessLink,
  onAttributeClothes,
  onRemoveEmployee,
  onEditEmployee,
  onToggleAbsence,
  ...rest
}) => {
  const reactTable = useRef(null);
  const OPERATOR_COLUMNS = [
    { ...checkboxesColumn(reactTable) },
    {
      Header: <SmallTextMedium color="grey" name="IDENTIFIER" />,
      accessor: 'client_employee_id',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="LAST_NAME" />,
      accessor: 'last_name',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="FIRST_NAME" />,
      accessor: 'first_name',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="POSITION" />,
      accessor: 'position_name',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="EMAIL_ADDRESS" />,
      accessor: 'email',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="HAS_RESULTS" />,
      accessor: 'has_results',
      Cell: YesNoCell,
      ...booleanFiltering
    },
    {
      Header: <SmallTextMedium color="grey" name="ACCESS" />,
      accessor: 'mail_sent',
      Cell: MailSent,
      ...booleanFiltering
    },
    {
      Header: <SmallTextMedium color="grey" name="ABSENT_EMPLOYEE" />,
      accessor: 'absent',
      Cell: props => <AbsentCell {...props} onChange={onToggleAbsence} />,
      ...booleanFiltering
    },
    {
      Cell: Actions({
        giveAccessToEmployee,
        copyEmployeeAccessLink,
        onAttributeClothes,
        onEditEmployee,
        onRemoveEmployee
      }),
      filterable: false,
      sortable: false,
      resizable: false,
      width: 200
    }
  ];
  return <StandardTable columns={OPERATOR_COLUMNS} ref={reactTable} {...rest} />;
};

export default CampaignEmployeeListing;
