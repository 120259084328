import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Caption } from '../../text';
import { IntegerInput } from '../base';
import { PhysicalInputError } from '../utils';
import {
  PhysicalInputLine,
  PhysicalLabelContainer,
  PhysicalInputContainer,
  ErrorContainer,
  Required
} from '../styles';

const AgeCaption = styled(Caption)`
  margin-left: 1rem;
  min-width: 3rem;
  text-align: left;
`;

class AgeInput extends React.PureComponent {
  state = {
    value: this.props.age,
    outOfLimits: false
  };

  checkLimits = (value, next) => {
    const { min, max } = this.props.limits;
    if (value < min) return next(true);
    if (value > max) return next(true);
    return next(false);
  };

  handleChange = (value, skip = false) => {
    this.checkLimits(value, invalid => {
      const outOfLimits = skip ? this.state.outOfLimits : invalid;
      this.setState({ value, outOfLimits }, () => {
        this.props.onChange(value, !invalid);
      });
    });
  };

  render() {
    const { min, max } = this.props.limits;
    return (
      <ErrorContainer>
        <PhysicalInputLine className="age-container">
          <PhysicalLabelContainer className="age-label-container">
            <Caption forwardedAs="label" htmlFor="age-input" name="AGE" />
            <Required />
          </PhysicalLabelContainer>
          <PhysicalInputContainer className="age-input-container">
            <IntegerInput
              className={this.props.theme.inputClassnames}
              required
              id="age-input"
              value={this.state.value}
              onChange={this.handleChange}
              min={this.state.value ? min : this.props.defaultValue}
              max={this.state.value ? max : this.props.defaultValue}
            />
            <AgeCaption name="YEARS" />
          </PhysicalInputContainer>
        </PhysicalInputLine>
        {this.state.outOfLimits && (
          <PhysicalInputError name="ERROR_INPUT_AGE" values={this.props.limits} />
        )}
      </ErrorContainer>
    );
  }
}

export default withTheme(AgeInput);
