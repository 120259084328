export const FUNCTIONAL_COLUMNS = [
  'COMPANY',
  'COMPANY_ID',
  'CAMPAIGN',
  'EMPLOYEE_LAST_NAME',
  'EMPLOYEE_FIRST_NAME',
  'EMAIL',
  'EMPLOYEE_ID',
  'POSITION_NAME',
  'POSITION_ID',
  'CLOTH_NAME',
  'CLOTH_ID',
  'MEASURE_DATE',
  'RECOMMENDED_SIZE',
  'CHOSEN_SIZE',
  'QUANTITY',
  'SIZE_CHOICE_REASON',
  'COMMENT'
];

const getColumnIndex = columns =>
  columns.reduce((accumulator, name, i) => ({ ...accumulator, [name]: i }), {});

export const sortColumns = (columnsToSort, allColumnsOrdered) => {
  const columnIndex = getColumnIndex(allColumnsOrdered);
  return columnsToSort.sort((x, y) => columnIndex[x] - columnIndex[y]);
};
