import React, { Component } from "react"
import { withCookies } from "react-cookie";
import styled from "styled-components"
import { history } from 'navigation';

import { MainTitle, Body } from 'shared_components';
import { colors } from 'shared_components';
import { ColoredButton } from 'library/buttons';
import LanguageSelector from 'library/LanguageSelector';


const StyleParams = {
    padding: '20px',
    margin: '5px'
}

const Checkbox = ({ labelName, value, onChange }) => {
    const Container = styled.div`
        padding-top: 15px; 
        padding-bottom: 4px; 
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: flex-start;
    `
    const Input = styled.input`
        margin-right: 10px;
        width: 20px;
        height: 20px;
    `
    const Label = styled.p`
      text-decoration: underline;
    `
    return (
      <Container>
        <Input type="checkbox" checked={value} onChange={onChange} />
        <Label>
          <BodyText name={labelName} />
        </Label>
      </Container>
    );
};

const LanguageWrapper = styled.div`
  margin: ${StyleParams.padding};
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: stretch;
  `

const Background = styled.div`
  position: fixed;
  z-index: 9997;
  right: 0;
  bottom: -200px;
  top: 0;
  left: 0;
  background-color: ${colors.lightBlue};
  backdrop-filter: blur(7px);
`

const CookieContainer = styled.div`
  color: ${colors.darkBlack};
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 9998;
  vertical-align: middle;
  white-space: nowrap;
  max-height: 100%;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  font-size: 14px;
  overflow-y: scroll;
  :after {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
`

const CookieInnerContainer = styled.div`
  width: min(90%, 700px);
  height: auto;
  max-width: none;
  border-radius: 5px;
  display: inline-block;
  z-index: 9999;
  background-color: ${colors.lightGrey};
  text-align: left;
  white-space: normal;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  position: relative;
  border: 1px solid ${colors.darkBlue};
  vertical-align: middle;
  `

const Wrapper = styled.div`
  max-height: 100%;
  height: auto;
  max-width: none;
  text-align: center;
  border-radius: 16px;
  display: inline-block;
  text-align: left;
  white-space: normal;
`

const CookieContentBlock = styled.div`
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: ${StyleParams.margin};
  margin-top: ${StyleParams.padding};
`

const CookieTextList = styled.ul`
  margin: 0;
  padding: 0;
  padding-inline-start: 1rem;
`

const CookieTextItem = styled.li`
  list-style: inside;
  margin: ${StyleParams.margin} 0;
  padding-left: 10px;
`

const CookieBannerText = styled.div`
  padding: 0 ${StyleParams.padding};
`

const ButtonContainer = styled.div`
  margin: ${StyleParams.padding};
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  `

const BodyText = styled(Body)`
  font-size: 0.9rem;
`;

class CookieBanner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      termsChecked: false,
      showRequiredTerms: false,
    }
  }

  handleTermsCheck = () => {
    this.setState({
        termsChecked: !this.state.termsChecked
      });
  };

  accept = () => {
    if (!this.state.termsChecked) {
        this.setState({
            showRequiredTerms: true,
        })
        return;
    }
    const toDay = new Date()
    toDay.setFullYear(toDay.getFullYear() + 1);
    this.props.cookies.set("consent", true, { sameSite: "strict", expires: toDay });
    history.goBack();

  }

  render() {
    return (
      <>
        <Background />
        <CookieContainer>
          <CookieInnerContainer>
            <Wrapper>
              <CookieBannerText>
                <LanguageWrapper>
                  <div>
                    <LanguageSelector />
                  </div>
                </LanguageWrapper>
                
                <MainTitle name="RGPD_TERMS_AND_CONDITIONS" />
                <CookieContentBlock>
                    <BodyText name="RGPD_TERMS_TEXT" />
                    <Checkbox
                        labelName="RGPD_CHECK_CASE"
                        value={this.state.termsChecked}
                        onChange={this.handleTermsCheck}
                    />
                    {this.state.showRequiredTerms &&
                    <BodyText style={{ color: 'red', paddingLeft: '0px', paddingRight: '0px', fontSize: '12px'}} name="REQUIRED_FIELD" />
                    }
                    <CookieTextList>
                        <CookieTextItem>
                          <BodyText name="RGDP_TERMS_CONDITION_1" />
                        </CookieTextItem>
                        <CookieTextItem>
                          <BodyText name="RGDP_TERMS_CONDITION_2" />
                        </CookieTextItem>
                        <CookieTextItem>
                          <BodyText name="RGDP_TERMS_CONDITION_3" />
                        </CookieTextItem>
                    </CookieTextList>
                </CookieContentBlock>
              </CookieBannerText>
              <ButtonContainer>
                <ColoredButton
                  color={colors.mainBlue}
                  onClick={() => {
                    this.accept()
                  }}
                >
                  <BodyText name="ACCEPT_AND_CONTINUE" />
                </ColoredButton>
              </ButtonContainer>
            </Wrapper>
          </CookieInnerContainer>
        </CookieContainer>
      </>
    )
  }
}

export default withCookies(CookieBanner)


