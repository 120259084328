import React from 'react';
import styled from 'styled-components/macro';
import { media, colors, CaptionMedium, SmallText } from 'shared_components';
import { Position } from '@blueprintjs/core';
import { IntegerInput } from 'library/forms';
import { BottomTooltip } from '../../common/styles';

// We don't use the flex shorthand because of IE 11
const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${media.phone`
    justify-content: center;
  `}
`;

const FixedQuantity = styled.div`
  background-color: ${colors.lightBlue};
  padding: 8px 12px;
  border-radius: 4px;

  ${media.phone`
    width:80%;
  `}
`;

const QuantityInputBox = styled.div`
  width: 60%;
`;

const StyledIntegerInput = styled(IntegerInput)`
  margin: 0;
  height: 2.5rem;
  ::before {
    content: none;
  }

  ${media.phone`
    width: 80%;
    height:2.5rem;
  `}
`;

const QuantityInputLabel = styled.label`
  margin-right: 1rem;
  width: 40%;
  text-align: right;
  color: black;
  ${media.phone`
    flex: 0 1 30%;
    color:white;
    margin-right: 0.4em;
    font-size: 1rem;
  `}
  ${media.phoneLarge`
    color:white;
    margin-right: 0.4em;
  `}
`;

const QuantityInput = ({ fixed, quantity, disabled, onChange }) => (
  <QuantityContainer>
    <QuantityInputLabel>
      <SmallText
        name="QUANTITY"
        style={{ color: 'inherit', fontSize: 'inherit', marginRight: 'inherit' }}
      />
    </QuantityInputLabel>
    {fixed ? (
      <BottomTooltip
        position={Position.TOP}
        content={<SmallText name="QUANTITY_TOOLTIP" color="white" />}
      >
        <FixedQuantity>
          <CaptionMedium text={quantity} />
          &nbsp;
          <SmallText text="(" />
          <SmallText name="FIXED" />
          <SmallText text=")" />
        </FixedQuantity>
      </BottomTooltip>
    ) : (
      <QuantityInputBox>
        <StyledIntegerInput
          disabled={disabled}
          min={0}
          center={true}
          onValueChange={onChange}
          initValue={quantity || ''}
        />
      </QuantityInputBox>
    )}
  </QuantityContainer>
);

export default QuantityInput;
