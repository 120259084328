import React, { useContext } from 'react';
import MdInfo from 'react-icons/lib/md/info';
import styled, { ThemeContext } from 'styled-components';

import { StyledButton } from '../../library/buttons';
import Tooltip, { Position, useTooltip } from '../../library/Tooltip';
import { Caption } from '../../text/index';

const ResponsiveTooltip = styled(Tooltip)`
  z-index: 2;
  text-align: left;
  width: 12.5rem;
  max-width: 40vw;
`;

const ShapeTooltip = ({ locale, sendTooltipDisplayedEvent }) => {
  const themeContext = useContext(ThemeContext);
  const { showTooltip, setShowTooltip } = useTooltip(sendTooltipDisplayedEvent);

  return (
    <>
      <StyledButton
        onClick={() => {
          setShowTooltip(prevState => !prevState);
        }}
        active={showTooltip}
      >
        <MdInfo size={12} />
      </StyledButton>
      <ResponsiveTooltip
        opened={showTooltip}
        close={() => setShowTooltip(false)}
        position={Position.TOP}
        borderColor={
          themeContext.primaryColor || (themeContext.colors && themeContext.colors.primary)
        }
      >
        <Caption name={locale} />
      </ResponsiveTooltip>
    </>
  );
};

export default ShapeTooltip;
