import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';

import { colors } from 'shared_components/styles';
import { Caption } from '../text';
import { MobilePhysicalLine, MobilePhysicalLineTexts, Required } from './styles';

const StyledSlider = styled(Slider)`
  position: relative;
  width: 100%;
  height: 2.375rem;
`;

const RailArea = styled.div`
  position: absolute;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  padding: 1rem 0;
`;

const StyledRail = styled.div`
  width: 100%;
  height: 0.25rem;
  border-radius: 0.375rem;
  background-color: lightgrey;
  cursor: pointer;
`;

const StyledHandle = styled.button`
  position: absolute;
  margin-left: -0.875rem;
  margin-top: 0.375rem;
  z-index: 2;
  width: 1.375rem;
  height: 1.375rem;
  text-align: center;
  cursor: grab;
  border-radius: 50%;
  background-color: white;
  border: 2px solid
    ${({ showActiveStyle, theme }) =>
      showActiveStyle ? theme.physicalInfoInputColor : colors.grey};

  :active {
    cursor: grabbing;
  }
`;

const Track = styled.div.attrs(props => ({
  style: {
    left: `${props.source.percent}%`,
    width: `${props.target.percent - props.source.percent}%`
  }
}))`
  z-index: 1;
  position: absolute;
  height: 0.25rem;
  border-radius: 0.375rem;
  background-color: ${({ showActiveStyle, theme }) =>
    showActiveStyle ? theme.physicalInfoInputColor : colors.grey};
  cursor: pointer;
  margin-top: 1rem;
`;

const SliderInput = React.memo(
  ({ id, onChange, value, defaultValue, step = 1, min, max, ariaLabel, showActiveStyle }) => {
    const onHandleChange = ([handleValue]) => onChange(handleValue);
    return (
      <StyledSlider
        domain={[min, max]}
        step={step}
        values={[value === undefined ? defaultValue : value]}
        onUpdate={onHandleChange}
      >
        <Rail>
          {({ getRailProps }) => (
            <RailArea {...getRailProps()}>
              <StyledRail />
            </RailArea>
          )}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map(handle => (
                <StyledHandle
                  id={id}
                  type="button"
                  style={{
                    left: `${handle.percent}%`
                  }}
                  key={handle.id}
                  showActiveStyle={showActiveStyle}
                  handle={handle}
                  {...getHandleProps(handle.id)}
                  role="slider"
                  aria-label={ariaLabel}
                  aria-valuemax={max}
                  aria-valuemin={min}
                  aria-valuenow={value}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  showActiveStyle={showActiveStyle}
                  source={source}
                  target={target}
                  {...getTrackProps()}
                />
              ))}
            </div>
          )}
        </Tracks>
      </StyledSlider>
    );
  }
);

const INPUTS_WIDTH = '4rem';
const INPUTS_HEIGHT = '2rem';
export const InputStyle = styled.div`
  position: relative;
  input {
    outline: none;
    border: none;
    border-radius: 3px;
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
      inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
    background: #ffffff;
    height: ${INPUTS_HEIGHT};
    width: ${INPUTS_WIDTH};
    padding: 0 10px;
    vertical-align: middle;
    line-height: ${INPUTS_HEIGHT};
    text-align: center;
    color: #182026;
    font-size: 14px;
    font-weight: 400;
    transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input ~ .ftl-input-border {
    display: none;
  }
`;

export const IntegerInput = ({
  value,
  onChange,
  max,
  min = 0,
  step = 1,
  placeholder = '',
  required,
  className
}) => {
  const handleChange = input => {
    if (value && !input) return onChange(input);
    if (input > max) return onChange(input);
    if (input >= min && input <= max) return onChange(input);
    return onChange(input, true);
  };
  return (
    <InputStyle>
      <input
        type="number"
        required={required}
        value={value || value === 0 ? value : ''}
        onChange={e => handleChange(parseInt(e.target.value))}
        onBlur={e => onChange(parseInt(e.target.value))}
        placeholder={placeholder}
        min={min}
        max={max}
        step={step}
        className={className}
      />
      <span className="ftl-input-border"></span>
    </InputStyle>
  );
};
const MobileLineValue = styled.div`
  flex: 1;
  text-align: right;
`;

export const PhysicalInput = ({
  inputId,
  name,
  required = false,
  value,
  limits,
  displayValue,
  onChange,
  settings,
  defaultValue,
  increaseInput,
  decreaseInput
}) => (
  <MobilePhysicalLine>
    <MobilePhysicalLineTexts>
      <Caption forwardedAs="label" htmlFor={inputId} name={name} style={{ fontWeight: 600 }} />
      {required && <Required />}
      {settings && settings}
      <MobileLineValue>{displayValue}</MobileLineValue>
    </MobilePhysicalLineTexts>
    <RangeInput
      id={inputId}
      min={limits.min}
      max={limits.max}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
      ariaLabel={name}
      increaseInput={increaseInput}
      decreaseInput={decreaseInput}
    />
  </MobilePhysicalLine>
);

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -0.625em;
  margin-right: -0.625rem;
`;

const SliderWrapper = styled.div`
  flex: 1;
  margin: 0 0.625rem;
`;

// Add padding to make buttons easier to click, especially on small screens
const SliderButtonContainer = styled.div`
  cursor: pointer;
  padding: 0.625rem;
`;

const SliderButton = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  text-align: center;
  line-height: 1rem;
  border: 2px transparent solid;
  margin: 0 auto;
  padding: 0 0 1.25rem 0;

  background-color: ${props =>
    props.showActiveStyle ? props.theme.physicalInfoInputColor : 'white'};
  border-color: ${props =>
    props.showActiveStyle ? 'transparent' : props.theme.physicalInfoInputColor};
  color: ${props => (props.showActiveStyle ? 'white' : props.theme.physicalInfoInputColor)};
`;

export const RangeInput = ({
  id,
  onChange,
  min,
  max,
  value,
  defaultValue,
  step = 1,
  ariaLabel,
  increaseInput,
  decreaseInput
}) => {
  const increase =
    increaseInput || (() => onChange(Math.min(max, Math.max(min, (value || defaultValue) + step))));
  const decrease =
    decreaseInput || (() => onChange(Math.min(max, Math.max(min, (value || defaultValue) - step))));

  const showActiveStyle = value !== undefined;
  // On clicking or focusing on the slider bar you can see a slight "jump" in value.
  // It appears to be caused by the size of the handle ...
  // It does not have a big impact on real world usage so TODO later.
  return (
    <SliderContainer>
      <SliderButtonContainer onClick={decrease}>
        <SliderButton
          className="slider-button"
          type="button"
          showActiveStyle={showActiveStyle}
          aria-label={ariaLabel}
          style={{ fontSize: '1.6rem', fontWeight: 300 }}
        >
          -
        </SliderButton>
      </SliderButtonContainer>
      <SliderWrapper>
        <SliderInput
          id={id}
          onChange={onChange}
          value={value}
          showActiveStyle={showActiveStyle}
          defaultValue={defaultValue}
          step={step}
          min={min}
          max={max}
          ariaLabel={ariaLabel}
        />
      </SliderWrapper>
      <SliderButtonContainer onClick={increase}>
        <SliderButton
          className="slider-button"
          type="button"
          showActiveStyle={showActiveStyle}
          aria-label={ariaLabel}
          style={{ fontSize: '1.6rem', fontWeight: 300 }}
        >
          +
        </SliderButton>
      </SliderButtonContainer>
    </SliderContainer>
  );
};

RangeInput.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number,
  value: PropTypes.number,
  defaultValue: PropTypes.number.isRequired
};
