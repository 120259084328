import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import { Popover, Menu, MenuItem } from '@blueprintjs/core';
import { Caption, CaptionMedium } from 'shared_components';

import { Campaign, KebabMenu } from 'library/icons';
import { StandardTable, booleanFiltering, YesNoCell } from 'library/tables';

const TextCell = ({ value }) => <Caption text={value} />;
const TitleCell = ({ value }) => <CaptionMedium text={value} />;

const DateCell = ({ value, language }) => {
  const date = new Date(value);
  return <Caption text={date.toLocaleDateString(language)} />;
};

const RedableTextCell = ({ value }) => (
  <CaptionMedium color={value === 0 ? 'black' : 'red'} text={value} />
);

const GreenableTextCell = ({ value, original }) => (
  <CaptionMedium
    color={
      original.completed_campaign_employees_count === original.campaign_employees_count &&
      original.campaign_employees_count > 0
        ? 'green'
        : 'black'
    }
    text={value}
  />
);

const ActionContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  align-items: center;
`;

const CampaignMenu = ({ onDelete, onArchive, onUnarchive, onEdit, onNavigate }) => (
  <Menu>
    <MenuItem icon="eye-open" text={<Caption name="CAMPAIGN_NAVIGATE" />} onClick={onNavigate} />
    {/* <MenuItem
      icon="edit"
      text={<Caption name="CAMPAIGN_EDIT" />}
      onClick={onEdit}
    /> */}
    {onArchive && (
      <MenuItem icon="compressed" text={<Caption name="ARCHIVE_CAMPAIGN" />} onClick={onArchive} />
    )}
    {onUnarchive && (
      <MenuItem icon="box" text={<Caption name="UNARCHIVE_CAMPAIGN" />} onClick={onUnarchive} />
    )}
    <MenuItem
      icon="trash"
      text={<Caption name="DELETE_CAMPAIGN" />}
      intent="danger"
      onClick={onDelete}
    />
  </Menu>
);

const Actions =
  ({ onDelete, onArchive, onUnarchive, onNavigate }) =>
  ({ original }) => {
    return (
      <ActionContainer onClick={e => e.stopPropagation()}>
        <Popover
          content={CampaignMenu({
            onDelete: () => onDelete(original),
            onArchive: onArchive && (() => onArchive(original)),
            onUnarchive: onUnarchive && (() => onUnarchive(original)),
            onNavigate: () => onNavigate(original)
          })}
        >
          <KebabMenu />
        </Popover>
      </ActionContainer>
    );
  };

const CampaignListing = ({
  data,
  onDelete,
  onArchive,
  onUnarchive,
  loading,
  onCompanyScreen,
  history,
  language
}) => {
  const navigateToCampaign = campaign => {
    history.push(`/campaigns/${campaign.id}/operation`);
  };
  const CAMPAIGN_COLUMNS = [
    {
      filterable: false,
      Cell: () => <Campaign color="grey" />,
      width: 30,
      resizable: false,
      sortable: false
    },
    {
      Header: <CaptionMedium color="grey" name="DATE" />,
      accessor: 'created_at',
      Cell: ({ value }) => <DateCell value={value} language={language} />
    },
    {
      Header: <CaptionMedium color="grey" name="CAMPAIGN_NAME" />,
      accessor: 'name',
      Cell: TitleCell
    },
    {
      Header: <CaptionMedium color="grey" name="DESCRIPTION" />,
      accessor: 'description',
      Cell: TextCell
    },
    // Hide company name and add active column
    !onCompanyScreen
      ? {
          Header: <CaptionMedium color="grey" name="COMPANY" />,
          accessor: 'company_name',
          Cell: TextCell
        }
      : {
          Header: <CaptionMedium color="grey" name="ACTIVE" />,
          accessor: 'active',
          Cell: YesNoCell,
          ...booleanFiltering
        },
    {
      Header: <CaptionMedium color="grey" name="CAMPAIGN_EMPLOYEES" />,
      accessor: 'campaign_employees_count',
      filterable: false,
      Cell: GreenableTextCell
    },
    {
      Header: <CaptionMedium color="grey" name="COMPLETED_CAMPAIGN_EMPLOYEES" />,
      headerClassName: 'word-wrap',
      accessor: 'completed_campaign_employees_count',
      filterable: false,
      Cell: GreenableTextCell
    },
    {
      Header: <CaptionMedium color="grey" name="ABSENT_CAMPAIGN_EMPLOYEES" />,
      accessor: 'absent_campaign_employees_count',
      filterable: false,
      Cell: RedableTextCell
    },
    {
      Cell: Actions({
        onDelete,
        onArchive,
        onUnarchive,
        onNavigate: navigateToCampaign
      }),
      filterable: false,
      resizable: false,
      sortable: false,
      width: 30
    }
  ];

  return (
    <StandardTable
      shrinkable
      data={data}
      columns={CAMPAIGN_COLUMNS}
      loading={loading}
      onRowClick={rowInfo => navigateToCampaign(rowInfo.original)}
      // Put archived campaigns at bottom by default
      defaultSorted={onCompanyScreen ? [{ id: 'active', desc: true }] : []}
    />
  );
};

export default connect(state => ({
  language: state.settings.language
}))(withRouter(CampaignListing));
