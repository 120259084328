import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { Caption } from '../../text';
import { isMobile } from '../../services/device';
import { RangeInput } from '../../physical_info_form/base';
import {
  PhysicalInputError,
  getCm,
  getInches,
  getInputLimits,
  InputSelector
} from '../../physical_info_form/utils';
import {
  Required,
  PhysicalInputLine,
  PhysicalLabelContainer,
  PhysicalInputContainer,
  MobilePhysicalLine,
  MobilePhysicalLineTexts
} from '../../physical_info_form/styles';

const INPUTS_WIDTH = '4rem';
const INPUTS_HEIGHT = '2rem';
export const InputStyle = styled.div`
  position: relative;
  input {
    outline: none;
    border: none;
    border-radius: 3px;
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
      inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
    background: #ffffff;
    height: ${INPUTS_HEIGHT};
    width: ${INPUTS_WIDTH};
    padding: 0 10px;
    vertical-align: middle;
    line-height: ${INPUTS_HEIGHT};
    text-align: center;
    color: #182026;
    font-size: 14px;
    font-weight: 400;
    transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input ~ .ftl-input-border {
    display: none;
  }
`;

const DesktopMetricInput = ({
  classNameBase,
  legendKey,
  isMetric,
  limits,
  value,
  onSwitch,
  onChange
}) => {
  const metricId = isMetric ? 'centimeters-input' : 'inches-input';
  const inputId = classNameBase + '-' + metricId;
  return (
    <PhysicalInputLine className={`${classNameBase}-input-container`}>
      <PhysicalLabelContainer className={`${classNameBase}-legend-container`}>
        <Caption forwardedAs="label" htmlFor={inputId} name={legendKey} color="black" />
        <Required />
      </PhysicalLabelContainer>
      <PhysicalInputContainer className={`${classNameBase}-container`}>
        <InputStyle>
          <input
            id={inputId}
            type="number"
            required
            value={value || value === 0 ? value : ''}
            onChange={e =>
              onChange(
                !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : e.target.value
              )
            }
            onBlur={e =>
              onChange(
                !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : e.target.value
              )
            }
            min={limits.min}
            max={limits.max}
            step={0.1}
          />
          <span className="ftl-input-border"></span>
        </InputStyle>
        <InputSelector
          labelFor={metricId}
          left="cm"
          right="in"
          isLeft={isMetric}
          onSwitch={onSwitch}
        />
      </PhysicalInputContainer>
    </PhysicalInputLine>
  );
};

const PhysicalLineTexts = styled(MobilePhysicalLineTexts)`
  justify-content: space-between;
`;

const PhysicalLineTextsLeft = styled.div`
  display: flex;
  align-items: center;
`;

const MobileMetricInput = ({
  theme,
  onChange,
  classNameBase,
  legendKey,
  value,
  limits,
  isMetric,
  onSwitch,
  defaultValue
}) => (
  <MobilePhysicalLine style={{ width: '100%' }}>
    <PhysicalLineTexts>
      <PhysicalLineTextsLeft>
        <Caption
          forwardedAs="label"
          htmlFor={classNameBase + '-slider'}
          name={legendKey}
          style={{ marginRight: 10 }}
        />
        <InputSelector left="cm" right="in" isLeft={isMetric} onSwitch={onSwitch} />
      </PhysicalLineTextsLeft>
      {value ? `${value.toFixed(1)}${isMetric ? ' cm' : '"'}` : ''}
    </PhysicalLineTexts>
    <RangeInput
      id={classNameBase + '-slider'}
      theme={theme}
      min={limits.min}
      max={limits.max}
      onChange={onChange}
      step={0.1}
      value={value}
      defaultValue={defaultValue}
    />
  </MobilePhysicalLine>
);

const FootLengthMeasurement = ({
  theme,
  footLength,
  onInputChange,
  lengthUnitType,
  switchLenghtUnitType
}) => {
  const limits = getInputLimits('footLength');
  const isMetric = lengthUnitType === 'metric';
  /* on parameter's default value :
        undefined and null can't be used on desktop as the text input would be interpreted as uncontrolled component by React,
        '' can't be used on mobile as the mobile slider expects float or int
    */
  let initialValue;
  if (isMobile()) {
    initialValue = footLength || undefined;
  } else {
    initialValue = footLength || '';
  }

  // Component states
  const [displayValue, setDisplayValue] = useState(
    isMetric ? initialValue : getInches(initialValue, 1)
  );

  const [displayError, setDisplayError] = useState(false);
  const [displayLimits, setDisplayLimits] = useState(
    isMetric
      ? limits
      : {
          min: getInches(limits.min, 1),
          max: getInches(limits.max, 1)
        }
  );
  const MetricInput = isMobile() ? MobileMetricInput : DesktopMetricInput;

  const handleChange = displayValue => {
    const centimeters = !isMetric && displayValue !== '' ? getCm(0, displayValue, 1) : displayValue;
    const isValueAcceptable = centimeters >= limits.min && centimeters <= limits.max;
    setDisplayValue(displayValue);
    setDisplayError((centimeters || centimeters === 0) && !isValueAcceptable);
    onInputChange({ foot_length: centimeters }, isValueAcceptable);
  };

  useEffect(() => {
    const switchToInches = !isMetric;
    let displayValue, displayLimits;

    if (switchToInches) {
      displayValue = footLength ? getInches(footLength, 1) : footLength;
      displayLimits = {
        min: getInches(limits.min, 1),
        max: getInches(limits.max, 1)
      };
    } else {
      displayValue = footLength;
      displayLimits = limits;
    }
    setDisplayValue(displayValue);
    setDisplayLimits(displayLimits);
  }, [lengthUnitType, footLength, isMetric, limits]);

  const onUnitSwitch = () => {
    switchLenghtUnitType(isMetric ? 'imperial' : 'metric');
  };

  return (
    <ThemeProvider theme={theme}>
      <MetricInput
        onChange={handleChange}
        limits={displayLimits}
        value={displayValue}
        isMetric={isMetric}
        onSwitch={onUnitSwitch}
        legendKey="FOOT_LENGTH"
        classNameBase="length"
        defaultValue={limits.defaultValue}
      />
      {displayError && (
        <PhysicalInputError
          name="ERROR_INPUT_LENGTH"
          values={{ ...displayLimits, unitLabel: isMetric ? 'cm' : 'in' }}
        />
      )}
    </ThemeProvider>
  );
};

export default FootLengthMeasurement;
