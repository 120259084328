import React from 'react';
import styled from 'styled-components/macro';
import FaClose from 'react-icons/lib/fa/close';
import { colors, Body, BodyMedium, Caption, CaptionMedium, TitleMedium } from 'shared_components';
import { useHistory, useLocation } from 'react-router-dom';

import Callout from 'library/Callout';
import { StandardTable } from 'library/tables';
import { SubmitButton, OutlineButton } from 'library/buttons';
import Progress from 'library/Progress';

const LayoutContainer = styled.div`
  background-color: white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const TopRow = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const CLoseLink = styled.a`
  position: absolute;
  right: 0;
  cursor: pointer;
  &:hover {
    filter: brightness(50%);
  }
`;

const Content = styled.div`
  position: relative;
  flex: 1;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageTitles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1.8rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const MainTitleContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const PROGRESS_ITEMS = [
  'POSITION_NAME_TITLE',
  'CLOTHES_SELECTION',
  'QUANTITY_CHOICE',
  'POSITION_INFO_TITLE'
];

export const Layout = ({ progress, onClose, children }) => {
  const location = useLocation();
  let disableProgressUpToIndex;
  if (location.state && location.state.onlyEditInfo) {
    disableProgressUpToIndex = 2;
  }
  return (
    <LayoutContainer>
      <TopRow>
        <Progress
          items={PROGRESS_ITEMS}
          progress={progress}
          disableUpToIndex={disableProgressUpToIndex}
        />
        <CLoseLink onClick={onClose} ariaLabel="Go back to the camaping screen">
          <FaClose size={32} color={colors.grey} />
        </CLoseLink>
      </TopRow>
      <Content>
        <PageTitles>
          <MainTitleContainer>
            <Body name="POSITION_CREATION" />
            <BodyMedium style={{ margin: '0px 5px' }} text={progress + 1} />
            <Body text="/ 4" />
          </MainTitleContainer>
          <TitleMedium name={PROGRESS_ITEMS[progress]} />
        </PageTitles>
        {children}
      </Content>
    </LayoutContainer>
  );
};

const PaneContainer = styled.div`
  width: 100%;
  max-width: 50rem;
  border-radius: 5px;
  margin: 1rem 0;
  background-color: ${colors.lighterGrey};
`;

const PaneTitle = styled.div`
  border-radius: 5px 5px 0 0;
  width: 100%;
  background-color: ${colors.mainBlue};
  padding: 1rem 2rem;
`;

const PaneContent = styled.div`
  border-radius: 0 0 5px 5px;
  background-color: ${colors.lighterGrey};
  padding: 1.25rem;
`;

export const Pane = ({ title, children, className }) => (
  <PaneContainer className={className}>
    <PaneTitle>
      <Body name={title} color="white" />
    </PaneTitle>
    <PaneContent>{children}</PaneContent>
  </PaneContainer>
);

export const Submit = styled(SubmitButton)`
  width: 12.5rem;
`;

const ButtonsContainer = styled.div`
  max-width: ${props => (props.fullWidth ? 'none' : '50rem')};
  width: 100%;
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
`;

export const BottomRowButtons = ({ submit, fullWidth, backDisabled }) => {
  const history = useHistory();
  return (
    <ButtonsContainer fullWidth={fullWidth}>
      <OutlineButton
        disabled={backDisabled}
        style={{ minWidth: 140, boxShadow: 'none' }}
        onClick={() => history.goBack()}
      >
        <Caption name="GO_BACK" color="grey" />
      </OutlineButton>
      <Submit onClick={submit} />
    </ButtonsContainer>
  );
};

export const Table = styled(StandardTable)`
  && .rt-thead.-filters {
    display: none;
  }
  && .rt-tbody,
  && .rt-thead.-header {
    background-color: inherit;
  }
  && .rt-td {
    display: flex;
    align-items: center;
  }
  &&&& .rt-tr-group {
    border-radius: 5px;
    margin: 0;
    border: 1px solid ${colors.lightGrey};
  }
`;

const Ellipsis = styled(CaptionMedium)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const ItemNameCell = ({ original }) => (
  <Ellipsis text={original.product_name + ' - ' + original.client_product_id} />
);

export const ExplanationCallout = styled(Callout)`
  max-width: 50rem;
  margin-bottom: 1rem;
  width: 100%;
`;
