import React from 'react';
import { connect } from 'react-redux';
import { Caption } from 'shared_components';

import { Pane, BottomRowButtons, ExplanationCallout } from '../common';
import QuantityTable from './QuantityTable';

const QuantityExplanation = () => (
  <ExplanationCallout intent="primary">
    <div style={{ marginBottom: 8 }}>
      <Caption name="QUANTITY_EXPLANATION" />
    </div>
    <div>
      <Caption color="mainBlue" name="FREE" style={{ marginRight: 10 }} />
      <Caption name="FREE_EXPLANATION" />
    </div>
    <div>
      <Caption color="mainBlue" name="FIXED" style={{ marginRight: 10 }} />
      <Caption name="FIXED_EXPLANATION" />
    </div>
  </ExplanationCallout>
);

const Quantity = ({ history, match, maleItems, femaleItems, updatePosition }) => {
  const onQuantityChange = gender => (value, index) => {
    const newItems = {
      maleItems: [...maleItems],
      femaleItems: [...femaleItems]
    };
    newItems[`${gender}Items`][index].quantity = value;
    updatePosition(newItems);
  };

  const submit = () => history.push(`/position/${match.params.campaign_id}/info`);

  return (
    <>
      <QuantityExplanation />
      <>
        {femaleItems.length > 0 && (
          <Pane title="FEMALE">
            <QuantityTable
              gender="FEMALE"
              items={femaleItems}
              onQuantityChange={onQuantityChange('female')}
            />
          </Pane>
        )}
        {maleItems.length > 0 && (
          <Pane title="MALE">
            <QuantityTable
              gender="MALE"
              items={maleItems}
              onQuantityChange={onQuantityChange('male')}
            />
          </Pane>
        )}
      </>
      <BottomRowButtons submit={submit} />
    </>
  );
};

const mapper = state => ({
  femaleItems: state.position.femaleItems,
  maleItems: state.position.maleItems
});

const dispatcher = dispatch => ({
  updatePosition: items => dispatch({ type: 'UPDATE_POSITION', ...items })
});

export default connect(mapper, dispatcher)(Quantity);
