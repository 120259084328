import React from 'react';
import MdHelp from 'react-icons/lib/md/help';
import { Tag } from '@blueprintjs/core';
import { colors, Caption } from 'shared_components';

export const LinkToDoc = ({ textName, link, ...props }) => (
  <a href={link} target="_blank" rel="noopener noreferrer" {...props}>
    <MdHelp style={{ marginRight: 4 }} />
    <Caption name={textName} />
  </a>
);

export const RoleTag = ({ isAdmin, style = {}, ...props }) => (
  <Tag
    style={{ backgroundColor: isAdmin ? colors.green : colors.blue, textAlign: 'center', ...style }}
    {...props}
  >
    <Caption name={isAdmin ? 'ADMIN' : 'OPERATOR'} color="white" />
  </Tag>
);
