import React from 'react';
import styled, { css } from 'styled-components/macro';

import { ITEM_DIMENSIONS } from './constants';

import { colors } from 'shared_components';
import { media } from 'shared_components';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  user-select: none;
  z-index: 2;

  height: ${ITEM_DIMENSIONS.HEIGHT};
  width: max(${ITEM_DIMENSIONS.WIDTH}, 25%);
  line-height: ${ITEM_DIMENSIONS.HEIGHT};
  border-radius: 5px;
  color: ${colors.white};
  margin: 0 2px;

  ${props =>
    props.active
      ? css`
          background-color: ${colors.blue};
          span {
            color: ${colors.white};
          }
        `
      : css`
          background-color: ${colors.white};
          ${media.screenLarge`
            background-color: transparent;
          `}
          cursor: pointer;
          span {
            color: ${colors.blue};
          }
          &:hover {
            filter: brightness(0.8);
          }
        `};
  ${media.screenLarge`
    border-radius: inherit;
  `}
  ${media.screenMedium`
    width: max(${ITEM_DIMENSIONS.WIDTH}, 90%);
  `}
  ${media.phone`
    margin: 5px 0 5px 0;
    width: 100%;
    border: 1px ${props => (props.borderColor ? props.borderColor : colors.grey)} solid;
  `}
  ${props =>
    props.disabled &&
    css`
      background-color: ${colors.lightGrey};
      span {
        color: ${colors.lightGrey};
      }
      &:hover {
        cursor: not-allowed;
        background-color: ${colors.lightGrey};
        filter: brightness(1);
      }
    `}
`;

const Item = ({ active, children, onClick, borderColor, disabled }) => (
  <Wrapper
    disabled={disabled}
    active={active}
    className="toggle-item"
    onClick={() => (disabled ? null : onClick())}
    borderColor={borderColor}
  >
    {children}
  </Wrapper>
);

export default Item;
