import React, { useState } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { SmallText } from '../text/index';
import FaAngleLeft from 'react-icons/lib/fa/angle-left';

import { colors } from 'shared_components/styles';

const ProductBadgeContainer = styled.div`
  user-select: none;
  width: 100%;
  transition: opacity 20ms;
  display: flex;
  justify-content: center;
  ${props => props.hide && 'display: none;'}
  ${props =>
    props.isOnBottomLeft &&
    `
    position: absolute;
    top: 0;
    bottom: 10px;
    left: 0;
  `}
`;

const ProductBadgeStyle = styled.div`
  cursor: pointer;
  width: 3.75em;
  height: 3.75em;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  transition: box-shadow 100ms ease;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.12);
  ${props =>
    props.isOnBottomLeft &&
    `
    margin-left: 1.25em;
    margin-bottom: 0.75em;
    position: absolute;
    bottom: 0;
    left: 0;
  `}

  & > img {
    pointer-events: none;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }

  :hover {
    box-shadow: none;
  }
`;

const ProductBadgeBig = styled.div`
  cursor: pointer;
  position: absolute;
  left: 1em;
  right: 1em;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.625em;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.12);
  z-index: 10;

  & > img {
    max-height: 90%;
    max-width: 90%;
    object-fit: contain;
  }
`;

const ProductBadgeoverlay = styled.div`
  position: fixed;
  opacity: 0.5;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: ${colors.black};
  z-index: 9;
`;

// const CloseIcon = styled(SmallText)`
//   margin-top: 1em;
//   height: 2em;
//   width: 4em;
//   align-self: flex-end;
//   color: ${colors.coal};
//   margin-right: 1em;
//   transition: opacity 100ms ease;
//   opacity: 1;

//   :hover {
//     opacity: 0.7;
//   }
// `;
const Wrapper = styled.button`
  display: inline-block;
  width: 100%;
  text-align: left;
  padding: 0.5rem 1.5rem 0 0;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  color: ${colors.coal};
  :focus {
    outline: 0 !important;
  }
`;

const ProductBadge = React.memo(({ photoUrl, isOnBottomLeft }) => {
  const [displayBig, setDisplayBig] = useState(false);

  const toggleProductBadgeBig = e => {
    e.stopPropagation();
    return setDisplayBig(!displayBig);
  };

  return (
    <ProductBadgeContainer hide={!photoUrl} isOnBottomLeft={isOnBottomLeft}>
      <ProductBadgeStyle isOnBottomLeft={isOnBottomLeft} onClick={toggleProductBadgeBig}>
        <img className="product-badge-photo" src={photoUrl} alt="product" />
      </ProductBadgeStyle>
      <CSSTransition classNames="fast-fade" in={displayBig} timeout={20} unmountOnExit>
        <div>
          <ProductBadgeoverlay onClick={toggleProductBadgeBig}></ProductBadgeoverlay>
          <ProductBadgeBig onClick={toggleProductBadgeBig}>
            <Wrapper>
              <FaAngleLeft color={colors.coal} size={18} />
              <SmallText name="BACK_TO_RECOMMENDATION" />
            </Wrapper>
            <img className="product-badge-photo" src={photoUrl} alt="product big" />
          </ProductBadgeBig>
        </div>
      </CSSTransition>
    </ProductBadgeContainer>
  );
});

export default ProductBadge;
