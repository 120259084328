import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import FaCloudDownload from 'react-icons/lib/fa/cloud-download';
import FaInfoCircle from 'react-icons/lib/fa/info-circle';
import { colors, Caption, Body, Title } from 'shared_components';

import api from 'api';
import { ColoredButton } from 'library/buttons';
import { CancellableDialog } from 'library/dialogs';
import Select from 'library/forms/select';
import { FUNCTIONAL_COLUMNS, sortColumns } from 'services/exports';
import Callout from '../../library/Callout';
import { Loading, Error, useAsync } from 'library/Async';
import { localizedString } from 'services/i18n';

const Pane = styled.div`
  &:last-child {
    margin-left: 15px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const ColumnSelectorContainer = styled.div`
  max-height: 300px;
  overflow-y: scroll;
`;

const ColumnLineContainer = styled.div`
  display: flex;
`;

const ExportBody = styled.div`
  display: flex;
  padding-top: 15px;
`;

const Header = styled.div`
  background-color: ${colors.blue};
  padding: 5px 12px 3px 12px;
  margin-bottom: 10px;
`;

const ColumnLine = ({ name, selected, onChange, displayValue }) => {
  return (
    <ColumnLineContainer>
      <input type="checkbox" checked={selected} onChange={e => onChange(name)} />
      <Caption text={displayValue} style={{ marginLeft: 12, marginTop: 3 }} />
    </ColumnLineContainer>
  );
};
const getColumnLabels = async language => {
  const measurement_columns = await api.campaignEmployees.getMeasurementLabels({ language });
  const functional_columns_map = FUNCTIONAL_COLUMNS.reduce(
    (item, column) => ({ ...item, [column]: localizedString(column) }),
    {}
  );
  return { ...functional_columns_map, ...measurement_columns };
};

const ColumnSelector = props => {
  const [columnLabels, loading, error] = useAsync(getColumnLabels, props.language);

  if (loading) return <Loading />;
  if (error) return <Error />;

  if (!columnLabels) return null;

  const onChange = name => {
    const { columns, onChange } = props;
    if (columns.includes(name)) {
      const res = columns.concat();
      res.splice(res.indexOf(name), 1);
      onChange(res);
    } else {
      // Using object keys to sort :O
      const res = sortColumns([...columns, name], Object.keys(columnLabels));
      onChange(res);
    }
  };

  return (
    <ColumnSelectorContainer>
      {Object.keys(columnLabels).map(name => {
        return (
          <ColumnLine
            key={name}
            name={name}
            displayValue={columnLabels[name]}
            selected={props.columns.includes(name)}
            onChange={onChange}
          />
        );
      })}
    </ColumnSelectorContainer>
  );
};

const Input = ({ name, children }) => {
  return (
    <InputContainer>
      <Caption name={name} style={{ marginRight: 12 }} />
      <div>{children}</div>
    </InputContainer>
  );
};

class CampaignExport extends React.Component {
  state = {
    showExportDialog: false,
    exportType: 'CSV',
    separator: ';'
  };

  export = async () => {
    await this.props.export({
      export_type: this.state.exportType,
      separator: this.state.separator,
      columns: this.props.columns
    });
    this.setState({ showExportDialog: false });
  };

  renderWarningBody = () => {
    return (
      <ExportBody style={{ maxWidth: 300 }}>
        <Body name="INCOMPLETE_CAMPAIGN_WARNING" />
      </ExportBody>
    );
  };
  renderWarningModal = () => {
    return (
      <CancellableDialog
        danger
        onClose={() => this.setState({ showWarningDialog: false })}
        onSubmit={() => this.setState({ showWarningDialog: false, showExportDialog: true })}
      />
    );
  };

  renderExportBody = () => {
    const { exportType, separator } = this.state;
    return (
      <ExportBody>
        <Pane>
          <Header>
            <Caption color="white" name="PARAMETERS" />
          </Header>
          <Input name="EXPORT_TYPE_SELECTION">
            <Select
              popoverProps={{ usePortal: false }}
              value={exportType}
              items={['CSV', 'XLSX']}
              renderItem={s => <Caption text={s} />}
              onChange={exportType => {
                this.setState({ exportType });
              }}
            />
          </Input>
          {exportType === 'CSV' && (
            <Input name="SEPARATOR">
              <input
                type="text"
                value={separator}
                onChange={e => this.setState({ separator: e.target.value })}
              />
            </Input>
          )}
        </Pane>
        <Pane>
          <Header>
            <Caption color="white" name="COLUMNS_TO_EXPORT" />
            <a
              target="blank"
              href="https://docs.sizerpro.com/apres-une-campagne/exportation-des-donnees-facilitee"
              style={{ marginLeft: 10 }}
            >
              <FaInfoCircle color="white" />
            </a>
          </Header>
          <ColumnSelector
            columns={this.props.columns}
            onChange={this.props.onColumnChange}
            language={this.props.language}
          />
        </Pane>
      </ExportBody>
    );
  };

  renderExportModal = () => {
    return (
      <CancellableDialog
        onClose={() => {
          this.setState({ showExportDialog: false });
        }}
        onSubmit={this.export}
        danger={this.state.showWarningDialog}
      >
        <Title name="EXPORT" />
        {this.props.showWarningDialog && (
          <Callout intent="warning" style={{ maxWidth: 450, marginTop: 20 }}>
            <Caption name="INCOMPLETE_CAMPAIGN_WARNING" />
          </Callout>
        )}
        {this.state.showWarningDialog ? this.renderWarningBody() : this.renderExportBody()}
      </CancellableDialog>
    );
  };

  render() {
    const renderSomething = this.state.showExportDialog || this.state.showWarningDialog;
    return (
      <React.Fragment>
        <ColoredButton onClick={() => this.setState({ showExportDialog: true })}>
          <FaCloudDownload color="white" />
          <Caption name={this.props.recompute ? 'RECOMPUTE' : 'EXPORT'} color="white" />
        </ColoredButton>
        {renderSomething && this.renderExportModal()}
      </React.Fragment>
    );
  }
}

const mapper = state => ({
  columns: state.preferences.columnsToExport,
  language: state.settings.language
});

const dispatcher = dispatch => ({
  onColumnChange: columns => dispatch({ type: 'CHANGE_EXPORT_COLUMNS', columns })
});

export default connect(mapper, dispatcher)(CampaignExport);
