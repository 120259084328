export const BOOB_REGIONS = ['DE', 'ES', 'FI', 'FR', 'IT', 'NL', 'NO', 'PT', 'UK', 'SE', 'US'];

const COUNTRY_MAPPING = {
  CN: 'CN',
  DE: 'DE',
  EN: 'UK',
  ES: 'ES',
  FI: 'FI',
  FR: 'FR',
  GB: 'UK',
  IT: 'IT',
  JP: 'JP',
  NL: 'NL',
  NO: 'NO',
  PT: 'PT',
  SE: 'SE',
  US: 'US'
};

export const getDefaultRegion = language => COUNTRY_MAPPING[language];

const EU_CUPS = ['AA', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];

const UK_CUPS = ['AA', 'A', 'B', 'C', 'D', 'DD', 'E', 'F', 'G', 'H', 'I', 'J'];
const US_CUPS = ['AA', 'A', 'B', 'C', 'D', 'DD', 'DDD', 'DDDD', 'H', 'I', 'J', 'K'];

const FR_CUPS = ['AA', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

const EU_BANDS = [60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125];
const FR_BANDS = EU_BANDS.map(x => x + 15);
const EN_BANDS = [28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54];

const BRA_SIZE_MAPPING = {
  CN: {
    bands: EU_BANDS,
    cups: EU_CUPS
  },
  DE: {
    bands: EU_BANDS,
    cups: EU_CUPS
  },
  ES: {
    bands: FR_BANDS,
    cups: FR_CUPS
  },
  FI: {
    bands: EU_BANDS,
    cups: EU_CUPS
  },
  FR: {
    bands: FR_BANDS,
    cups: FR_CUPS
  },
  GB: {
    bands: EN_BANDS,
    cups: UK_CUPS
  },
  IT: {
    bands: EU_BANDS,
    cups: EU_CUPS
  },
  JP: {
    bands: EU_BANDS,
    cups: EU_CUPS
  },
  NL: {
    bands: EU_BANDS,
    cups: EU_CUPS
  },
  NO: {
    bands: EU_BANDS,
    cups: EU_CUPS
  },
  PT: {
    bands: EU_BANDS,
    cups: EU_CUPS
  },
  SE: {
    bands: EU_BANDS,
    cups: EU_CUPS
  },
  UK: {
    bands: EN_BANDS,
    cups: UK_CUPS
  },
  US: {
    bands: EN_BANDS,
    cups: US_CUPS
  }
};

const makePossibilities = () => {
  const res = [];
  Object.keys(BRA_SIZE_MAPPING).forEach(region => {
    const { bands, cups } = BRA_SIZE_MAPPING[region];
    bands.forEach(band =>
      cups.forEach(cup =>
        res.push({
          band,
          cup,
          region,
          bra_size: band + cup,
          label: band + cup,
          value: { band, cup, region }
        })
      )
    );
  });
  return res;
};

export const BRA_SIZE_POSSIBILITES = makePossibilities();

export const getPossibleRegions = bra_size => {
  return BRA_SIZE_POSSIBILITES.filter(x => x.bra_size === bra_size).map(x => x.region);
};

// Sort bra sizes so that sizes from the argument region are on top
export const getSortedPossibilities = region =>
  BRA_SIZE_POSSIBILITES.sort((a, b) => {
    if (a.region === b.region) return 0;
    if (a.region === region) return -1;
    if (b.region === region) return 1;
    return 0;
  });

export const getFlatPossibilities = region => BRA_SIZE_MAPPING[region];

export const getPlaceholder = region => {
  if (!region) {
    return 'ex. 85B';
  }
  if (['UK', 'US', 'GB'].includes(region)) {
    return 'ex. 32B';
  }
  return 'ex. 85B';
};

export const isBraInfoDefined = bra => {
  if (bra) {
    return bra.cup && bra.band && bra.region;
  }
  return false;
};
