import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auth from './reducers/auth';
import position from './reducers/position';
import settings from './reducers/settings';
import preferences from './reducers/preferences';

const persistConfig = {
  key: 'sizerpro-root',
  storage
};

const rootReducer = combineReducers({
  auth,
  settings,
  preferences,
  position
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const persistor = persistStore(store);

export default store;
