import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import {
  Switch,
  Popover,
  PopoverInteractionKind,
  PopoverPosition,
  TextArea,
  Collapse
} from '@blueprintjs/core';
import { colors, CaptionMedium, Caption } from 'shared_components';
import IoIosArrowDown from 'react-icons/lib/io/arrow-down-b';

import { LANGUAGES } from 'i18n';
import { getFlag, localizedString } from 'services/i18n';
import { isNullOrUndefined } from 'services/utils';

const SwitchContainer = styled.div`
  margin-top: 0.25rem;
  display: flex;
  .bp3-control {
    margin: 0;
  }
  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

const SWITCH_TEXTS = {
  true: 'ACTIVATED',
  false: 'DEACTIVATED',
  null: 'UNAVAILABLE',
  undefined: 'UNAVAILABLE'
};

const SwitchCell = ({ value, index, onChange, isDeactivatedOrUnassociated }) => {
  const isDisabled = isNullOrUndefined(value);

  let popOverText;
  if (isDeactivatedOrUnassociated) {
    popOverText = 'DEACTIVATED_OR_UNASSOCIATED';
  } else if (isDisabled) {
    popOverText = 'UNAVAILABLE_FOR_PRODUCT';
  } else if (!value) {
    popOverText = 'FIT_DEACTIVATED';
  } else if (value) {
    popOverText = 'FIT_ACTIVATED';
  }

  return (
    <Popover
      position={PopoverPosition.TOP}
      interactionKind={PopoverInteractionKind.HOVER}
      content={
        <PopoverContent>
          <Caption name={popOverText} />
        </PopoverContent>
      }
    >
      <SwitchContainer isDisabled={isDisabled}>
        <Switch
          // Cast to boolean, value can be 'null' which cause uncontrolled warning
          checked={!!value}
          disabled={isDisabled}
          onChange={e => onChange(e.target.checked, index)}
        />
        <Caption name={SWITCH_TEXTS[value]} />
      </SwitchContainer>
    </Popover>
  );
};

const Flag = styled.img`
  width: 1rem;
  object-fit: contain;
`;

const SizeAdviceRow = ({ sizeAdvice, updateSizeAdvice, itemIndex }) => {
  const onChange = e => {
    const newSizeAdvice = { ...sizeAdvice, content: e.target.value };
    updateSizeAdvice(newSizeAdvice, itemIndex);
  };
  return (
    <div style={{ display: 'flex', margin: '0.5rem 0' }}>
      <div>
        <Flag src={getFlag(sizeAdvice.language)} alt={sizeAdvice.language} />
      </div>
      <TextArea
        fill
        style={{ fontSize: '0.75rem', marginLeft: '0.5rem' }}
        rows={2}
        maxLength={250}
        value={sizeAdvice.content || ''}
        onChange={onChange}
        placeholder={localizedString('POSITION_PRODUCT_SIZE_ADVICE_PLACEHOLDER')}
      />
    </div>
  );
};

const Col = styled.div`
  background-color: ${colors.white};
  padding: 1rem 0.5rem;
  grid-column: ${props => props.position};

  ${props => props.position === '1 / 2' && 'border-radius: 5px 0 0 5px;'}
  ${props => props.position === '3 / 4' && 'border-radius: 0 5px 5px 0;'}
`;

const CollapseButton = styled.button`
  border: none;
  border-radius: 5px;
  background-color: ${colors.lightBlueGrey};
  color: ${colors.black};
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 15rem;
  cursor: pointer;
  margin-bottom: 1rem;

  :hover {
    opacity: 0.8;
  }

  svg {
    margin-left: 0.5rem;
    ${props => props.isOpen && 'transform: rotate(180deg);'}
  }
  span {
    margin-top: 2px;
  }
`;

const advicesOrder = (adviceA, adviceB) => {
  const aIndex = LANGUAGES.indexOf(adviceA.language);
  const bIndex = LANGUAGES.indexOf(adviceB.language);
  if (aIndex > bIndex) return 1;
  if (aIndex < bIndex) return -1;
  return 0;
};

const Item = ({ item, index, updatePreferredFit, updateSizeAdvice }) => {
  const [isOpen, setIsOpen] = useState(index === 0);
  return (
    <>
      <Col position="1 / 2">
        <CaptionMedium text={item.product_name + ' - ' + item.client_product_id} />
      </Col>
      <Col position="2 / 3">
        <SwitchCell
          value={item.enable_preferred_fit}
          onChange={updatePreferredFit}
          index={index}
          isDeactivatedOrUnassociated={item.isDeactivatedOrUnassociated}
        />
      </Col>
      <Col position="3 / 4">
        <CollapseButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <Caption name={isOpen ? 'CLOSE_SIZE_ADVICES' : 'SEE_SIZE_ADVICES'} />
          <IoIosArrowDown />
        </CollapseButton>
        <Collapse isOpen={isOpen}>
          {item.size_advices.sort(advicesOrder).map(sizeAdvice => (
            <SizeAdviceRow
              key={sizeAdvice.language}
              sizeAdvice={sizeAdvice}
              itemIndex={index}
              updateSizeAdvice={updateSizeAdvice}
            />
          ))}
        </Collapse>
      </Col>
    </>
  );
};

export default Item;
