import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { FileInput, ProgressBar } from '@blueprintjs/core';
import { Caption } from 'shared_components';

const Container = styled.div`
  width: 268px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MediaUpload = ({ handleFile }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleFileChange = async e => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const data = new FormData();
    data.set('file', file);
    setIsLoading(true);
    setHasError(false);
    try {
      await handleFile(data);
      setIsLoading(false);
    } catch (error) {
      setHasError(true);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Container>
        <ProgressBar intent="primary" />
      </Container>
    );
  } else {
    return (
      <Container>
        <FileInput onInputChange={handleFileChange} />
        {hasError && <Caption name="ERROR" color="red" style={{ marginTop: '0.25rem' }} />}
      </Container>
    );
  }
};

export default MediaUpload;
