import React from 'react';
import styled from 'styled-components/macro';
import { MainTitle } from 'shared_components';
import { withRouter } from 'react-router-dom';

import api from 'api';
import CampaignManagement from './CampaignManagement';
import Async from 'library/Async';
import { SmallTitleSection } from 'library/layout';
import { Campaign } from 'library/icons';
import { TabRouter, Tab } from 'library/navigation/tab-router';

const Container = styled.div``;

class CampaignsScreen extends React.Component {
  refresh = async () => {
    const [campaigns, companies] = await Promise.all([api.campaigns.list(), api.companies.list()]);
    return { companies, campaigns };
  };
  render() {
    return (
      <Container>
        <SmallTitleSection>
          <Campaign color="grey" />
          <MainTitle name="CAMPAIGNS" />
        </SmallTitleSection>
        <Async fetchProps={this.refresh} ref={a => (this.async = a)}>
          {({ companies, campaigns }) => (
            <TabRouter>
              <Tab
                name="ACTIVE_CAMPAIGNS"
                to="/campaigns"
                exact
                component={() => (
                  <CampaignManagement
                    companies={companies}
                    campaigns={campaigns.filter(c => c.active)}
                    refresh={this.async.refresh}
                  />
                )}
              />
              <Tab
                name="ARCHIVED_CAMPAIGNS"
                to="/campaigns/archived"
                component={() => (
                  <CampaignManagement
                    onArchivedScreen
                    companies={companies}
                    campaigns={campaigns.filter(c => !c.active)}
                    refresh={this.async.refresh}
                  />
                )}
              />
            </TabRouter>
          )}
        </Async>
      </Container>
    );
  }
}

export default withRouter(CampaignsScreen);
