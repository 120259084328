import React from 'react';
import styled from 'styled-components/macro';
import { colors, MainTitle, Body } from 'shared_components';
import { Link } from 'react-router-dom';

import api from 'api';
import Async from 'library/Async';
import { TitleSection } from 'library/layout';
import { BackLink } from 'library/navigation';
import { TabRouter, Tab } from 'library/navigation/tab-router';
import Positions from './positions';
import Operation from './operation';
import { Campaign, Company, Position, Operation as OperationIcon } from 'library/icons';

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`;

const CampaignHeader = ({ campaign }) => {
  const backUrl = `/companies/${campaign.company_id}/campaigns`;
  return (
    <TitleSection left={<BackLink to={backUrl} name="BACK_TO_COMPANY" />}>
      <TitlesContainer>
        <Row>
          <Campaign color="grey" size="1.325rem" style={{ marginRight: 10 }} />
          <MainTitle text={campaign.name} />
        </Row>
        <Row>
          <Link to={backUrl} style={{ display: 'flex' }}>
            <Company color={colors.grey} style={{ marginRight: 5 }} />
            <Body text={campaign.company_name} color={colors.grey} />
          </Link>
        </Row>
      </TitlesContainer>
    </TitleSection>
  );
};

class CampaignDetails extends React.Component {
  getCampaignId = () => {
    return this.props.match.params.campaign_id;
  };

  fetchCampaign = async () => {
    return await api.campaigns.get({ id: this.getCampaignId() });
  };

  render() {
    return (
      <Async fetchProps={this.fetchCampaign}>
        {campaign => (
          <Container>
            <CampaignHeader campaign={campaign} />
            <TabRouter>
              <Tab
                name="POSITIONS"
                to="/campaigns/:campaign_id/product_sets"
                component={Positions}
                Icon={Position}
              />
              <Tab
                name="OPERATION"
                to="/campaigns/:campaign_id/operation"
                component={Operation}
                Icon={OperationIcon}
              />
            </TabRouter>
          </Container>
        )}
      </Async>
    );
  }
}

export default CampaignDetails;
