import React from 'react';
import styled from 'styled-components/macro';
import { media, colors, ErrorText, SmallText, CaptionMedium, Caption } from 'shared_components';
import { Tooltip, AnchorButton } from '@blueprintjs/core';

import Callout from 'library/Callout';
import { Check } from 'library/icons';
import { IconCheckContainer } from '../common/styles';

export const PositionItemInfo = styled.div`
  overflow: auto;
  position: relative;
  background-color: ${colors.lighterGrey};
  ${media.phone`
    background: inherit;
  `}
`;

const JustifedText = styled.p`
  text-align: justify;
`;

const StyledCallout = styled(Callout)`
  padding: 0.625rem 1.25rem;
  margin: 2.5rem 3rem 0rem 3rem;
  justify-content: inherit;
  & div {
    flex-direction: row;
    justify-content: inherit;
  }
  ${props => props.hasMarginBottom && 'margin-bottom: 2rem;'}
  ${media.phone`
    background: rgba(52, 165, 248, 0.25);
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    padding: 0.5rem 0.5rem;
  `}
`;

export const SizeAdvice = ({ language, itemSizeAdvices, hasMarginBottom }) => {
  const displayedSizeAdvice = itemSizeAdvices.find(sizeAdvice => sizeAdvice.language === language);

  if (!displayedSizeAdvice || !displayedSizeAdvice.content) {
    return null;
  }

  return (
    <StyledCallout hasMarginBottom={hasMarginBottom} intent="primary" icon={false}>
      <JustifedText>
        <CaptionMedium name="SIZE_ADVICE" color={colors.mainBlue} />
        <Caption
          text={displayedSizeAdvice.content}
          style={{ padding: '0px 0px 0px 5px', color: colors.white }}
        />
      </JustifedText>
    </StyledCallout>
  );
};

const BottomContainer = styled.div`
  margin: 1em 0em;
`;

const WhiteCallout = styled(Callout)`
  background-color: ${colors.white};
`;

export const NoRecoCallout = () => (
  <BottomContainer>
    <WhiteCallout>
      <ErrorText name="NO_RECO_FOUND" />
    </WhiteCallout>
  </BottomContainer>
);

const ValidationContainer = styled.div`
  flex: 1 1 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 2;
  margin-top: 1rem;

  ${media.phone`
    justify-content: flex-start;
    order: 3;
  `}
`;
const ValidationContent = styled.div`
  text-align: center;
  ${media.phone`
    width:60%;
    margin-right: 10%;
    margin-left: 30%;
  `};
`;
const ModifyContent = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${media.phone`
    margin-left: 35%;
  `};
`;
const ModifyButton = styled.button`
  cursor: pointer;
  background: transparent;
  text-decoration: underline;
  color: ${colors.grey};
  padding-left: 0.4rem;
  :hover {
    text-decoration: underline;
    color: ${colors.black};
  }
  ${media.phone`
    color:white
  `}
`;

const ValidationButton = styled(AnchorButton)`
  &&&& {
    box-shadow: none;
    border: none;
    min-width: 7rem;
    border-radius: 0.3125em;
    padding: 0.5rem 2.5rem;
    margin: 0rem 0rem;
    color: white;
    background-color: ${props => (props.disabled ? colors.grey : colors.mainBlue)};
    background-image: none;
    :hover {
      ${props => (props.disabled ? null : `filter: brightness(105%)`)};
    }
  }
  ${media.phone`
    margin: 0rem;
    padding:0em;
    width:100%;
    display: inline-block;
  `}
`;

export const Validation = ({ validated, validationTooltip, onToggle, isAccessory }) => (
  <ValidationContainer>
    {validated ? (
      <ModifyContent>
        <IconCheckContainer backgroundColor={colors.green}>
          <Check size={'80%'} style={{ size: 'inherit' }} />
        </IconCheckContainer>
        <ModifyButton type="button" onClick={onToggle} aria-label="modify choices">
          <SmallText name="MODIFY_MY_CHOICES" style={{ color: 'inherit', fontSize: '0.8rem' }} />
        </ModifyButton>
      </ModifyContent>
    ) : (
      <ValidationContent>
        <Tooltip
          disabled={validationTooltip === 'OK'}
          content={<SmallText name={validationTooltip} color="white" />}
        >
          <ValidationButton disabled={validationTooltip !== 'OK'} onClick={onToggle}>
            <SmallText name="VALIDATE" color="white" style={{ fontSize: '1rem' }} />
          </ValidationButton>
        </Tooltip>
        {isAccessory && <ErrorText name="FUNNEL_ACCESSORY_WARNING" />}
      </ValidationContent>
    )}
  </ValidationContainer>
);
