import styled from 'styled-components';
import { colors } from 'shared_components/styles';

export const CELL_WIDTH_PX = 110;

export const Cell = styled.td`
  text-align: center;
  padding: 8px 0;
  color: ${colors.coal};
  vertical-align: middle;
`;

export const SizingChartCell = styled(Cell)`
  min-width: ${CELL_WIDTH_PX}px;
  position: relative;
  padding: 0;
`;

export const FixedCell = styled(SizingChartCell)`
  background-color: ${colors.white};
  position: sticky;
  left: 0;
  z-index: 1;
`;
