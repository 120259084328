import React from 'react';
import { Switch, Route, withRouter, matchPath, Link } from 'react-router-dom';
import { Caption } from 'shared_components';

import styled from 'styled-components/macro';
import { colors } from 'shared_components';

const TabLink = styled(Link)`
  padding: 0.75rem 2rem;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    content: '';
  }
  &:hover {
    & * {
      color: #bae2ff;
    }
    &:after {
      background-color: #bae2ff;
    }
  }
  &.-active:hover {
    & * {
      color: ${colors.blue};
    }
    filter: brightness(100%);
  }
  &.-active:after {
    background-color: ${colors.blue};
  }
`;

const NavTab = ({ active, name, text, to, Icon }) => {
  const textColor = active ? colors.blue : colors.grey;
  const className = '-tab' + (active ? ' -active' : '');
  return (
    <TabLink className={className} to={to}>
      {Icon && <Icon color={textColor} style={{ marginRight: 8 }} />}
      <Caption name={name} text={text} color={textColor} />
    </TabLink>
  );
};

const TabsContainer = styled.div`
  display: flex;
  border-bottom: 1px ${colors.lightGrey} solid;
  background-color: white;
  box-shadow: 0px 7px 5px -5px rgba(0, 0, 0, 0.07);
`;

export const Tabs = ({ value, options, onChange }) => {
  return (
    <TabsContainer>
      {options.map(({ name, text, to, Icon }, i) => (
        <NavTab Icon={Icon} key={i} to={to} text={text} name={name} active={i === value} />
      ))}
    </TabsContainer>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

class TabRouterNav extends React.Component {
  formatRoute = route => {
    const { match } = this.props;
    const matched = matchPath(route, match);
    if (matched !== null) {
      const paramMap = matched.params;
      Object.keys(paramMap).forEach(k => {
        const v = paramMap[k];
        route = route.replace(v, match.params[k]);
      });
    }
    return route;
  };
  activeTab = () => {
    const { location } = this.props;
    const tabs = this.getTabProps();
    for (let i = 0; i < tabs.length; i++) {
      const route = this.formatRoute(tabs[i].to);
      if (tabs[i].exact) {
        if (location.pathname === route) {
          return i;
        }
      } else if (location.pathname.startsWith(route)) {
        return i;
      }
    }
  };
  getTabProps = () => React.Children.map(this.props.children, child => child.props);
  getTabs = () => {
    const tabProps = this.getTabProps();
    return tabProps.map(({ to, ...rest }) => ({
      to: this.formatRoute(to),
      ...rest
    }));
  };
  render() {
    return (
      <Container>
        <Tabs options={this.getTabs()} value={this.activeTab()} />
        <Switch>
          {this.getTabProps().map(({ to, component, ...rest }) => (
            <Route key={to} path={to} component={component} {...rest} />
          ))}
        </Switch>
      </Container>
    );
  }
}

export const TabRouter = withRouter(TabRouterNav);

export const Tab = ({ to, component, name, Icon }) => null;
