import React, { useState } from 'react';
import styled from 'styled-components/macro';
import FaTrash from 'react-icons/lib/fa/trash';
import FaPlus from 'react-icons/lib/fa/plus';
import { Intent } from '@blueprintjs/core';
import { colors, Body, CaptionMedium } from 'shared_components';

import { showToast } from 'services/toaster';

import { Pane, BottomRowButtons } from '../common';
import ProductsTable from './ProductsTable';

const Add = styled.div`
  width: 25px;
  height: 25px;
  background: white;
  border: 1px ${colors.grey} solid;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
`;

const Delete = styled(FaTrash)`
  cursor: pointer;
`;

const addAction =
  selectProduct =>
  ({ product }) =>
    (
      <Add onClick={() => selectProduct(product)}>
        <FaPlus color="grey" />
      </Add>
    );

const removeAction =
  (removeProduct, gender) =>
  ({ product }) =>
    <Delete onClick={() => removeProduct(product, gender)} color={colors.red} />;

const PaneContainer = styled.div`
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex: 1;
`;

const StyledPane = styled(Pane)`
  margin: 0;
  max-width: none;
  width: auto;
  flex: 1;
  margin-left: ${props => (props.isRight ? '1rem' : 0)};
`;

const RightTableContainer = styled.div`
  max-height: 50%;
  overflow: auto;
`;

const LeftPane = ({ products, selectProduct }) => (
  <StyledPane title="AVAILABLE_CLOTHES">
    <ProductsTable products={products} Action={addAction(selectProduct)} />
  </StyledPane>
);

const RightPane = ({ maleProducts, femaleProducts, removeProduct }) => (
  <StyledPane title="SELECTED_CLOTHES" isRight>
    <RightTableContainer style={{ marginBottom: '2rem' }}>
      <CaptionMedium name="FEMALE_CLOTHES" />
      <ProductsTable
        shrinkable
        products={femaleProducts}
        Action={removeAction(removeProduct, 'female')}
      />
    </RightTableContainer>
    <RightTableContainer>
      <CaptionMedium name="MALE_CLOTHES" />
      <ProductsTable
        shrinkable
        products={maleProducts}
        Action={removeAction(removeProduct, 'male')}
      />
    </RightTableContainer>
  </StyledPane>
);

const ProductsPane = ({ products, initValues, onSubmit }) => {
  const [maleProducts, setMaleProducts] = useState(initValues.maleProducts);
  const [femaleProducts, setFemaleProducts] = useState(initValues.femaleProducts);

  const maleProductIds = new Set(maleProducts.map(p => p.id));
  const femaleProductIds = new Set(femaleProducts.map(p => p.id));

  const selectProduct = product => {
    const gender = product.gender_name;
    if (gender === 'male' || gender === 'unisex') {
      if (!maleProductIds.has(product.id)) {
        setMaleProducts(prev => [...prev, product]);
      }
    }
    if (gender === 'female' || gender === 'unisex') {
      if (!femaleProductIds.has(product.id)) {
        setFemaleProducts(prev => [...prev, product]);
      }
    }
  };

  const removeProduct = (product, gender) => {
    if (gender === 'male') {
      setMaleProducts(prev => prev.filter(p => p.id !== product.id));
    }
    if (gender === 'female') {
      setFemaleProducts(prev => prev.filter(p => p.id !== product.id));
    }
  };

  const submit = () => {
    if (maleProducts.length + femaleProducts.length === 0) {
      showToast({
        message: <Body name="NO_CLOTHES_SELECTED" />,
        intent: Intent.WARNING
      });
    } else {
      onSubmit({ maleProducts, femaleProducts });
    }
  };

  const remainingProducts = products.filter(
    p => !maleProductIds.has(p.id) || !femaleProductIds.has(p.id)
  );
  return (
    <>
      <PaneContainer>
        <LeftPane products={remainingProducts} selectProduct={selectProduct} />
        <RightPane
          maleProducts={maleProducts}
          femaleProducts={femaleProducts}
          removeProduct={removeProduct}
        />
      </PaneContainer>
      <BottomRowButtons fullWidth submit={submit} />
    </>
  );
};

export default ProductsPane;
