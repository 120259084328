import FR from './FR.json';
import EN from './EN.json';
import NL from './NL.json';

// ⚠️  Careful when adding new language to Sizerpro !
// Check the documentation here before doing anything: https://www.notion.so/fitle/Adding-a-new-language-on-Sizerpro-eceb2435b9824f65bde04bd0b18ff019
const i18n = { FR, EN, NL };

export const LANGUAGES = Object.keys(i18n);

export default i18n;
