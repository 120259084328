import React from 'react';
import styled from 'styled-components/macro';
import { Title } from 'shared_components';

import { Card } from 'library/layout';
import logo from 'assets/images/sizerpro-logo-white.png';
import { colors } from 'shared_components';
import LanguageSelector from 'library/LanguageSelector';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.deepBlue};
`;

const Logo = styled.img`
  width: 300px;
  max-width: 300px;
  margin-bottom: 30px;
`;

const TitleContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
`;

const AuthContainer = ({ title, children }) => {
  return (
    <Container>
      <Logo src={logo} alt="sizerpro-logo" />
      <Card style={{ position: 'relative' }}>
        <Row>
          <LanguageSelector />
        </Row>
        <TitleContainer>
          <Title name={title} />
        </TitleContainer>
        {children}
      </Card>
    </Container>
  );
};

export default AuthContainer;
