import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { localizedString, capitalize } from '../services/i18n/translations';
import { SLIDE_VERTICAL_DURATION_MS, SLIDE_DOWN_TOTAL_MS } from './Animations';
import { Wrapper, PreviousStep, ListTitle, ContainerListItem, ListItem } from './common';

const CategoriesList = ({
  categories,
  dispatch,
  selectedBrand,
  selectedReferences,
  referenceBeingEdited
}) => (
  <>
    <Wrapper>
      <CSSTransition
        classNames="reference-slide-up"
        timeout={SLIDE_VERTICAL_DURATION_MS}
        in={true}
        appear={true}
        unmountOnExit={true}
      >
        <PreviousStep
          number="1"
          text={selectedBrand.name}
          onEdit={() => dispatch({ type: 'EDIT_BRAND' })}
          ariaLabel="edit brand choice"
          hideEdit={Boolean(referenceBeingEdited)}
        />
      </CSSTransition>
      <CSSTransition
        classNames="reference-slide-down"
        timeout={SLIDE_DOWN_TOTAL_MS}
        in={true}
        appear={true}
        unmountOnExit={true}
      >
        <div>
          <ListTitle number="2" text="CHOOSE_CATEGORY" />
          <ContainerListItem>
            {categories.map(({ slug, translation_key }) => (
              <ListItem
                key={slug}
                text={capitalize(localizedString(translation_key))}
                onClick={() =>
                  dispatch({ type: 'SELECT_CATEGORY', selectedCategory: { slug, translation_key } })
                }
                disabled={selectedReferences.some(
                  r => r.name === selectedBrand.name && r.category.slug === slug
                )}
              />
            ))}
          </ContainerListItem>
        </div>
      </CSSTransition>
    </Wrapper>
  </>
);

export default CategoriesList;
