import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ArrowDown from 'react-icons/lib/md/arrow-drop-down';
import ArrowUp from 'react-icons/lib/md/arrow-drop-up';

import Flag from './Flag';
import { colors } from 'shared_components/styles';

const Wrapper = styled.div`
  position: relative;
  user-select: none;
`;

const Select = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Trigger = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  cursor: pointer;
`;

const Options = styled.div`
  border: none;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 10%) 0px 5px 10px 0px;
  display: block;
  background: ${colors.white};
`;

const OptionsWrapper = styled.div`
  padding: 0 1rem;
  position: absolute;
  top: 100%;
  width: 3.75rem;
  left: -0.875rem;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 99;
  max-height: 115px;
  overflow: auto;

  ${p =>
    p.isOpen &&
    `
    opacity: 1;
    visibility: visible;
    pointer-events: all;
     `}
`;

const Option = styled.span`
  position: relative;
  display: block;
  text-align: center;
  padding: 0.5rem 0.25rem;
  cursor: pointer;
  transition: all 0.5s;

  :hover {
    cursor: pointer;
    background-color: ${colors.lightBlueGrey};
  }

  ${p => p.selected && `background-color: ${colors.lightBlueGrey}`}
`;

const Arrow = ({ isOpen, renderArrow }) => {
  if (renderArrow) {
    const Icon = isOpen ? ArrowUp : ArrowDown;
    // pointer-events to make select.current.contains line 100 work with the svg
    // https://stackoverflow.com/questions/57664614/why-is-svg-not-identified-inside-a-node-using-contain-method-using-javascript
    return <Icon style={{ marginLeft: '0.25rem', pointerEvents: 'none' }} color={colors.coal} />;
  } else {
    return null;
  }
};

const FlagSelector = ({ selectFlag, value, options, renderArrow = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const select = useRef(null);

  const selectOption = option => {
    setIsOpen(false);
    selectFlag(option);
  };

  const toggleIsOpen = () => setIsOpen(prev => !prev);

  useEffect(() => {
    const closeSelect = e => {
      // e.preventDefault();
      if (select.current && !select.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    window.addEventListener('click', closeSelect);
    return () => window.removeEventListener('click', closeSelect);
  }, []);

  return (
    <Wrapper>
      <Select ref={select}>
        <Trigger onClick={toggleIsOpen}>
          <Flag value={value} />
          <Arrow isOpen={isOpen} renderArrow={renderArrow} />
        </Trigger>
        <OptionsWrapper isOpen={isOpen}>
          <Options>
            {options.map(o => (
              <Option key={o.value} onClick={() => selectOption(o)} selected={o.value === value}>
                <Flag value={o.value} />
              </Option>
            ))}
          </Options>
        </OptionsWrapper>
      </Select>
    </Wrapper>
  );
};

export default FlagSelector;
