import React from 'react';
import styled from 'styled-components';
import { SvgLoader } from 'react-svgmt';

import { getComfortTextSvgElements } from '../proxys';
import { Title, Caption, CaptionMedium } from '../../text';
import ProductBadge from '../../library/ProductBadge';
import { media } from 'shared_components/styles';

const SVG_FOLDER_PATH = `${process.env.REACT_APP_STATIC_URL}/plugin/data/shapes/unique-size-v2/`;

const ComfortContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 1.25em;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
`;

const TitleContainer = styled.div`
  margin-bottom: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
`;

const ComfortRendererContainer = styled.div`
  align-self: center;
  margin-right: 1em;
`;

const Shape = styled(SvgLoader)`
  overflow: visible;
  width: 250px;
  height: 280px;

  ${media.bigScreen`
    width: 290px;
    height: 300px;
  `}
`;

const OneSize = ({ size, clothType, gender, language, photoUrl, sizingChartHasOneSize }) => {
  const svgElements = getComfortTextSvgElements(size.comfort, language);
  return (
    <ComfortContainer className="comfort-clothes">
      <TitleContainer>
        {sizingChartHasOneSize ? (
          <>
            <Caption
              style={{ marginBottom: '0.5em' }}
              className="comfort-clothes-title"
              color="coal"
              name="PRODUCT_UNIQUE_SIZE"
            />
            <CaptionMedium
              className="comfort-clothes-title"
              color="coal"
              name="ON_YOU_THIS_CLOTH"
            />
          </>
        ) : (
          // This is the weird case when we recommend only one size or for Sizerpro
          <Title
            className="comfort-clothes-title"
            color="coal"
            name="SIZE_COMFORT"
            values={{ size: size.name }}
          />
        )}
      </TitleContainer>
      <ComfortRendererContainer>
        <Shape path={`${SVG_FOLDER_PATH}${clothType}-${gender}.svg`}>{svgElements}</Shape>
      </ComfortRendererContainer>
      <ProductBadge photoUrl={photoUrl} isOnBottomLeft={true} />
    </ComfortContainer>
  );
};

export default OneSize;
