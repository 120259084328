import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Intent } from '@blueprintjs/core';
import { Body, Caption } from 'shared_components';

import api from 'api';
import Async from 'library/Async';
import Callout from 'library/Callout';
import { showToast } from 'services/toaster';
import { ColoredButton } from 'library/buttons';
import { OptionsRow, Content, Card } from 'library/layout';
import { AddPerson, AddGroup, Send } from 'library/icons';
import { useSelectManyTableRows } from 'library/tables';

import CampaignExport from 'screens/campaigns/CampaignExport';
import AddEmployeeDialog from './AddEmployeeDialog';
import AddManyEmployeesDialog from './AddManyEmployeesDialog';
import CopyLinkDialog from './CopyLinkDialog';
import EditPositionDialog from './EditPositionDialog';
import GiveAccessDialog from './GiveAccessDialog';
import CampaignEmployeeListing from './CampaignEmployeeListing';
import RemoveEmployeeDialog from './RemoveEmployeeDialog';

const LinkContainer = styled.div`
  margin-top: 10px;
  text-align: center;
`;

const NoPositions = ({ campaignId }) => (
  <Callout intent="primary" style={{ maxWidth: '30rem', margin: 'auto' }}>
    <Body name="CAMPAIGN_EMPLOYEE_MANAGEMENT_NEEDS_POSITIONS" />
    <LinkContainer>
      <Link to={`/campaigns/${campaignId}/product_sets`}>
        <Body name="CREATE_POSITION" color="mainBlue" />
      </Link>
    </LinkContainer>
  </Callout>
);

const OperationRoot = ({ language, history, match }) => {
  const campaignId = match.params.campaign_id;

  const asyncComponent = useRef(null);
  const refresh = () => asyncComponent.current && asyncComponent.current.refresh();

  const [loading, setLoading] = useState(false);
  const [campaignEmployees, setCampaignEmployees] = useState([]);
  const { selected, setSelected, checkboxesColumn } = useSelectManyTableRows(campaignEmployees);

  const fetchEmployeesAndPosition = async () => {
    const [employees, positions] = await Promise.all([
      api.campaigns.listEmployees({ campaign_id: campaignId }),
      api.positions.list({ campaign_id: campaignId })
    ]);
    setCampaignEmployees(employees.sort((ceA, ceB) => ceB.id - ceA.id));
    return positions;
  };

  const toggleCampaignEmployeeAbsence = async campaignEmployee => {
    try {
      setLoading(true);
      await api.campaignEmployees.setAbsence({
        campaign_employee_id: campaignEmployee.id,
        absent: !campaignEmployee.absent
      });
      const employees = [...campaignEmployees];
      const index = employees.findIndex(e => e.id === campaignEmployee.id);
      employees[index] = {
        ...campaignEmployee,
        absent: !campaignEmployee.absent
      };
      setCampaignEmployees(employees);
    } catch (e) {
      setLoading(false);
      showToast({
        message: <Body name="EMPLOYEE_UPDATE_FAILURE" color="white" />,
        intent: Intent.DANGER
      });
    } finally {
      setLoading(false);
    }
  };

  // REMOVE EMPLOYEE LOGIC ////////////////////////////////////////////////////
  const [pendingRemoveFromCampaign, setPendingRemoveFromCampaign] = useState(null);

  const removeCampaignEmployee = async andDelete => {
    try {
      setLoading(true);
      if (andDelete) {
        await api.employees.delete({
          employee_id: pendingRemoveFromCampaign.employee_id
        });
      } else {
        await api.campaignEmployees.delete({
          campaign_employee_id: pendingRemoveFromCampaign.id
        });
      }
      setCampaignEmployees(campaignEmployees.filter(c => c.id !== pendingRemoveFromCampaign.id));
      setSelected(selected.filter(s => s.id !== pendingRemoveFromCampaign.id));
    } catch (e) {
      setLoading(false);
      showToast({
        message: <Body name="EMPLOYEE_DELETION_FAILURE" color="white" />,
        intent: Intent.DANGER
      });
    } finally {
      setLoading(false);
      setPendingRemoveFromCampaign(null);
    }
  };

  // EXPORT LOGIC /////////////////////////////////////////////////////////////
  let showExportWarning = false;
  for (let i = 0; i < campaignEmployees.length; i++) {
    if (!campaignEmployees[i].has_results) {
      showExportWarning = true;
    }
  }
  const exportData = async p => {
    await api.campaigns.export({
      campaign_id: campaignId,
      language: language,
      ...p
    });
  };

  // GIVE ACCESS TO EMPLOYEES LOGIC ///////////////////////////////////////////
  const [pendingAccessGiven, setPendingAccessGiven] = useState([]);

  const openGiveAccessDialog = employees => {
    if (employees.every(e => !e.email)) {
      const text = employees.length === 1 ? 'EMPLOYEE_MISSING_MAIL' : 'ALL_EMPLOYEES_MISSING_MAIL';
      showToast({
        message: <Body name={text} color="white" />,
        intent: Intent.DANGER
      });
    } else {
      setPendingAccessGiven(employees);
    }
  };
  const closeGiveAccessDialog = () => setPendingAccessGiven([]);

  const giveAccess = async mailLanguage => {
    try {
      await api.campaigns.mailEmployees({
        campaign_id: campaignId,
        campaign_employee_ids: pendingAccessGiven.filter(e => e.email).map(e => e.id),
        language: mailLanguage
      });
      closeGiveAccessDialog();
      showToast({
        message: <Body name="ACCESS_GRANTED" />,
        intent: Intent.SUCCESS
      });
    } catch (e) {
      showToast({
        message: <Body name="ERROR" color="white" />,
        intent: Intent.DANGER
      });
    }
  };

  // DIALOGS STATES ///////////////////////////////////////////////////////////
  const [pendingEdit, setPendingEdit] = useState(null);
  const [pendingCopyLink, setPendingCopyLink] = useState(null);
  const [campaignEmployeeCreateOpen, setCampaignEmployeeCreateOpen] = useState(false);
  const [campaignEmployeeMassAssociation, setCampaignEmployeeMassAssociation] = useState(false);

  return (
    <Async fetchProps={fetchEmployeesAndPosition} ref={asyncComponent}>
      {positions =>
        positions.length === 0 ? (
          <NoPositions campaignId={campaignId} />
        ) : (
          <Content>
            <OptionsRow>
              <CampaignExport
                campaign_id={campaignId}
                showWarningDialog={showExportWarning}
                export={exportData}
              />
              <ColoredButton onClick={() => setCampaignEmployeeCreateOpen(true)}>
                <AddPerson style={{ marginRight: '8px' }} color="white" />
                <Caption name="ADD_CAMPAIGN_EMPLOYEE" color="white" />
              </ColoredButton>
              <ColoredButton onClick={() => setCampaignEmployeeMassAssociation(true)}>
                <AddGroup style={{ marginRight: '8px' }} color="white" />
                <Caption name="UPDATE_CAMPAIGN_EMPLOYEES" color="white" />
              </ColoredButton>
              <ColoredButton
                onClick={() => openGiveAccessDialog(selected)}
                disabled={selected.length === 0}
              >
                <Send style={{ marginRight: '8px' }} color="white" />
                <Caption name="GIVE_ACCESS" color="white" />
              </ColoredButton>
            </OptionsRow>
            {/* 
              EMPLOYEES TABLE
            */}
            <Card>
              <CampaignEmployeeListing
                data={campaignEmployees}
                loading={loading}
                checkboxesColumn={checkboxesColumn}
                onRemoveEmployee={employee => setPendingRemoveFromCampaign(employee)}
                onEditEmployee={campaignEmployee => setPendingEdit(campaignEmployee)}
                onAttributeClothes={campaignEmployee =>
                  history.push({
                    pathname: `${new URL(campaignEmployee.activation_link).pathname}/physical_info`,
                    state: { return_pathname: document.location.pathname }
                  })
                }
                giveAccessToEmployee={campaignEmployee => openGiveAccessDialog([campaignEmployee])}
                copyEmployeeAccessLink={campaignEmployee =>
                  setPendingCopyLink(campaignEmployee.activation_link)
                }
                onToggleAbsence={toggleCampaignEmployeeAbsence}
              />
            </Card>
            {/* 
              ALL THE DIALOGS 
            */}
            <AddEmployeeDialog
              campaignId={campaignId}
              positions={positions}
              isOpen={campaignEmployeeCreateOpen}
              onClose={hasNewEmployee => {
                setCampaignEmployeeCreateOpen(false);
                if (hasNewEmployee) refresh();
              }}
            />
            <AddManyEmployeesDialog
              campaignId={campaignId}
              positions={positions}
              isOpen={campaignEmployeeMassAssociation}
              onClose={hasNewEmployees => {
                setCampaignEmployeeMassAssociation(false);
                if (hasNewEmployees) refresh();
              }}
            />
            <RemoveEmployeeDialog
              isOpen={Boolean(pendingRemoveFromCampaign)}
              campaignId={campaignId}
              employee={pendingRemoveFromCampaign}
              onClose={() => setPendingRemoveFromCampaign(null)}
              onSubmit={removeCampaignEmployee}
            />
            <EditPositionDialog
              isOpen={Boolean(pendingEdit)}
              onClose={modified => {
                setPendingEdit(null);
                if (modified) refresh();
              }}
              employee={pendingEdit}
              campaign_id={campaignId}
            />
            <GiveAccessDialog
              isOpen={pendingAccessGiven.length > 0}
              displayLanguage={language}
              pendingAccessGiven={pendingAccessGiven}
              onClose={closeGiveAccessDialog}
              onSubmit={giveAccess}
            />
            <CopyLinkDialog accessLink={pendingCopyLink} onClose={() => setPendingCopyLink(null)} />
          </Content>
        )
      }
    </Async>
  );
};

export default withRouter(
  connect(state => ({
    language: state.settings.language
  }))(OperationRoot)
);
