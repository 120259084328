/* Contains data the user can configure himself to configure the page  */

const DEFAULT_STATE = {
  token: null,
  clientName: null,
  isAdmin: false,
  firstName: null,
  lastName: null,
  email: null,
  id: null
};

export default function reducer(state = DEFAULT_STATE, action) {
  const { type, ...rest } = action;
  switch (type) {
    case 'LOGIN':
      return { ...state, ...rest };
    case 'LOGOUT':
      return DEFAULT_STATE;
    default:
      return state;
  }
}
