import React from 'react';
import styled from 'styled-components/macro';
import { media, colors, Caption } from 'shared_components';

import { DarkPage, CompanyName, CardTitle, SizerproLogo } from './common/styles';
import LanguageSelector from 'library/LanguageSelector';
import Callout from 'library/Callout';
import { ButtonRow } from 'library/buttons';
import LinkButton from 'library/buttons/LinkButton';

const Text = styled(Caption)`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  width: 100%;
  ${media.phoneLarge`
    font-size: 0.8rem;
  `}
`;

// width 100% for IE 11
const PrivacyCallout = styled(Callout)`
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
`;

const CardContainer = styled.div`
  background-color: ${colors.white};
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: min(95%, 30rem);
  margin: 1rem 0.625em;
  border-radius: 0.2em;
  line-height: 1.5;

  ${media.phone`
    padding: 1rem;
    width: 95%;
  `}
  ${media.phoneVerySmall`
    padding: 1vh 1rem;
    width: 95%;
    line-height: 1.2;
  `}
  ${media.phoneSmall`
    padding: 1vh 1rem;
    width: 95%;
    line-height: 1.2;
  `}
  ${media.phoneLarge`
    padding: 1rem;
    width: 95%;
    height: 500px;
  `}
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
`
const SizedImg = styled.img`
  margin: 0.5rem;
  left: 0;
  max-width: 41%;
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
`;
class Start extends React.Component {
  render() {
    const { details } = this.props;
    const { company_name, company_logo, first_name, last_name } = details;
    return (
      <DarkPage>
        <CompanyName text={company_name} />
        <CardContainer>
          <LanguageSelector />
          <CardTitle name="HELLO" values={{ first_name, last_name }} />
          <Text name="WELCOME" />
          <div>
            <Text name="WELCOME_TEXT" style={{ fontWeight: 700 }} />
            <Text name="WELCOME_TEXT_NEXT" />
          </div>
          <PrivacyCallout intent="primary">
            <Caption color={colors.darkGrey} name="PHYSICAL_INFO_PRIVACY" />
          </PrivacyCallout>
          <ButtonRow>
            <LinkButton
              style={{ marginRight: 0 }}
              to={`/clothes_attribution/${this.props.match.params.campaign_employee_token}/explanation`}
            >
              <Caption name="BEGIN" color="white" />
            </LinkButton>
          </ButtonRow>
          {company_logo && (
            <FlexContainer>
              <SizedImg src={company_logo} alt="client-logo" />
            </FlexContainer>
          )}
        </CardContainer>
        <SizerproLogo />
      </DarkPage>
    );
  }
}

export default Start;
