import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'shared_components/styles';
import MdClose from 'react-icons/lib/md/close';
import { isIE } from '../services/device';

export const Position = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right'
};

export const TooltipStyle = styled.div`
  font-size: 1rem;
  opacity: ${props => (props.opened ? 1 : 0)};
  visibility: ${props => (props.opened ? 'visible' : 'hidden')};
  position: absolute;
  display: block;

  background-color: ${colors.white};
  border: solid 1px ${props => props.borderColor || colors.lightBlueGrey};
  padding: 1.25rem 1rem;
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  border-radius: 5px;
  box-shadow: 0px 5px 10px ${colors.lightBlueGrey};
  transform: ${props => (props.opened ? 'scale(1)' : 'scale(0)')};
  transition: transform 0.3s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  :before {
    position: absolute;
    z-index: 1;
    content: '';
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent ${colors.white} transparent;
    transition-duration: 0.3s;
    transition-property: transform;
  }
  :after {
    position: absolute;
    z-index: 0;
    content: '';
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent ${props => props.borderColor || colors.lightBlueGrey}
      transparent;
    transition-duration: 0.3s;
    transition-property: transform;
  }
  // for IE not being able to center absolute div if relative div's width is smaller than abolute
  // fix: using left:50% and translateX-50% compatible from IE 8
  // https://www.inflectra.com/support/knowledgebase/kb234.aspx
  ${() => isIE() && 'left: 50%;transform: translate(-50%, 0);'}

  ${p => {
    switch (p.position) {
      case Position.TOP:
        return css`
          bottom: calc(100% + 15px);
          :before {
            top: calc(100% - 1px);
            left: calc(50% - 10px);
            transform: rotate(180deg);
          }
          :after {
            top: 100%;
            left: calc(50% - 10px);
            transform: rotate(180deg);
          }
        `;
      case Position.BOTTOM:
        return css`
          top: calc(100% + 15px);
          :before {
            left: calc(50% - 10px);
            bottom: calc(100% - 1px);
          }
          :after {
            left: calc(50% - 10px);
            bottom: 100%;
          }
        `;
      case Position.LEFT:
        return css`
          right: calc(100% + 10px);
          :before {
            left: calc(100% - 6px);
            transform: rotate(90deg);
            top: 45%;
          }
          :after {
            left: calc(100% - 5px);
            transform: rotate(90deg);
            top: 45%;
          }
        `;
      case Position.RIGHT:
        return css`
          left: calc(100% + 10px);
          :before {
            right: calc(100% - 6px);
            transform: rotate(270deg);
            top: 45%;
          }
          :after {
            right: calc(100% - 5px);
            transform: rotate(270deg);
            top: 45%;
          }
        `;
      default:
        break;
    }
  }};
`;

const CloseIcon = styled(MdClose)`
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  color: ${colors.grey};

  :hover {
    color: ${colors.black};
  }
`;

const Tooltip = ({
  close,
  opened,
  position = Position.TOP,
  children,
  globalEventOff = true,
  notCloseIcon,
  ...rest
}) => {
  // Click outside and inside the Tooltip will close the Tooltip
  useEffect(() => {
    if (opened && globalEventOff) {
      // When the tooltip is hidden, the component is not unmount. Re-opening it will add another EventListener.
      // We need to remove the old one if it exists.
      window.removeEventListener('click', close);
      window.addEventListener('click', close);
    }
    return () => window.removeEventListener('click', close);
  }, [close, opened, globalEventOff]);

  return (
    <TooltipStyle opened={opened} position={position} {...rest}>
      {notCloseIcon ? '' : <CloseIcon onClick={close} />}
      {children}
    </TooltipStyle>
  );
};

export default Tooltip;

// Custom hook to handle the showTooltip state and send the event to Mixpanel on tooltip open.
export const useTooltip = sendTooltipDisplayedEvent => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (sendTooltipDisplayedEvent && showTooltip) sendTooltipDisplayedEvent();
  }, [showTooltip, sendTooltipDisplayedEvent]);

  return { showTooltip, setShowTooltip };
};
