import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { SLIDE_VERTICAL_DURATION_MS } from './Animations';
import MdCreate from 'react-icons/lib/md/create';
import { colors, media } from 'shared_components/styles';
import { Caption } from '../text';

const HEADER_HEIGHT = '15%';
const HEADER_MARGIN_BOTTOM = '1em';
export const ITEMS_HEIGHT = '2.5rem';

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  padding: 0.25em;
  overflow: auto;
`;

export const ContainerListItem = styled.ul`
  list-style: reset;
`;

export const ItemContainer = styled.button`
  height: ${ITEMS_HEIGHT};
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid ${colors.lightBlueGrey};
  border-radius: 5px;
  padding: 0.5em;
  margin-bottom: 0.25em;
  cursor: pointer;

  :disabled {
    background-color: ${colors.lightGrey};
    cursor: not-allowed;
  }

  :hover {
    background-color: ${props => (props.disabled ? colors.lightGrey : colors.lighterGrey)};
  }

  :focus {
    outline: none;
    background-color: ${colors.lighterGrey};
  }

  /* To reset user agent style sheet (otherwise button background is grey on Safari ) */
  background-color: ${colors.white};
`;

export const LogoContainer = styled.div`
  text-align: center;
  width: 15%;
  margin-right: 0.5em;
`;

export const Logo = styled.img`
  max-width: 2em;
  max-height: 1em;
`;

export const ListItem = ({ text, name, imageUrl, onClick, disabled = false }) => (
  <li>
    <ItemContainer onClick={onClick} type="button" ariaLabel={'select ' + text} disabled={disabled}>
      <LogoContainer>{imageUrl && <Logo src={imageUrl} alt={text} />}</LogoContainer>
      <Caption text={text} name={name} color="coal" />
    </ItemContainer>
  </li>
);

export const Number = styled(Caption)`
  font-size: 0.55em;
  background: ${colors.coal};
  border-radius: 0.8em;
  color: ${colors.white};
  display: inline-block;
  font-weight: 600;
  line-height: 1.6em;
  text-align: center;
  width: 1.6em;
  margin-right: 1rem;
  margin-left: 0.75rem;
`;

const TitleContainer = styled.div`
  height: ${ITEMS_HEIGHT};
  width: 100%;
  display: inline-block;
  padding: 0.5em;
`;

export const ListTitle = ({ number, text }) => (
  <TitleContainer>
    {number && <Number text={number} />}
    <Caption name={text} color="coal" />
  </TitleContainer>
);

const PreviousStepContainer = styled.div`
  height: ${ITEMS_HEIGHT};
  display: flex;
  align-items: center;
  padding: 0.5em;
  border: 1px solid ${colors.grey};
  border-radius: 5px;
  margin-bottom: 0.5em;
  /* To reset user agent style sheet (otherwise button background is grey on Safari ) */
  background-color: ${colors.white};
`;

export const EditButton = styled.button`
  cursor: pointer;
  color: ${colors.grey};
  background-color: ${colors.white};
  :hover {
    color: ${props => props.theme.primaryColor};
  }
`;

export const PreviousStep = ({ number, text, name, onEdit, ariaLabel, hideEdit }) => (
  <PreviousStepContainer>
    <Number text={number} />
    <Caption text={text} name={name} color="coal" style={{ flex: 1 }} />
    {!hideEdit && (
      <EditButton type="button" aria-label={ariaLabel} onClick={onEdit}>
        <MdCreate />
      </EditButton>
    )}
  </PreviousStepContainer>
);

const HeaderContainer = styled.div`
  height: ${HEADER_HEIGHT};
  display: flex;
  flex-direction: column;
  margin-bottom: ${HEADER_MARGIN_BOTTOM};
  ${media.phoneVerySmall`
    font-size: 0.9rem;
    margin-bottom: 1rem;
    width:60%;
    margin-right: auto;
    margin-left: auto;
  `};
  ${media.phoneSmall`
    font-size: 0.9rem;
    margin-bottom: 1rem;
    width:60%;
    margin-right: auto;
    margin-left: auto;
  `};
  ${media.phoneMedium`
    width:60%;
    margin-right: auto;
    margin-left: auto;
  `};
  ${media.phoneLarge`
    width:60%;
    margin-right: auto;
    margin-left: auto;
  `};
`;

const MainTitle = styled(Caption)`
  color: ${colors.coal};
  text-align: center;
  line-height: 1.5em;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
`;

const Separator = styled.div`
  height: 0px;
  border-bottom: solid 1px ${colors.lightGrey};
  margin: 0 -1.25em;
  ${media.bigScreen`
    margin: 0 -3.125em;
  `};
`;

export const Header = ({ text, values = {}, showSeparator = true }) => (
  <HeaderContainer className="references-title">
    <CSSTransition
      classNames="reference-title-slide-up"
      timeout={SLIDE_VERTICAL_DURATION_MS}
      in={true}
      appear={true}
      unmountOnExit={true}
    >
      <MainTitle forwardedAs="h1" name={text} values={values} />
    </CSSTransition>
    {showSeparator && <Separator />}
  </HeaderContainer>
);
