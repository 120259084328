/* Contains data the user can configure himself to configure the page  */
import { selectLanguage } from 'shared_components/services/i18n/translations';

const DEFAULT_STATE = {
  language: 'FR'
};

export default function reducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (!action.payload) {
        selectLanguage(state.language);
        return state;
      }
      selectLanguage(action.payload.settings.language);
      return {
        ...state,
        ...action.payload.settings
      };
    case 'CHANGE_LANGUAGE':
      selectLanguage(action.language);
      return { ...state, language: action.language };
    case 'LOGOUT':
      return DEFAULT_STATE;
    default:
      return state;
  }
}
