import React, { useEffect } from 'react';


import { isNullOrUndefined } from 'services/utils';

import { References } from 'shared_components';



import {
  Container,
  ReferenceContainer,
  ReferenceContent
} from './styles';

import { useSizerproReferences } from './fetch_references';

const ReferenceBrandsForm= ({
    details,
    match,
    theme,
    selectedReferences,
    selectReferences,
    addReference,
    updateReference,
    deleteReference,
    language,
    setIsloading,
    setIsError,
    skipSection,
    isShoe
    }) => {
  const token = match.params.campaign_employee_token;
  const campaign_employee_id = details.id;
  // selectedReferences is null while we wait for the saved references to be fetched
  const { references, mostUsed, savedSelectedReferences, isLoading, isError } =
    useSizerproReferences(details.gender_name, details.client_id, campaign_employee_id, token, isShoe);
  useEffect(() => {
    selectReferences(savedSelectedReferences);
    setIsloading(isLoading)
    setIsError(isError)
  }, [savedSelectedReferences, isLoading, isError, selectReferences, setIsloading, setIsError ]);

  return (
      <Container>
        <ReferenceContainer>
          <ReferenceContent>
            <References
              theme={theme}
              availableReferences={references}
              selectedReferences={selectedReferences || []}
              addReference={addReference}
              mostUsedReferences={mostUsed}
              updateReference={updateReference}
              deleteReference={deleteReference}
              language={language}
              isLoading={isLoading || isNullOrUndefined(selectedReferences)} // Saved references have not been fetched and set to selectedReferences yet
              isError={isError}
              skipSection={skipSection}
            />
          </ReferenceContent>
        </ReferenceContainer>
      </Container>
  );
};

export default ReferenceBrandsForm
