import React from 'react';
import { Switch, Route } from 'react-router-dom';

import OperationRoot from './OperationRoot';

const Operation = () => (
  <Switch>
    <Route exact path="/campaigns/:campaign_id/operation" component={OperationRoot} />
  </Switch>
);

export default Operation;
