import React from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router';

import { media, colors, Title, Caption } from 'shared_components';
import { BottomBackButton } from '../common/styles';

const HeaderContainer = styled.div`
  width: 100%;
  margin-bottom: ${props => (props.gender === 'male' ? '1em' : '2em')};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.phoneVerySmall`
    margin-bottom: 0rem
  `}
  ${media.phoneSmall`
    margin-bottom: 0rem
  `}
  ${media.phoneMedium`
    margin-bottom: 0rem
  `}
  ${media.phoneLarge`
    margin-bottom: 1rem
  `}
`;

const HeaderText = styled(Title)`
  text-align: center;
  max-width: 100%;
  ${media.phoneVerySmall`
    font-size: 0.85rem;
    width:65%;
    margin-top: 0.6rem;
  `}
  ${media.phoneSmall`
    font-size: 0.85rem;
    width:65%;
    margin-top: 0.6rem;
  `}
  ${media.phoneMedium`
    font-size: 0.85rem;
    width:65%;
    margin-top: 0.6rem;
  `}
  ${media.phoneLarge`
    font-size: 1rem;
    width:70%;
    margin-top: 0.6rem;
  `}
`;

export const BodyShapeHeader = ({ gender, isFirstStep }) => {
  let headerText;
  if (gender === 'male') {
    headerText = <HeaderText name="STANDARD_TITLE" />;
  } else {
    if (isFirstStep) {
      headerText = <HeaderText name="HOW_IS_WAIST" />;
    } else {
      headerText = <HeaderText name="WAIST_COMPARE_CHEST" />;
    }
  }

  return <HeaderContainer gender={gender}>{headerText}</HeaderContainer>;
};

export const BackButton = withRouter(({ history, isFirstStep, returnFirstChoice }) => {
  if (isFirstStep) {
    return (
      <BottomBackButton onClick={() => history.goBack()}>
        <Caption name="BACK" color={colors.darkGrey} />
      </BottomBackButton>
    );
  } else {
    return (
      <BottomBackButton onClick={returnFirstChoice}>
        <Caption name="BACK" color={colors.darkGrey} />
      </BottomBackButton>
    );
  }
});
