import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import FaPlus from 'react-icons/lib/fa/plus';
import 'react-table/react-table.css';
import { Intent } from '@blueprintjs/core';
import { Body, MainTitle, Caption } from 'shared_components';

import api from 'api';
import Async from 'library/Async';
import { Admin } from 'library/icons';
import { LinkToDoc } from 'library/misc';
import { ColoredButton } from 'library/buttons';
import { Dialog, CancellableDialog } from 'library/dialogs';
import { OptionsRow, Content, Card, SmallTitleSection } from 'library/layout';
import OperatorForm from './OperatorForm';
import { OperatorListing } from './OperatorListing';
import OperatorDetails from './OperatorDetails';
import { showToast } from 'services/toaster';

const Container = styled.div``;

const operatorErrors = error => {
  switch (error.status) {
    case 409:
      showToast({
        message: <Body name="OPERATOR_CONFLICT" />,
        intent: Intent.DANGER
      });
      break;
    default:
      showToast({
        message: <Body name="UNKNOWN_ERROR" />,
        intent: Intent.DANGER
      });
      break;
  }
};

class AdminScreen extends React.Component {
  state = {
    createDialogOpen: false,
    pendingDelete: null,
    pendingEdit: null,
    showingDetails: null
  };
  fetchOperators = () => {
    try {
      return api.operators.list();
    } catch (e) {
      operatorErrors(e);
    }
  };
  toggleCreateModal = () => {
    this.setState({ createDialogOpen: !this.state.createDialogOpen });
  };
  createOperator = async values => {
    try {
      await api.operators.create(values);
      await this.async.refresh();
      this.toggleCreateModal();
    } catch (e) {
      operatorErrors(e);
    }
  };
  deleteOperator = async () => {
    const operator_id = this.state.pendingDelete.id;
    this.setState({ pendingDelete: null }, async () => {
      try {
        await api.operators.delete({ operator_id });
        await this.async.refresh();
      } catch (e) {
        operatorErrors(e);
      }
    });
  };
  editOperator = async operator => {
    this.setState({ pendingEdit: null }, async () => {
      try {
        const { id: operator_id, ...rest } = operator;
        await api.operators.update({ operator_id, ...rest });
        await this.async.refresh();
      } catch (e) {
        operatorErrors(e);
      }
    });
  };

  renderCreateModal = () => {
    return (
      <Dialog isOpen onClose={this.toggleCreateModal}>
        <OperatorForm onSubmit={this.createOperator} />
      </Dialog>
    );
  };

  renderEditModal = () => {
    return (
      <Dialog isOpen onClose={() => this.setState({ pendingEdit: null })}>
        <OperatorForm
          operator={this.state.pendingEdit}
          onSubmit={this.editOperator}
          isSelf={this.props.operatorId === this.state.pendingEdit.id}
        />
      </Dialog>
    );
  };

  renderDeleteModal = () => {
    return (
      <CancellableDialog
        onSubmit={this.deleteOperator}
        onClose={() => this.setState({ pendingDelete: null })}
        confirmationString={this.state.pendingDelete.email}
        danger
      >
        <Body name="CONFIRM_DELETE_OPERATOR" values={this.state.pendingDelete} />
      </CancellableDialog>
    );
  };

  renderDetailsModal = () => {
    return (
      <Dialog isOpen onClose={() => this.setState({ showingDetails: null })}>
        <OperatorDetails {...this.state.showingDetails} />
      </Dialog>
    );
  };

  renderOptionsRow = () => {
    return (
      <OptionsRow style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <LinkToDoc
          textName="MORE_INFO_ADMIN"
          link="https://docs.sizerpro.com/prise-en-main-de-sizerpro/creer-un-compte#ajouter-un-nouvel-operateur"
        />
        <ColoredButton onClick={this.toggleCreateModal}>
          <FaPlus color="white" />
          <Caption name="OPERATOR_CREATION" color="white" />
        </ColoredButton>
      </OptionsRow>
    );
  };

  render = () => {
    return (
      <Container>
        <SmallTitleSection>
          <Admin color="grey" />
          <MainTitle name="ACCESS_RIGHTS" />
        </SmallTitleSection>
        <Async fetchProps={this.fetchOperators} ref={a => (this.async = a)}>
          {operators => (
            <Content>
              {this.renderOptionsRow()}
              <Card>
                <OperatorListing
                  data={operators}
                  onDelete={pendingDelete => this.setState({ pendingDelete })}
                  onEdit={pendingEdit => this.setState({ pendingEdit })}
                  onDetails={showingDetails => this.setState({ showingDetails })}
                />
              </Card>
              {this.state.createDialogOpen && this.renderCreateModal()}
              {this.state.pendingDelete && this.renderDeleteModal()}
              {this.state.pendingEdit && this.renderEditModal()}
              {this.state.showingDetails && this.renderDetailsModal()}
            </Content>
          )}
        </Async>
      </Container>
    );
  };
}

export default connect(state => ({
  operatorId: state.auth.id
}))(AdminScreen);
