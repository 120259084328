import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components/macro';
import { Tooltip, Position } from '@blueprintjs/core';
import { media, colors, Caption, CaptionMedium } from 'shared_components';

import { Loading } from 'library/Async';
import Toggle, { ToggleContainer } from 'library/toggle';

import Comfort from './Comfort';
import UserInfo from './UserInfo';
import { TooltipContent } from './components';
import { userFunnelTheme } from 'styles/themes';

const StyledToggleContainer = styled(ToggleContainer)`
  padding-bottom: 0px;
`;

const TabsContainer = styled.div`
  display: flex;
  box-shadow: 0 3px 4px 0 ${colors.lightBlueGrey};
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2.5rem;
  justify-content: space-between;
  ${media.phone`
    padding-left: inherit;
    padding-right: inherit;
    padding-top: 1.5rem;
  `}
  ${media.phoneLarge`
    padding-top: 1.5rem;
  `}
`;

const Tab = styled.button.attrs(() => ({
  type: 'button'
}))`
  height: 50px;
  padding: 0rem 1rem;
  cursor: pointer;
  text-align: center;
  background-color: inherit;
  color: ${props => (props.active ? colors.mainBlue : colors.grey)};
  margin-left: 2rem;
  ${media.phone`
    flex: 1;
    margin: 0;
    padding: 0;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'none')};;
  `};
  ${media.phoneMedium`
    border-top-right-radius: 11px;
    border-top-left-radius: 11px;
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'none')};;
  `}
  ${media.phoneLarge`
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'none')};;
 
  `}
`;

const WhiteBackground = styled.div`
  background-color: ${colors.white};
  padding: 2rem;
  ${media.phone`
    padding: 2rem 1rem;
  `}
  ${media.phoneLarge`
    padding: 1rem 1rem;
  `}
`;

const LoadingFullHeight = styled(Loading)`
  height: 26.11rem;
`;

const StyledTooltip = styled(Tooltip)`
  margin-right: 2rem;
  && .bp3-popover-content {
    background-color: ${colors.mainBlue};
  }
  && .bp3-popover-arrow-fill {
    fill: ${colors.mainBlue};
  }
  ${media.phone`
    flex: 1;
    margin-left: 0.2rem;
    margin-right: 0rem;
  `};
  ${media.phoneMedium`
    flex: 1;
    margin-left: 0.2rem;
  `}
  ${media.phoneLarge`
    flex: 1;
    margin-left: 0.2rem;
  `}
`;

const PreferredFitContainer = styled.div`
  padding: 2.5rem 3rem 0rem 3rem;
  ${media.phone`
    padding: 1.25rem 0.625rem;
  `};
`;

const FIT_MAPPING = [
  { name: 'tighter', translationKey: 'PREFERRED_FIT_TIGHTER', index: 0 },
  { name: 'standard', translationKey: 'PREFERRED_FIT_STANDARD', index: 1 },
  { name: 'looser', translationKey: 'PREFERRED_FIT_LOOSER', index: 2 }
];

const Recommendation = ({
  recoSizeName,
  chosenSizeName,
  selectSize,
  showTooltip,
  reco,
  language,
  gender,
  clothType,
  enablePreferredFit,
  sizingChartSizes,
  sizingChartInfo,
  userMeasurements
}) => {
  // ACTIVE FIT LOGIC
  const [activeFit, setActiveFit] = useState(FIT_MAPPING[1]);
  const [fakeLoader, setFakeLoader] = useState(false);

  // This logic is only used in RecommendationTooltip a few children below.
  // But it is impossible to "save" the previous recoSizeName there as the component unmounts and remounts on each activeFit change.
  const [recoUnchanged, setRecoUnchanged] = useState(false);

  const onToggleFit = index => {
    setFakeLoader(true);
    const newFit = FIT_MAPPING.find(x => x.index === index);
    setActiveFit(newFit);

    setTimeout(() => {
      const newRecoSizeName = reco[newFit.name][0].name;
      selectSize(newRecoSizeName, true);

      // Toggle recoUnchanged to display specific text in RecommendationTooltip.
      // Here we can just use recoSizeName as it is "saved" in the function on each render.
      if (newRecoSizeName === recoSizeName && index !== 1) {
        setRecoUnchanged(true);
      } else {
        setRecoUnchanged(false);
      }

      setFakeLoader(false);
    }, 500);
  };

  // TOGGLE COMFORT / USER INFO LOGIC
  const [showComfort, setShowComfort] = useState(true);
  const [isTooltipOpen, setIsTooltipOpen] = useState(showTooltip);

  const onClickSizingTab = () => {
    setIsTooltipOpen(false);
    setShowComfort(false);
  };

  const closeTooltip = e => {
    e.stopPropagation();
    setIsTooltipOpen(false);
  };

  return (
    <>
      {clothType === 'top' && enablePreferredFit && (
        <PreferredFitContainer>
          <CaptionMedium name="PREFERRED_FIT_CAPTION" style={{ color: colors.white }} />
          <StyledToggleContainer>
            <Toggle
              activeIndex={activeFit.index}
              onToggle={onToggleFit}
              style={{ marginBottom: 20 }}
              borderColor={colors.mainBlue}
              backgroundColor={colors.yellow}
              shadowColor={colors.red}
            >
              {FIT_MAPPING.map(fit => (
                <Toggle.Item
                  key={fit.index}
                  disabled={reco[fit.name].length === 0}
                  backgroundColor={colors.white}
                >
                  <Caption
                    name={fit.translationKey}
                    color={activeFit.index !== fit.index ? colors.mainBlue : colors.white}
                  />
                </Toggle.Item>
              ))}
            </Toggle>
          </StyledToggleContainer>
        </PreferredFitContainer>
      )}
      <TabsContainer>
        <Tab
          onClick={() => setShowComfort(true)}
          active={showComfort}
          backgroundColor={showComfort ? colors.white : colors.grey}
        >
          <Caption color={showComfort ? colors.mainBlue : colors.darkGrey} name="EXPERIMENT_RECO" />
        </Tab>
        <StyledTooltip
          isOpen={isTooltipOpen}
          content={<TooltipContent onClose={closeTooltip} />}
          style={{ backgroundColor: colors.mainBlue }}
          usePortal={false}
          position={Position.TOP_LEFT}
        >
          <Tab
            onClick={onClickSizingTab}
            active={!showComfort}
            style={{ width: '100%' }}
            backgroundColor={!showComfort ? colors.white : colors.grey}
          >
            <div style={{ outline: 'none' }}>
              <Caption
                color={!showComfort ? colors.mainBlue : colors.darkGrey}
                name="EXPERIMENT_SC"
              />
            </div>
          </Tab>
        </StyledTooltip>
      </TabsContainer>
      <WhiteBackground>
        <ThemeProvider theme={userFunnelTheme}>
          {showComfort ? (
            <>
              {fakeLoader ? (
                <LoadingFullHeight />
              ) : (
                <Comfort
                  recoUnchanged={recoUnchanged}
                  selectSize={selectSize}
                  recoSizeName={recoSizeName}
                  chosenSizeName={chosenSizeName}
                  activeFit={activeFit}
                  language={language}
                  gender={gender}
                  sizes={reco[activeFit.name]}
                  clothType={clothType}
                  sizingChartSizes={sizingChartSizes}
                  showSizingChart={onClickSizingTab}
                />
              )}
            </>
          ) : (
            <UserInfo
              recoSizeName={recoSizeName}
              userMeasurements={userMeasurements}
              sizingChartInfo={sizingChartInfo}
            />
          )}
        </ThemeProvider>
      </WhiteBackground>
    </>
  );
};

export default Recommendation;
