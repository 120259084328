import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import PropTypes from 'prop-types';

import { media } from 'shared_components/styles';
import colors from '../../../styles/colors';
import Checkbox from '../../../library/Checkbox';
import { Caption, CaptionMedium } from '../../../text/index';
import { Container, ShapeCheckbox } from '../../styles';
import { BODY_SHAPES } from '../../../services/utils';
import { firstStepChoicesFemale } from '../first-step/index';

const MainContainer = styled(Container)`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Text = styled(Caption)`
  max-width: 100%;
  text-align: center;
  line-height: 1.5em;
  ${media.phone`
    line-height: 1em;
    font-size: 0.75rem;
  `}
`;
//TODO: translate text to other lang
const CustomTextWrapper = styled.div`
  display: 'inline-block';
  text-align: center;
  width: 75%;
  ${media.phoneMedium`
    width: 75%;
    font-size: 3vh;
  `}
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const FemaleShapeCheckbox = styled(ShapeCheckbox)`
  flex: 1;
  height: 100%;
  position: relative;
  justify-content: flex-end;
`;

const ShapeContainer = styled.div`
  margin-top: 1em;
  padding: 1em;
  border-radius: 3px;
  background-color: ${colors.white};
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: ${props =>
    props.shapeSelected ? '0 10px 13px 0 #EAEFF2' : '0 2px 5px 0 rgba(0,0,0,0.06)'};
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.shapeSelected ? 'space-between' : 'center')};
  align-items: center;
  border: 1px solid ${colors.mainBlue};

  ${media.bigScreen`
    flex: 1;
    svg {
      height: 9.5em;
    }
  `}
  ${media.phone`
    padding: 0em;
    min-height: 35vh;
    span {
      margin-top: 1em;
      margin-bottom: 1em;
    }
    svg {
      height: 25vh;
    }
  `}
  ${media.phoneVerySmall`
    flex: 1;
    svg {
      height: 10em;
    }
  `}

  ${media.phoneSmall`
    flex: 1;
    svg {
      height: 10em;
    }
  `}
`;

const ARROW_HEIGHT = 17;
const Arrow = () => (
  <SvgLoader
    svgXML={`
    <svg
      width="25"
      height="${ARROW_HEIGHT}"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="path-1"
          d="M2.037 5.52l-.792-1.032V3.77h23l-.474.718-.83 1.055-1.048 1.245H3.018z"
        />
      </defs>
      <g id="Symbols-&amp;-Visual-assets" fill="none" fill-rule="evenodd">
        <g id="Arrow" transform="translate(0 -3)">
          <path id="Rectangle" fill-opacity="0" fill="#EAEFF2" d="M0 0h25v25H0z" />
          <path
            id="Triangle"
            stroke="${colors.mainBlue}"
            fill="#FFF"
            fill-rule="nonzero"
            d="M12.498 19.984L1 4.988l23-.004z"
          />
          <mask id="mask-2" fill="#fff"><use xlink:href="#path-1" /></mask>
          <use id="Path-13" fill="#FFF" xlink:href="#path-1" />
        </g>
      </g>
    </svg>
    `}
    viewBox="7 0 13 19"
    style={{
      position: 'absolute',
      bottom: `-${ARROW_HEIGHT + 1}px`, // + 1 so it covers the border of the div below
      zIndex: 3,
      transform: 'rotate(180deg)'
    }}
  />
);

const BodyShapeFemaleSecondStep = ({ theme, userBodyShape, submitBodyShape, firstStepChoice }) => {
  const shapes = BODY_SHAPES.filter(shape => shape.group === firstStepChoice);
  const handleInputChange = event => submitBodyShape(event.target.value);
  const selectedShape = BODY_SHAPES.find(shape => shape.slug === userBodyShape);
  return (
    <ThemeProvider theme={theme}>
      <MainContainer>
        <CheckBoxContainer>
          {shapes.map(shape => (
            <FemaleShapeCheckbox key={shape.slug}>
              {shape.hasBoldText ? (
                <CustomTextWrapper>
                  <Text
                    id={`shape${shape.slug}Label1`}
                    name={shape.localeHelperBefore}
                    style={{ margin: '0 0.1rem', display: 'inline-block' }}
                  />
                  <Text
                    id={`shape${shape.slug}Label2`}
                    name={shape.localeHelperBold}
                    style={{ margin: '0 0.1rem', fontWeight: '600' }}
                  />
                  <Text
                    id={`shape${shape.slug}Label3`}
                    name={shape.localeHelperAfter}
                    style={{ margin: '0 0.1rem' }}
                  />
                </CustomTextWrapper>
              ) : (
                <Text
                  id={`shape${shape.slug}Label`}
                  name={shape.localeHelper}
                  style={{ margin: '0 0.5em' }}
                />
              )}
              <Checkbox
                value={shape.slug}
                name={shape.slug}
                labelId={`shape${shape.slug}Label`}
                isChecked={shape.slug === userBodyShape}
                onChange={handleInputChange}
                size={1.5}
              />
              {shape.slug === userBodyShape && <Arrow />}
            </FemaleShapeCheckbox>
          ))}
        </CheckBoxContainer>
        <ShapeContainer shapeSelected={Boolean(userBodyShape)}>
          {userBodyShape ? (
            <>
              <Text name={selectedShape.localesSVG[0]} style={{ margin: '0.5rem' }} />
              <SvgLoader
                path={`${process.env.REACT_APP_STATIC_URL}/plugin/data/body-shapes/${firstStepChoice}.svg`}
              >
                {shapes.map(shape => (
                  <SvgProxy
                    key={shape.slug}
                    selector={`#${shape.slug}`}
                    visibility={shape.slug === userBodyShape ? 'visible' : 'hidden'}
                  />
                ))}
              </SvgLoader>
              <CaptionMedium name={selectedShape.localesSVG[1]} style={{ maxWidth: '100%' }} />
            </>
          ) : (
            <Text color="grey" name="FEMALE_SVG_PLACEHOLDER" style={{ margin: '2em' }} />
          )}
        </ShapeContainer>
      </MainContainer>
    </ThemeProvider>
  );
};

BodyShapeFemaleSecondStep.defaultProps = {
  theme: {
    primaryColor: colors.black
  }
};

BodyShapeFemaleSecondStep.propTypes = {
  theme: PropTypes.object,
  firstStepChoice: PropTypes.oneOf(firstStepChoicesFemale).isRequired,
  submitBodyShape: PropTypes.func.isRequired,
  userBodyShape: PropTypes.oneOf(BODY_SHAPES.filter(b => b.gender === 'female').map(b => b.slug))
};

export default BodyShapeFemaleSecondStep;
