import React from 'react';
import styled from 'styled-components';
import { CaptionMedium, Caption } from '../text';

const ErrorContainer = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorTitle = styled(CaptionMedium)`
  margin-bottom: 1em;
`;

const ErrorMessage = () => (
  <ErrorContainer>
    <ErrorTitle name="ERROR_TITLE" color="red" />
    <Caption name="ERROR_MESSAGE" />
  </ErrorContainer>
);

export default ErrorMessage;
