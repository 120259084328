import React, { useReducer, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';
import { PhysicalInfoInputs } from 'shared_components';
import { Body } from 'shared_components';
import { Intent } from '@blueprintjs/core';
import { withRouter } from 'react-router';

import api from 'api';
import { INITIAL_STATE, reducer } from './store';
import { media } from 'shared_components';
import { isMobile } from 'services/device';
import { showToast } from 'services/toaster';
import HasInfo from '../common/HasInfo';
import PhysicalInfoBottomRow from './PhysicalInfoBottomRow';
import { physicalInfoInputsTheme } from 'styles/themes';
import FootLengthMeasurement from './FootLengthMeasurement';

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${media.phone`
    width: 100vw;
    flex: 1; 
  `}
  ${media.phoneLarge`
    width: 100%;
  `}

  /* Hack to show boob picker table on IE (doesn't support flex: 1)
  The media query will only match on IE 11
   */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .bra-tables-container {
      flex: auto;
      height: 100%;
    }
  }
`;

const PhysicalInfoInputsContainer = styled.div`
  width: 21em;
  padding-bottom: 1rem;

  ${media.phone`
    width: 100vw;
  `}
  ${media.phoneLarge`
    width: 100%;
    margin: 1em 0 0.5em 0;
  `}
  ${media.phoneMedium`
    margin: 0.2em 0 0.5em 0;
    padding-bottom: 0rem;
  `}
`;

const PhysicalInfoForm = ({
  details,
  employee,
  language,
  token,
  onSubmitted,
  history,
  location
}) => {
  const [
    {
      hasShoes,
      displayGender,
      currentScreen,
      bra,
      gender,
      height,
      weight,
      age,
      foot_length,
      termsAgreed,
      isFormValid,
      submitting,
      set_foot_only,
      hasError,
      lengthUnitType,
      weightUnitType
    },
    dispatch
  ] = useReducer(reducer, INITIAL_STATE);
  useEffect(() => {
    dispatch({
      type: 'INIT',
      initValues: {
        termsAgreed: employee.has_physical_info,
        currentScreen: employee.has_physical_info ? 'usePreviousInfo' : 'clothInputs',
        lengthUnitType: 'metric',
        weightUnitType: 'metric'
      }
    });
  }, [employee, language]);

  const handleChangeInputs = (info, isFormValid) =>
    dispatch({ type: 'UPDATE_INFO', info, isFormValid });

  const handleChangeTerms = () => dispatch({ type: 'TERMS_CLICKED', termsAgreed: !termsAgreed });

  const handleChangeLengthUnitType = unitType =>
    dispatch({ type: 'CHANGE_UNIT', unit: { lengthUnitType: unitType } });
  const handleChangeWeightUnitType = unitType =>
    dispatch({ type: 'CHANGE_UNIT', unit: { weightUnitType: unitType } });

  const submit = useCallback(async () => {
    try {
      const res = await api.campaignEmployees.updatePhysicalInfo({
        campaign_employee_id: employee.id,
        set_foot_only,
        token: token,
        age,
        bra,
        gender,
        height,
        weight,
        foot_length,
        measure_agreement: termsAgreed
      });
      if (res !== 'OK') throw new Error(`Issue with request: ${res}`);
      await onSubmitted();
    } catch (e) {
      dispatch('ERROR');
    }
  }, [
    age,
    bra,
    employee.id,
    foot_length,
    gender,
    height,
    onSubmitted,
    termsAgreed,
    token,
    weight,
    set_foot_only
  ]);

  useEffect(() => {
    const submitAndNavigate = async () => {
      await submit();
      history.push({
        ...location,
        pathname: `/clothes_attribution/${token}/body_shapes`
      });
    };
    if (submitting) {
      submitAndNavigate();
    }
  }, [submitting, submit, history, location, token]);

  useEffect(() => {
    if (hasError) {
      showToast({
        message: <Body name="ERROR" color="white" />,
        intent: Intent.DANGER
      });
    }
  }, [hasError]);

  useEffect(() => {
    /* when we update foot_length alone, we skip the physical info screen (UX) 
    so gender is None (for privacy), we must use employee.gender_name */
    if (
      !hasShoes &&
      (((gender === 'female' || employee.gender_name === 'female') && details.has_shoes_female) ||
        ((gender === 'male' || employee.gender_name === 'male') && details.has_shoes_male))
    ) {
        dispatch('HAS_SHOES');
    }
  }, [hasShoes, gender, details, employee.gender_name]);

  useEffect(() => {
    if (hasShoes && employee.has_physical_info && !employee.has_foot_info && details.client_id!==545) {
        dispatch('UPDATE_FOOT_INFO');
        dispatch('SHOW_SHOES');
    } 
  }, [hasShoes, employee.has_physical_info, employee.has_foot_info, details]);

  let screenToRender, returnClick, submitClick, allInputsFilled;
  switch (currentScreen) {
    case 'usePreviousInfo':
      allInputsFilled = true;
      screenToRender = <HasInfo modifyInfo={() => dispatch('SHOW_CLOTH')} STEP="PHYSICAL_INFO" />;
      returnClick = () => history.goBack();
      submitClick = () =>
        history.push({
          ...location,
          pathname: `/clothes_attribution/${token}/body_shapes`
        });
      break;
    case 'clothInputs':
      screenToRender = (
        <PhysicalInfoInputsContainer>
          <PhysicalInfoInputs
            theme={physicalInfoInputsTheme}
            bra={bra}
            onChangeValue={handleChangeInputs}
            gender={gender}
            age={age}
            weight={weight}
            height={height}
            language={language}
            isMobile={isMobile()}
            displayGenderSelect={displayGender}
            lengthUnitType={lengthUnitType}
            onChangeLengthUnitType={handleChangeLengthUnitType}
            weightUnitType={weightUnitType}
            onChangeWeightUnitType={handleChangeWeightUnitType}
            useFullScreenBoobPicker={isMobile() ? true : false}
            unavailableGenders={details.unavailable_genders}
          />
        </PhysicalInfoInputsContainer>
      );
      returnClick = () => history.goBack();
      if (hasShoes) {
        if(details.client_id===545){
          submitClick = () => dispatch('SUBMIT');
        }
        else{
          submitClick = () => dispatch('SHOW_SHOES');
        }
      } else {
        submitClick = () => dispatch('SUBMIT');
      }
      allInputsFilled = Boolean(gender) && Boolean(age) && Boolean(weight) && Boolean(height);
      break;
    case 'shoeInputs':
      screenToRender = (
        <FootLengthMeasurement
          theme={physicalInfoInputsTheme}
          footLength={foot_length}
          onChange={handleChangeInputs}
          lengthUnitType={lengthUnitType}
          switchLenghtUnitType={handleChangeLengthUnitType}
        />
      );
      returnClick = () => dispatch('SHOW_CLOTH');
      submitClick = () => dispatch('SUBMIT');
      allInputsFilled = Boolean(foot_length);
      break;
    default:
      throw new Error(`Unknown currentScreen: "${currentScreen}" in PhysicalInfoForm`);
  }

  return (
    <Form>
      {screenToRender}
      <PhysicalInfoBottomRow
        displayTerms={currentScreen === 'clothInputs'}
        returnClick={returnClick}
        submitClick={submitClick}
        handleChangeTerms={handleChangeTerms}
        termsAgreed={termsAgreed}
        allInputsFilled={allInputsFilled}
        isFormValid={isFormValid}
        companyName={employee.company_name}
        isFormSubmitting={submitting}
      />
    </Form>
  );
};

export default withRouter(PhysicalInfoForm);
