import React from 'react';
import styled from 'styled-components';
import { Title, Caption } from 'shared_components';
import { media } from 'shared_components';

import { Dialog } from 'library/dialogs';
import { StandardButton } from 'library/buttons';
import { ColoredAnchorButton } from '../common/styles';

const TitleContainer = styled.div`
  text-align: center;
`;

const BodyContainer = styled.div`
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const DialogButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 2em;
  padding-right: 2em;
  ${media.phone`
    padding: 0;
  `}
`;

const Modify = styled(StandardButton)`
  height: 100%;
  margin-right: 1em;
  padding: 0.5rem;
  background-color: white;
  ${media.phone`
    padding: 0;
    margin:0;
    width: 50%;
    min-width: 50%;
  `}
`;

const ConfirmationDialog = ({ isOpen, onClose, onSubmit, submitting }) => (
  <Dialog onClose={onClose} isOpen={isOpen}>
    <TitleContainer>
      <Title name="SIZE_AND_QUANTITY_CONFIRMATION" style={{ fontWeight: '600' }} />
    </TitleContainer>
    <BodyContainer>
      <Caption name="CHOICES_WILL_BE_SAVED" />
    </BodyContainer>
    <DialogButtonsRow>
      <Modify onClick={onClose}>
        <Caption
          name="MODIFY"
          style={{ textDecorationLine: 'underline', fontWeight: '600', backgroundColor: 'white' }}
        />
      </Modify>
      <ColoredAnchorButton onClick={onSubmit} disabled={submitting}>
        <Caption name="CONFIRM" color="white" />
      </ColoredAnchorButton>
    </DialogButtonsRow>
  </Dialog>
);

export default ConfirmationDialog;
