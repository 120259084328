import React from 'react';

import { SmallTextMedium, Caption } from 'shared_components';
import { StandardTable } from 'library/tables';

const TextCell = ({ value }) => <Caption text={value} />;

const ProductListing = ({ products }) => {
  const columns = [
    {
      Header: <SmallTextMedium color="grey" name="NAME" />,
      accessor: 'name',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="IDENTIFIER" />,
      accessor: 'client_product_id',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="CATEGORY" />,
      accessor: 'category_name',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="GENDER" />,
      accessor: 'gender_name',
      Cell: TextCell
    }
  ];
  return <StandardTable shrinkable data={products} columns={columns} />;
};

export default ProductListing;
