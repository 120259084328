import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { MainTitle, Title, Caption } from 'shared_components';

import api from 'api';
import { Content, TitleSection, Card } from 'library/layout';
import ResetPasswordForm from './ResetPasswordForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CardContent = styled.div`
  margin: 1.5em auto 0 auto;
  max-width: 25em;
`;
class Profile extends React.Component {
  state = {
    resetError: false,
    resetSuccess: false
  };
  resetPassword = async params => {
    try {
      await api.operators.resetPassword(params);
      this.resetForm();
      this.setState({ resetError: false, resetSuccess: true });
    } catch (e) {
      this.setState({ resetError: true, resetSuccess: false });
    }
  };
  render() {
    const { firstName, lastName } = this.props;
    return (
      <Container>
        <TitleSection>
          <MainTitle text={firstName + ' ' + lastName} />
        </TitleSection>
        <Content>
          <Card style={{ maxWidth: '40em', margin: 'auto' }}>
            <Title name="RESET_PASSWORD" />
            <CardContent>
              <ResetPasswordForm
                onSubmit={this.resetPassword}
                onResetHook={resetForm => (this.resetForm = resetForm)}
              />
              <Message style={{ marginTop: 12 }}>
                {this.state.resetError && <Caption color="red" name="AUTH_ERROR" />}
                {this.state.resetSuccess && <Caption color="green" name="PASSWORD_RESET_SUCCESS" />}
              </Message>
            </CardContent>
          </Card>
        </Content>
      </Container>
    );
  }
}

const mapper = state => ({
  ...state.auth
});

export default connect(mapper)(Profile);
