import React from 'react';
import styled from 'styled-components/macro';
import { Caption, Body } from 'shared_components';

import { colors } from 'shared_components';

const Button = ({ children, ...rest }) => (
  <button type="button" {...rest}>
    {children}
  </button>
);

export const StandardButton = styled(Button)`
  user-select: none;
  box-shadow: none;
  height: 2rem;
  border-radius: 5px;
  padding: 0.3125rem 0.625rem;
  cursor: pointer;
  opacity: 1;
  transition: opacity 200ms ease-in;
  min-width: 140px;
  line-height: inherit;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    box-shadow: none;
  }

  &:disabled {
    box-shadow: none;
    background-color: ${colors.grey};
    cursor: not-allowed;
    opacity: 1;
  }
`;

export const OutlineButton = styled(StandardButton)`
  border: 2px ${colors.blue} solid;
  background-color: white;

  span {
    color: ${colors.blue};
  }

  &:disabled {
    opacity: 0.3;
    background-color: white;
  }
`;

export const ColoredButton = styled(StandardButton)`
  background-color: ${props => (props.color ? props.color : colors.mainBlue)};
`;

export const DangerButton = styled(StandardButton)`
  background-color: ${colors.red};
`;

const DisabledButton = styled(StandardButton)`
  background-color: ${colors.grey};
  cursor: not-allowed;
`;

export const SubmitButton = ({ danger, disabled, name, ...rest }) => {
  let Sub = ColoredButton;
  if (danger) Sub = DangerButton;
  if (disabled) Sub = DisabledButton;
  return (
    <Sub disabled={disabled} {...rest} type="submit">
      <Caption color="white" name={name ? name : 'SUBMIT'} />
    </Sub>
  );
};
const ColoredButtonStyle = styled(StandardButton)``;
export const CancelButton = props => {
  return (
    <ColoredButtonStyle {...props}>
      <Caption color={colors.red} name="CANCEL" />
    </ColoredButtonStyle>
  );
};

export const ButtonRow = styled.div`
  display: flex;
  width: 100%;
  & button {
    flex: 1;
    margin-right: 8px;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const HugeBottomButtonStyle = styled.div`
  width: 100%;
  background-color: white;
  height: 60px;
  border: 1px ${colors.blue} solid;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    filter: brightness(99%);
  }
`;

export const HugeBottomButton = ({ Icon, name, ...other }) => {
  return (
    <HugeBottomButtonStyle {...other}>
      {Icon && <Icon color={colors.blue} style={{ marginRight: 15 }} />}
      <Body name={name} color="mainBlue" />
    </HugeBottomButtonStyle>
  );
};
