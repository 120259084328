import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components/macro';
import { Caption } from 'shared_components';

import { Form, Field } from 'library/forms';
import { OutlineButton } from 'library/buttons';
import { alphanum, normalize } from 'services/utils';
import { Submit } from '../common';

const generateId = (name, existingPositionIds) => {
  const id = alphanum(name).toUpperCase();
  let result = id;
  let count = 0;
  while (existingPositionIds.includes(result)) {
    count += 1;
    result = `${id}(${count})`;
  }
  return result;
};

const GenerateButton = styled(OutlineButton)`
  align-self: flex-end;
  margin-top: -10px;
  margin-bottom: 20px;
`;

const NameFormRender = ({
  errors,
  touched,
  isSubmitting,
  values,
  setFieldValue,
  existingPositionIds,
  ...props
}) => (
  <Form
    errors={errors}
    touched={touched}
    values={values}
    style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
  >
    <Field type="text" name="positionName" label="NAME" required />
    <Field type="text" name="positionId" label="IDENTIFIER" required />
    <GenerateButton
      disabled={!(values.positionName && values.positionName.length > 0)}
      onClick={() =>
        setFieldValue('positionId', generateId(values.positionName, existingPositionIds))
      }
    >
      <Caption name="GENERATE" />
    </GenerateButton>
    <Submit
      style={{ alignSelf: 'center' }}
      disabled={Object.keys(errors).length !== 0 || isSubmitting}
    />
  </Form>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NameForm = ({ onSubmit, positions, name, clientPositionId }) => {
  let existingPositionIds = positions.map(({ client_position_id }) => client_position_id);
  // Remove client position id from blacklist to avoid conflict
  const editingPositionId = existingPositionIds.indexOf(clientPositionId);
  if (editingPositionId > -1) {
    // only happen when editing
    existingPositionIds.splice(editingPositionId, 1);
  }

  const validate = ({ positionName, positionId }) => {
    const errors = {};
    if (!positionName || positionName.length === 0) {
      errors.positionName = 'MISSING_FIELD';
    }
    if (!positionId || positionId.length === 0) {
      errors.positionId = 'MISSING_FIELD';
    }
    if (existingPositionIds.map(id => normalize(id)).includes(normalize(positionId))) {
      errors.positionId = 'POSITION_ID_CONFLICT';
    }
    return errors;
  };

  return (
    <Container>
      <Formik
        initialValues={{
          positionName: name || '',
          positionId: clientPositionId || ''
        }}
        validate={validate}
        onSubmit={(values, actions) => {
          onSubmit(values.positionName, values.positionId);
          actions.setSubmitting(false);
        }}
      >
        {props => <NameFormRender existingPositionIds={existingPositionIds} {...props} />}
      </Formik>
    </Container>
  );
};

export default NameForm;
