import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider, withTheme } from 'styled-components';
import FaFemale from 'react-icons/lib/fa/female';
import FaMale from 'react-icons/lib/fa/male';
import { colors, media } from 'shared_components/styles';
import { getTheme } from '../styles/theme';
import { Caption } from '../text';
import {
  PhysicalInputLine,
  PhysicalLabelContainer,
  PhysicalInputContainer,
  ErrorContainer,
  Required
} from './styles';
import { localizedString } from '../services/i18n/translations';
import { TooltipStyle } from '../library/Tooltip';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  ${media.phone`
    font-size: min(1em, 5vw);
  `};
  ${media.phoneSmall`
    font-size: min(1em, 8vw);
  `};
  ${media.phoneMedium`
    font-size: min(1.15em, 8vw);
  `};
  ${media.phoneLarge`
    font-size: min(1.25em, 8vw);
  `};
  /* ${props => getTheme(props, 'GenderSwitch')} */
`;

const SwitchItem = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.active ? 'default' : 'pointer')};
  color: ${props => (props.active ? props.theme.primaryColor : colors.darkBlack)};
  background-color: white;
  font-size: 1em;
  height: 2em;
  width: 2em;
  border-radius: 50%;
  border: 1px solid ${props => (props.active ? props.theme.primaryColor : colors.grey)};
  box-shadow: ${props =>
    props.active ? 'none' : '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)'};
  margin-left: 1em;
  transition: box-shadow 100ms ease-out;
  :hover {
    color: ${props => props.theme.primaryColor};
    border-color: ${props => props.theme.primaryColor};
    box-shadow: none;
    ${TooltipStyle} {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
      transition: all 0.3s cubic-bezier(0.75, -0.02, 0.2, 0.97);
    }
  }
  :disabled {
    cursor: not-allowed;
    color: ${colors.grey};
    border-color: ${colors.grey};
  }
`;

// GenderSwitch can be used as a stand-alone export so it needs its own way to manage the theme
// Hence the ThemeProvider,
const GenderSwitch = React.memo(
  ({ theme, genderSwitchTheme, gender, onChange, unavailableGenders }) => {
    const handleClickFemale = () => {
      if (gender !== 'female') {
        onChange('female', true);
      }
    };

    const handleClickMale = () => {
      if (gender !== 'male') {
        onChange('male', true);
      }
    };

    const combinedTheme = {
      theme,
      ...genderSwitchTheme
    };
    return (
      <ThemeProvider theme={combinedTheme}>
        <ErrorContainer>
          <PhysicalInputLine className="gender-switch">
            <PhysicalLabelContainer className="gender-switch-label-container">
              <label htmlFor="gender-input">
                <Caption name="SEX" className="gender-switch-label" style={{ fontWeight: 600 }} />
                <Required />
              </label>
            </PhysicalLabelContainer>
            <PhysicalInputContainer className="gender-switch-input-container">
              <Wrapper id="gender-input">
                <SwitchItem
                  disabled={unavailableGenders.includes('female')}
                  className="gender-switch-item"
                  aria-label="female"
                  type="button"
                  onClick={handleClickFemale}
                  active={gender === 'female'}
                >
                  <FaFemale />
                  {unavailableGenders.includes('female') && (
                    <TooltipStyle width={300}>
                      <Caption
                        name="GENDER_POPOVER_TEXT"
                        values={{
                          gender: localizedString('WOMEN')
                        }}
                      />
                    </TooltipStyle>
                  )}
                </SwitchItem>

                <SwitchItem
                  disabled={unavailableGenders.includes('male')}
                  className="gender-switch-item"
                  aria-label="male"
                  type="button"
                  onClick={handleClickMale}
                  active={gender === 'male'}
                >
                  <FaMale />
                  {unavailableGenders.includes('male') && (
                    <TooltipStyle width={300}>
                      <Caption
                        name="GENDER_POPOVER_TEXT"
                        values={{
                          gender: localizedString('MEN')
                        }}
                      />
                    </TooltipStyle>
                  )}
                </SwitchItem>
              </Wrapper>
            </PhysicalInputContainer>
          </PhysicalInputLine>
        </ErrorContainer>
      </ThemeProvider>
    );
  }
);

GenderSwitch.defaultProps = {
  theme: {},
  genderSwitchTheme: {},
  unavailableGenders: []
};

GenderSwitch.propTypes = {
  theme: PropTypes.object,
  genderSwitchTheme: PropTypes.object,
  gender: PropTypes.oneOf(['male', 'female']),
  onChange: PropTypes.func.isRequired,
  unavailableGenders: PropTypes.arrayOf(PropTypes.string)
};

export default withTheme(GenderSwitch);
