import React, { useState } from 'react';
import { Intent } from '@blueprintjs/core';
import { Body, SmallText, Caption } from 'shared_components';

import api from 'api';
import { showToast } from 'services/toaster';
import HasInfo from '../common/HasInfo';
import { bodyShapeTheme } from 'styles/themes';
import {
  LightPage,
  Header,
  BottomButtonsRow,
  BottomTooltip,
  ColoredAnchorButton
} from '../common/styles';
import { BodyShapeHeader, BackButton } from './utils';
import InputBodyShapeMale from './input-body-shape-male';
import InputBodyShapeFemale from './input-body-shape-female';

const getTitleTranslationKey = (gender, isFirstStep) => {
  if (gender === 'male') {
    return 'FUNNEL_EXPLANATION_MORPHOLOGY_TITLE';
  }
  if (isFirstStep) {
    return 'MORPHOLOGY_TITLE_FEMALE_ONE';
  }
  return 'MORPHOLOGY_TITLE_FEMALE_TWO';
};

// There can be problems if the campaign employee changes their gender. To be fixed by
// a rearchitecture of the funnel and corresponding API (see https://trello.com/c/ZlUpwwZf/432-make-the-funnel-infrastructure-better)
const BodyShapeForm = ({ details, match, history, location, onSubmitted }) => {
  const [bodyShape, setBodyShape] = useState(null);
  const [isFemaleFirstStep, setIsFemaleFirstStep] = useState(details.gender_name === 'female');
  const [femaleFirstStepChoice, setFemaleFirstStepChoice] = useState(null);
  const [usePreviousInfo, setUsePreviousInfo] = useState(details.has_body_shape);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const token = match.params.campaign_employee_token;

  const isValidateDisabled = isFemaleFirstStep
    ? !usePreviousInfo && !femaleFirstStepChoice
    : !usePreviousInfo && (!bodyShape || isSubmitting);
  const selectFemaleFirstStepChoice = firstChoice => {
    setBodyShape(null);
    setFemaleFirstStepChoice(firstChoice);
  };

  const submitShape = async () => {
    setIsSubmitting(true);
    try {
      const res = await api.campaignEmployees.updateBodyShape({
        campaign_employee_id: details.id,
        token,
        body_shape: bodyShape
      });
      if (res !== 'OK') throw new Error(`Issue with request: ${res}`);
      setIsSubmitting(false);
      await onSubmitted();
      history.push({ ...location, pathname: `/clothes_attribution/${token}/brands` });
    } catch (error) {
      setIsSubmitting(false);
      showToast({
        message: <Body name="ERROR" color="white" />,
        intent: Intent.DANGER
      });
    }
  };

  const onValidate = () => {
    if (usePreviousInfo) {
      return history.push({ ...location, pathname: `/clothes_attribution/${token}/brands` });
    } else {
      isFemaleFirstStep ? setIsFemaleFirstStep(false) : submitShape();
    }
  };

  return (
    <LightPage currentProgress={1}>
      <Header
        titleName={getTitleTranslationKey(details.gender_name, isFemaleFirstStep)}
        details={details}
        hidePersonalDetails={true}
      />
      {usePreviousInfo ? (
        <HasInfo modifyInfo={() => setUsePreviousInfo(false)} STEP="MORPHOLOGY" />
      ) : (
        <>
          <BodyShapeHeader gender={details.gender_name} isFirstStep={isFemaleFirstStep} />
          {details.gender_name === 'male' ? (
            <InputBodyShapeMale
              theme={bodyShapeTheme}
              selectShape={setBodyShape}
              selectedShape={bodyShape}
              details={details}
            />
          ) : (
            <InputBodyShapeFemale
              theme={bodyShapeTheme}
              isFirstStep={isFemaleFirstStep}
              selectedFirstStepChoice={femaleFirstStepChoice}
              selectFirstStepChoice={selectFemaleFirstStepChoice}
              selectShape={setBodyShape}
              selectedShape={bodyShape}
            />
          )}
        </>
      )}
      <BottomButtonsRow>
        <BackButton
          isFirstStep={isFemaleFirstStep || details.gender_name === 'male'}
          token={token}
          returnFirstChoice={() => setIsFemaleFirstStep(true)}
        />
        <BottomTooltip
          disabled={!isValidateDisabled}
          usePortal={false}
          content={<SmallText name="MORPHOLOGY_TOOLTIP" color="white" />}
        >
          <ColoredAnchorButton disabled={isValidateDisabled} onClick={onValidate}>
            <Caption name={'VALIDATE_MORPHOLOGY'} color="white" />
          </ColoredAnchorButton>
        </BottomTooltip>
      </BottomButtonsRow>
    </LightPage>
  );
};

export default BodyShapeForm;
