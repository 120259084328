import React, { useState } from 'react';
import styled from 'styled-components';
import { Callout, Intent, Checkbox } from '@blueprintjs/core';
import { Body, Caption } from 'shared_components';

import api from 'api';
import Async from 'library/Async';
import { CancellableDialog } from 'library/dialogs';

const CheckboxContainer = styled.div`
  margin-top: 3rem;
  display: flex;
`;

const RemoveEmployeeDialog = ({ employee, campaignId, isOpen, onClose, onSubmit }) => {
  const [andDelete, setAndDelete] = useState(false);

  const fetchCampaign = async () => {
    return await api.campaigns.get({ id: campaignId });
  };

  return (
    <CancellableDialog
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={() => onSubmit(andDelete)}
      danger
    >
      <Async fetchProps={fetchCampaign}>
        {campaign => (
          <>
            <Body
              name="CONFIRM_DELETE_CAMPAIGN_EMPLOYEE"
              values={{
                first_name: employee.first_name,
                last_name: employee.last_name,
                campaign_name: campaign.name
              }}
            />
            <CheckboxContainer>
              <Checkbox
                type="checkbox"
                value={andDelete}
                onChange={() => setAndDelete(!andDelete)}
              />
              <Body name="ALSO_DELETE_EMPLOYEE_FROM_COMPANY" values={campaign} />
            </CheckboxContainer>
            {andDelete && (
              <Callout style={{ maxWidth: '35rem' }} intent={Intent.WARNING}>
                <Caption name="DELETE_EMPLOYEE_WARNING" values={campaign} />
              </Callout>
            )}
          </>
        )}
      </Async>
    </CancellableDialog>
  );
};

export default RemoveEmployeeDialog;
