import React from 'react';

import { SmallText } from 'shared_components';

import Toggle from 'library/toggle';

const CampaignEmployeeToggle = ({ disabled, activeIndex, onToggle }) => {
  return (
    <Toggle disabled={disabled} activeIndex={activeIndex} onToggle={onToggle}>
      <Toggle.Item>
        <SmallText name="SELECT_EMPLOYEE" />
      </Toggle.Item>
      <Toggle.Item>
        <SmallText name="CREATE_EMPLOYEE" />
      </Toggle.Item>
    </Toggle>
  );
};

export default CampaignEmployeeToggle;
