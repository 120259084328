import React from 'react';
import styled from 'styled-components';

import CN from '../../../assets/images/flags/cn.png';
import GB from '../../../assets/images/flags/gb.png';
import FR from '../../../assets/images/flags/fr.png';
import US from '../../../assets/images/flags/us.png';
import DE from '../../../assets/images/flags/de.png';
import ES from '../../../assets/images/flags/es.png';
import IT from '../../../assets/images/flags/it.png';
import PT from '../../../assets/images/flags/pt.png';
import EU from '../../../assets/images/flags/eu.png';
import JP from '../../../assets/images/flags/jp.png';
import NL from '../../../assets/images/flags/nl.png';
import NO from '../../../assets/images/flags/no.png';
import SE from '../../../assets/images/flags/se.png';
import FI from '../../../assets/images/flags/fi.png';

const FLAG_URIS_MAP = {
  CN: CN,
  DE: DE,
  EN: GB,
  ES: ES,
  EU: EU,
  FR: FR,
  FI: FI,
  GB,
  IT: IT,
  JP: JP,
  NL: NL,
  NO: NO,
  PT: PT,
  SE: SE,
  UK: GB,
  US: US
};
export const getFlag = entity => {
  const flag = FLAG_URIS_MAP[entity];
  if (!flag) {
    throw new Error(`No flag for entity ${entity}`);
  }
  return flag;
};

const FlagImage = styled.img`
  width: 18px;
  height: 12px;
  cursor: pointer;
`;

const Flag = ({ value, ...rest }) => (
  <FlagImage className="i18n-flag" src={getFlag(value)} alt={value} {...rest} />
);

export default Flag;
