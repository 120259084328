import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import colors from '../../styles/colors';

const Container = styled.div`
  text-align: center;
`;

const Dot = styled.span`
  line-height: 0.5rem;
  font-size: 2.5rem;
  :before {
    vertical-align: top;
    content: '.';
  }
  color: ${({ active, primaryColor }) => (active ? primaryColor : colors.lightBlueGrey)};
`;

const Dots = ({ shapes, dots }) => {
  const themeContext = useContext(ThemeContext);
  const sortFromShapes = (a, b) => {
    return shapes.indexOf(a) - shapes.indexOf(b);
  };
  return (
    <Container>
      {Object.keys(dots)
        .sort(sortFromShapes)
        .map(shape => (
          <Dot
            key={shape}
            active={dots[shape]}
            primaryColor={
              themeContext.primaryColor || (themeContext.colors && themeContext.colors.primary)
            }
          />
        ))}
    </Container>
  );
};

export default Dots;
