import React from 'react';
import { Formik } from 'formik';
import { Title } from 'shared_components';

import { Form, Field, SelectField } from 'library/forms';
import styled from 'styled-components/macro';
import { SubmitButton } from 'library/buttons';
import { normalize } from 'services/utils';

const CampaignCreationFormRender = ({
  errors,
  touched,
  isSubmitting,
  values,
  companies,
  setFieldValue,
  setFieldTouched
}) => (
  <Form
    errors={errors}
    touched={touched}
    values={values}
    setFieldValue={setFieldValue}
    setFieldTouched={setFieldTouched}
    style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
  >
    {/* Campaign creation can be done from a compagny page. In this case, 
    there are no companies passed as props and thus no need to show a select */}
    {companies && (
      <SelectField
        name="company_id"
        items={companies}
        valueKey="id"
        labelKey="name"
        label="COMPANY"
        placeholderName="SELECT_COMPANY"
        required
      />
    )}
    <Field type="text" name="name" label="CAMPAIGN_NAME" required />
    <Field type="textarea" name="description" label="DESCRIPTION" />
    <SubmitButton disabled={isSubmitting} />
  </Form>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

class CampaignCreationForm extends React.Component {
  validate = ({ name, company_id, description }) => {
    const errors = {};
    if (!name || name.length === 0) {
      errors.name = 'MISSING_FIELD';
    } else {
      const { campaigns } = this.props;
      // The campaign name must be unique per company
      let relevantCampaigns;
      if (!this.props.companies) {
        // If no companies are passed, then all campaigns are relevant
        relevantCampaigns = campaigns;
      } else if (company_id) {
        // If companies are passed and a company is selected, only the campaigns
        //  of this company are relevant
        relevantCampaigns = campaigns.filter(camp => camp.company_id === company_id);
      } else {
        // If no company is selected yet, we don't do the comparison
        // It will be done once a company is selected
        relevantCampaigns = [];
      }
      // We use normalize here because MySQL 5.6.34 doesnt have case- and accent-
      // sensitive collation for utf8. Can be changed when we upgrade to MySQL 8.0.1 or later
      if (relevantCampaigns.map(c => normalize(c.name)).includes(normalize(name))) {
        errors.name = 'CAMPAIGN_NAME_CONFLICT';
      }
    }
    // we only validate company if we actually have options
    if (this.props.companies && !company_id) {
      errors.company_id = 'MISSING_FIELD';
    }
    return errors;
  };
  render() {
    const { onSubmit, companies } = this.props;
    return (
      <Container>
        <Title name="CAMPAIGN_CREATION" style={{ marginBottom: 30 }} />
        <Formik
          initialValues={{
            name: '',
            description: '',
            company_id: ''
          }}
          onSubmit={async (values, actions) => {
            await onSubmit(values);
            actions.setSubmitting(false);
          }}
          validate={values => this.validate(values)}
          render={props => <CampaignCreationFormRender companies={companies} {...props} />}
        />
      </Container>
    );
  }
}

export default CampaignCreationForm;
