import React from 'react';
import styled from 'styled-components/macro';

import { NavLink, withRouter } from 'react-router-dom';
import { colors, Caption, SmallText, CaptionMedium } from 'shared_components';
import { connect } from 'react-redux';
import FaSignOut from 'react-icons/lib/fa/sign-out';
import MdAccountCircle from 'react-icons/lib/md/account-circle';
import MdHelp from 'react-icons/lib/md/help';

import logo from 'assets/images/sizerpro-logo-white.png';
import { logout } from 'services/auth';
import { CancellableDialog } from 'library/dialogs';
import { Campaign, Company, Admin } from 'library/icons';
import LanguageSelector from 'library/LanguageSelector';
import { getLocaleString } from 'services/i18n';
import { RoleTag } from 'library/misc';

const baseBackground = colors.deepBlue;

const Container = styled.div`
  height: 100%;
  max-height: 100%;
  background-color: ${baseBackground};
  width: 10rem;
  max-width: 10rem;
  color: white;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 7px 0px 5px -5px rgba(0, 0, 0, 0.2);
`;

const LogoContainer = styled.div`
  height: 5rem;
  max-height: 5rem;
  width: 100%;
  max-width: 100%;
  padding: 2rem;
`;

const Logo = styled.img`
  max-width: 100%;
  cursor: pointer;
`;

const LogoutContainer = styled.div`
  height: 3rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  cursor: pointer;
  background-color: ${baseBackground};
  &:hover {
    filter: brightness(90%);
  }
`;

const Nav = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-left: 1rem;
  background-color: ${baseBackground};
  &:hover {
    filter: brightness(120%);
  }
`;

const NavItem = styled.div`
  width: 100%;
  height: 3rem;
  .nav-link-active {
    & > * {
      background-color: ${colors.blue};
      box-shadow: inset 0px 0px 56px -17px rgba(0, 0, 0, 0.41);
      &:hover {
        filter: brightness(100%);
      }
    }
  }
`;

const SideLink = ({ to, children, Icon, name }) => (
  <NavItem>
    <NavLink to={to} activeClassName="nav-link-active">
      <Nav>
        {Icon && <Icon color="white" style={{ marginRight: 12 }} />}
        {name && <CaptionMedium name={name} color="white" />}
        {children}
      </Nav>
    </NavLink>
  </NavItem>
);

const Logout = ({ onClick }) => {
  return (
    <LogoutContainer onClick={onClick}>
      <FaSignOut color="white" style={{ marginRight: 12 }} />
      <Caption name="LOGOUT" color="white" style={{ marginRight: 5 }} />
    </LogoutContainer>
  );
};

const TopLinks = styled.div`
  margin-top: 1.25rem;
`;

class LeftNav extends React.Component {
  state = {
    showLogoutModal: false
  };

  goHome = () => {
    this.props.history.push('/');
  };

  toggleLogoutModal = () => {
    this.setState({ showLogoutModal: !this.state.showLogoutModal });
  };

  logout = () => {
    this.toggleLogoutModal();
    logout();
  };

  render() {
    const { isConnected, firstName, lastName, isAdmin, clientName, language } = this.props;
    return (
      <Container>
        <div>
          <LogoContainer>
            <Logo src={logo} onClick={this.goHome} />
          </LogoContainer>
          <LanguageSelector />
          <TopLinks>
            <SideLink to="/campaigns" Icon={Campaign} name="CAMPAIGNS" />
            <SideLink to="/companies" Icon={Company} name="COMPANIES" />
            {isAdmin && <SideLink to="/admin" Icon={Admin} name="ACCESS_RIGHTS" />}
          </TopLinks>
        </div>
        {isConnected && (
          <div>
            <SideLink to="/profile" Icon={MdAccountCircle}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Caption text={firstName + ' ' + lastName} color="white" />
                {clientName && <SmallText text={clientName} color="white" />}
                <RoleTag isAdmin={isAdmin} style={{ marginTop: '0.20em' }} />
              </div>
            </SideLink>
            <NavItem>
              <a href={getLocaleString(language, 'DOCS_URL')} target="blank">
                <Nav>
                  <MdHelp color="white" style={{ marginRight: 12 }} />
                  <Caption name="HELP" color="white" />
                </Nav>
              </a>
            </NavItem>
            <Logout onClick={this.toggleLogoutModal} />
          </div>
        )}
        <CancellableDialog
          danger
          isOpen={this.state.showLogoutModal}
          onClose={this.toggleLogoutModal}
          onSubmit={this.logout}
        >
          <Caption name="CONFIRM_LOGOUT" color="black" />
        </CancellableDialog>
      </Container>
    );
  }
}
const mapper = state => ({
  isAdmin: state.auth.isAdmin,
  isConnected: !!state.auth.token,
  lastName: state.auth.lastName,
  firstName: state.auth.firstName,
  clientName: state.auth.clientName,
  language: state.settings.language
});

export default connect(mapper, null, null, { pure: false })(withRouter(LeftNav));
