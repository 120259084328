import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Route, Switch } from 'react-router-dom';

import Login from 'screens/auth/Login';
import Signup from 'screens/auth/Signup';
import ForgotPassword from 'screens/auth/ForgotPassword';
import ForgotPasswordReset from 'screens/auth/ForgotPasswordReset';
import Admin from 'screens/admin';
import Companies from 'screens/companies';
import CompanyDetails from 'screens/companies/CompanyDetails';
import Campaigns from 'screens/campaigns/campaign-listing';
import Employees from 'screens/employees';
import Splash from 'screens/Splash';
import Profile from 'screens/profile';
import CampaignDetails from 'screens/campaigns/campaign-details';
import Collections from 'screens/collections';
import CollectionDetails from 'screens/collections/CollectionDetails';
import UserFunnel from 'screens/campaigns/campaign-details/operation/user-funnel';
import PositionFunnel from 'screens/position';
import LeftNav from 'screens/left-nav';
import { colors } from 'shared_components';
import CookieBanner from 'shared_components/gdpr-consent'

const Page = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: ${colors.lightGrey};
`;

const Screen = styled.div`
  flex: 1;
  overflow: auto;
`;

const Routes = () => {
  const LEFT_NAV_PATHS = ['/campaigns', '/companies', '/admin', '/profile'];
  // The order of the route matters. The first path to match will be rendered
  // The Switch only allows one route among its children to be rendered
  return (
    <Page>
      {LEFT_NAV_PATHS.map(path => (
        <Route key={path} path={path} component={LeftNav} />
      ))}
      <Screen>
        <Switch>
          <Route exact path="/" component={Splash} />
          <Route exact path="/consent" component={CookieBanner} />
          <Route exact path="/auth" component={Login} />
          <Route exact path="/forgot_password" component={ForgotPassword} />
          <Route path="/forgot_password_reset" component={ForgotPasswordReset} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/signup/:token" component={Signup} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/companies" component={Companies} />
          <Route exact path="/campaigns/archived" component={Campaigns} />
          <Route path="/campaigns/:campaign_id" component={CampaignDetails} />
          <Route exact path="/campaigns" component={Campaigns} />
          <Route exact path="/collections" component={Collections} />
          <Route exact path="/collections/:collection_id" component={CollectionDetails} />
          <Route path="/companies/:company_id/" component={CompanyDetails} />
          <Route path="/companies/employees" component={Employees} />
          <Route path="/clothes_attribution/:campaign_employee_token" component={UserFunnel} />
          <Route path="/position/:campaign_id" component={PositionFunnel} />
        </Switch>
      </Screen>
    </Page>
  );
};

// Connect to force re-rendering on language change. (Needed because the language is set in fitle component as a standard variable)
const mapper = state => ({
  currentLanguage: state.settings.language
});

export default connect(mapper)(Routes);
