import React from 'react';
import styled from 'styled-components';
import { CaptionMedium } from '../text';
import { media } from 'shared_components/styles';

export const PhysicalGenderContainer = styled.div`
  height: 2.5em;
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 25px;
  .physical-gender-button-wrapper {
    flex: 1;
  }
`;

export const PhysicalInputLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
  margin-bottom: 5px;
  height: 2.5em;
  ${media.phoneVerySmall`
    margin-bottom: 0px;
  `};
  ${media.phoneSmall`
    margin-bottom: 0px;
  `};
`;

export const PhysicalLabelContainer = styled.div`
  flex: 0 1;
  margin-right: 0.5em;
`;

export const PhysicalInputContainer = styled.div`
  min-width: 100px;
  flex: 0 1 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
`;

export const PhysicalSliderContainer = styled.div`
  flex: 1;
  padding: 0 10px;
`;

export const PhysicalInputContainerMobile = styled.div`
  display: flex;
`;

export const MobilePhysicalLine = styled.div`
  margin-bottom: 1em;
  ${media.phoneVerySmall`
    margin-bottom: 0em;
  `};
  ${media.phoneSmall`
    margin-bottom: 0em;
  `};
`;

export const MobilePhysicalLineTexts = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  ${media.phoneVerySmall`
    margin-bottom: 0px;
    padding-top: 0.5em;
  `};
  ${media.phoneSmall`
    margin-bottom: 0px;
    padding-top: 0.5em;
  `};
`;

export const Required = () => <CaptionMedium text="*" style={{ marginLeft: 3 }} />;
