import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from 'shared_components/styles';

const SpinnerContainer = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
`;

const SpinnerBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 6px ${colors.black} solid;
  border-radius: 50%;
  opacity: 0.4;
`;

const rotateSpinner = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const SpinnerTop = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  border: 6px transparent solid;
  border-top: 6px ${colors.black} solid;
  animation: ${rotateSpinner} 0.8s infinite linear;
`;

const Spinner = ({ color, size }) => {
  color = color || colors.grey;
  size = size || 30;
  let borderWidth = Math.sqrt(size);
  return (
    <SpinnerContainer style={{ width: size, height: size }}>
      <SpinnerBackground style={{ borderColor: color, borderWidth }} />
      <SpinnerTop style={{ borderTopColor: color, borderWidth }} />
    </SpinnerContainer>
  );
};

export default Spinner;
