import React from 'react';
import { colors, SmallTextMedium, Caption } from 'shared_components';
import FaEye from 'react-icons/lib/fa/eye';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { StandardTable } from 'library/tables';

const TextCell = ({ value }) => <Caption text={value} />;

const Eye = styled(FaEye)`
  cursor: pointer;
`;

const ActionLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Actions = ({ original }) => (
  <ActionLink to={'/collections/' + original.id}>
    <Eye color={colors.blue} />
  </ActionLink>
);

const CollectionListing = ({ collections }) => {
  const columns = [
    {
      Header: <SmallTextMedium color="grey" name="NAME" />,
      accessor: 'name',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="DESCRIPTION" />,
      accessor: 'description',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="ACTIONS" />,
      Cell: Actions,
      filterable: false
    }
  ];
  return <StandardTable shrinkable data={collections} columns={columns} />;
};

export default CollectionListing;
