import React from 'react';
import styled from 'styled-components/macro';
import { media, colors, SmallText, Caption } from 'shared_components';
import {
  BottomButtonsRow,
  BottomTooltip,
  BottomBackButton,
  ColoredAnchorButton
} from '../common/styles';

const BottomSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Required = styled.div`
  width: 90%;
  ${media.phoneVerySmall`
    display:none;
  `}
  ${media.phoneSmall`
    display:none;
  `}
  ${media.phoneMedium`
    margin-bottom: 0.2em auto;
  `}
  ${media.phoneLarge`
    font-size: 0.8rem;
    width:90%;
    margin-bottom: 0.1em;
    margin-left: 0.1em;
  `}
`;
const BottomPhysicalInfoForm = ({
  displayTerms,
  returnClick,
  submitClick,
  allInputsFilled,
  termsAgreed,
  isFormValid,
  isFormSubmitting,
  handleChangeTerms,
  companyName
}) => {
  const isFormComplete = allInputsFilled;

  let tooltipText = 'FORM_MISSING_TERMS';
  if (!allInputsFilled) {
    tooltipText = 'FORM_MISSING_INPUT_AND_TERMS';
  } else if (!allInputsFilled || !isFormValid) {
    tooltipText = 'FORM_MISSING_INPUT';
  }

  return (
    <BottomSection>
      <Required>
        <SmallText name="REQUIRED" />
      </Required>
      <BottomButtonsRow>
        <BottomBackButton onClick={returnClick}>
          <Caption name="BACK" color={colors.darkGrey} />
        </BottomBackButton>
        <BottomTooltip
          disabled={isFormComplete && isFormValid}
          usePortal={false}
          content={<SmallText color="white" name={tooltipText} />}
        >
          <ColoredAnchorButton
            disabled={!isFormComplete || !isFormValid || isFormSubmitting}
            type="submit"
            onClick={submitClick}
          >
            <Caption name="VALIDATE_INFOS" color="white" />
          </ColoredAnchorButton>
        </BottomTooltip>
      </BottomButtonsRow>
    </BottomSection>
  );
};

export default BottomPhysicalInfoForm;
