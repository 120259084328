import { createGlobalStyle } from 'styled-components';

const SLIDE_HORIZONTAL_DURATION_MS = 400;
const SLIDE_HORIZONTAL_DELAY_MS = 400;
export const SLIDE_HORIZONTAL_TIMEOUT = SLIDE_HORIZONTAL_DURATION_MS + SLIDE_HORIZONTAL_DELAY_MS;

const SLIDE_VERTICAL_DURATION_MS = 500;
export const SLIDE_VERTICAL_TIMEOUT = SLIDE_VERTICAL_DURATION_MS;

export const GlobalSlideAnimationsStyle = createGlobalStyle`
  /*
  * SLIDE: RIGHT TO LEFT <=== [  ] <===
  */
  .slide-right-to-left-enter {
    right: -100%;
  }

  .slide-right-to-left-enter-active {
    right: 0;
    transition: right ${SLIDE_HORIZONTAL_DURATION_MS}ms ease ${SLIDE_HORIZONTAL_DELAY_MS}ms;
  }

  .slide-right-to-left-exit {
    left: 0;
  }

  .slide-right-to-left-exit-active {
    left: -100%;
  transition: left ${SLIDE_HORIZONTAL_DURATION_MS}ms ease;
  }

  /*
  * SLIDE: LEFT TO RIGHT ===> [  ] ===>
  */
  .slide-left-to-right-enter {
    left: -100%;
  }

  .slide-left-to-right-enter-active {
    left: 0;
    transition: left ${SLIDE_HORIZONTAL_DURATION_MS}ms ease ${SLIDE_HORIZONTAL_DELAY_MS}ms;
  }

  .slide-left-to-right-exit {
    right: 0;
    && .shoe-comfort-box {
      opacity: 1;
    }
  }

  .slide-left-to-right-exit-active {
    right: -100%;
    transition: right ${SLIDE_HORIZONTAL_DURATION_MS}ms ease;
    && .shoe-comfort-box {
      opacity: 0;
      transition: opacity 150ms ease;
    }
  } 
   
  /* ------------------------------------------- */

  .enter-slide-up-enter {
    z-index: 5;
    transform: translateY(100%);
  }

  .enter-slide-up-enter-active {
    z-index: 5;
    transform: translateY(0);
    transition: transform ${SLIDE_VERTICAL_DURATION_MS}ms ease;
  }

  .enter-slide-up-exit {
    z-index: 5;
    transform: translateY(0);
  }

  .enter-slide-up-exit-active {
    z-index: 5;
    transform: translateY(100%);
    transition: transform ${SLIDE_VERTICAL_DURATION_MS}ms ease;
  }

  /* ------------------------------------------- */

  .enter-slide-down-full-screen-enter {
    z-index: 5;
    transform: translateY(-100%);
  }

  .enter-slide-down-full-screen-enter-active {
    z-index: 5;
    transform: translateY(0);
    transition: transform ${SLIDE_VERTICAL_DURATION_MS}ms ease;
  }

  .enter-slide-down-full-screen-exit {
    z-index: 5;
    transform: translateY(0);
  }

  .enter-slide-down-full-screen-exit-active {
    z-index: 5;
    transform: translateY(-100%);
    transition: transform ${SLIDE_VERTICAL_DURATION_MS}ms ease;
  }

  /* ------------------------------------------- */

  .enter-slide-down-enter {
    max-height: 0px;
    opacity: 0;
  }

  .enter-slide-down-enter-active {
    max-height: 35em;
    opacity: 1;
    transition: max-height ${SLIDE_VERTICAL_DURATION_MS}ms ease, opacity ${SLIDE_VERTICAL_DURATION_MS}ms ease;
  }

  .enter-slide-down-exit {
    max-height: 35em;
    opacity: 1;
  }

  .enter-slide-down-exit-active {
    max-height: 0px;
    opacity: 0;
    transition: max-height ${SLIDE_VERTICAL_DURATION_MS}ms ease, opacity ${SLIDE_VERTICAL_DURATION_MS}ms ease;
  }
`;
