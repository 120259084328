import React from 'react';
import ReferenceBrandsForm from './ReferenceBrandsForm';


const ShoesStepReferences= ({
    details,
    match,
    theme,
    selectReferences,
    selectedReferences,
    addReference,
    updateReference,
    deleteReference,
    language,
    setIsloading,
    setIsError,
    skipSection,
    }) => {
        const isShoe = true
    return(<>
        <ReferenceBrandsForm
             details={details}
             match={match}
             theme={theme}
             selectedReferences={selectedReferences}
             selectReferences={selectReferences}
             addReference={addReference}
             updateReference={updateReference}
             deleteReference={deleteReference}
             language={language}
             setIsloading={setIsloading}
             setIsError={setIsError}
             skipSection={skipSection}
             isShoe={isShoe}
            />
    </>)

}; export default ShoesStepReferences;