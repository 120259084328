import React, { useEffect, useReducer, useState } from 'react';
import styled from 'styled-components/macro';
import { Spinner } from '@blueprintjs/core';
import { Title } from 'shared_components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px;
`;

export const Loading = p => (
  <Container {...p}>
    <Spinner />
  </Container>
);

export const Error = p => (
  <Container {...p}>
    <Title name="ERROR" color="red" />
  </Container>
);

class Async extends React.Component {
  state = {
    loading: true,
    error: false,
    fetchedProps: null
  };

  refresh = () => {
    this.setState({ loading: true, error: false }, async () => {
      try {
        const fetchedProps = await this.props.fetchProps(this.props.params);
        this.setState({ fetchedProps, loading: false });
      } catch (e) {
        console.error(e);
        this.setState({ error: true, loading: false });
      }
    });
  };

  componentDidMount = () => {
    const fetchProps = this.props.fetchProps;
    if (!fetchProps) throw new Error('No fetchProps passed to Async');
    this.refresh();
  };

  componentWillReceiveProps = newProps => {
    if (newProps.params !== this.props.params) {
      this.refresh();
    }
  };

  render() {
    const { loading, error, fetchedProps } = this.state;
    if (error) {
      return <Error />;
    }
    if (loading) {
      return <Loading />;
    }
    if (this.props.render) {
      return this.props.render(fetchedProps);
    }
    if (this.props.children) {
      return this.props.children(fetchedProps);
    }
    throw new Error('Need a render prop or children for Async');
  }
}

export default Async;

const asyncReducer = (state, action) => {
  switch (action.type) {
    case 'ASYNC_INIT':
      return { ...state, loading: true, error: false };
    case 'ASYNC_SUCCESS':
      return { ...state, loading: false, error: false, data: action.payload };
    case 'ASYNC_FAILURE':
      return { ...state, loading: false, error: true };
    default:
      throw new Error('Unknown action');
  }
};

// Hook to handle async tasks
// Inspired by https://www.robinwieruch.de/react-hooks-fetch-data/ and https://overreacted.io/a-complete-guide-to-useeffect/
// To make the hook lazy (not do the async task on render but instead imperatively), set lazy to true
// Caution: the async task will be launched whenever promiseFactory changes which will happen
// at every render if it is an arrow function. Per Overreacted article, either move it
// out of the component, or if it needs to be in the component, use useCallback
export const useAsync = (promiseFactory, initialArguments, lazy = false) => {
  const [args, setArguments] = useState(initialArguments);
  const [state, dispatch] = useReducer(asyncReducer, {
    data: undefined,
    loading: false,
    error: false
  });

  const asyncEffect = force => {
    let ignore = false;
    const doAsync = async () => {
      dispatch({ type: 'ASYNC_INIT' });
      try {
        const data = await promiseFactory(args);
        if (!ignore) {
          dispatch({ type: 'ASYNC_SUCCESS', payload: data });
        }
      } catch (error) {
        if (!ignore) {
          dispatch({ type: 'ASYNC_FAILURE' });
        }
      }
    };

    // If lazy and args are still initialArguments, don't do the async task
    // Except if it's forced
    if (!(lazy && Object.is(args, initialArguments)) || force) {
      doAsync();
    }
    return () => {
      ignore = true;
    };
  };

  useEffect(asyncEffect, [promiseFactory, args, lazy, initialArguments]);

  const forceAsync = newArgs => {
    // If the new args are the same as the old args (useEffect use Object.is), we have to call the effect directly
    // Otherwise just set the new args and the effect will run itself
    if (Object.is(args, newArgs)) {
      asyncEffect(true);
    } else {
      setArguments(newArgs);
    }
  };

  const { data, loading, error } = state;
  return [data, loading, error, forceAsync];
};
