import { useState } from 'react';

/*
  SIZES POSITIONS:
  if S2 === "SMALLER" && S3 === "BIGGER"
    step 1: S1 => "right", S2 => "left", button => "right" - "Plus grand"
    step 2: S1 => "left", S3 => "right", button => "left" - "Plus petit"
  if S2 === "TIGHTER" && S3 === "LOOSER"
    step 1: S1 => "right", S2 => "left", button => "right" - "Plus ample"
    step 2: S1 => "left", S3 => "right", button => "left" - "Plus serrée"
  if S2 === "SHORTER" && S3 === "LONGER"
    step 1: S1 => "right", S2 => "left", button => "right" - "Plus long"
    step 2: S1 => "left", S3 => "right", button => "left" - "Plus court"
  if S2 === "LONGER" && S3 === "SHORTER"
    step 1: S1 => "left", S2 => "right", button => "left" - "Plus court"
    step 2: S1 => "right", S3 => "left", button => "right" - "Plus long"
  if S2 === "LOOSER" && S3 === "TIGHTER"
    step 1: S1 => "left", S2 => "right", button => "left" - "Plus serrée"
    step 2: S1 => "right", S3 => "left", button => "right" - "Plus ample"
  if S2 === "BIGGER" && S3 === "SMALLER"
    step 1: S1 => "left", S2 => "right", button => "left" - "Plus petit"
    step 2: S1 => "right", S3 => "left", button => "right" - "Plus grand"
  else
    step 1: S1 => "left", S2 => "right", button => "center" - "Voir la taille S2.name"
    step 2: S1 => "left", S3 => "right", button => "center" - "Voir la taille S3.name"
*/

const getSizesPosition = (recommendedSize, comparedSize) => {
  if (['SMALLER', 'TIGHTER', 'SHORTER'].indexOf(comparedSize.comparison) > -1) {
    return {
      leftSize: comparedSize,
      rightSize: recommendedSize
    };
  } else {
    return {
      leftSize: recommendedSize,
      rightSize: comparedSize
    };
  }
};

export const getHeaderTextLineTwo = comparedSize => {
  switch (comparedSize.comparison) {
    case 'SMALLER':
    case 'TIGHTER':
    case 'SHORTER':
    case 'BIGGER':
    case 'LOOSER':
    case 'LONGER':
      return `COMPARISON_HEADER_TWO_${comparedSize.comparison}`;
    default:
      return 'COMPARISON_HEADER_TWO_DEFAULT';
  }
};

const styleButtonMap = {
  'SMALLER-BIGGER': {
    position: 'flex-end',
    arrowDirection: 'right',
    text: 'BUTTON_BIGGER'
  },
  'TIGHTER-LOOSER': {
    position: 'flex-end',
    arrowDirection: 'right',
    text: 'BUTTON_LOOSER'
  },
  'SHORTER-LONGER': {
    position: 'flex-end',
    arrowDirection: 'right',
    text: 'BUTTON_LONGER'
  },
  'BIGGER-SMALLER': {
    postion: 'flex-start',
    arrowDirection: 'left',
    text: 'BUTTON_SMALLER'
  },
  'LOOSER-TIGHTER': {
    position: 'flex-start',
    arrowDirection: 'left',
    text: 'BUTTON_TIGHTER'
  },
  'LONGER-SHORTER': {
    position: 'flex-start',
    arrowDirection: 'left',
    text: 'BUTTON_SHORTER'
  }
};

const getButtonInfo = (displayed, hidden) => {
  return (
    styleButtonMap[`${displayed}-${hidden}`] || {
      position: 'center',
      text: 'BUTTON_SEE_SIZE'
    }
  );
};

export const useComparisonLogic = sizes => {
  const [comparedSize, setComparedSize] = useState(sizes[1]);
  const { leftSize, rightSize } = getSizesPosition(sizes[0], comparedSize);

  let hiddenSize;
  let buttonInfo = { show: false };

  if (sizes.length > 2) {
    // Remove the recommended size (first one), so we only have two sizes left.
    hiddenSize = sizes.slice(1).find(s => s.name !== comparedSize.name);

    buttonInfo = {
      show: true,
      ...getButtonInfo(comparedSize.comparison, hiddenSize.comparison, hiddenSize.name)
    };
  }

  const handleSizeButtonClick = e => {
    e.target.blur();
    setComparedSize(hiddenSize);
  };

  return { comparedSize, leftSize, rightSize, hiddenSize, buttonInfo, handleSizeButtonClick };
};
