import React from 'react';
import styled from 'styled-components/macro';
import { Formik } from 'formik';
import { Body } from 'shared_components';

import AuthContainer from './AuthContainer';
import { Form, Field } from 'library/forms';
import { SubmitButton } from 'library/buttons';
import api from 'api';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmailFormRender = ({ errors, touched, isSubmitting, resetForm, resetFormHook }) => (
  <Form
    errors={errors}
    touched={touched}
    style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
  >
    <Field type="email" name="email" label="EMAIL" />
    <SubmitButton disabled={isSubmitting}>Submit</SubmitButton>
  </Form>
);

const validate = ({ email, password }) => {
  const errors = {};
  if (!email || !email.includes('@')) {
    errors.email = 'INVALID_EMAIL';
  }
  return errors;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmailForm = ({ onSubmit }) => {
  return (
    <Container>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={async (values, actions) => {
          await onSubmit(values);
          actions.setSubmitting(false);
        }}
        validate={values => validate(values)}
        render={EmailFormRender}
      />
    </Container>
  );
};

class ForgotPassword extends React.Component {
  state = {
    done: false
  };
  submitForm = async p => {
    await api.operators.forgotPassword(p);
    this.setState({ done: true });
  };
  render() {
    let content;
    if (this.state.done) {
      content = <Body name="CHECK_EMAIL" />;
    } else {
      content = (
        <Content>
          <Body name="FORGOT_PASSWORD_EXPLANATION" />
          <div style={{ marginTop: 20 }}>
            <EmailForm onSubmit={this.submitForm} />
          </div>
        </Content>
      );
    }
    return <AuthContainer title="FORGOT_PASSWORD">{content}</AuthContainer>;
  }
}

export default ForgotPassword;
