import React from 'react';
import { Formik } from 'formik';
import { ErrorText, Title, Caption } from 'shared_components';
import styled from 'styled-components/macro';

import { Form, Field } from 'library/forms';
import { SubmitButton, OutlineButton } from 'library/buttons';
import { alphanum, normalize } from 'services/utils';

const GenerateButton = styled(OutlineButton)`
  align-self: flex-end;
  margin-top: -10px;
  margin-bottom: 20px;
`;

const generateId = (name, companies) => {
  const count = companies.filter(c => c.name === name).length;
  const id = alphanum(name).toUpperCase();
  return count > 0 ? id + `(${count})` : id;
};

const CompanyCreationFormRender = ({
  errors,
  touched,
  isSubmitting,
  values,
  setFieldValue,
  companies
}) => (
  <Form
    errors={errors}
    touched={touched}
    values={values}
    style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
  >
    <Field type="text" name="name" label="COMPANY_NAME" required />
    <Field type="text" name="client_company_id" label="CLIENT_COMPANY_ID" required />
    <GenerateButton
      disabled={!values.name || values.name.length === 0}
      onClick={() => setFieldValue('client_company_id', generateId(values.name, companies))}
    >
      <Caption name="GENERATE" />
    </GenerateButton>

    <Field type="textarea" name="description" label="DESCRIPTION" />
    <SubmitButton disabled={isSubmitting} />
  </Form>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

class CompanyCreationForm extends React.Component {
  validate = ({ name, client_company_id }) => {
    const errors = {};
    if (!name || name.length === 0) {
      errors.name = 'MISSING_FIELD';
    } // We use normalize here because MySQL 5.6.34 doesnt have case- and accent-
    // sensitive collation for utf8. Can be changed when we upgrade to MySQL 8.0.1 or later
    else if (this.props.companies.map(c => normalize(c.name)).includes(normalize(name))) {
      errors.name = 'COMPANY_NAME_CONFLICT';
    }
    if (!client_company_id || client_company_id.length === 0) {
      errors.client_company_id = 'MISSING_FIELD';
    }
    if (
      this.props.companies
        .map(c => normalize(c.client_company_id))
        .includes(normalize(client_company_id))
    ) {
      errors.client_company_id = 'COMPANY_ID_CONFLICT';
    }
    return errors;
  };

  render() {
    const { onSubmit, error, companies } = this.props;
    return (
      <Container>
        <Title name="COMPANY_CREATION" style={{ marginBottom: 15 }} />
        <Formik
          validate={this.validate}
          initialValues={{
            name: '',
            client_company_id: '',
            description: ''
          }}
          onSubmit={async (values, actions) => {
            await onSubmit(values);
            actions.setSubmitting(false);
          }}
          render={p => <CompanyCreationFormRender companies={companies} {...p} />}
        />
        {error && <ErrorText name="ERROR_OCURRED" style={{ marginTop: 10 }} />}
      </Container>
    );
  }
}

export default CompanyCreationForm;
