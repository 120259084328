import React from 'react';
import styled, { withTheme } from 'styled-components';
import { SvgLoader } from 'react-svgmt';

import { BodyMedium, SmallText } from '../../../text';
import { getComfortTextSvgElements, getComfortCircleSvgElements } from '../../proxys';
import { colors, media } from 'shared_components/styles';

import Checkbox from '../../../library/Checkbox';

// For phones like the iphone 5 we have smaller svgs.
const SVG_FOLDER_PATH = `${process.env.REACT_APP_STATIC_URL}/plugin/data/shapes/comparison/`;

const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const RECOMMENDED_HEIGHT = '1.7em';
const RECOMMENDED_MARGIN_BOTTOM = '1em';
const CHECKBOX_OUTLINE_OFFSET = '6px';

const Recommended = styled(SmallText)`
  text-align: center;
  position: relative;
  width: 100%;
  border-radius: 6px;
  background-color: ${colors.green};
  margin-bottom: ${RECOMMENDED_MARGIN_BOTTOM};
  color: ${colors.white};
  visibility: ${props => (props.isShown ? 'visible' : 'hidden')};
  line-height: ${RECOMMENDED_HEIGHT};

  :after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: ${colors.green};
    border-width: 0.5em;
    margin-left: -0.5em;
  }
`;

const SizeContainer = styled.div`
  padding: 1em;
  height: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  transition: box-shadow 100ms ease-out;
  box-shadow: ${props => (props.isSelected ? '0 2px 10px 0 rgba(0, 0, 0, 0.1)' : 'none')};
  border: 1px solid ${props => (props.isSelected ? props.theme.primaryColor : colors.lightBlueGrey)};
  cursor: pointer;
  position: relative;
  :hover {
    border-color: ${props => (props.isSelected ? props.theme.primaryColor : colors.grey)};
  }
`;

const ClickableSizeContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

const COMFORT_SVG_DIMENSIONS = {
  phone: {
    width: '150px',
    height: '220px'
  },
  phoneSmall: {
    width: '135px',
    height: '200px'
  },
  bigScreen: {
    width: '175px',
    height: '230px'
  }
};

const TRANSPARENT_BACKGROUND_HEIGHT = '0.5em';

// We have to calculate the margins for each screen size in order to center the SVG correctly.
// Yeah it's ugly
const SvgContainer = styled.div`
  top: 50%;
  left: 50%;
  position: absolute;

  margin-top: calc(
    (
        -${COMFORT_SVG_DIMENSIONS.phone.height} - (${TRANSPARENT_BACKGROUND_HEIGHT} * 2) + ${RECOMMENDED_HEIGHT} +
          ${RECOMMENDED_MARGIN_BOTTOM} - ${CHECKBOX_OUTLINE_OFFSET}
      ) / 2
  );
  margin-left: calc(-${COMFORT_SVG_DIMENSIONS.phone.width} / 2);
  ${media.phoneSmall`
  margin-top: calc(
    (
        -${COMFORT_SVG_DIMENSIONS.phoneSmall.height} - (${TRANSPARENT_BACKGROUND_HEIGHT} * 2) + ${RECOMMENDED_HEIGHT} +
          ${RECOMMENDED_MARGIN_BOTTOM} - ${CHECKBOX_OUTLINE_OFFSET}
      ) / 2
  );
  margin-left: calc(-${COMFORT_SVG_DIMENSIONS.phoneSmall.width}/2);
  `}

  ${media.bigScreen`
  margin-top: calc(
    (
        -${COMFORT_SVG_DIMENSIONS.bigScreen.height} - (${TRANSPARENT_BACKGROUND_HEIGHT} * 2) + ${RECOMMENDED_HEIGHT} +
          ${RECOMMENDED_MARGIN_BOTTOM} - ${CHECKBOX_OUTLINE_OFFSET}
      ) / 2
  );
  margin-left: calc(-${COMFORT_SVG_DIMENSIONS.bigScreen.width}/2);
  `}
`;

const ComfortSvg = styled(SvgLoader)`
  overflow: visible;
  width: ${COMFORT_SVG_DIMENSIONS.phone.width};
  height: ${COMFORT_SVG_DIMENSIONS.phone.height};

  ${media.phoneSmall`
  width: ${COMFORT_SVG_DIMENSIONS.phoneSmall.width};
  height: ${COMFORT_SVG_DIMENSIONS.phoneSmall.height};
  `}

  ${media.bigScreen`
  width: ${COMFORT_SVG_DIMENSIONS.bigScreen.width};
  height: ${COMFORT_SVG_DIMENSIONS.bigScreen.height};
  `}
`;

const SvgBackground = styled.div`
  background-color: ${props => props.theme.backgroundColor || colors.white};
`;

const TransparentBackgroundTop = styled.div`
  background: linear-gradient(
    to bottom,
    ${props => props.transparentBackgroundColor},
    ${({ theme }) => theme.backgroundColor || colors.white}
  );
  height: ${TRANSPARENT_BACKGROUND_HEIGHT};
  width: 50%;
  margin: auto;
`;

const TransparentBackgroundBottom = styled.div`
  background: linear-gradient(
    to top,
    ${props => props.transparentBackgroundColor},
    ${({ theme }) => theme.backgroundColor || colors.white}
  );
  height: ${TRANSPARENT_BACKGROUND_HEIGHT};
  width: 50%;
  margin: auto;
`;

const SizeName = styled(BodyMedium)``;
const CheckboxComfort = styled(Checkbox)`
  margin-bottom: 0;
`;

const ComfortRenderer = ({
  theme,
  shapeFileName,
  rightSize,
  leftSize,
  language,
  selectSize,
  selectedSize,
  recommendedSize,
  clothType
}) => {
  const rightElements = getComfortTextSvgElements(rightSize.comfort, language, 'right');
  const leftElements = getComfortTextSvgElements(leftSize.comfort, language, 'left');
  const circleElements = getComfortCircleSvgElements(Object.keys(rightSize.comfort));

  // Change the last 1 in the rgba color to 0, to make the color transparent.
  // Safari does not handle the `transparent` in `linear-gradient()`. See: https://css-tricks.com/thing-know-gradients-transparent-black/
  // We wrap that in a try catch so if we the code below fail we just don't have a linear-gradient.
  let transparentBackgroundColor;
  try {
    transparentBackgroundColor = theme.backgroundColor.split(',').slice(0, -1).join(',') + ',0)';
  } catch (e) {
    transparentBackgroundColor = '';
  }

  return (
    <Section>
      <Container style={{ marginRight: '1.25em' }}>
        <Recommended name="RECOMMENDED_FOR_SIZE" isShown={leftSize.name === recommendedSize} />
        <SizeContainer isSelected={leftSize.name === selectedSize}>
          <ClickableSizeContainer onClick={() => selectSize(leftSize)} />
          <SizeName text={leftSize.name} id={`select-size-${leftSize.name}`} />
          <CheckboxComfort
            value={leftSize.name}
            name={leftSize.name}
            labelId={`select-size-${leftSize.name}`}
            isChecked={leftSize.name === selectedSize}
            onChange={() => selectSize(leftSize)}
          />
        </SizeContainer>
      </Container>
      <Container>
        <Recommended name="RECOMMENDED_FOR_SIZE" isShown={rightSize.name === recommendedSize} />
        <SizeContainer isSelected={rightSize.name === selectedSize}>
          <ClickableSizeContainer onClick={() => selectSize(rightSize)} />
          <SizeName text={rightSize.name} id={`select-size-${rightSize.name}`} />
          <CheckboxComfort
            value={rightSize.name}
            name={rightSize.name}
            labelId={`select-size-${rightSize.name}`}
            isChecked={rightSize.name === selectedSize}
            onChange={() => selectSize(rightSize)}
          />
        </SizeContainer>
      </Container>
      <SvgContainer clothType={clothType}>
        <TransparentBackgroundTop transparentBackgroundColor={transparentBackgroundColor} />
        <SvgBackground>
          <ComfortSvg path={`${SVG_FOLDER_PATH}${shapeFileName}.svg`}>
            {/* FYI the order does not matter, svgmt does its thing regardless of the order */}
            {rightElements}
            {leftElements}
            {circleElements}
          </ComfortSvg>
        </SvgBackground>
        <TransparentBackgroundBottom transparentBackgroundColor={transparentBackgroundColor} />
      </SvgContainer>
    </Section>
  );
};

export default withTheme(ComfortRenderer);
