import { useEffect } from 'react';

export const isNullOrUndefined = value => {
  return value === undefined || value === null;
};

// This mimic the componentDidMount behavior. This is not how functional component should work, only use it as a last resort.
// eslint-disable-next-line
export const useMountEffect = callback => useEffect(callback, []);

const ACCENT_MAP = {
  á: 'a',
  à: 'a',
  â: 'a',
  ä: 'a',
  é: 'e',
  è: 'e',
  ê: 'e',
  ë: 'e',
  í: 'i',
  ó: 'o',
  ú: 'u'
};

export const accentFold = s => {
  if (!s) {
    return '';
  }
  var ret = '';
  for (var i = 0; i < s.length; i++) {
    ret += ACCENT_MAP[s.charAt(i)] || s.charAt(i);
  }
  return ret;
};

export const normalize = s => accentFold(s.toLowerCase());

export const alphanum = s => {
  return normalize(s).replace(/[^0-9a-z]/gi, '');
};
