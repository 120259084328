import React from 'react';
import { withTheme } from 'styled-components';
import { Caption } from '../../text';
import { getPounds, getKgs, getValue, PhysicalInputError, InputSelector } from '../utils';
import { IntegerInput } from '../base';
import {
  PhysicalInputLine,
  PhysicalLabelContainer,
  PhysicalInputContainer,
  ErrorContainer,
  Required
} from '../styles';

class WeightInput extends React.PureComponent {
  state = {
    outOfLimits: false,
    metric: getValue(this.props.weight),
    imperial: getPounds(this.props.weight)
  };

  isMetric = () => this.props.unitType === 'metric';

  switch = () => {
    const unitType = this.isMetric() ? 'imperial' : 'metric';
    this.props.onChangeUnitType(unitType);
  };

  checkLimits = (value, next) => {
    const { min, max } = this.props.limits;
    if (value < min) return next(true);
    if (value > max) return next(true);
    return next(false);
  };

  getLimits = () => {
    const { min, max } = this.props.limits;
    if (this.isMetric()) {
      return {
        min: this.state.metric ? min : this.props.defaultValue,
        max: this.state.metric ? max : this.props.defaultValue
      };
    } else {
      const defaultPounds = getPounds(this.props.defaultValue);
      return {
        min: this.state.imperial ? getPounds(min) : defaultPounds,
        max: this.state.imperial ? getPounds(max) : defaultPounds
      };
    }
  };

  onChange = (value, skip = false) => {
    const isMetric = this.isMetric();
    const metric = isMetric ? value : getKgs(value);
    const imperial = isMetric ? getPounds(value) : value;
    this.checkLimits(metric, invalid => {
      const outOfLimits = skip ? this.state.outOfLimits : invalid;
      this.setState({ metric, imperial, outOfLimits }, () => {
        this.props.onChange(metric, !invalid);
      });
    });
  };

  renderError = () => {
    let outputLimits, unitLabel;
    if (!this.state.outOfLimits) return null;

    if (this.isMetric()) {
      unitLabel = 'kg';
      outputLimits = this.props.limits;
    } else {
      unitLabel = 'lbs';
      outputLimits = {
        min: getPounds(this.props.limits.min),
        max: getPounds(this.props.limits.max)
      };
    }
    return <PhysicalInputError name="ERROR_INPUT_WEIGHT" values={{ ...outputLimits, unitLabel }} />;
  };

  render() {
    const isMetric = this.isMetric();
    const inputId = 'weight-input-' + isMetric ? 'kilos' : 'pounds';
    return (
      <ErrorContainer>
        <PhysicalInputLine className="weight-container">
          <PhysicalLabelContainer className="weight-label-container">
            <Caption forwardedAs="label" htmlFor={inputId} name="WEIGHT" />
            <Required />
          </PhysicalLabelContainer>
          <PhysicalInputContainer className="weight-input-container">
            <IntegerInput
              className={this.props.theme.inputClassnames}
              required
              id={inputId}
              value={isMetric ? this.state.metric : this.state.imperial}
              onChange={this.onChange}
              {...this.getLimits()}
            />
            <InputSelector left="kg" right="lb" isLeft={isMetric} onSwitch={this.switch} />
          </PhysicalInputContainer>
        </PhysicalInputLine>
        {this.renderError()}
      </ErrorContainer>
    );
  }
}

export default withTheme(WeightInput);
