import { Select as BpSelect } from '@blueprintjs/select';
import { MenuItem, Button } from '@blueprintjs/core';
import React from 'react';

const Select = ({ renderItem, value, items, onChange, onClose, usePortal, ...other }) => {
  return (
    <BpSelect
      items={items}
      itemRenderer={(item, { handleClick, modifiers }) => (
        <MenuItem
          className={modifiers.active && 'bp3-active'}
          key={JSON.stringify(item || '')}
          onClick={handleClick}
          text={renderItem(item)}
        />
      )}
      onItemSelect={item => onChange(item)}
      style={{ width: '100%' }}
      popoverProps={{
        minimal: true,
        onClose,
        usePortal
      }}
      {...other}
    >
      <Button text={renderItem(value)} rightIcon="double-caret-vertical" />
    </BpSelect>
  );
};

export default Select;
