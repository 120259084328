import React, { useState, useEffect } from 'react';
import { SvgProxy } from 'react-svgmt';

import { setText } from './logic';
import { localizeStringToLanguage } from '../services/i18n/translations';

const COMFORTS = {
  TOO_TIGHT: 'Xtight',
  TIGHT: 'tight',
  ADJUSTED: 'adjusted',
  LOOSE: 'large',
  TOO_LOOSE: 'Xlarge'
};

const POSITIONS = {
  chest: ['Chest'],
  underchest: ['Underchest'],
  waist: ['Waist'],
  hips: ['Hips'],
  inseam: ['Inseam', 'InseamFloor'],
  height: ['Height']
};

const HEIGHT_OFFSET = 14;

export const ProxyText = ({
  id,
  language,
  text,
  heightOffset,
  isInseamOrHeight,
  isTitle,
  position
}) => {
  const [originalElement, setOriginalElement] = useState(undefined);
  const [parent, setParent] = useState(undefined);

  // when language is changed, need to update svg
  useEffect(() => {
    setText(text, parent, originalElement, heightOffset, isInseamOrHeight, isTitle, position);
  }, [language, text, parent, originalElement, heightOffset, isInseamOrHeight, isTitle, position]);

  const onElementSelected = el => {
    setOriginalElement(el);
    setParent(el.parentElement);
  };

  return <SvgProxy selector={id} onElementSelected={onElementSelected} font-size="0.75rem" />;
};

export const getComfortTextSvgElements = (comforts, language, position) => {
  const svgElements = [];
  const placement = position ? `-${position}` : '';
  Object.keys(POSITIONS).forEach(comfortPositionKey => {
    Object.keys(COMFORTS).forEach(comfortKey => {
      const measurementKeys = POSITIONS[comfortPositionKey];
      const visible = comforts && measurementKeys.some(key => comfortKey === comforts[key]);
      const comfort = COMFORTS[comfortKey];
      const selector = `#comfort-${comfortPositionKey}-${comfort}`;
      const container = (
        <SvgProxy
          key={selector}
          selector={selector + placement}
          visibility={visible ? 'visible' : 'hidden'}
        />
      );

      // for inseam and height the case is bit different :
      // - the title has to be translated
      // - the locale names are different
      // - the text is too close the titles
      let isInseamOrHeight = false;

      if (comfortPositionKey === 'inseam' || comfortPositionKey === 'height') {
        isInseamOrHeight = true;
        let titleContent = `${comfortPositionKey.toUpperCase()}_COMFORT`;
        const title = (
          <ProxyText
            key={`${selector}-title${placement}`}
            id={`${selector}-title${placement} tspan`}
            language={language}
            text={localizeStringToLanguage(language, titleContent)}
            heightOffset={HEIGHT_OFFSET}
            isTitle={true}
            // For unique size (= position undefined), height and inseam are on the left
            position={position || (isInseamOrHeight && 'left')}
          />
        );
        svgElements.push(title);
      }

      const textContent = isInseamOrHeight
        ? `${comfort.toUpperCase()}_HEIGHT`
        : comfort.toUpperCase();
      const text = (
        <ProxyText
          key={`${selector}-text${placement}`}
          id={`${selector}-text${placement} tspan`}
          language={language}
          text={localizeStringToLanguage(language, textContent)}
          heightOffset={HEIGHT_OFFSET}
          isInseamOrHeight={isInseamOrHeight}
          // For unique size (= position undefined), height and inseam are on the left
          position={position || (isInseamOrHeight && 'left')}
        />
      );
      svgElements.push(text);

      svgElements.push(container);
    });
  });

  return svgElements;
};

export const getComfortCircleSvgElements = comfortsToDisplay => {
  return Object.keys(POSITIONS).map(comfortPositionKey => {
    let isVisible = false;
    POSITIONS[comfortPositionKey].forEach(comfortPosition => {
      if (comfortsToDisplay.indexOf(comfortPosition) !== -1) {
        isVisible = true;
      }
    });
    return (
      <SvgProxy
        key={`#comfort-circle-${comfortPositionKey}`}
        selector={`#comfort-circle-${comfortPositionKey}`}
        visibility={isVisible ? 'visible' : 'hidden'}
      />
    );
  });
};
