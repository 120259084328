import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components/macro';
import { FlagSelector } from 'shared_components';
import { colors, Body, Title, Caption } from 'shared_components';
import Callout from 'library/Callout';
import { CancellableDialog } from 'library/dialogs';
import { languages } from 'services/i18n';

const AccessDialogContainer = styled(CancellableDialog)`
  max-width: 37.5rem;
`;

const AccessDialogTitle = styled(Title)`
  text-align: center;
  font-weight: 600;
`;

const AccessDialogBody = styled(Body)`
  text-align: center;
  margin-top: 2em;
`;

const MailCallout = styled(Callout)`
  margin-top: 2em;
`;

const Many = ({ number }) => (
  <>
    <AccessDialogTitle name="GIVE_ACCESS_TO_MANY" values={{ number }} />
    <AccessDialogBody name="GIVING_ACCESS_EXPLANATION_MANY" color="darkGrey" />
  </>
);

const Single = ({ name }) => (
  <>
    <AccessDialogTitle name="GIVE_ACCESS_TO" values={{ name }} />
    <AccessDialogBody name="GIVING_ACCESS_EXPLANATION" color="darkGrey" />
  </>
);

const MailWarning = ({ employees }) => {
  let counter = 0;
  employees.forEach(employee => (employee.email ? false : counter++));
  if (counter === 0) return null;

  return (
    <MailCallout intent="danger" style={{ marginTop: '2em' }}>
      <Caption name="GIVING_ACCESS_MISSING_MAIL" values={{ counter }} color="red" />
    </MailCallout>
  );
};

const flagSelectorTheme = {
  primaryColor: colors.mainBlue,
  FlagSelector: `
    width: 2.4em;
    margin: auto
  `
};

const AccessDialog = ({ isOpen, displayLanguage, pendingAccessGiven, onClose, onSubmit }) => {
  const [mailLanguage, setMailLanguage] = useState(displayLanguage);

  if (pendingAccessGiven.length !== 0) {
    return (
      <AccessDialogContainer
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={() => onSubmit(mailLanguage.toLowerCase())}
      >
        {pendingAccessGiven.length > 1 ? (
          <Many number={pendingAccessGiven.length} />
        ) : (
          <Single name={`${pendingAccessGiven[0].first_name} ${pendingAccessGiven[0].last_name}`} />
        )}
        <AccessDialogBody name="SELECT_EMAIL_LANGUAGE" />
        <ThemeProvider theme={flagSelectorTheme}>
          <FlagSelector
            value={mailLanguage}
            selectFlag={option => option.value && setMailLanguage(option.value)}
            options={languages.map(lang => ({
              label: lang,
              value: lang
            }))}
          />
        </ThemeProvider>
        <MailWarning employees={pendingAccessGiven} />
      </AccessDialogContainer>
    );
  } else {
    return null;
  }
};

export default AccessDialog;
