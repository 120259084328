import React from 'react';
import styled from 'styled-components';
import { colors } from 'shared_components/styles';
import MdInfo from 'react-icons/lib/md/info';
import MdWarning from 'react-icons/lib/md/warning';
import FaCheck from 'react-icons/lib/fa/check';
// import {media} from 'shared_components';

const getBackgroundColor = intent => {
  switch (intent) {
    case 'primary':
      return colors.lightBlue;
    case 'danger':
      return colors.lightRed;
    case 'warning':
      return colors.lightYellow;
    case 'success':
      return colors.green;
    default:
      return colors.lightGrey;
  }
};

const LeftPane = styled.div`
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Left = ({ intent, iconColor }) => {
  switch (intent) {
    case 'primary':
      return (
        <LeftPane>
          <MdInfo color={iconColor || colors.blue} />
        </LeftPane>
      );
    case 'info':
      return (
        <LeftPane>
          <MdInfo color={iconColor || colors.darkGrey} />
        </LeftPane>
      );
    case 'danger':
      return (
        <LeftPane>
          <MdWarning color={iconColor || colors.red} />
        </LeftPane>
      );
    case 'warning':
      return (
        <LeftPane>
          <MdWarning color={iconColor || colors.darkGrey} />
        </LeftPane>
      );
    case 'success':
      return (
        <LeftPane>
          <FaCheck color={iconColor || colors.green} />
        </LeftPane>
      );
    default:
      return <div />;
  }
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 3px 7px 3px 7px;
  border-radius: 3px;
  background-color: ${props => props.backgroundColor || getBackgroundColor(props.intent)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Callout = ({ children, intent, icon = true, backgroundColor, iconColor, ...rest }) => (
  <Container intent={intent} backgroundColor={backgroundColor} {...rest}>
    {icon && <Left intent={intent} iconColor={iconColor} />}
    <Content>{children}</Content>
  </Container>
);

export default Callout;
