import { colors } from 'shared_components';

export const physicalInfoInputsTheme = {
  primaryColor: colors.mainBlue,
  physicalInfoInputColor: colors.darkBlack
};

export const referencesTheme = {
  primaryColor: colors.mainBlue
};

export const bodyShapeTheme = {
  primaryColor: colors.mainBlue
};

export const userFunnelTheme = {
  primaryColor: colors.mainBlue,
  backgroundColor: colors.white
};

export const sizingChartTheme = {
  primaryColor: colors.mainBlue
};

export const ratingTheme = {
  primaryColor: colors.mainBlue
};
