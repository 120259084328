import React from 'react';
import styled from 'styled-components/macro';

import { Select } from '@blueprintjs/select';
import { Button } from '@blueprintjs/core';
import { media, colors, ErrorText, Caption, SmallText } from 'shared_components';
import { StyledMenuItem } from '../../common/styles';

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Recommended = styled(SmallText)`
  margin: 0 5px;
  padding: 0.18rem 0.3125rem;
  border-radius: 7px;
  background-color: ${colors.darkGreen};
  color: white;
  line-height: 1rem;
  height: 1rem;
  font-size: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const renderSize = (size, recommendedSizeId, { handleClick, modifiers }) => (
  <StyledMenuItem
    active={modifiers.active}
    key={size.id}
    onClick={handleClick}
    text={
      <FlexRow>
        <Caption text={size.name} />
        {size.id === recommendedSizeId && <Recommended name="RECOMMENDED" />}
      </FlexRow>
    }
  />
);

const SizeChoiceContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0rem 2rem;
  ${media.phone`
    margin: 0rem;
    justify-content: center;
  `}
`;

const SizeButton = styled(Button)`
  &&&& {
    padding: 0 0.3rem;
    height: 2.5em;
    border-radius: 4px;
    border: 1px solid ${colors.lightGrey};
    background-color: white;
    box-shadow: none;
    :hover {
      ${props => (props.disabled ? null : `border-color: ${colors.blue}`)};
    }
    :disabled {
      background-color: ${colors.lightBlue};
    }

    ${media.phone`
      width: 100%;
    `}
  }
`;

const SizeButtonText = styled.div`
  display: flex;
  align-items: center;
  max-width: 12rem;
`;

const Size = styled(Caption)`
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${media.phone`
    font-size: 1rem;
  `}
`;

const SizeChoiceLabel = styled.label`
  margin-right: 1em;
  width: 40%;
  text-align: right;
  color: black;
  ${media.phone`
    flex: 0 1 30%;
    color:white;
    margin-right: 0.4em;
    font-size: 1rem;
  `}
  ${media.phoneLarge`
    flex: 0 1 30%;
    color:white;
    margin-right: 0.4em;
    font-size: 1rem;
  `};
`;

const SelectSize = styled(Select)`
  marginleft: 0.5em;
  width: 60%;
  ${media.phone`
    height:2.5rem;
    
    /* class override below, combine with { usePortal: false }, makes the Select responsive */
    .bp3-popover-wrapper {
      width: 100%;

      .bp3-transition-container {
        width: 100%;

        .bp3-popover {
          width: 100%;
        }
      }
    }
  `}
`;

const SizeInput = ({ sizes, selectedSizeId, recommendedSizeId, onChange, validated }) => {
  const selectedSize = sizes.find(s => s.id === selectedSizeId);
  return (
    <SizeChoiceContainer>
      {selectedSize ? (
        <>
          <SizeChoiceLabel>
            <SmallText
              name="SIZE"
              style={{ color: 'inherit', fontSize: 'inherit', marginRight: 'inherit' }}
            />
          </SizeChoiceLabel>
          <SelectSize
            items={sizes}
            filterable={false}
            itemRenderer={(size, args) => renderSize(size, recommendedSizeId, args)}
            onItemSelect={size => onChange(size.id)}
            disabled={validated}
          >
            <SizeButton
              rightIcon="caret-down"
              disabled={validated}
              text={
                <SizeButtonText>
                  <Size text={selectedSize.name} />
                  {selectedSizeId === recommendedSizeId && <Recommended name="RECOMMENDED" />}
                </SizeButtonText>
              }
            />
          </SelectSize>
        </>
      ) : (
        <ErrorText name="CHOSEN_SIZE_DOESNT_EXIST" />
      )}
    </SizeChoiceContainer>
  );
};

export default SizeInput;
