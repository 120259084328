import React from 'react';
import styled from 'styled-components/macro';
import FaVenus from 'react-icons/lib/fa/venus';
import FaMars from 'react-icons/lib/fa/mars';
import FaVenusMars from 'react-icons/lib/fa/venus-mars';
import { colors, SmallTextMedium } from 'shared_components';

import { StandardTable, TextCell } from 'library/tables';

const StyledTable = styled(StandardTable)`
  margin-top: 0.5rem;
  .rt-thead.-header {
    .rt-th {
      border-right: 1px solid ${colors.mainBlue} !important;
    }
    .rt-th:last-child {
      border-right: 0 !important;
    }
  }
`;

const ActionCell =
  Action =>
  ({ original }) =>
    <Action product={original} />;

const GenderCell = ({ value }) => {
  let Icon;
  if (value === 'male') {
    Icon = FaMars;
  } else if (value === 'female') {
    Icon = FaVenus;
  } else {
    Icon = FaVenusMars;
  }
  return <Icon />;
};

const ProductTable = ({ products, Action, ...other }) => {
  const columns = [
    {
      Header: <SmallTextMedium color="grey" name="NAME" />,
      accessor: 'name',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="IDENTIFIER" />,
      accessor: 'client_product_id',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="GENDER" />,
      accessor: 'gender_name',
      Cell: GenderCell,
      width: 50,
      filterable: false
    },
    {
      Header: <SmallTextMedium color="grey" name="ACTIONS" />,
      Cell: ActionCell(Action),
      filterable: false,
      width: 50
    }
  ];
  return <StyledTable columns={columns} data={products} {...other} />;
};

export default ProductTable;
