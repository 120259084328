import React from 'react';
import styled from 'styled-components';
import { colors, SmallText, CaptionMedium } from 'shared_components';

import { localizedString } from 'services/i18n';

const RecommendedContainer = styled.div`
  width: 48%;
  text-align: center;
  position: relative;
  border-radius: 6px;
  background-color: ${colors.green};
  margin-bottom: 0.5rem;
  color: ${colors.white};
  line-height: 0.8rem;
  padding: 0.35rem 0.4rem;
  border-bottom: 0.5px solid ${colors.green};
  :after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: ${colors.green};
    border-width: 0.5em;
    margin-left: -0.5em;
  }
`;

const Divider = styled.div`
  width: 100%;
  opacity: 0.5;
  border-bottom: 1px solid ${colors.white};
  margin: 0.4rem 0 0.4rem 0;
`;

const RecommendedTooltip = ({ activeFit, sizes, recoUnchanged }) => {
  const recoSizeName = sizes[0].name;
  const nextSizeName = sizes[1] && sizes[1].name;

  return (
    <RecommendedContainer>
      <CaptionMedium name="RECOMMENDED" color="white" />
      {recoUnchanged && nextSizeName && (
        <>
          <Divider />
          <SmallText
            name="PREFERRED_FIT_UNCHANGED_RECO_DISCLAIMER"
            values={{
              preferred_fit: localizedString(activeFit.translationKey).toLowerCase(),
              recommended_size_name: recoSizeName,
              word_compare: localizedString(activeFit.translationKey).split(' ').pop(),
              next_size: nextSizeName
            }}
            color="white"
          />
        </>
      )}
    </RecommendedContainer>
  );
};

export default RecommendedTooltip;
