import React, { useState, useEffect, useCallback } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import api from 'api';
import { Loading, Error } from 'library/Async';
import { raiseSentryError } from 'services/sentry';
import { setLanguageFromUrl } from 'services/i18n';

import Start from './start';
import PositionConfirmation from './PositionConfirmation';
import FunnelExplanation from './FunnelExplanation';
import PhysicalInfo from './physical-info-form';
import ReferenceBrands from './references-form';
import BodyShapes from './body-shapes-form';
import PositionItemsForm from './position-items-form';
import End from './end';

const UserFunnel = ({ match, history, location, language }) => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // We get updated details so that they are correctly displayed if the user goes back
  const synchronizeDetails = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const token = match.params.campaign_employee_token;
      const campaignEmployeeDetails = await api.campaignEmployees.auth({
        token
      });
      setDetails(campaignEmployeeDetails);
      setLoading(false);
    } catch (error) {
      raiseSentryError(error, {
        message: 'Got error while synchronizing details',
        token: match.params.campaign_employee_token
      });
      setError(true);
      setLoading(false);
    }
  }, [match.params.campaign_employee_token]);

  // Get language from invitation URL on mount
  useEffect(() => {
    setLanguageFromUrl();
  }, []);

  useEffect(() => {
    synchronizeDetails();
    const token = match.params.campaign_employee_token;
    if (location.pathname === `/clothes_attribution/${token}`) {
      history.replace({
        ...location,
        pathname: `/clothes_attribution/${token}/start`
      });
    }
  }, [history, location, match.params.campaign_employee_token, synchronizeDetails]);

  if (error) {
    return <Error />;
  }
  if (loading) {
    return <Loading />;
  }
  return (
    <Switch>
      <Route
        exact
        path="/clothes_attribution/:campaign_employee_token/start"
        render={routeProps => <Start {...routeProps} details={details} />}
      />
      <Route
        exact
        path="/clothes_attribution/:campaign_employee_token/confirmation"
        render={routeProps => <PositionConfirmation {...routeProps} details={details} />}
      />
      <Route
        exact
        path="/clothes_attribution/:campaign_employee_token/explanation"
        render={routeProps => <FunnelExplanation {...routeProps} details={details} />}
      />
      <Route
        exact
        path="/clothes_attribution/:campaign_employee_token/physical_info"
        render={routeProps => (
          <PhysicalInfo
            {...routeProps}
            language={language}
            details={details}
            onSubmitted={synchronizeDetails}
          />
        )}
      />
      <Route
        exact
        path="/clothes_attribution/:campaign_employee_token/body_shapes"
        render={routeProps => (
          <BodyShapes
            {...routeProps}
            details={details}
            language={language}
            onSubmitted={synchronizeDetails}
          />
        )}
      />
      <Route
        exact
        path="/clothes_attribution/:campaign_employee_token/brands"
        render={routeProps => (
          <ReferenceBrands {...routeProps} details={details} language={language} />
        )}
      />
      <Route
        exact
        path="/clothes_attribution/:campaign_employee_token/clothes"
        render={routeProps => <PositionItemsForm {...routeProps} details={details} />}
      />
      <Route
        exact
        path="/clothes_attribution/:campaign_employee_token/end"
        render={routeProps => <End {...routeProps} details={details} />}
      />
    </Switch>
  );
};

export default withRouter(
  connect(state => ({
    language: state.settings.language
  }))(UserFunnel)
);
