import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components/macro';
import { ErrorText } from 'shared_components';

import { Form, Field } from 'library/forms';
import { SubmitButton } from 'library/buttons';

const AuthFormRender = ({ errors, touched, isSubmitting }) => (
  <Form
    errors={errors}
    touched={touched}
    style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
  >
    <Field type="email" autoComplete="username" name="email" label="EMAIL" />
    <Field type="password" autoComplete="current-password" name="password" label="PASSWORD" />
    <SubmitButton disabled={isSubmitting}>Submit</SubmitButton>
  </Form>
);

const validate = ({ email, password }) => {
  const errors = {};
  if (!email || !email.includes('@')) {
    errors.email = 'INVALID_EMAIL';
  }
  return errors;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  max-width: 400px;
`;

const AuthForm = ({ onSubmit, error }) => {
  return (
    <Container>
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={async (values, actions) => {
          await onSubmit(values);
          actions.setSubmitting(false);
        }}
        validate={values => validate(values)}
        render={AuthFormRender}
      />
      {error && <ErrorText name="AUTH_ERROR" style={{ marginTop: 10 }} />}
    </Container>
  );
};

export default AuthForm;
