import React from 'react';
import styled from 'styled-components/macro';
import CampaignListing from 'screens/campaigns/campaign-listing/CampaignListing';
import { Caption, Body } from 'shared_components';
import FaPlus from 'react-icons/lib/fa/plus';
import { connect } from 'react-redux';

import api from 'api';
import { Content, OptionsRow, Card } from 'library/layout';
import { Dialog, CancellableDialog, NotificationDialog } from 'library/dialogs';
import { ColoredButton } from 'library/buttons';
import CampaignCreationForm from './CampaignCreationForm';
import { alphanum } from 'services/utils';
import CampaignExport from '../CampaignExport';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
class CampaignManagement extends React.Component {
  state = {
    pendingDelete: null,
    showCreateDialog: false
  };

  toggleCreateModal = () => {
    this.setState({ showCreateDialog: !this.state.showCreateDialog });
  };

  archiveCampaign = async campaign => {
    await api.campaigns.update({ id: campaign.id, active: false });
    this.props.refresh();
  };

  unarchiveCampaign = async campaign => {
    await api.campaigns.update({ id: campaign.id, active: true });
    this.props.refresh();
  };

  deleteCampaign = async () => {
    const { id } = this.state.pendingDelete;
    await api.campaigns.delete({ id });
    this.setState({ pendingDelete: null });
    this.props.refresh();
  };

  createCampaign = async data => {
    // note : because the company_id is '' when using a company_id prop,
    // we need to spread the data FIRST
    const params = {
      ...data,
      company_id: this.props.company_id || data.company_id
    };
    await api.campaigns.create(params);
    this.toggleCreateModal();
    this.props.refresh();
  };

  renderDeleteDialog = () => {
    const confirmationString = alphanum(this.state.pendingDelete.name).toUpperCase();
    return (
      <CancellableDialog
        onClose={() => {
          this.setState({ pendingDelete: null });
        }}
        onSubmit={this.deleteCampaign}
        confirmationString={confirmationString}
        danger
      >
        <Body name="CONFIRM_DELETE_CAMPAIGN" values={this.state.pendingDelete} />
      </CancellableDialog>
    );
  };

  renderCreateDialog = () => {
    const { campaigns, companies } = this.props;
    // CampaignManagement has a prop companies when used in the Campaigns screen
    // and no prop companies when used in a company screen
    // If there is the prop but with length 0, we show the notification
    if (companies && !companies.length) {
      return (
        <NotificationDialog onClose={this.toggleCreateModal} isOpen>
          <Body name="CAMPAIGN_CREATION_NEEDS_COMPANY" />
        </NotificationDialog>
      );
    }
    return (
      <Dialog isOpen={this.state.showCreateDialog} onClose={this.toggleCreateModal}>
        <CampaignCreationForm
          onSubmit={this.createCampaign}
          error={this.state.createError}
          companies={companies}
          campaigns={campaigns}
        />
      </Dialog>
    );
  };

  incompleteCampaigns = () => {
    const { campaigns } = this.props;
    for (let i = 0; i < campaigns.length; i++) {
      const c = campaigns[i];
      if (c.completed_campaign_employees_count < c.campaign_employees_count) {
        return true;
      }
    }
    return false;
  };

  export = async params => {
    const { company_id, language } = this.props;
    if (company_id) {
      await api.companies.exportCampaigns({ company_id, language, ...params });
    } else {
      await api.campaigns.exportAll({ language, ...params });
    }
  };

  renderTopRow = () =>
    this.props.campaigns.length > 0 && (
      <OptionsRow style={{ marginBottom: 15 }}>
        <React.Fragment>
          <CampaignExport export={this.export} showWarningDialog={this.incompleteCampaigns()} />
          <ColoredButton onClick={this.toggleCreateModal}>
            <FaPlus color="white" />
            <Caption name="CAMPAIGN_CREATION" color="white" />
          </ColoredButton>
        </React.Fragment>
      </OptionsRow>
    );

  render() {
    const { companies, campaigns, onArchivedScreen } = this.props;
    let content;
    if (campaigns.length > 0) {
      content = (
        <Card>
          <CampaignListing
            data={campaigns}
            onDelete={pendingDelete => this.setState({ pendingDelete })}
            onArchive={!onArchivedScreen && (campaign => this.archiveCampaign(campaign))}
            onCompanyScreen={!companies}
            onUnarchive={onArchivedScreen && (campaign => this.unarchiveCampaign(campaign))}
          />
        </Card>
      );
    } else if (onArchivedScreen) {
      content = (
        <Card>
          <Body name="NO_ARCHIVED_CAMPAIGNS" />
        </Card>
      );
    } else {
      content = (
        <Container>
          <Body color="grey" name="NO_CAMPAIGNS_IN_COMPANY" style={{ marginBottom: '20px' }} />
          <ColoredButton onClick={this.toggleCreateModal}>
            <FaPlus color="white" />
            <Caption name="CAMPAIGN_CREATION" color="white" />
          </ColoredButton>
        </Container>
      );
    }
    return (
      <Content>
        {this.renderTopRow()}
        {content}
        {this.state.pendingDelete && this.renderDeleteDialog()}
        {this.state.showCreateDialog && this.renderCreateDialog()}
      </Content>
    );
  }
}

export default connect(state => ({
  language: state.settings.language
}))(CampaignManagement);
