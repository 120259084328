import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import PropTypes from 'prop-types';

import Checkbox from '../../../library/Checkbox';
import { Caption, CaptionMedium } from '../../../text/index';
import { Container, ShapeImage, ShapeCheckbox } from '../../styles';
import { SimpleTextButton } from '../../../library/buttons';
import Callout from '../../../library/Callout';
import colors from '../../../styles/colors';
import Tooltip, { Position, useTooltip } from '../../../library/Tooltip';
import { BODY_SHAPES } from '../../../services/utils';

export const MainContainer = styled(Container)`
  flex-direction: column;
  justify-content: space-around;
`;

const ShapeText = styled(Caption)`
  line-height: 1.5em;
  text-align: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ShapeCheckboxWoman = styled(ShapeCheckbox)`
  height: auto;
  flex: 1;
  text-align: center;
`;

const TopCheckboxText = styled(CaptionMedium)`
  margin-bottom: 0.5em;
`;
const BottomCheckboxText = styled(Caption)`
  margin-bottom: 0.5em;
`;
const CalloutContainer = styled.div`
  display: none;
  justify-content: center;
  position: relative;
  margin-top: 10px;
`;

const SVG_PATH = `${process.env.REACT_APP_STATIC_URL}/plugin/data/body-shapes/waist_female.svg`;

export const firstStepChoicesFemale = [
  ...new Set(BODY_SHAPES.filter(b => b.gender === 'female').map(b => b.group))
];

const BodyShapeFemaleFirstStep = ({
  theme,
  selectShape,
  selectedShape,
  sendTooltipDisplayedEvent
}) => {
  const handleInputChange = event => selectShape(event.target.value);
  const { showTooltip, setShowTooltip } = useTooltip(sendTooltipDisplayedEvent);

  const svgShapes = ['default', ...firstStepChoicesFemale];
  const svgShapeToDisplay = selectedShape || 'default';
  return (
    <ThemeProvider theme={theme}>
      <MainContainer>
        <ShapeText name="FEMALE_WAIST_SUBTITLE" />
        <CalloutContainer>
          <Callout
            intent="info"
            backgroundColor={colors.lighterGrey}
            iconColor={theme.primaryColor}
          >
            <SimpleTextButton
              name="FEMALE_BODY_SHAPE_CALLOUT"
              onClick={e => {
                e.preventDefault();
                setShowTooltip(showTooltip => !showTooltip);
              }}
              active={showTooltip}
              style={{ display: 'flex' }}
            />

            <Tooltip
              opened={showTooltip}
              close={() => setShowTooltip(false)}
              style={{ left: 0 }}
              position={Position.BOTTOM}
              borderColor={theme.primaryColor}
            >
              <CaptionMedium name="FEMALE_BODY_SHAPE_TOOLTIP_START" />{' '}
              <Caption name="FEMALE_BODY_SHAPE_TOOLTIP_MIDDLE" />
              <div>
                <Caption name="FEMALE_BODY_SHAPE_TOOLTIP_END" />
              </div>
            </Tooltip>
          </Callout>
        </CalloutContainer>
        <ShapeImage>
          <SvgLoader path={SVG_PATH} height="100%" width="30%">
            {svgShapes.map(shape => (
              <SvgProxy
                key={shape}
                selector={`#${shape}`}
                visibility={shape === svgShapeToDisplay ? 'visible' : 'hidden'}
              />
            ))}
          </SvgLoader>
        </ShapeImage>
        <CheckboxContainer>
          <ShapeCheckboxWoman>
            <TopCheckboxText id="averageWaistLabelOne" name="AVERAGE_WAIST_ONE" />
            <BottomCheckboxText id="averageWaistLabelTwo" name="AVERAGE_WAIST_TWO" />
            <Checkbox
              value="average-waist"
              name="average-waist"
              labelId="averageWaistLabelOne averageWaistLabelTwo"
              isChecked={'average-waist' === selectedShape}
              onChange={handleInputChange}
              size={1.75}
            />
          </ShapeCheckboxWoman>
          <ShapeCheckboxWoman>
            <TopCheckboxText id="narrowWaistLabelOne" name="NARROW_WAIST_ONE" />
            <BottomCheckboxText id="narrowWaistLabelTwo" name="NARROW_WAIST_TWO" />
            <Checkbox
              value="narrow-waist"
              name="narrow-waist"
              labelId="narrowWaistLabelOne narrowWaistLabelTwo"
              isChecked={'narrow-waist' === selectedShape}
              onChange={handleInputChange}
              size={1.75}
            />
          </ShapeCheckboxWoman>
        </CheckboxContainer>
      </MainContainer>
    </ThemeProvider>
  );
};

BodyShapeFemaleFirstStep.defaultProps = {
  theme: {
    primaryColor: colors.black
  }
};

BodyShapeFemaleFirstStep.propTypes = {
  theme: PropTypes.object,
  selectShape: PropTypes.func.isRequired,
  selectedShape: PropTypes.oneOf(firstStepChoicesFemale),
  sendTooltipDisplayedEvent: PropTypes.func
};

export default BodyShapeFemaleFirstStep;
