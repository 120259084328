import React from 'react';
import styled from 'styled-components/macro';
import { colors } from 'shared_components';

const TitleSectionStyle = styled.div`
  padding: 15px 15px;
  background-color: ${colors.white};
  height: 80px;
  max-height: 80px;
  overflow: hidden;
  border-bottom: 1px ${colors.lightGrey} solid;
  width: 100%;
  display: flex;
  box-shadow: 0px 7px 5px -5px rgba(0, 0, 0, 0.07);
`;

const Divider = styled.div`
  flex: ${({ flex }) => flex || 1};
  display: flex;
  align-items: center;
  height: 100%;
`;

export const TitleSection = ({ left, right, children, ...rest }) => {
  left = left || <div />;
  right = right || <div />;
  return (
    <TitleSectionStyle {...rest}>
      <Divider style={{ justifyContent: 'flex-start' }}>{left}</Divider>
      <Divider flex={2} style={{ justifyContent: 'center' }}>
        {children}
      </Divider>
      <Divider style={{ justifyContent: 'flex-end' }}>{right}</Divider>
    </TitleSectionStyle>
  );
};

export const SmallTitleSection = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0px 7px 5px -5px rgba(0, 0, 0, 0.07);
  padding: 10px 20px;

  svg {
    font-size: 2em;
    margin-right: 0.75rem;
  }

  span {
    align-self: flex-end;
  }
`;

export const Card = styled.div`
  border-radius: 3px;
  background-color: ${colors.white};
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

export const OptionsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  & > * {
    margin-left: 8px;
  }
  & > *:first-child {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  padding: 15px;
`;
