import React, { useState, useEffect } from 'react';
import ReactTable, { ReactTableDefaults } from 'react-table';
import styled, { css } from 'styled-components/macro';
import { connect } from 'react-redux';
import { Caption, CaptionMedium, Body } from 'shared_components';
import FaSearch from 'react-icons/lib/fa/search';
import FaCaretLeft from 'react-icons/lib/fa/caret-left';
import FaCaretRight from 'react-icons/lib/fa/caret-right';
import { Checkbox } from '@blueprintjs/core';

import { colors } from 'shared_components';
import Select from 'library/forms/select';
import { normalize } from 'services/utils';
import { getLocaleString } from 'services/i18n';
import { Check } from 'library/icons';

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
  & input {
    width: auto;
    margin-right: 5px;
    flex: 1;
  }
`;
const Filter = props => {
  return (
    <FilterContainer>
      <ReactTableDefaults.FilterComponent {...props} />
      <FaSearch color="grey" size={14} />
    </FilterContainer>
  );
};
const StandardTableStyle = styled(ReactTable)`
  &.ReactTable {
    border: none;
    & .rt-thead.-header {
      box-shadow: none;
      background-color: white;
    }

    & .rt-thead .rt-th.-sort-asc {
      box-shadow: inset 0 3px 0 0 ${colors.blue};
    }
    & .rt-thead .rt-th.-sort-desc {
      box-shadow: inset 0 -3px 0 0 ${colors.blue};
    }
    & .-pagination {
      box-shadow: none;
      border: none;
      font-size: 12px;
    }

    .rt-thead .rt-th,
    .rt-thead.-filters .rt-th,
    .rt-tbody .rt-td {
      text-align: left;
      border-right: none;
      &:focus {
        outline: none;
      }
    }

    .rt-th {
      /* Wrap the text when overflow */
      white-space: pre-line !important;
      word-wrap: break-word;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .rt-tbody,
    .rt-thead.-filters {
      background-color: white;
    }

    .rt-thead.-filters {
      border-bottom: none;
    }

    .rt-tbody .rt-tr-group {
      margin: 5px 5px 0 5px;
      background-color: white;
      border: 1px rgba(0, 0, 0, 0.1) solid;
      border-radius: 3px;

      &:last-child {
        margin-bottom: 5px;
        border: 1px rgba(0, 0, 0, 0.05) solid;
      }
    }

    ${props =>
      props.error &&
      css`
        border: solid ${colors.red};
      `};
  }
`;

const PageNavigationStyle = styled.button`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:disabled {
    cursor: default;
  }
`;

const Previous = p => {
  return (
    <PageNavigationStyle {...p}>
      <FaCaretLeft style={{ marginRight: 8 }} />
      <Caption name={'PREVIOUS'} />
    </PageNavigationStyle>
  );
};

const Next = ({ disabled, ...rest }) => {
  return (
    <PageNavigationStyle disabled={disabled} {...rest}>
      <Caption name={'NEXT'} />
      <FaCaretRight style={{ marginLeft: 8 }} />
    </PageNavigationStyle>
  );
};

const getDefaultPageSize = props => {
  const { data, shrinkable, defaultPageSize } = props;
  let pageSize = defaultPageSize || 25;
  if (shrinkable) {
    pageSize = Math.max(1, Math.min(pageSize, data.length));
  }
  return pageSize;
};
export class StandardTableStupid extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      loading: false,
      error: null,
      fetchedProps: [],
      pageSize: getDefaultPageSize(this.props),
      selectedPageSize: false
    };
  }

  dataFunction = typeof this.props.data === 'function';
  componentDidMount() {
    if (this.dataFunction) this.refresh();
  }

  componentWillReceiveProps = newProps => {
    if (this.dataFunction && newProps.params !== this.props.params) {
      this.refresh();
    }
    if (
      newProps.data &&
      this.props.data &&
      this.props.data.length !== newProps.data.length &&
      !this.state.selectedPageSize
    ) {
      this.setState({ pageSize: getDefaultPageSize(newProps) });
    }
  };

  refresh = () => {
    if (this.dataFunction) {
      this.setState({ loading: true, error: null }, async () => {
        try {
          const fetchedProps = await this.props.data(this.props.params);
          this.setState({ fetchedProps, loading: false });
        } catch (error) {
          this.setState({ error, loading: false });
        }
      });
    }
  };

  render() {
    let { language, data, onRowClick, ...p } = this.props;
    let tableData = data;
    if (typeof data === 'function') {
      tableData = this.state.fetchedProps;
    }
    const defaultPageSize = getDefaultPageSize(this.props);
    return (
      <StandardTableStyle
        className={this.props.className}
        ref={this.inputRef}
        pageSize={this.state.pageSize}
        onPageSizeChange={pageSize => this.setState({ pageSize, selectedPageSize: true })}
        defaultPageSize={defaultPageSize}
        showPagination={data.length > defaultPageSize}
        data={tableData}
        noDataText={this.state.error && <Body name="ERROR" />}
        loading={this.state.loading}
        filterable
        FilterComponent={Filter}
        // PaginationComponent={Pagination}
        defaultFilterMethod={(filter, row) =>
          normalize(String(row[filter.id])).indexOf(normalize(filter.value)) >= 0
        }
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              if (onRowClick && rowInfo) {
                onRowClick(rowInfo);
              }
              // IMPORTANT! React-Table uses onClick internally to trigger
              // events like expanding SubComponents and pivots.
              // By default a custom 'onClick' handler will override this functionality.
              // If you want to fire the original onClick handler, call the
              // 'handleOriginal' function.
              if (handleOriginal) {
                console.log('----handleOriginal');
                handleOriginal();
              }
            },
            style: {
              cursor: onRowClick ? 'pointer' : 'default'
            }
          };
        }}
        PreviousComponent={Previous}
        NextComponent={Next}
        ofText={getLocaleString(language, 'TABLE_OF')}
        rowsText={getLocaleString(language, 'TABLE_ROWS')}
        {...p}
      />
    );
  }
}

const TextCellContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
export const TextCell = ({ value }) => (
  <TextCellContainer>
    <Caption text={value} />
  </TextCellContainer>
);
export const TextCellMedium = ({ value }) => (
  <TextCellContainer>
    <CaptionMedium text={value} />
  </TextCellContainer>
);

export const YesNoCell = ({ value, color = colors.blue }) => (
  <CenteredCell>{value && <Check size={18} color={color} />}</CenteredCell>
);

export const booleanFiltering = (trueLocale = 'YES', falseLocale = 'NO') => {
  return {
    // otherwise the dropdown is hidden behind the header div
    getHeaderProps: () => ({
      style: {
        overflow: 'visible'
      }
    }),
    filterMethod: (filter, row) => {
      if (filter.value.value === 'all') {
        return true;
      } else if (filter.value.value === 'true') {
        return row[filter.id];
      } else {
        return !row[filter.id];
      }
    },
    Filter: ({ filter, onChange }) => {
      const items = [
        { name: 'ALL', value: 'all' },
        { name: trueLocale, value: 'true' },
        { name: falseLocale, value: 'false' }
      ];
      return (
        <div style={{ overflow: 'visible' }}>
          <Select
            filterable={false}
            usePortal={false}
            value={(filter && filter.value) || items[0]}
            items={items}
            renderItem={item => <Caption name={item.name} />}
            onChange={onChange}
          />
        </div>
      );
    }
  };
};

export const CenteredCell = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.width || 'auto'};
`;

export const CheckboxCell = styled(Checkbox)`
  && {
    margin-bottom: 0;
  }
`;

const mapper = state => ({ language: state.settings.language });

export const StandardTable = connect(mapper, null, null, { forwardRef: true })(StandardTableStupid);

// Custom hook that handles the "selected" and "selectAll" states in a table.
// As well as provides the object for adding a column with checkboxes, based on ids, to that table.
export const useSelectManyTableRows = tableRows => {
  const [selected, setSelected] = useState([]);
  // selectAll states are 0 => nothing selected, 1 => all selected and 2 => some selected
  const [selectAll, setSelectAll] = useState(0);

  const toggleRow = employee => {
    if (selected.some(s => s.id === employee.id)) {
      setSelected(selected.filter(s => s.id !== employee.id));
    } else {
      setSelected([...selected, employee]);
    }
  };

  const toggleSelectAll = sortedData => {
    let newSelected = [];
    if (selectAll === 0) {
      // Get filtered rows
      sortedData.forEach(row => {
        // _original is the product object
        newSelected.push(row._original);
      });
    }
    setSelected(newSelected);
  };

  useEffect(() => {
    if (selected.length === 0) {
      setSelectAll(0);
    } else if (selected.length === tableRows.length) {
      setSelectAll(1);
    } else {
      setSelectAll(2);
    }
  }, [selected, tableRows]);

  // "table" is the ref of the react-table
  const checkboxesColumn = table => ({
    id: 'checkbox',
    Cell: ({ original }) => (
      <CenteredCell>
        <CheckboxCell
          checked={selected.some(s => s.id === original.id)}
          onChange={() => toggleRow(original)}
          onClick={() => console.log('----- check click')}
        />
      </CenteredCell>
    ),
    Header: () => (
      <CenteredCell>
        <CheckboxCell
          checked={selectAll === 1}
          indeterminate={selectAll === 2}
          onChange={() => {
            const { sortedData } =
              table.current && table.current.inputRef.current.getResolvedState();
            toggleSelectAll(sortedData);
          }}
        />
      </CenteredCell>
    ),
    filterable: false,
    resizable: false,
    sortable: false,
    width: 30
  });

  return {
    selected,
    setSelected,
    selectAll,
    setSelectAll,
    toggleRow,
    toggleSelectAll,
    checkboxesColumn
  };
};
