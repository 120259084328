import React from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import { MainTitle } from 'shared_components';

import api from 'api';
import Async from 'library/Async';
import CompanyManagement from './CompanyManagement';
import { SmallTitleSection } from 'library/layout';
import { Company } from 'library/icons';

const Container = styled.div``;

class CompaniesScreen extends React.Component {
  fetchCompanies = () => {
    return api.companies.list();
  };

  render() {
    return (
      <Container>
        <SmallTitleSection>
          <Company color="grey" />
          <MainTitle name="COMPANIES" />
        </SmallTitleSection>
        <Async fetchProps={this.fetchCompanies} ref={a => (this.async = a)}>
          {companies => <CompanyManagement companies={companies} refresh={this.async.refresh} />}
        </Async>
      </Container>
    );
  }
}

export default withRouter(CompaniesScreen);
