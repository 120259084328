import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Formik, FieldArray } from 'formik';
import { Body, Caption, SmallText, SmallTextMedium } from 'shared_components';

import api from 'api';
import { isNullOrUndefined } from 'util';
import Async from 'library/Async';
import { Dialog } from 'library/dialogs';
import { Form, SelectField } from 'library/forms';
import { ButtonRow, SubmitButton } from 'library/buttons';
import { StandardTable, booleanFiltering } from 'library/tables';
import { default as Toggle, ToggleContainer } from 'library/toggle';
import EmployeesImportForm from 'library/EmployeesImportForm';

const TextCell = ({ value }) => <Caption text={value} />;
const SelectContainer = styled.div`
  display: flex;
  align-items: center;
`;

const EmployeeListingForm = ({
  employees,
  positions,
  values,
  setValues,
  errors,
  touched,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
  isValid
}) => {
  const CheckboxHeader = (
    <input
      name="employee_ids"
      type="checkbox"
      checked={values.employee_ids.length === employees.length}
      onChange={e => {
        if (e.target.checked) setValues({ ...values, employee_ids: employees.map(c => c.id) });
        else setValues({ ...values, employee_ids: [] });
      }}
    />
  );
  const CheckboxCell =
    helpers =>
    ({ original }) =>
      (
        <input
          name="employee_ids"
          type="checkbox"
          value={original.id}
          checked={values.employee_ids.includes(original.id)}
          onChange={e => {
            if (e.target.checked) helpers.push(original.id);
            else helpers.remove(values.employee_ids.indexOf(original.id));
          }}
        />
      );
  const getColumns = helpers => [
    {
      Header: CheckboxHeader,
      sortable: false,
      Cell: CheckboxCell(helpers),
      width: 80,
      ...booleanFiltering
    },
    {
      Header: <SmallTextMedium color="grey" text="ID" />,
      accessor: 'client_employee_id',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="LAST_NAME" />,
      accessor: 'last_name',
      Cell: TextCell
    },
    {
      Header: <SmallTextMedium color="grey" name="FIRST_NAME" />,
      accessor: 'first_name',
      Cell: TextCell
    }
  ];
  return (
    <Form
      values={values}
      touched={touched}
      errors={errors}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
    >
      <SelectContainer>
        <SelectField
          required
          name="position_id"
          valueKey="id"
          labelKey="name"
          label="POSITION"
          items={positions}
          placeholderName="SELECT_POSITION"
          usePortal={false}
        />
      </SelectContainer>
      <FieldArray
        name="employee_ids"
        render={helpers => (
          <StandardTable
            data={employees}
            columns={getColumns(helpers)}
            defaultPageSize={8}
            noDataText={<Body name="EMPLOYEES_ALL_ASSOCIATED" />}
            error={touched && touched.employee_ids && errors && errors.employee_ids}
          />
        )}
      />
      <ButtonRow style={{ marginTop: '1rem' }}>
        <SubmitButton disabled={isSubmitting || !isValid} />
      </ButtonRow>
    </Form>
  );
};

const CampaignEmployeesForm = ({ positions, campaignId, afterSubmit }) => {
  const fetchAvailableEmployees = async () => {
    const employees = await api.campaigns.availableEmployees({ campaign_id: campaignId });
    return employees;
  };

  const validate = ({ employee_ids, position_id }) => {
    const errors = {};
    if (!employee_ids || !employee_ids.length) {
      errors.employee_ids = 'MISSING_FIELD';
    }
    if (isNullOrUndefined(position_id)) {
      errors.position_id = 'MISSING_FIELD';
    }
    return errors;
  };

  const onSubmit = ({ employee_ids, position_id }) => {
    return api.campaigns.updateEmployees({
      campaign_id: campaignId,
      position_id,
      employee_ids_to_add: employee_ids
    });
  };

  return (
    <Async fetchProps={fetchAvailableEmployees}>
      {employees => (
        <Formik
          initialValues={{
            employee_ids: [],
            position_id: null
          }}
          validate={validate}
          onSubmit={async values => {
            await onSubmit(values);
            afterSubmit();
          }}
        >
          {props => <EmployeeListingForm employees={employees} positions={positions} {...props} />}
        </Formik>
      )}
    </Async>
  );
};

const AddManyEmployeesDialog = ({ isOpen, onClose, campaignId, positions }) => {
  const [importFromCSV, setImportFromCSV] = useState(false);

  return (
    <Dialog width={'50rem'} isOpen={isOpen} onClose={() => onClose(false)}>
      <ToggleContainer>
        <Toggle
          activeIndex={!importFromCSV ? 0 : 1}
          onToggle={() => setImportFromCSV(!importFromCSV)}
          style={{ marginBottom: 20 }}
        >
          <Toggle.Item>
            <SmallText name="TOGGLE_EXISTING_EMPLOYEES" />
          </Toggle.Item>
          <Toggle.Item>
            <SmallText name="TOGGLE_IMPORT_FROM_FILE" />
          </Toggle.Item>
        </Toggle>
      </ToggleContainer>
      {!importFromCSV ? (
        <CampaignEmployeesForm
          positions={positions}
          campaignId={campaignId}
          afterSubmit={() => onClose(true)}
        />
      ) : (
        <EmployeesImportForm
          campaignId={campaignId}
          onSuccess={() => onClose(true)}
          onFileSelected={() => null}
        />
      )}
    </Dialog>
  );
};

export default AddManyEmployeesDialog;
