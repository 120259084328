import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext, ThemeProvider } from 'styled-components';
import { SvgLoader, SvgProxy } from 'react-svgmt';

import { colors } from 'shared_components/styles';
import { Caption } from '../text';

const StarButton = styled.button`
  background-color: transparent;
  margin-right: 1em;
  cursor: pointer;
`;

const STAR_SVG =
  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path id="star" d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" stroke-width="2" stroke="#c8c8c8" fill="none"/></svg>';

const Star = ({ hovered, selected, onMouseOver, onClick }) => {
  const themeContext = useContext(ThemeContext);
  return (
    // The div makes the star easier to click
    <StarButton onMouseOver={onMouseOver} onClick={onClick}>
      <SvgLoader svgXML={STAR_SVG}>
        <SvgProxy
          selector="#star"
          stroke={selected ? themeContext.primaryColor : colors.grey}
          fill={selected ? themeContext.primaryColor : hovered ? colors.grey : 'none'}
        />
      </SvgLoader>
    </StarButton>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StarsContainer = styled.div`
  display: flex;
  margin-bottom: 0.5em;
`;

const RatingText = styled(Caption)`
  font-weight: 600;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  color: ${props => (props.selected ? props.theme.primaryColor : colors.grey)};
`;

const RATINGS_VALUES = [1, 2, 3, 4];
const RATINGS_KEYS = ['USELESS', 'DISAPPOINTING', 'SOMEWHAT_USEFUL', 'USEFUL'];

const Rating = ({
  selectedRating = null,
  selectRating,
  showTexts = true,
  theme = {
    primaryColor: colors.black
  }
}) => {
  // Star indexes go from 0 to 3, ratings from 1 to 4
  const [highestHovered, setHighestHovered] = useState(-Infinity);
  const textIndex = selectedRating ? selectedRating - 1 : highestHovered;
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <StarsContainer onMouseLeave={() => setHighestHovered(-Infinity)}>
          {RATINGS_VALUES.map((value, index) => (
            <Star
              key={index}
              hovered={index <= highestHovered}
              selected={index < selectedRating}
              onMouseOver={() => {
                setHighestHovered(index);
              }}
              onClick={() => selectRating(value)}
            />
          ))}
        </StarsContainer>
        {showTexts && (
          <RatingText
            // We use the first text if no text should be shown
            // It's not visible but it's important to have height
            name={textIndex >= 0 ? RATINGS_KEYS[textIndex] : null}
            visible={textIndex >= 0}
            selected={!!selectedRating}
          />
        )}
      </Container>
    </ThemeProvider>
  );
};

Rating.propTypes = {
  selectedRating: PropTypes.number,
  selectRating: PropTypes.func.isRequired,
  showTexts: PropTypes.bool,
  theme: PropTypes.object
};

export default Rating;
