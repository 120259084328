import { useReducer, useEffect, useMemo } from 'react';
import api from 'api';
import { raiseSentryError } from 'services/sentry';

const REFERENCES_INITIAL_STATE = {
  references: {},
  mostUsed: [],
  isLoading: false,
  isError: false
};

const DEFAULT_GET_REF_RETURN = [
  REFERENCES_INITIAL_STATE.references,
  REFERENCES_INITIAL_STATE.mostUsed
];

const getReferences = async (gender, numberOfMostUsedWanted, isShoe, clientId) => {
  if (!gender) return DEFAULT_GET_REF_RETURN;

  const typeOfReferences = isShoe ? 'shoe' : 'cloth';
  const { references, most_used_brands } = await api.references.getAllV2(
    gender,
    typeOfReferences,
    clientId,
    numberOfMostUsedWanted
  );

  if (Object.keys(references).length === 0) {
    raiseSentryError('Got no references', { clientId, gender, numberOfMostUsedWanted, isShoe });
    throw new Error('Got no references');
  }
  return [references, most_used_brands];
};

const referencesFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        references: action.payload.references,
        mostUsed: action.payload.mostUsed
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    default:
      throw new Error('Unknown action type given to referencesFetchReducer');
  }
};

const useFetchReferences = (userGender, clientId, isShoe) => {
  const [state, dispatch] = useReducer(referencesFetchReducer, REFERENCES_INITIAL_STATE);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });
      try {
        const [references, mostUsed] = await getReferences(userGender, 5, isShoe, clientId);
        dispatch({ type: 'FETCH_SUCCESS', payload: { references, mostUsed } });
      } catch (error) {
        dispatch({ type: 'FETCH_FAILURE' });
        raiseSentryError(error);
      }
    };
    fetchData();
  }, [userGender, isShoe, clientId]);

  return state;
};

const SELECTED_REFERENCES_INITIAL_STATE = {
  selectedReferences: null,
  isLoading: true,
  isError: false
};

const selectedReferencesReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        selectedReferences: action.payload.selectedReferences
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    default:
      throw new Error('Unknown action type given to referencesFetchReducer');
  }
};
const useFetchSelectedReferences = (campaignEmployeeId, token) => {
  const [state, dispatch] = useReducer(
    selectedReferencesReducer,
    SELECTED_REFERENCES_INITIAL_STATE
  );

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });
      try {
        const selectedReferences = await api.campaignEmployees.getReferences({
          campaign_employee_id: campaignEmployeeId,
          token
        });

        dispatch({ type: 'FETCH_SUCCESS', payload: { selectedReferences } });
      } catch (error) {
        dispatch({ type: 'FETCH_FAILURE' });
        raiseSentryError(error);
      }
    };
    fetchData();
  }, [campaignEmployeeId, token]);

  return state;
};

export const useSizerproReferences = (userGender, clientId, campaignEmployeeId, token, isShoe) => {
  const {
    references,
    mostUsed,
    isLoading: referencesAreLoading,
    isError: referencesAreError
  } = useFetchReferences(userGender, clientId, isShoe);
  const {
    selectedReferences,
    isLoading: selectedReferencesAreLoading,
    isError: selectedReferencesAreError
  } = useFetchSelectedReferences(campaignEmployeeId, token);
  const flatReferences = useMemo(
    () =>
      Object.keys(references).reduce((acc, key) => {
        const categories = references[key];
        return [
          ...acc,
          ...Object.keys(categories).reduce((acc_, current_) => {
            return [...acc_, ...categories[current_]];
          }, [])
        ];
      }, []),
    [references]
  );

  const fullSelectedReferences = useMemo(() => {
    if (!(flatReferences.length > 0 && selectedReferences)) return null;
    return selectedReferences.reduce((acc, { sizing_chart_slug, size_name }) => {
      const ref = flatReferences.find(r => r.sizing_chart_slug === sizing_chart_slug);
      // We dismiss selected references for whom we don't find the full reference
      if (!ref) return acc;
      return [...acc, { ...ref, size_name }];
    }, []);
  }, [flatReferences, selectedReferences]);
  return {
    references,
    mostUsed,
    savedSelectedReferences: fullSelectedReferences,
    isLoading: referencesAreLoading || selectedReferencesAreLoading,
    isError: referencesAreError || selectedReferencesAreError
  };
};
