export const INITIAL_STATE = {
  isMobile: false,
  hasShoes: false,
  displayGender: true,
  currentScreen: 'clothInputs',
  bra: { band: undefined, cup: undefined, region: undefined },
  gender: undefined,
  height: undefined,
  weight: undefined,
  age: undefined,
  foot_length: undefined,
  termsAgreed: false,
  isFormValid: true,
  submitting: false,
  set_foot_only: false,
  hasError: false,
  lengthUnitType: 'metric',
  weightUnitType: 'metric'
};

export const reducer = (state, action) => {
  switch (action.type || action) {
    case 'INIT':
      return { ...state, ...action.initValues };
    case 'SHOW_CLOTH':
      return { ...state, currentScreen: 'clothInputs' };
    case 'SHOW_SHOES':
      return { ...state, currentScreen: 'shoeInputs' };
    case 'HAS_SHOES':
      return { ...state, hasShoes: true };
    case 'UPDATE_INFO':
      return { ...state, ...action.info, isFormValid: action.isFormValid };
    case 'TERMS_CLICKED':
      return { ...state, termsAgreed: action.termsAgreed };
    case 'CHANGE_UNIT':
      return { ...state, ...action.unit };
    case 'SUBMIT':
      return { ...state, submitting: true, error: false };
    case 'UPDATE_FOOT_INFO':
      return { ...state, set_foot_only: true };
    case 'ERROR':
      return { ...state, submitting: false, error: true };
    default:
      throw new Error('Unknown action type for physical info store');
  }
};
