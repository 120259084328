import React from 'react';
import { withTheme } from 'styled-components';
import { Caption } from '../../text';
import { getValue, getCm, getFeetInches, PhysicalInputError, InputSelector } from '../utils';
import { IntegerInput } from '../base';
import {
  PhysicalInputLine,
  PhysicalLabelContainer,
  PhysicalInputContainer,
  ErrorContainer,
  Required
} from '../styles';

class HeightInput extends React.PureComponent {
  state = {
    outOfLimits: false,
    value: getValue(this.props.height),
    ...getFeetInches(this.props.height)
  };

  isMetric = () => this.props.unitType === 'metric';

  switch = () => {
    const unitType = this.isMetric() ? 'imperial' : 'metric';
    this.props.onChangeUnitType(unitType);
  };

  checkLimits = (value, next) => {
    const { min, max } = this.props.limits;

    // We add 1 on max with inches to allow 6'11, as the max is 210cm and 6'11 is 211cm but 6'10 is only 208cm.
    const tweakedMax = this.isMetric() ? max : max + 1;

    if (value < min) return next(true);
    if (value > tweakedMax) return next(true);
    return next(false);
  };

  onChangeMetric = (value, skip = false) => {
    this.checkLimits(value, invalid => {
      const outOfLimits = skip ? this.state.outOfLimits : invalid;
      this.setState({ value, outOfLimits, ...getFeetInches(value) }, () => {
        this.props.onChange(value, !invalid);
      });
    });
  };

  onChangeFeet = (feet, skip = false) => {
    const onChange = () => {
      const value = getCm(feet, this.state.inches);
      this.checkLimits(value, invalid => {
        const outOfLimits = skip ? this.state.outOfLimits : invalid;
        this.setState({ feet, value, outOfLimits }, () => {
          this.props.onChange(value, !invalid);
        });
      });
    };

    // we need default inches to calculate the value in cm and see if the value is out of limits
    if (this.state.inches === undefined) {
      this.setState({ inches: 0 }, onChange);
    } else {
      onChange();
    }
  };

  onChangeInches = (inches, skip = false) => {
    const onChange = () => {
      const value = getCm(this.state.feet, inches);
      this.checkLimits(value, invalid => {
        const outOfLimits = skip ? this.state.outOfLimits : invalid;
        this.setState({ inches, value, outOfLimits }, () => {
          this.props.onChange(value, !invalid);
        });
      });
    };

    // we need default feet to calculate the value in cm and see if the value is out of limits
    if (this.state.feet === undefined) {
      this.setState({ feet: 0 }, onChange);
    } else {
      onChange();
    }
  };

  renderError = () => {
    let outputLimits, unitLabel;
    const { min, max } = this.props.limits;
    if (!this.state.outOfLimits) return null;

    if (this.isMetric()) {
      unitLabel = 'cm';
      outputLimits = this.props.limits;
    } else {
      unitLabel = '';
      outputLimits = {
        min: getFeetInches(min, true),
        max: getFeetInches(max, true)
      };
    }
    return <PhysicalInputError name="ERROR_INPUT_HEIGHT" values={{ ...outputLimits, unitLabel }} />;
  };

  render() {
    let inputs;
    const { min, max } = this.props.limits;

    if (this.isMetric()) {
      inputs = (
        <PhysicalInputContainer className="height-container">
          <IntegerInput
            className={this.props.theme.inputClassnames}
            required
            aria-label="height-centimeters-input"
            value={this.state.value}
            onChange={this.onChangeMetric}
            min={this.state.value ? min : this.props.defaultValue}
            max={this.state.value ? max : this.props.defaultValue}
          />
          <InputSelector left="cm" right="in" isLeft={this.isMetric()} onSwitch={this.switch} />
        </PhysicalInputContainer>
      );
    } else {
      const defaultValue = getFeetInches(this.props.defaultValue);
      const maxValue = getFeetInches(max);
      const minValue = getFeetInches(min);
      const minInches = this.state.feet === minValue.feet ? minValue.inches : 0;
      const maxInches = this.state.feet === maxValue.feet ? maxValue.inches : 12;
      inputs = (
        <PhysicalInputContainer className="height-container">
          <IntegerInput
            className={this.props.theme.inputClassnames}
            required
            aria-label="height-feet-input"
            value={this.state.feet}
            onChange={this.onChangeFeet}
            min={this.state.value ? minValue.feet : defaultValue.feet}
            max={this.state.value ? maxValue.feet : defaultValue.feet}
          />
          <Caption text="ft" style={{ margin: '0 5px' }} />
          <IntegerInput
            className={this.props.theme.inputClassnames}
            required
            aria-label="height-inches-input"
            value={this.state.inches}
            onChange={this.onChangeInches}
            min={this.state.value ? minInches : defaultValue.inches}
            max={this.state.value ? maxInches : defaultValue.inches}
          />
          <InputSelector left="cm" right="in" isLeft={this.isMetric()} onSwitch={this.switch} />
        </PhysicalInputContainer>
      );
    }
    return (
      <ErrorContainer>
        <PhysicalInputLine className="height-input-container">
          <PhysicalLabelContainer>
            <Caption name="HEIGHT" />
            <Required />
          </PhysicalLabelContainer>
          {inputs}
        </PhysicalInputLine>
        {this.renderError()}
      </ErrorContainer>
    );
  }
}

export default withTheme(HeightInput);
