import React from 'react';
import styled from 'styled-components/macro';
import { Caption } from 'shared_components';
import { BottomBackButton, InfoIcon } from './styles';

const HasInfoContainer = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HasInfoIcon = styled(InfoIcon)`
  margin: 1rem 0;
`;

const HasInfoText = styled(Caption)`
  max-width: 28rem;
  line-height: 1.5;
  text-align: center;
`;

const HasInfo = ({ modifyInfo, STEP }) => (
  <HasInfoContainer style={{ marginBottom: '1.5rem' }}>
    <HasInfoIcon style={{ margin: '1rem 0' }} />
    <HasInfoText name={`${STEP}_ALREADY_ENTERED_MAILING`} style={{ marginBottom: '1.5rem' }} />
    <BottomBackButton onClick={modifyInfo}>
      <Caption name={`MODIFY_MY_${STEP}`} />
    </BottomBackButton>
  </HasInfoContainer>
);

export default HasInfo;
