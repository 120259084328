import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Collapse } from '@blueprintjs/core';
import FaCaretDown from 'react-icons/lib/fa/caret-down';
import FaCaretUp from 'react-icons/lib/fa/caret-up';
import FaTrash from 'react-icons/lib/fa/trash';
import FaEdit from 'react-icons/lib/fa/edit';
import { colors, Caption, CaptionMedium } from 'shared_components';

import { Position as PositionIcon } from 'library/icons';

const Container = styled.div`
  margin-bottom: 15px;
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`;

const TopBar = styled.div`
  display: flex;
  padding: 20px 20px;
  justify-content: space-between;
  cursor: pointer;
  background-color: white;
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  &:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    width: 5px;
    background-color: ${colors.blue};
  }
`;

const PositionsDetails = styled(Collapse)`
  background-color: ${colors.lightGrey};
  border-top: 1px ${colors.lightGrey} solid;
`;

const SubsectionContainer = styled.div`
  padding: 10px 15px;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    width: 5px;
    background-color: ${colors.blue};
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 0.6;
  }
`;

const ProductContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 10px;
  border: 1px ${colors.lightGrey} solid;
  border-bottom: none;
  &:last-child {
    border-bottom: 1px ${colors.lightGrey} solid;
  }
`;

const ComposedName = styled.div`
  display: flex;
  align-items: flex-end;
`;

const DeleteIcon = styled(FaTrash)`
  cursor: pointer;
  &:hover {
    filter: brightness(80%);
  }
  margin-right: 12px;
`;

const EditIcon = styled(FaEdit)`
  cursor: pointer;
  &:hover {
    filter: brightness(80%);
  }
  margin-right: 12px;
`;

const Product = ({ product_name, client_product_id, quantity }) => (
  <ProductContainer>
    <ComposedName>
      <Caption text={product_name} />
      <Caption text={' - ' + client_product_id} color={colors.grey} style={{ marginLeft: 5 }} />
    </ComposedName>
    <span>
      <Caption name="QUANTITY" /> {quantity ? <Caption text={quantity} /> : <Caption name="FREE" />}
    </span>
  </ProductContainer>
);

const Subsection = ({ title, products }) => (
  <SubsectionContainer>
    <CaptionMedium name={title} forwardedAs="p" style={{ marginBottom: 8 }} />
    {products.map(p => (
      <Product key={p.id} {...p} />
    ))}
  </SubsectionContainer>
);

const Position = ({ position, onDelete, onClickEdit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const {
    name,
    client_position_id,
    items: { male, female }
  } = position;

  const Caret = isOpen ? FaCaretUp : FaCaretDown;

  return (
    <Container>
      <TopBar onClick={toggle}>
        <div>
          <ComposedName>
            <PositionIcon style={{ marginRight: 8 }} color={colors.grey} />
            <CaptionMedium text={name} />
            <Caption
              text={' - (id: ' + client_position_id + ')'}
              color={colors.grey}
              style={{ marginLeft: 5 }}
            />
          </ComposedName>
        </div>
        <div>
          <EditIcon
            color={colors.darkGrey}
            onClick={e => {
              e.stopPropagation();
              onClickEdit();
            }}
          />
          <DeleteIcon
            color={colors.darkRed}
            onClick={e => {
              e.stopPropagation();
              onDelete();
            }}
          />
          <Caret color={colors.grey} />
        </div>
      </TopBar>
      <PositionsDetails isOpen={isOpen}>
        {male && <Subsection title="MALE_CLOTHES" products={male} />}
        {female && <Subsection title="FEMALE_CLOTHES" products={female} />}
      </PositionsDetails>
    </Container>
  );
};

export default Position;
