import { FUNCTIONAL_COLUMNS } from 'services/exports';

const DEFAULT_STATE = { columnsToExport: FUNCTIONAL_COLUMNS };

export default function reducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (!action.payload) return state;
      return {
        ...state,
        ...action.payload.preferences
      };
    case 'CHANGE_EXPORT_COLUMNS':
      return { ...state, columnsToExport: action.columns };
    case 'LOGOUT':
      return DEFAULT_STATE;
    default:
      return state;
  }
}
