import React from 'react';
import styled from 'styled-components/macro';
import FaTrash from 'react-icons/lib/fa/trash';
import FaEdit from 'react-icons/lib/fa/edit';
import FaEye from 'react-icons/lib/fa/eye';
import { CaptionMedium, Caption } from 'shared_components';

import { RoleTag } from 'library/misc';
import { StandardTable, booleanFiltering, YesNoCell, CenteredCell } from 'library/tables';
import { colors } from 'shared_components';
import { Admin } from 'library/icons';

const StatusCell = ({ value }) => (
  <CenteredCell width="80%">
    <RoleTag isAdmin={value} fill />
  </CenteredCell>
);

const TextCell = ({ value }) => <Caption text={value} />;

const ActionContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  & svg {
    cursor: pointer;
  }
  color: ${colors.mainBlue};
`;

const Actions =
  ({ onDelete, onEdit, onDetails }) =>
  ({ original }) =>
    (
      <ActionContainer>
        <FaTrash color={colors.red} onClick={() => onDelete(original)} />
        <FaEdit onClick={() => onEdit(original)} />
        <FaEye onClick={() => onDetails(original)} />
      </ActionContainer>
    );

export const OperatorListing = ({ data, onDelete, onEdit, onDetails, loading }) => {
  const OPERATOR_COLUMNS = [
    {
      filterable: false,
      Cell: () => <Admin color="grey" />,
      width: 30,
      resizable: false,
      sortable: false
    },
    {
      Header: <CaptionMedium color="grey" name="FIRST_NAME" />,
      accessor: 'first_name',
      Cell: TextCell
    },
    {
      Header: <CaptionMedium color="grey" name="LAST_NAME" />,
      accessor: 'last_name',
      Cell: TextCell
    },
    {
      Header: <CaptionMedium color="grey" text="Email" />,
      accessor: 'email',
      Cell: TextCell
    },
    {
      Header: <CaptionMedium color="grey" name="DESCRIPTION" />,
      accessor: 'description',
      Cell: TextCell
    },
    {
      Header: <CaptionMedium color="grey" name="ROLE" />,
      accessor: 'is_admin',
      Cell: StatusCell,
      ...booleanFiltering('ADMIN', 'OPERATOR')
    },
    {
      Header: <CaptionMedium color="grey" name="REGISTERED" />,
      accessor: 'registered',
      Cell: YesNoCell,
      ...booleanFiltering()
    },
    {
      Header: <CaptionMedium color="grey" name="ACTIONS" />,
      Cell: Actions({ onDelete, onEdit, onDetails }),
      filterable: false,
      resizable: false,
      sortable: false
    }
  ];
  return <StandardTable data={data} columns={OPERATOR_COLUMNS} loading={loading} />;
};
