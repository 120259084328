import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { SLIDE_VERTICAL_DURATION_MS, SLIDE_DOWN_TOTAL_MS } from './Animations';
import { localizedString, capitalize } from '../services/i18n/translations';

import { Wrapper, PreviousStep, ListTitle } from './common';
import TableList from '../library/TableList';

const SizesTable = ({
  references,
  dispatch,
  submitNewReference,
  referenceBeingEdited,
  submitEditedReference,
  selectedBrand,
  selectedCategory
}) => {
  const onSelect = (sizeName, reference) => {
    const size = reference.sizes.find(s => s.name === sizeName);
    const toSubmit = {
      ...reference,
      size_name: size.name,
      size_id: size.id
    };

    if (referenceBeingEdited) {
      submitEditedReference(toSubmit);
    } else {
      submitNewReference(toSubmit);
    }

    return dispatch({ type: 'SELECT_REFERENCE' });
  };

  return (
    <>
      <Wrapper>
        <PreviousStep
          number="1"
          text={selectedBrand.name}
          onEdit={() => dispatch({ type: 'EDIT_BRAND' })}
          ariaLabel="edit brand choice"
          hideEdit={Boolean(referenceBeingEdited)}
        />
        <CSSTransition
          classNames="reference-slide-up"
          timeout={SLIDE_VERTICAL_DURATION_MS}
          in={true}
          appear={true}
          unmountOnExit={true}
        >
          <PreviousStep
            number="2"
            text={capitalize(localizedString(selectedCategory.translation_key))}
            onEdit={() => dispatch({ type: 'EDIT_CATEGORY' })}
            ariaLabel="edit category choice"
            hideEdit={Boolean(referenceBeingEdited)}
          />
        </CSSTransition>
        <CSSTransition
          classNames="reference-slide-down"
          timeout={SLIDE_DOWN_TOTAL_MS}
          in={true}
          appear={true}
          unmountOnExit={true}
        >
          <div>
            <ListTitle number="3" text="CHOOSE_SIZE" />
            {references.map(ref => (
              <TableList
                key={ref.id}
                items={ref.sizes.map(s => s.name)}
                onSelect={sizeName => onSelect(sizeName, ref)}
                previousItem={
                  referenceBeingEdited &&
                  ref.id === referenceBeingEdited.id &&
                  referenceBeingEdited.size_name
                }
              />
            ))}
          </div>
        </CSSTransition>
      </Wrapper>
    </>
  );
};

export default SizesTable;
