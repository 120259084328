import React from 'react';
import { Caption } from 'shared_components';
import styled from 'styled-components/macro';
import FaAngleLeft from 'react-icons/lib/fa/angle-left';
import { Link } from 'react-router-dom';
import { media } from 'shared_components';

import { colors } from 'shared_components';

import FaArrowRight from 'react-icons/lib/io/android-arrow-forward';
import { SmallText } from 'shared_components';

const BackContainer = styled.div`
  cursor: pointer;
  width: auto;
  align-self: flex-start; /* in case it's in a box, so that it doesnt go full width*/
  &:hover {
    filter: brightness(1.1);
  }
`;

export const GoBack = ({ onClick, ...rest }) => (
  <BackContainer onClick={onClick} {...rest}>
    <FaAngleLeft color={colors.grey} style={{ marginRight: 5 }} />
    <Caption name="GO_BACK" color="grey" />
  </BackContainer>
);

export const BackLink = ({ to, name }) => (
  <Link to={to}>
    <BackContainer>
      <FaAngleLeft color={colors.grey} style={{ marginRight: 5 }} />
      <Caption name={name || 'GO_BACK'} color="grey" />
    </BackContainer>
  </Link>
);

const SkipContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 35px;
  outline-offset: -5px;
  margin: auto;
  padding-left: 0.5rem;

  background-color: white;
  ${media.phone`
    margin-top: 0;
    height:40%;
  `}
  ${media.phoneLarge`

  `}
`;

export const Skip = ({ onClick, ...props }) => {
  const handleClick = e => {
    e.target.blur();
    onClick();
  };

  return (
    <SkipContainer onClick={handleClick} {...props}>
      <SmallText
        name="SKIP"
        color="black"
        className="link"
        back
        style={{ textDecorationLine: 'underline' }}
      />
      <FaArrowRight color={colors.blue} size={18} />
      {/* <FaArrowRight /> */}
    </SkipContainer>
  );
};
