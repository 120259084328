import { localizedString } from '../services/i18n/translations';

export const makeBrands = availableReferences => {
  return Object.keys(availableReferences)
    .map(brand => {
      const firstCategory = Object.keys(availableReferences[brand])[0];
      const firstReference = availableReferences[brand][firstCategory][0];
      return { name: brand, logoUrl: firstReference.brand_logo_url };
    })
    .sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
};

export const makeCategories = (availableReferences, brandName) => {
  return Object.keys(availableReferences[brandName])
    .map(category => {
      const firstReference = availableReferences[brandName][category][0];
      return firstReference.category;
    })
    .sort((a, b) => {
      const aTranslated = localizedString(a.translation_key).toLowerCase();
      const bTranslated = localizedString(b.translation_key).toLowerCase();
      return aTranslated.localeCompare(bTranslated);
    });
};
