import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { colors, CaptionMedium, SmallText } from 'shared_components';

import { LANGUAGES } from 'i18n';
import { useMountEffect, isNullOrUndefined } from 'services/utils';

import Item from './Item';

const HeaderCell = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.75rem;
`;

const DocumentationButton = styled.button`
  cursor: pointer;
  text-align: left;
  background-color: transparent;
  span {
    color: ${colors.mainBlue};
    text-decoration: underline;
    font-style: italic;
  }
`;

const TitleRow = ({ openSizeDialog, openComfortDialog }) => (
  <>
    <HeaderCell style={{ gridColumn: '1 / 2' }}>
      <CaptionMedium color="black" name="POSITION_PRODUCTS" />
    </HeaderCell>
    <HeaderCell style={{ gridColumn: '2 / 3' }}>
      <CaptionMedium color="black" name="POSITION_INFO_CONFORT_CHOICE" />
      <DocumentationButton onClick={openComfortDialog}>
        <SmallText name="WHAT_IS_IT" />
      </DocumentationButton>
    </HeaderCell>
    <HeaderCell style={{ gridColumn: '3 / 4' }}>
      <CaptionMedium color="black" name="POSITION_INFO_SIZE_ADVICE" />
      <DocumentationButton onClick={openSizeDialog}>
        <SmallText name="WHAT_IS_IT" />
      </DocumentationButton>
    </HeaderCell>
  </>
);

const Container = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  grid-template-columns: 2fr 1.25fr 3fr;
`;

const ItemsList = ({ items, products, onInputChange, openSizeDialog, openComfortDialog }) => {
  const productsMapping = useMemo(
    () =>
      products.reduce((mapping, p) => {
        mapping[p.id] = p;
        return mapping;
      }, {}),
    [products]
  );

  const updateSizeAdvice = (sizeAdvice, itemIndex) => {
    const newSizeAdvices = [...items[itemIndex].size_advices];
    const adviceIndex = items[itemIndex].size_advices.findIndex(
      a => a.language === sizeAdvice.language
    );
    if (adviceIndex === -1) {
      newSizeAdvices.push(sizeAdvice);
    } else {
      newSizeAdvices.splice(adviceIndex, 1, sizeAdvice);
    }
    onInputChange(newSizeAdvices, itemIndex, 'size_advices');
  };

  const updatePreferredFit = (value, index) => onInputChange(value, index, 'enable_preferred_fit');

  const setDeactivatedOrUnassociated = index =>
    onInputChange(true, index, 'is_deactivated_or_unassociated');

  // set default values on mount
  useMountEffect(() => {
    items.forEach((item, index) => {
      if (!productsMapping[item.product_id]) {
        // Can happen if the product has been unassociated or deactivated. We initialize the corresponding object so the set default values doesn't crash
        productsMapping[item.product_id] = {};
        // Flag used to display why the preferred fit is not editable ('body_part is not a top' or 'product is deactivated/unassociated')
        setDeactivatedOrUnassociated(index);
      }
      if (
        productsMapping[item.product_id].body_part === 'top' &&
        isNullOrUndefined(item.enable_preferred_fit)
      ) {
        updatePreferredFit(true, index);
      }
      if (productsMapping[item.product_id].body_part !== 'top') {
        updatePreferredFit(null, index);
      }

      // Set size advice for all available languages.
      LANGUAGES.forEach(lang => {
        const currentLangSizeAdvice = item.size_advices.find(sa => sa.language === lang);

        let content = '';
        if (currentLangSizeAdvice && currentLangSizeAdvice.content) {
          content = currentLangSizeAdvice.content;
        }

        updateSizeAdvice({ language: lang, content }, index);
      });
    });
  });

  return (
    <Container>
      <TitleRow openComfortDialog={openComfortDialog} openSizeDialog={openSizeDialog} />
      {items.map((item, index) => (
        <Item
          key={index}
          item={item}
          index={index}
          updatePreferredFit={updatePreferredFit}
          updateSizeAdvice={updateSizeAdvice}
        />
      ))}
    </Container>
  );
};

export default ItemsList;
