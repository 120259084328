import React from 'react';
import { withRouter } from 'react-router';
import { ColoredButton } from './index';

const LinkButton = props => {
  const {
    history,
    location,
    match,
    staticContext,
    to,
    onClick,
    buttonComponent,
    // ⬆ filtering out props that `button` doesn’t know what to do with.
    ...rest
  } = props;
  const ButtonComponent = buttonComponent || ColoredButton;
  return (
    <ButtonComponent
      {...rest} // `children` is just another prop!
      onClick={event => {
        onClick && onClick(event);
        history.push(to);
      }}
    />
  );
};

export default withRouter(LinkButton);
