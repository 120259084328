import React from 'react';
import { login } from 'services/auth';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { SmallText } from 'shared_components';

import AuthContainer from './AuthContainer';
import AuthForm from './AuthForm';

const Forgot = styled.div`
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translate(-50%, 0);
`;

class AuthScreen extends React.Component {
  state = {
    error: false
  };

  submitForm = async ({ email, password }) => {
    try {
      await login({ email, password });
    } catch (e) {
      this.setState({ error: true });
    }
  };
  render() {
    return (
      <AuthContainer title="LOGIN">
        <AuthForm onSubmit={this.submitForm} error={this.state.error} />
        <Forgot>
          <Link to="/forgot_password">
            <SmallText color="white" name="FORGOT_PASSWORD" />
          </Link>
        </Forgot>
      </AuthContainer>
    );
  }
}

export default AuthScreen;
