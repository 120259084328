import React from 'react';
import styled from 'styled-components/macro';
import { Switch } from '@blueprintjs/core';
import { colors, Caption, CaptionMedium } from 'shared_components';

import { isNullOrUndefined } from 'services/utils';
import { IntegerInput } from 'library/forms';
import { Table, ItemNameCell } from '../common';

const Line = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem 0.25rem 0;
  height: 2.5rem;
  max-height: 2.5rem;
`;

const SwitchContainer = styled.div`
  margin: 0 0.5rem;
  .bp3-control {
    margin: 0;
  }
`;

const QuantityCell = ({ index, quantity, onChange }) => {
  const isFixed = !isNullOrUndefined(quantity);
  return (
    <Line>
      <Caption name="FREE" color={isFixed ? colors.grey : colors.black} />
      <SwitchContainer>
        <Switch checked={isFixed} onChange={e => onChange(e.target.checked ? 1 : null, index)} />
      </SwitchContainer>
      <Caption name="FIXED" color={isFixed ? colors.black : colors.grey} />
      {isFixed && (
        <IntegerInput
          style={{ marginLeft: 15 }}
          min={1}
          initValue={quantity || 1}
          onValueChange={value => onChange(value, index)}
        />
      )}
    </Line>
  );
};

const QuantityTable = ({ items, onQuantityChange }) => {
  const COLUMNS = [
    {
      Header: <CaptionMedium color="black" name="POSITION_PRODUCTS" />,
      accessor: 'product_name',
      Cell: ItemNameCell,
      filterable: false
    },
    {
      Header: <CaptionMedium color="black" name="QUANTITY" />,
      accessor: 'quantity',
      Cell: ({ index, value }) => (
        <QuantityCell index={index} quantity={value} onChange={onQuantityChange} />
      ),
      filterable: false
    }
  ];
  return <Table data={items} columns={COLUMNS} shrinkable />;
};

export default QuantityTable;
