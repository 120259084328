import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { GlobalSlideAnimationsStyle } from '../styles/animations/GlobalSlideAnimationsStyle';

import { isMobile } from '../services/device';
import { getInputLimits, getInputDefaultValue } from './utils';
import { colors, media, isPhoneSmall, isPhoneMedium } from 'shared_components/styles';
import { GENDERS } from '../services/utils';

import GenderSwitch from './GenderSwitch';
import DesktopAgeInput from './desktop/AgeInput';
import DesktopHeightInput from './desktop/HeightInput';
import DesktopWeightInput from './desktop/WeightInput';
import MobileAgeInput from './mobile/AgeInput';
import MobileHeightInput from './mobile/HeightInput';
import MobileWeightInput from './mobile/WeightInput';
import BoobPicker from './boobs';
import Callout from '../library/Callout';
import Tooltip, { Position } from '../library/Tooltip';
import { Caption, CaptionMedium, SmallText } from '../text/index';
import { SimpleTextButton } from '../library/buttons';

const Container = styled.div`
  width: 100%;
  ${media.phone`
    padding: 0 1em;
  `}
`;

const CalloutContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin: 1em 0;
  ${media.phoneVerySmall`
    display: none;
  `};
  ${media.phoneMedium`
    margin-bottom: 1em;
  `};
  ${media.phoneLarge`
    margin-bottom: 2em;
  `};
`;

const ProgressBarNumber = styled.div`
  z-index: 2;
  height: 1rem;
  width: 1rem;
  border-radius: 50px;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  font-size: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SectionNumber = styled(ProgressBarNumber)`
  ${media.phone`
    display: flex;
  `}
  ${media.phoneSmall`
    position:absolute;
  `}
`;
const SectionNumberPad = styled(ProgressBarNumber)`
  /* display: none; */
  display: flex;
  margin: 1rem 0;

  ${media.phone`
    display: flex;
    margin: 1rem 0;
  `}
  ${media.phoneVerySmall`
    display: flex;
    margin: 0.2rem 0;
  `}
  ${media.phoneSmall`
    display: flex;
    margin: 0.2rem 0;
  `}
  ${media.phoneMedium`
    display: flex;
    margin: 0.2rem 0;
  `}
`;

/** Controlled component to input physical info */
class PhysicalInfoInputs extends React.PureComponent {
  state = {
    invalidFields: [],
    showTooltip: false
  };

  handleChange = field => (value, isValueValid) => {
    const { invalidFields } = this.state;
    const isFormValid = invalidFields.length === 0;
    const isFieldAlreadyInvalid = invalidFields.includes(field);
    let nextInvalidFields;

    const hasFieldValidityChange =
      (isValueValid && isFieldAlreadyInvalid) || (!isValueValid && !isFieldAlreadyInvalid);

    if (hasFieldValidityChange) {
      if (isValueValid) {
        nextInvalidFields = invalidFields.filter(f => f !== field);
      } else {
        nextInvalidFields = [...invalidFields, field];
      }

      this.setState({ invalidFields: nextInvalidFields }, () => {
        this.props.onChangeValue({ [field]: value }, nextInvalidFields.length === 0);
      });
    } else {
      this.props.onChangeValue({ [field]: value }, isFormValid);
    }
  };

  getLimits = field => getInputLimits(field, this.props.forChild);
  getDefaultValue = field => getInputDefaultValue(field, this.props.forChild);

  hasValidAgeHeightAndWeight = () => {
    const isInLimits = (limits, value) => value >= limits.min && value <= limits.max;
    const { age, height, weight } = this.props;
    return (
      isInLimits(this.getLimits('age'), age) &&
      isInLimits(this.getLimits('height'), height) &&
      isInLimits(this.getLimits('weight'), weight)
    );
  };

  // a bit redondant but avoids a LOT of inputs rerenders
  changeGender = this.handleChange('gender');
  changeAge = this.handleChange('age');
  changeWeight = this.handleChange('weight');
  changeHeight = this.handleChange('height');
  changeBra = (value, validated) => this.handleChange('bra')(value, validated);

  componentDidUpdate = (_prevProps, prevState) => {
    if (
      this.props.sendTooltipDisplayedEvent &&
      this.state.showTooltip !== prevState.showTooltip &&
      this.state.showTooltip
    )
      this.props.sendTooltipDisplayedEvent();
  };

  calloutButtonClicked = e => {
    e.preventDefault();
    this.setState(prevState => ({ showTooltip: !prevState.showTooltip }));
  };

  render = () => {
    const {
      theme,
      gender,
      age,
      height,
      weight,
      bra,
      language,
      displayGenderSelect,
      forChild,
      lengthUnitType,
      onChangeLengthUnitType,
      weightUnitType,
      onChangeWeightUnitType,
      useFullScreenBoobPicker,
      unavailableGenders = []
    } = this.props;
    const mobile = isMobile();
    const AgeInput = mobile ? MobileAgeInput : DesktopAgeInput;
    const HeightInput = mobile ? MobileHeightInput : DesktopHeightInput;
    const WeightInput = mobile ? MobileWeightInput : DesktopWeightInput;

    return (
      <>
        <GlobalSlideAnimationsStyle />
        <ThemeProvider theme={theme}>
          <CalloutContainer>
            <Callout
              intent="info"
              backgroundColor={colors.lighterGrey}
              iconColor={theme.physicalInfoInputColor || colors.black}
            >
              <div style={{ paddingBottom: '0.375rem' }}>
                <SmallText name="PHYSICAL_INFO_CALLOUT_START" />{' '}
                <SimpleTextButton
                  onClick={this.calloutButtonClicked}
                  name="WHY"
                  style={{ display: 'inline-block' }}
                  active={this.state.showTooltip}
                />{' '}
                <SmallText name="PHYSICAL_INFO_CALLOUT_END" style={{ display: 'block' }} />
              </div>
              <Tooltip
                opened={this.state.showTooltip}
                close={() => this.setState({ showTooltip: false })}
                style={{ left: 0, zIndex: 3 }}
                borderColor={theme.primaryColor}
                position={isPhoneSmall() || isPhoneMedium() ? Position.BOTTOM : Position.TOP}
              >
                <CaptionMedium name="PHYSICAL_INFO_TOOLTIP_START" />
                <div>
                  <Caption name="PHYSICAL_INFO_TOOLTIP_MIDDLE" />
                </div>
                <div style={{ marginTop: '0.75rem' }}>
                  <Caption name="PHYSICAL_INFO_TOOLTIP_END" />
                </div>
              </Tooltip>
            </Callout>
          </CalloutContainer>
          <Container className="physical-info-inputs-container">
            <SectionNumber>1</SectionNumber>
            {displayGenderSelect && (
              <GenderSwitch
                gender={gender}
                onChange={this.changeGender}
                unavailableGenders={unavailableGenders}
              />
            )}
            <SectionNumberPad>2</SectionNumberPad>
            <AgeInput
              language={language}
              age={age}
              onChange={this.changeAge}
              limits={this.getLimits('age')}
              defaultValue={this.getDefaultValue('age')}
            />
            <HeightInput
              language={language}
              height={height}
              onChange={this.changeHeight}
              limits={this.getLimits('height')}
              defaultValue={this.getDefaultValue('height')}
              unitType={lengthUnitType}
              onChangeUnitType={onChangeLengthUnitType}
            />
            <WeightInput
              language={language}
              weight={weight}
              onChange={this.changeWeight}
              limits={this.getLimits('weight')}
              defaultValue={this.getDefaultValue('weight')}
              unitType={weightUnitType}
              onChangeUnitType={onChangeWeightUnitType}
            />
            {!forChild && (displayGenderSelect || gender === 'female') && (
              <BoobPicker
                bra={bra}
                onChange={this.changeBra}
                language={language}
                gender={gender}
                highlightBraButton={this.hasValidAgeHeightAndWeight()}
                useFullScreenBoobPicker={useFullScreenBoobPicker}
                isBraInputMandatory={false}
              />
            )}
          </Container>
        </ThemeProvider>
      </>
    );
  };
}

PhysicalInfoInputs.defaultProps = {
  useFullScreenBoobPicker: true,
  isBraInputMandatory: false,
  forChild: false,
  theme: {
    primaryColor: colors.black
  }
};

PhysicalInfoInputs.propTypes = {
  onChangeValue: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  displayGenderSelect: PropTypes.bool.isRequired,
  theme: PropTypes.object,
  gender: PropTypes.oneOf(GENDERS.map(gender => gender.name)),
  age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bra: PropTypes.object.isRequired,
  forChild: PropTypes.bool.isRequired,
  lengthUnitType: PropTypes.oneOf(['metric', 'imperial']).isRequired,
  onChangeLengthUnitType: PropTypes.func.isRequired,
  weightUnitType: PropTypes.oneOf(['metric', 'imperial']).isRequired,
  onChangeWeightUnitType: PropTypes.func.isRequired,
  useFullScreenBoobPicker: PropTypes.bool,
  isBraInputMandatory: PropTypes.bool,
  sendTooltipDisplayedEvent: PropTypes.func
};

export default PhysicalInfoInputs;
