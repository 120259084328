import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { Intent } from '@blueprintjs/core';
import { Body } from 'shared_components';

import api from 'api';
import { showToast } from 'services/toaster';
import { Layout } from './common';
import Name from './name/Name';
import Products from './products/Products';
import Quantity from './quantity/Quantity';
import Info from './info/Info';

const PositionFunnel = ({
  positionId,
  clientPositionId,
  name,
  femaleItems,
  maleItems,
  history,
  match,
  resetPositionStore
}) => {
  const campaignId = match.params.campaign_id;

  const goToCampaignScreen = () => {
    resetPositionStore();
    history.push(`/campaigns/${campaignId}/product_sets`);
  };

  const submitPosition = async () => {
    const data = {
      name,
      client_position_id: clientPositionId,
      items: {
        male: maleItems,
        female: femaleItems
      }
    };
    try {
      // A position without an id is not in the db yet.
      if (positionId) {
        await api.positions.edit({ position_id: positionId, data });
      } else {
        await api.positions.create({ campaign_id: campaignId, data });
      }
      goToCampaignScreen();
    } catch (error) {
      showToast({
        message: <Body name="ERROR" color="white" />,
        intent: Intent.DANGER
      });
    }
  };

  return (
    <Switch>
      <Route
        exact
        path="/position/:campaign_id/name"
        render={routeProps => (
          <Layout progress={0} onClose={goToCampaignScreen}>
            <Name {...routeProps} />
          </Layout>
        )}
      />
      <Route
        exact
        path="/position/:campaign_id/clothes"
        render={routeProps => (
          <Layout progress={1} onClose={goToCampaignScreen}>
            <Products {...routeProps} />
          </Layout>
        )}
      />
      <Route
        exact
        path="/position/:campaign_id/quantity"
        render={routeProps => (
          <Layout progress={2} onClose={goToCampaignScreen}>
            <Quantity {...routeProps} />
          </Layout>
        )}
      />
      <Route
        exact
        path="/position/:campaign_id/info"
        render={routeProps => (
          <Layout progress={3} onClose={goToCampaignScreen}>
            <Info submitPosition={submitPosition} {...routeProps} />
          </Layout>
        )}
      />
    </Switch>
  );
};

const mapper = state => ({
  ...state.position
});

const dispatcher = dispatch => ({
  resetPositionStore: () => dispatch({ type: 'RESET_POSITION ' })
});

export default connect(mapper, dispatcher)(PositionFunnel);
