import React from 'react';
import styled from 'styled-components/macro';
import { Formik, FieldArray } from 'formik';
import { ErrorText, Title, Body, Caption, SmallTextMedium } from 'shared_components';

import api from 'api';
import OperatorRoleChoice from './OperatorRoleChoice';
import { colors } from 'shared_components';
import Async from 'library/Async';
import { SubmitButton } from 'library/buttons';
import { Form, Field } from 'library/forms';
import { StandardTable } from 'library/tables';

const TextCell = ({ value }) => <Caption text={value} />;

const CheckBoxCell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CompaniesTable = ({ values, setValues, companies, disabled }) => {
  const getColumns = helpers => [
    {
      Header: <SmallTextMedium color="grey" name="COMPANY" />,
      accessor: 'name',
      Cell: TextCell,
      filterable: true,
      width: 200
    },
    {
      Header: (
        <CheckBoxCell>
          <input
            name="company_ids"
            type="checkbox"
            checked={values.company_ids.length === companies.length}
            onChange={e => {
              if (e.target.checked) setValues({ ...values, company_ids: companies.map(c => c.id) });
              else setValues({ ...values, company_ids: [] });
            }}
          />
        </CheckBoxCell>
      ),
      sortable: false,
      accessor: 'id',
      Cell: ({ value }) => (
        <CheckBoxCell>
          <input
            name="company_ids"
            type="checkbox"
            value={value}
            checked={values.company_ids.includes(value)}
            onChange={e => {
              if (e.target.checked) helpers.push(value);
              else helpers.remove(values.company_ids.indexOf(value));
            }}
          />
        </CheckBoxCell>
      ),
      width: 50,
      filterable: false
    }
  ];
  return (
    <FieldArray
      name="company_ids"
      render={helpers => (
        <React.Fragment>
          <StandardTable
            data={companies}
            columns={getColumns(helpers)}
            shrinkable
            loading={disabled}
            defaultPageSize={10}
            loadingText={<Body name="ADMIN_EXPLANATION" />}
          />
        </React.Fragment>
      )}
    />
  );
};

const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;
const Pane = styled.div`
  margin: 5px;
`;

const Content = styled.div`
  padding: 15px;
`;

const Header = styled.div`
  padding: 8px 12px;
  background-color: ${colors.blue};
`;

const OperatorFormRender =
  ({ companies, isSelf }) =>
  ({ errors, touched, isSubmitting, values, setValues }) =>
    (
      <Form errors={errors} touched={touched} values={values}>
        <InputsContainer>
          <Pane>
            <Header>
              <Caption color="white" name="INFO" />
            </Header>
            <Content>
              <Field type="text" name="first_name" label="FIRST_NAME" required />
              <Field type="text" name="last_name" label="LAST_NAME" required />
              <Field type="email" name="email" label="EMAIL" required />
              <Field type="textarea" name="description" label="DESCRIPTION" />
              <Field component={OperatorRoleChoice} name="is_admin" disabled={isSelf} />
            </Content>
          </Pane>
          <Pane>
            <Header>
              <Caption name="PERMISSIONS" color="white" />
            </Header>
            <Content>
              <CompaniesTable
                disabled={values.is_admin}
                values={values}
                setValues={setValues}
                companies={companies}
              />
            </Content>
          </Pane>
        </InputsContainer>

        <SubmitButton disabled={isSubmitting} style={{ width: 200, alignSelf: 'flex-end' }} />
      </Form>
    );

const validate = ({ email, first_name, last_name }) => {
  const errors = {};
  if (!email || !email.includes('@')) {
    errors.email = 'INVALID_EMAIL';
  }
  if (!first_name || first_name.length === 0) {
    errors.first_name = 'MISSING_FIELD';
  }
  if (!last_name || last_name.length === 0) {
    errors.last_name = 'MISSING_FIELD';
  }
  return errors;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

class OperatorForm extends React.Component {
  fetchCompanies = () => api.companies.list();
  render() {
    const { onSubmit, error, operator, isSelf } = this.props;
    const initialValues = {
      email: '',
      first_name: '',
      last_name: '',
      description: '',
      is_admin: false,
      company_ids: [],
      ...operator
    };
    return (
      <Container>
        <Title
          name={operator ? 'OPERATOR_EDIT' : 'OPERATOR_CREATION'}
          style={{ marginBottom: 30 }}
        />
        <Async
          fetchProps={this.fetchCompanies}
          render={companies => (
            <Formik
              initialValues={initialValues}
              onSubmit={async (values, actions) => {
                await onSubmit(values);
                actions.setSubmitting(false);
              }}
              validate={values => validate(values)}
              render={OperatorFormRender({ companies, isSelf })}
            />
          )}
        />
        {error && <ErrorText name="ERROR_OCURRED" style={{ marginTop: 10 }} />}
      </Container>
    );
  }
}

export default OperatorForm;
