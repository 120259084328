import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { SLIDE_VERTICAL_DURATION_MS, SLIDE_DOWN_TOTAL_MS } from './Animations';
import { colors } from 'shared_components/styles';
import MdSearch from 'react-icons/lib/md/search';
import { Wrapper, ContainerListItem, ListItem, ITEMS_HEIGHT } from './common';
import { localizedString } from '../services/i18n/translations';
import { CaptionMedium } from '../text';
import { isMobile } from '../services/device';

const SearchBoxContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1em;
`;

const SearchIcon = styled(MdSearch)`
  position: absolute;
  top: 30%;
  left: 7%;
  color: ${colors.coal};
`;

const SearchBox = styled.input`
  height: ${ITEMS_HEIGHT};
  font-size: 0.75em;
  padding: 12px 12px 12px 56px;
  border: 1px solid ${colors.grey};
  border-radius: 5px;
  color: ${colors.coal};
  width: 100%;
  -webkit-appearance: textfield;

  ::placeholder {
    color: ${colors.grey};
    font-style: italic;
  }

  :focus {
    outline: none;
    border: 1px solid ${props => props.theme.primaryColor};
  }

  // Safari mobile zooms when focusing an input with a font-size less 16px, we don't want that.
  // The query below targets Safari on mobile.
  @media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) and (max-width: 420px) {
    font-size: 16px;

    ::placeholder {
      font-size: 0.75rem;
    }
  }
`;

const SectionTitle = styled.div`
  margin: 0.5em 1rem;
`;

const BrandSelector = ({ brands, mostUsedBrandsNames, focusedBrandName, dispatch }) => {
  const [searchText, setSearchText] = useState('');
  const searchInput = useRef(null);
  // const skipSection = skipSection;
  useEffect(() => {
    if (!isMobile()) searchInput.current.focus();
  }, [searchInput]);

  const filteredBrands = brands.filter(
    ({ name }) => name.toLowerCase().indexOf(searchText.toLocaleLowerCase()) !== -1
  );

  const focusedBrand = brands.filter(({ name }) => name === focusedBrandName)[0];
  const showFocusedBrand = focusedBrand && searchText.length === 0;

  const mostUsedBrands = brands.filter(({ name }) => mostUsedBrandsNames.indexOf(name) !== -1);
  const showMostUsedBrands = mostUsedBrands.length > 0 && searchText.length === 0;

  const selectBrand = selectedBrand => dispatch({ type: 'SELECT_BRAND', selectedBrand });

  return (
    <>
      <Wrapper>
        <CSSTransition
          classNames="reference-slide-up"
          timeout={SLIDE_VERTICAL_DURATION_MS}
          in={true}
          appear={true}
          unmountOnExit={true}
        >
          <SearchBoxContainer>
            <SearchIcon />
            <SearchBox
              ref={searchInput}
              type="text"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              placeholder={localizedString('SELECT_BRAND')}
            />
          </SearchBoxContainer>
        </CSSTransition>
        <CSSTransition
          classNames="reference-slide-down"
          timeout={SLIDE_DOWN_TOTAL_MS}
          in={true}
          appear={true}
          unmountOnExit={true}
        >
          <div>
            {showFocusedBrand && (
              <ContainerListItem>
                <ListItem
                  text={focusedBrand.name}
                  imageUrl={focusedBrand.logoUrl}
                  onClick={() => selectBrand(focusedBrand)}
                />
              </ContainerListItem>
            )}
            {showMostUsedBrands && (
              <>
                <SectionTitle>
                  <CaptionMedium name="MOST_USED_REFERENCES" />
                </SectionTitle>
                <ContainerListItem>
                  {mostUsedBrands.map(({ name, logoUrl }) => (
                    <ListItem
                      key={name}
                      text={name}
                      imageUrl={logoUrl}
                      onClick={() => selectBrand({ name, logoUrl })}
                    />
                  ))}
                </ContainerListItem>
              </>
            )}
            {showMostUsedBrands && (
              <SectionTitle style={{ marginTop: '1rem' }}>
                <CaptionMedium name="ALL_REFERENCES" />
              </SectionTitle>
            )}
            <ContainerListItem>
              {filteredBrands.map(({ name, logoUrl }) => (
                <ListItem
                  key={name}
                  text={name}
                  imageUrl={logoUrl}
                  onClick={() => selectBrand({ name, logoUrl })}
                />
              ))}
            </ContainerListItem>
          </div>
        </CSSTransition>
      </Wrapper>
    </>
  );
};

export default BrandSelector;
