import React from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router';

import media from 'shared_components/styles/media';
import Progress from 'library/Progress';

const PROGRESS_ITEMS = [
  'FUNNEL_EXPLANATION_INFO_TITLE',
  'FUNNEL_EXPLANATION_MORPHOLOGY_TITLE',
  'FUNNEL_EXPLANATION_REFERENCES_TITLE',
  'FUNNEL_EXPLANATION_SIZE_TITLE'
];

const ProgressRowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1em;
  ${media.phone`
    margin: 1.25rem 0.625em 0 0.625rem;
  `}
  ${media.phoneVerySmall`
    margin: 0rem 0rem 0rem 0rem;
  `}
  ${media.phoneSmall`
    margin: 0rem 0rem 0rem 0rem;
  `}
  ${media.phoneMedium`
    margin: min(0.5rem,1vh) 0rem 0rem 0rem;
  `}
  ${media.phoneLarge`
    margin: 0.5rem 0em 0 0rem;
  `}
`;

const ProgressRow = React.memo(
  withRouter(({ location, currentProgress }) => {
    return (
      <ProgressRowContainer>
        <Progress items={PROGRESS_ITEMS} progress={currentProgress} />
      </ProgressRowContainer>
    );
  })
);

export default ProgressRow;
