export const MEASUREMENTS = [
  'Chest',
  'Underchest',
  'Waist',
  'Hips',
  'Height',
  'Weight',
  'ShoulderSpan',
  'ArmLength',
  'LegContour',
  'Inseam',
  'InseamFloor',
  'FootLength'
  // 'WaistNavel',
  // 'Neck',
  // 'NeckDiameter',
  // 'NeckBase',
  // 'NeckBaseDiameter',
  // 'Calves',
  // 'CrotchLengthHalf',
  // 'ForearmCircumference',
  // 'HalfShoulder',
  // 'HalfShoulderArm',
  // 'HeadCircumferenceTop',
  // 'HeadCircumferenceBottom',
  // 'MiddleBack',
  // 'Belt',
  // 'BeltHeight',
  // 'ArmCircumference',
  // 'HeightTorso',
  // Remove as long as we don't have a client with a sizing chart
  // with shoe width. At this point, we want to put it back and
  // think how we handle the references who don't have width
  // (all of them at the moment)
  // 'FootWidth'
];

// We use this variable to determine if we show the comfort on Sizerpro or not.
// Use for the hack where we assign accesories, hat, gloves, ... to a sizing chart
// with measurements not in this array so we can still display them.
export const MEASUREMENTS_IN_COMFORT_SVGS = [
  'Height',
  'Hips',
  'Chest',
  'Underchest',
  'Waist',
  'Inseam',
  'FootLength'
];

export const MEASUREMENTS_TEXT_MAPPING = {
  Height: 'HEIGHT_SC',
  Weight: 'WEIGHT_SC',
  Hips: 'HIPS_SC',
  Chest: 'CHEST_SC',
  Underchest: 'UNDERCHEST_SC',
  Waist: 'WAIST_SC',
  LegContour: 'LEGCONTOUR_SC',
  Inseam: 'INSEAM_SC',
  InseamFloor: 'INSEAMFLOOR_SC',
  ShoulderSpan: 'SHOULDERSPAN_SC',
  ArmLength: 'ARMLENGTH_SC',
  FootLength: 'FOOTLENGTH_SC'
};

const isRange = rel => {
  if (rel.length !== 2) return false;
  return (rel[0] === '<' && rel[1] === '>') || (rel[0] === '>' && rel[1] === '<');
};

export const extractMeasurements = data => {
  const measurements = new Set();
  data.size_constraints.forEach(sc =>
    sc.constraints.forEach(c => measurements.add(c.measurement_name))
  );
  // To keep the order define in MEASUREMENTS
  return MEASUREMENTS.filter(m => measurements.has(m));
};

const getInputType = (measurement, data) => {
  /* we iterate over all sizes until we find a constraint set which is valid
    if we can't, we consider the whole measurement is invalid */
  const scs = data.size_constraints;
  for (let i = 0; i < scs.length; i++) {
    const constraints = scs[i].constraints.filter(c => c.measurement_name === measurement);
    if (constraints.length === 1 && constraints[0].relation === '=') {
      return 'EXACT';
    }
    if (isRange(constraints.map(c => c.relation))) {
      return 'RANGE';
    }
  }
  return 'INVALID';
};

const getMeasurementSchema = data => {
  const measurements = extractMeasurements(data);
  return measurements.map(measurement => [measurement, getInputType(measurement, data)]);
};

const extractRange = constraints => {
  if (constraints.length !== 2) return null;
  const rel = constraints.map(c => c.relation);
  if (!isRange(rel)) {
    return null;
  }
  let min;
  let max;
  if (constraints[0].relation === '<') {
    min = constraints[1].value;
    max = constraints[0].value;
  } else {
    min = constraints[0].value;
    max = constraints[1].value;
  }
  return { min, max };
};

const normalizeConstraints = (constraints, type) => {
  switch (type) {
    case 'EXACT':
      if (constraints.length === 1 && constraints[0].relation === '=') {
        return { value: constraints[0].value };
      } else {
        return null;
      }
    case 'RANGE':
      return extractRange(constraints);
    default:
      return null;
  }
};

const createTable = (data, schema) => {
  const rows = [];
  schema.forEach(([measurement, type]) => {
    const row = [];
    data.size_constraints.forEach(({ constraints }) => {
      const filteredConstraints = constraints.filter(
        ({ measurement_name }) => measurement_name === measurement
      );
      row.push(normalizeConstraints(filteredConstraints, type));
    });
    rows.push(row);
  });
  return rows;
};

export const normalizeData = data => {
  const { size_constraints, comment, length_unit, unique_name, primary_measurements } = data;
  const measurementSchema = getMeasurementSchema(data);
  const measurements = measurementSchema.map(u => u[0]);
  const inputTypes = measurementSchema.map(u => u[1]);
  const sizes = size_constraints.map(x => x.size_name);
  const table = createTable(data, measurementSchema);
  const availableMeasurements = MEASUREMENTS.filter(m => !measurements.includes(m));
  return {
    sizes,
    measurements,
    inputTypes,
    table,
    availableMeasurements,
    primary_measurements,
    comment: comment || '',
    length_unit,
    unique_name
  };
};

const LENGTH_UNITS = ['cm', 'in'];

export const convertToInches = cm => Math.round(cm * 0.3937 * 100) / 100;
export const convertToCm = inches => Math.round((inches / 0.3937) * 100) / 100;

export const convertTableToUnit = (table, unit) => {
  if (!LENGTH_UNITS.includes(unit)) {
    throw new Error(`unit must be one of ${LENGTH_UNITS}, got ${unit}`);
  }
  const convert = unit === 'cm' ? convertToCm : convertToInches;
  return table.map(row =>
    row.map(cell =>
      Object.keys(cell).reduce((acc, key) => ({ ...acc, [key]: convert(cell[key]) }), {})
    )
  );
};

// Function that convert EXACT constraints to RANGE constraints.
export const exactToRangeConstraints = constraints => {
  // This function is complex because it needs to handle special edge cases for multidimensional sizing chart.
  // An example of such sizing chart can be found in "Fitle" sizing charts, male, id: 8860
  const n = constraints.length;
  const row = [];
  if (n === 1) {
    row.push({ min: constraints[0].value, max: constraints[0].value });
  } else {
    constraints.forEach(() => row.push({}));
    const lower = (3 * constraints[0].value - constraints[1].value) / 2.0;
    const upper = (3 * constraints[n - 1].value - constraints[n - 2].value) / 2.0;
    row[0].min = lower;
    row[n - 1].max = upper;
    for (let i = 0; i < n; i++) {
      // Min calculation
      if (i > 0) {
        if (constraints[i - 1].value <= constraints[i].value || i === n - 1) {
          row[i].min = (constraints[i].value + constraints[i - 1].value) / 2.0;
        } else {
          // Multidimensional sizing chart, constraints are not increasing.
          row[i].min = (3 * constraints[i].value - constraints[i + 1].value) / 2.0;
        }
      }
      // Max calculation
      if (i < n - 1) {
        if (constraints[i].value <= constraints[i + 1].value || i === 0) {
          row[i].max = (constraints[i].value + constraints[i + 1].value) / 2.0;
        } else {
          // Multidimensional sizing chart, constraints are not increasing.
          row[i].max = (3 * constraints[i].value - constraints[i - 1].value) / 2.0;
        }
      }

      // For multidimensional sizing chart, multiple decreasing constraints can cause the min and max to be reversed.
      // reordering them here.
      let temp = row[i].min;
      row[i].min = Math.min(row[i].min, row[i].max);
      row[i].max = Math.max(temp, row[i].max);
    }
  }
  return row;
};

// Function that convert RANGE constraints to EXACT constraints.
export const rangeToExactConstraints = constraints => {
  return constraints.map(({ min, max }) => ({ value: (min + max) / 2 }));
};

export const computeOutOfBounds = (constraints, userMeasurement, inputType) => {
  const outOfBounds = { min: false, max: false };
  if (inputType === 'EXACT') {
    constraints = exactToRangeConstraints(constraints);
  }
  // Here we don't suppose that the tighter constraint is at the index 0 and the looser at the index n - 1. Even if this is almost always the case.
  // Weird multi-dimensional sizing chart can sometimes not satisfy this. This function will be memoized anyway.
  if (userMeasurement < Math.min(...constraints.map(constraint => constraint.min)))
    outOfBounds.min = true;
  if (userMeasurement > Math.max(...constraints.map(constraint => constraint.max)))
    outOfBounds.max = true;
  return outOfBounds;
};
