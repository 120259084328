import React from 'react';
import styled from 'styled-components/macro';
import { Body, Caption } from 'shared_components';

import { colors } from 'shared_components';
import { RoleTag, LinkToDoc } from 'library/misc';

const RoleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const RoleCheckboxes = styled.div`
  max-width: 60%;
  display: flex;
  flex-direction: column;
`;

const RoleCheckBoxContainer = styled.div`
  width: 100%;
  display: flex;
`;

const RoleCheckBox = styled.input.attrs(() => ({ type: 'radio' }))`
  margin-top: 10px;
  cursor: pointer;
`;

const RoleText = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
`;

const RoleLabel = styled.div`
  margin-top: 10px;
  display: flex;
`;

const Doc = styled(LinkToDoc)`
  margin-top: 20px;
  align-self: flex-end;
  font-size: 0.625rem;
  span {
    font-size: 0.625rem;
  }
`;

const OperatorRoleChoice = ({ field, form }) => {
  const onClickAdmin = () => {
    if (field.value) return null;
    form.setFieldValue('is_admin', true);
  };

  const onClickOperator = () => {
    if (!field.value) return null;
    form.setFieldValue('is_admin', false);
  };

  return (
    <RoleContainer>
      <RoleLabel>
        <Caption name="ROLE" />
        <Body color={colors.blue} text="*" style={{ marginLeft: 4 }} />
      </RoleLabel>
      <RoleCheckboxes>
        <RoleCheckBoxContainer>
          {/* We need that div container here otherwise the radio clickable area is super super small on Safari */}
          <div>
            <RoleCheckBox
              aria-label="if checked the user will be set to administrator"
              onChange={onClickAdmin}
              checked={field.value}
            />
          </div>
          <RoleText>
            <div>
              <RoleTag large isAdmin />
            </div>
            <Caption style={{ margin: '5px 0 20px 0' }} color="grey" name="ADMIN_EXPLANATION" />
          </RoleText>
        </RoleCheckBoxContainer>
        <RoleCheckBoxContainer>
          {/* We need that div container here otherwise the radio clickable area is super super small on Safari */}
          <div>
            <RoleCheckBox
              aria-label="if checked the user will be set to operator"
              onChange={onClickOperator}
              checked={!field.value}
            />
          </div>
          <RoleText>
            <div>
              <RoleTag large />
            </div>
            <Caption style={{ marginTop: 5 }} color="grey" name="OPERATOR_EXPLANATION" />
          </RoleText>
        </RoleCheckBoxContainer>
        <Doc
          textName="MORE_INFO_ADMIN"
          link="https://docs.sizerpro.com/prise-en-main-de-sizerpro/creer-un-compte#ajouter-un-nouvel-operateur"
        />
      </RoleCheckboxes>
    </RoleContainer>
  );
};

export default OperatorRoleChoice;
