import React from 'react';
import { Toaster, Position } from '@blueprintjs/core';
import { Provider } from 'react-redux';
import store from 'store';
import styled from 'styled-components';

export const AppToaster = Toaster.create({ position: Position.BOTTOM });

// Set message text color to white. (necessary since using shared_components texts that default to black.)
const WhiteTextMessage = styled.div`
  span {
    color: white;
  }
`;

export const showToast = ({ message, ...rest }, key) => {
  return AppToaster.show(
    {
      message: (
        <Provider store={store}>
          <WhiteTextMessage>{message}</WhiteTextMessage>
        </Provider>
      ),
      ...rest
    },
    key
  );
};
