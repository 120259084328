import React, { useState } from 'react';
import { UserMeasurementsAndSizingChart, InputSelector } from 'shared_components';
import { sizingChartTheme } from 'styles/themes';

const UserInfo = ({ recoSizeName, userMeasurements, sizingChartInfo }) => {
  const [unitSystem, setUnitSystem] = useState('metric');
  const onUnitSwitch = () => setUnitSystem(unitSystem === 'metric' ? 'imperial' : 'metric');

  return (
    <>
      <InputSelector
        left="cm"
        right="in"
        isLeft={unitSystem === 'metric'}
        onSwitch={onUnitSwitch}
      />
      <UserMeasurementsAndSizingChart
        userMeasurements={userMeasurements}
        sizingChartInfo={sizingChartInfo}
        recommendedSizeName={recoSizeName}
        unitSystem={unitSystem}
        theme={sizingChartTheme}
      />
    </>
  );
};

export default UserInfo;
