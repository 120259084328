import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import FaPlus from 'react-icons/lib/fa/plus';
import { connect } from 'react-redux';
import { Caption, Body, CaptionMedium } from 'shared_components';

import api from 'api';
import Async from 'library/Async';
import { OptionsRow, Content } from 'library/layout';
import { ColoredButton, HugeBottomButton } from 'library/buttons';
import { Dialog, CancellableDialog, NotificationDialog } from 'library/dialogs';

import PositionListing from './PositionListing';

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.5;
`;

const EditWarningDialog = ({
  campaignId,
  history,
  isOpen,
  onClose,
  pendingEdit,
  addPositionToStore
}) => {
  const goToEditPositionInfo = () => {
    addPositionToStore(pendingEdit);
    history.push(`/position/${campaignId}/info`, { onlyEditInfo: true });
  };
  return (
    <Dialog style={{ width: 550 }} isOpen={isOpen} onClose={onClose}>
      {pendingEdit && (
        <ColumnContainer>
          <CaptionMedium
            color="mainBlue"
            name="POSITION_HAS_RESULTS"
            values={{ employees_number: pendingEdit.number_of_associated_employees }}
          />
          <CaptionMedium
            style={{ marginBottom: 20 }}
            color="mainBlue"
            name="ONLY_MODIFY_POSITION_INFO"
          />
          <Caption style={{ marginBottom: 60 }} name="STILL_WANT_MODIFY_POSITION" />
          <ColoredButton
            onClick={goToEditPositionInfo}
            style={{ alignSelf: 'flex-end', minWidth: 200 }}
          >
            <Caption color="white" name="I_UNDERSTAND" />
          </ColoredButton>
        </ColumnContainer>
      )}
    </Dialog>
  );
};

const DeleteDialog = ({ isOpen, onSubmit, onClose, position }) => (
  <CancellableDialog isOpen={isOpen} danger onSubmit={onSubmit} onClose={onClose}>
    {position && (
      <Body name="CONFIRM_DELETE_POSITION" values={{ position_name: position.position_name }} />
    )}
  </CancellableDialog>
);

const CannotDeleteDialog = ({ isOpen, onClose, position }) => (
  <NotificationDialog isOpen={isOpen} onClose={onClose}>
    {position && (
      <Body
        name="CANNOT_DELETE_PRODUCT_SET"
        values={{
          position_name: position.position_name,
          employees_number: position.number_of_associated_employees
        }}
      />
    )}
  </NotificationDialog>
);

const Positions = ({ match, history, resetPositionStore, addPositionToStore }) => {
  const async = useRef(null);
  const campaignId = match.params.campaign_id;

  const [pendingEdit, setPendingEdit] = useState(null);
  const [showEditWarning, setShowEditWarning] = useState(true);

  useEffect(() => {
    if (pendingEdit === null) {
      setShowEditWarning(false);
    } else {
      if (pendingEdit.number_of_employees_with_results) {
        setShowEditWarning(true);
      } else {
        addPositionToStore(pendingEdit);
        history.push(`/position/${campaignId}/name`);
      }
    }
  }, [campaignId, history, pendingEdit, addPositionToStore]);

  const [pendingDelete, setPendingDelete] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [showCannotDelete, setShowCannotDelete] = useState(false);

  useEffect(() => {
    if (pendingDelete === null) {
      setShowDelete(false);
      setShowCannotDelete(false);
    } else {
      if (pendingDelete.number_of_associated_employees === 0) {
        setShowDelete(true);
      } else {
        setShowCannotDelete(true);
      }
    }
  }, [pendingDelete]);

  const fetchPositions = () => api.positions.list({ campaign_id: campaignId });
  const createPosition = () => {
    resetPositionStore();
    history.push(`/position/${campaignId}/name`);
  };
  const deletePosition = async () => {
    await api.positions.delete({
      id: pendingDelete.id
    });
    setPendingDelete(null);
    async.current.refresh();
  };

  return (
    <Content>
      <Async fetchProps={fetchPositions} ref={async}>
        {positions => (
          <>
            {positions.length === 0 ? (
              <HugeBottomButton
                style={{ maxWidth: '30rem', margin: 'auto' }}
                name="CREATE_POSITION"
                onClick={createPosition}
              />
            ) : (
              <>
                <OptionsRow>
                  <ColoredButton onClick={createPosition}>
                    <FaPlus color="white" />
                    <Caption name="CREATE_POSITION" color="white" />
                  </ColoredButton>
                </OptionsRow>
                <PositionListing
                  positions={positions}
                  onDelete={pendingDelete => setPendingDelete(pendingDelete)}
                  onClickEdit={pendingEdit => setPendingEdit(pendingEdit)}
                />
              </>
            )}
            <EditWarningDialog
              campaignId={campaignId}
              history={history}
              isOpen={showEditWarning}
              pendingEdit={pendingEdit}
              onClose={() => setPendingEdit(null)}
              addPositionToStore={addPositionToStore}
            />
            <DeleteDialog
              isOpen={showDelete}
              position={pendingDelete}
              onSubmit={deletePosition}
              onClose={() => setPendingDelete(null)}
            />
            <CannotDeleteDialog
              isOpen={showCannotDelete}
              position={pendingDelete}
              onClose={() => setPendingDelete(null)}
            />
          </>
        )}
      </Async>
    </Content>
  );
};

const dispatcher = dispatch => ({
  resetPositionStore: () => dispatch({ type: 'RESET_POSITION' }),
  addPositionToStore: position =>
    dispatch({
      type: 'UPDATE_POSITION',
      ...{
        positionId: position.id,
        name: position.name,
        clientPositionId: position.client_position_id,
        femaleItems: position.items.female || [],
        maleItems: position.items.male || []
      }
    })
});

export default connect(null, dispatcher)(Positions);
