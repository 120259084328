export const getObserver = (ref, options, handler) => {
  let observer = ref.current;
  if (observer !== null) {
    return observer;
  }
  let newObserver = new IntersectionObserver(handler, options);
  ref.current = newObserver;
  return newObserver;
};

export const objectFromArray = (array, initialValue) => {
  const object = {};
  array.forEach(key => (object[key] = initialValue));
  return object;
};
