import React, { useState, useEffect } from 'react';
import { SvgProxy } from 'react-svgmt';
import { setText, setVisibility } from './logic';

export const ProxyContainer = (id, visible, key, SVG_PATH_ELEMENTS) => {
  const onElementSelected = el => {
    el.classList.add('indicator');
    setVisibility(el, visible);
    SVG_PATH_ELEMENTS[id] = el;
  };

  return <SvgProxy selector={id} onElementSelected={onElementSelected} key={key} />;
};

export const ProxyText = ({
  id,
  language,
  text,
  heightOffset,
  isInseamOrHeight,
  isTitle,
  fontSize
}) => {
  const [originalElement, setOriginalElement] = useState(undefined);
  const [parent, setParent] = useState(undefined);

  // when language is changed, need to update svg
  useEffect(() => {
    setText(text, parent, originalElement, heightOffset, isInseamOrHeight, isTitle);
  }, [language, text, parent, originalElement, heightOffset, isInseamOrHeight, isTitle]);

  const onElementSelected = el => {
    setOriginalElement(el);
    setParent(el.parentElement);
  };

  return (
    <SvgProxy
      selector={id}
      onElementSelected={onElementSelected}
      font-size={fontSize ? fontSize : '0.75rem'}
    />
  );
};
