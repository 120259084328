import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { colors, SmallText, SmallTextMedium } from 'shared_components';

import { media } from 'shared_components';

const DESKTOP_BARWIDTH_MAX = '200px';
const DESKTOP_BARWIDTH_VW = '22vw';
const MOBILE_BARWIDTH_PX = '22vw'; // with that current width it allows for 4 items on mobile.
const TEXT_BOX_HEIGHT_PX = 30; // with that current height it allows for 2 lines text.

const ProgressContainer = styled.div`
  width: 90%;
  max-width: 750px;
  display: flex;
  justify-content: center;
  margin-bottom: ${TEXT_BOX_HEIGHT_PX + 10}px;

  ${media.phone`
    margin-bottom: 0;
    width:90%;
  `}
  ${media.phoneLarge`
    width:90%
  `}
`;

const CERCLE_BORDER = 3; //px

const ProgressCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: ${CERCLE_BORDER}px ${colors.lightGrey} solid;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ state }) => (state === 'todo' ? colors.lightGrey : colors.mainBlue)};
  position: relative;
  margin-left: ${props => (props.isFirst ? 0 : `min(${props.barWidthPx},${DESKTOP_BARWIDTH_MAX})`)};

  ${media.phone`
      margin-left: ${props => (props.isFirst ? 0 : `${MOBILE_BARWIDTH_PX}`)};
  `}
  ${media.phoneVerySmall`
    width: 20px;
    height: 20px;
    border-radius:50%
  `}
  ${media.phoneSmall`
    width: 20px;
    height: 20px;
    border-radius:50%
  `}
  ${media.phoneMedium`
    width: 20px;
    height:20px;
    border-radius:50%
  `}
  ${media.phoneLarge`
    width: 20px;
    height: 20px;
    border-radius:50%
  `}

  ${props =>
    props.disabled &&
    css`
      border-color: ${colors.red};
      background-color: ${colors.red};
    `}
`;

const Circle = styled.div`
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: ${colors.mainBlue};
  ${media.phoneVerySmall`
    width: 14px;
    height: 14px;
    border-radius:50%
  `}
  ${media.phoneSmall`
    width: 14px;
    height: 14px;
    border-radius:50%
  `}
  ${media.phoneMedium`
    width: 14px;
    height: 14px;
    border-radius:50%
  `}
  ${media.phoneLarge`
    width: 14px;
    height: 14px;
    border-radius:50%
  `}
`;

const ProgressBar = styled.div`
  display: ${props => (props.isFirst ? 'none' : 'flex')};
  height: 12px;
  width: min(${DESKTOP_BARWIDTH_VW}, ${DESKTOP_BARWIDTH_MAX});
  left: max(
    calc(-${DESKTOP_BARWIDTH_VW} - ${CERCLE_BORDER}px),
    calc(-${DESKTOP_BARWIDTH_MAX} - ${CERCLE_BORDER}px)
  );
  position: absolute;
  align-items: center;
  background-color: ${({ state }) => (state === 'todo' ? 'transparent' : colors.lightGrey)};

  ${props =>
    props.disabled &&
    css`
      background-color: ${colors.lighterGrey};
    `}

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 6px;
    background-color: ${({ state }) => (state === 'todo' ? colors.lightGrey : colors.mainBlue)};

    ${props =>
      props.disabled &&
      css`
        background-color: ${colors.lighterGrey};
      `}
  }

  ${media.phone`
    width: ${MOBILE_BARWIDTH_PX};
    left: calc(-${MOBILE_BARWIDTH_PX} - ${CERCLE_BORDER}px);
  `}
`;

const ProgressText = styled.div`
  width: min(${DESKTOP_BARWIDTH_VW}, ${DESKTOP_BARWIDTH_MAX});
  position: absolute;
  height: ${TEXT_BOX_HEIGHT_PX}px;
  bottom: -${TEXT_BOX_HEIGHT_PX + 10}px;
  width: ${props => props.width}px;
  text-align: center;

  ${media.phone`
    display: none;
  `}
  ${media.screenMedium`
    display: none;
  `}

  ${props =>
    props.disabled &&
    css`
      span {
        color: ${colors.lightBlueGrey};
      }
    `}
`;

// This component takes an array of text locales to display the items of the progress bar.
// If you don't want any texts just use empty strings in the array (ex: ['', '', '']).
// All texts are hidden on mobile.
export const Progress = React.memo(
  ({ items, disableUpToIndex, progress, className, barWidthPx }) => (
    <ProgressContainer className={className}>
      {items.map((text, index) => {
        const isDisabled = index <= disableUpToIndex;
        let state = 'todo';
        let circleContent = null;
        let Text = SmallText;
        circleContent = <Circle style={{ background: colors.lightGrey }} />;

        if (index < progress) {
          state = 'done';
          circleContent = <Circle style={{ background: colors.mainBlue }} />;
        } else if (index === progress) {
          state = 'doing';
          circleContent = <Circle />;
          Text = SmallTextMedium;
        }

        if (isDisabled) {
          circleContent = <Circle style={{ background: colors.lightGrey }} />;
        }

        return (
          <ProgressCircle
            key={index}
            isFirst={index === 0}
            state={state}
            barWidthPx={barWidthPx}
            disabled={isDisabled}
          >
            {circleContent}
            <ProgressBar
              disabled={index <= disableUpToIndex + 1} // We want to disable the bar if the item before is disabled
              isFirst={index === 0}
              state={state}
              barWidthPx={barWidthPx}
            />
            {text.length > 0 && (
              <ProgressText disabled={isDisabled}>
                <Text name={text} />
              </ProgressText>
            )}
          </ProgressCircle>
        );
      })}
    </ProgressContainer>
  )
);

Progress.defaultProps = {
  barWidthPx: DESKTOP_BARWIDTH_VW,
  disableUpToIndex: -1
};

Progress.propTypes = {
  className: PropTypes.string,
  barWidthPx: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  progress: PropTypes.number.isRequired,
  disableUpToIndex: PropTypes.number
};

export default Progress;
