import React from 'react';
import styled from 'styled-components';
import { ComfortOneSize } from 'shared_components';
import { isIE } from 'shared_components';

import { media } from 'shared_components';
import Comparison from './Comparison';
import { NoRecoCallout } from '../common';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.phone`
    flex-direction: column;
  `}
  ${media.phoneMedium`
    padding-top: 0.2rem;
  `}
  ${media.phoneLarge`
    padding-top: 0.2rem;
  `}
`;

const Comfort = ({
  recoUnchanged,
  selectSize,
  recoSizeName,
  chosenSizeName,
  activeFit,
  language,
  gender,
  sizes,
  clothType,
  sizingChartSizes,
  showSizingChart
}) => {
  if (sizes.length === 0) {
    return (
      <Container>
        <NoRecoCallout />
      </Container>
    );
  } else if (sizes.length === 1 || isIE()) {
    return (
      <Container>
        <ComfortOneSize
          size={sizes[0]}
          clothType={clothType}
          gender={gender}
          language={language}
          sizingChartHasOneSize={sizingChartSizes.length === 1}
        />
      </Container>
    );
  } else {
    return (
      <Container>
        <Comparison
          recoUnchanged={recoUnchanged}
          selectSize={selectSize}
          recoSizeName={recoSizeName}
          chosenSizeName={chosenSizeName}
          activeFit={activeFit}
          sizes={sizes}
          clothType={clothType}
          gender={gender}
          language={language}
          showSizingChart={showSizingChart}
        />
      </Container>
    );
  }
};

export default Comfort;
