import React from 'react';
import styled from 'styled-components';

import { colors } from 'shared_components/styles';

const checkboxFocused = (e, name) => {
  document.getElementById(`addFocusOn${name}`).style.boxShadow = `0 0 0 3px ${colors.grey}`;
};

const checkboxBlurred = (e, name) => {
  document.getElementById(`addFocusOn${name}`).style.boxShadow = 'none';
};

// the onFocus and onBlur are here to apply a "focus" style on the input label
const HiddenCheckbox = props => (
  <input
    style={{ cursor: 'pointer', height: `${props.size}em`, width: `${props.size}em` }}
    type="checkbox"
    onFocus={event => checkboxFocused(event, props.name)}
    onBlur={event => checkboxBlurred(event, props.name)}
    {...props}
  />
);

const CheckboxStyle = styled.label.attrs(props => ({
  id: `addFocusOn${props.name}` // used to apply focus style, works with the HiddenInput in common.js
}))`
  height: ${props => props.size}em;
  width: ${props => props.size}em;
  background-color: ${colors.white};
  border-radius: 50%;
  border: 1px solid ${props => (props.isChecked ? props.theme.primaryColor : colors.grey)};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
  }

  :hover {
    border: 1px solid ${props => (props.isChecked ? props.theme.primaryColor : colors.darkGrey)};
  }
`;

const CheckboxFill = styled.span`
  cursor: pointer;
  border-radius: 50%;
  height: ${props => (props.expanded ? `${props.size - 0.25}em` : '0em')};
  width: ${props => (props.expanded ? `${props.size - 0.25}em` : '0em')};
  opacity: ${props => (props.expanded ? 1 : 0)};
  background: ${props => props.theme.primaryColor};
  border: 0.2em solid ${props => props.theme.backgroundColor || colors.white};
  transition: all 0.25s ease-in-out;
`;

const Checkbox = ({ value, name, labelId, isChecked, onChange, size = 1.25 }) => {
  const handleCheck = e => {
    e.target.blur();
    onChange(e);
  };

  return (
    <CheckboxStyle name={name} isChecked={isChecked} size={size}>
      <CheckboxFill expanded={isChecked} size={size} />
      <HiddenCheckbox
        aria-labelledby={labelId}
        name={name}
        value={value}
        checked={isChecked}
        onChange={handleCheck}
        size={size}
      />
    </CheckboxStyle>
  );
};

export default Checkbox;
