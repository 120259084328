import React from 'react';
import styled, { css } from 'styled-components';

import { Caption } from '../text';
import { colors } from 'shared_components/styles';
import { localizedString } from '../services/i18n/translations';

const Button = styled.button`
  background-color: ${props => {
    if (props.theme.primaryColor) {
      return props.theme.primaryColor;
    } else if (props.theme.colors) {
      return props.theme.colors.primary;
    }
  }};
  border-width: 0px;
  height: 35px;
  border-radius: ${props => {
    if (props.theme.validateButton && props.theme.validateButton.borderRadius) {
      return props.theme.validateButton.borderRadius;
    } else {
      return '0.25em;';
    }
  }};
  box-shadow: ${props => {
    if (props.theme.validateButton && props.theme.validateButton.dropShadow) {
      return props.theme.validateButton.dropShadow;
    } else {
      return '0 3px 6px 0 rgba(0,0,0,0.15);';
    }
  }};
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: brightness(0.95);
  }

  &:active {
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${colors.grey};
  }

  &:disabled {
    box-shadow: none;
    background-color: ${colors.grey};
    cursor: not-allowed;

    &:hover {
      filter: none;
    }
  }

  ${props =>
    props.round &&
    css`
      width: 50px;
      height: 50px;
      border-radius: 50%;
    `};
`;

const GenericButton = ({
  style,
  children,
  onClick,
  className = '',
  disabled = false,
  round = false
}) => {
  return (
    <Button
      type="button"
      className={className}
      disabled={disabled}
      onClick={onClick}
      round={round}
      style={style}
    >
      {children}
    </Button>
  );
};

export const PrimaryButton = ({
  style,
  name,
  values,
  text,
  onClick,
  className,
  disabled,
  ...props
}) => {
  const handleClick = e => {
    e.target.blur();
    onClick(e);
  };
  return (
    <GenericButton
      disabled={disabled}
      style={style}
      onClick={handleClick}
      className={className}
      {...props}
    >
      <Caption color="white" name={name} values={values} text={text} />
    </GenericButton>
  );
};

const Secondary = styled(GenericButton)`
  background-color: transparent;
  border: 1.5px solid
    ${props => {
      if (props.theme.primaryColor) {
        return props.theme.primaryColor;
      } else if (props.theme.colors) {
        return props.theme.colors.primary;
      }
    }};
  span {
    color: ${props => {
      if (props.theme.primaryColor) {
        return props.theme.primaryColor;
      } else if (props.theme.colors) {
        return props.theme.colors.primary;
      }
    }};
  }

  :disabled {
    background-color: transparent;
    border-color: ${colors.grey};

    span {
      color: ${colors.grey};
    }
  }
`;

export const SecondaryButton = ({
  style,
  name,
  values,
  text,
  onClick,
  className,
  disabled,
  ...props
}) => (
  <Secondary disabled={disabled} style={style} onClick={onClick} className={className} {...props}>
    <Caption name={name} values={values} text={text} />
  </Secondary>
);

export const StyledButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  text-decoration: underline;
  font-size: 0.625rem;
  font-weight: 600;
  color: ${props => (props.active ? colors.grey : colors.black)};

  &:focus {
    outline: none;
  }
`;

export const SimpleTextButton = ({ onClick, name, active }) => {
  return (
    <StyledButton onClick={onClick} active={active}>
      {localizedString(name)}
    </StyledButton>
  );
};
