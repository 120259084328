import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MdChevronLeft from 'react-icons/lib/md/chevron-left';

import TableList from '../../library/TableList';
import FlagSelector from '../../library/FlagSelector';
import { SecondaryButton } from '../../library/buttons';
import { Caption, Title } from '../../text';
import { getFlatPossibilities, BOOB_REGIONS } from './utils';

import { colors, media } from 'shared_components/styles';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FullScreenContainer = styled(Container)`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  z-index: 4;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;

const RegionSelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackButton = styled.button`
  background-color: transparent;
  position: absolute;
  left: 20px;
  cursor: pointer;
  color: ${colors.coal};

  :hover {
    opacity: 0.5;
  }
`;

const Content = styled.div`
  flex: 1;
  overflow: auto;
  width: 100%;
  padding: 0 1.25em;
  & > * {
    margin: 10px 0px;
  }

  ${media.bigScreen`
    padding: 0 3.125em;
  `}
`;

const ButtonContainer = styled.div`
  overflow: auto;
  align-self: stretch;
  padding: 1em 1.25em;

  ${media.bigScreen`
    padding: 1em 3.125em;
  `}
`;

const FormContent = ({ region, setRegion, bands, band, setBand, cups, cup, setCup, submit }) => (
  <>
    <RegionSelectContainer className="bra-region-selection">
      <Caption name="REGION_SELECTION" style={{ marginRight: '0.25rem' }} />
      <FlagSelector
        renderArrow
        style={{ zIndex: 5 }}
        value={region}
        options={BOOB_REGIONS.map(value => ({ value }))}
        selectFlag={({ value }) => setRegion(value)}
      />
    </RegionSelectContainer>
    <Content className="bra-tables-container">
      <TableList
        items={bands}
        previousItem={band}
        onSelect={newBand => setBand(newBand)}
        name="BAND_SIZE"
      />
      <TableList
        items={cups}
        previousItem={cup}
        disabled={!band}
        onSelect={newCup => setCup(newCup)}
        name="CUP_SIZE"
      />
    </Content>
    <ButtonContainer>
      <SecondaryButton
        disabled={!(cup && band)}
        onClick={() => submit({ region, band, cup })}
        name="VALIDATE_BRAS_INFOS"
      />
    </ButtonContainer>
  </>
);

const BoobPickerForm = props => {
  const [region, setRegion] = useState(props.region);
  const [band, setBand] = useState(props.band);
  const [cup, setCup] = useState(props.cup);

  const { bands, cups } = getFlatPossibilities(region);

  useEffect(() => {
    if (bands.indexOf(band) < 0) {
      setBand(null);
      setCup(null);
    } else if (cups.indexOf(cup) < 0) {
      setCup(null);
    }
  }, [band, cup, cups, bands]);

  if (props.isFullScreen) {
    return (
      <FullScreenContainer className="bra-form-container">
        <Header className="bra-form-title">
          <BackButton type="button" aria-label="close bra select" onClick={props.onClose}>
            <MdChevronLeft size={30} />
          </BackButton>
          <Title name="SIZE_SELECTION" />
        </Header>
        <FormContent
          band={band}
          bands={bands}
          cup={cup}
          cups={cups}
          region={region}
          setBand={setBand}
          setCup={setCup}
          setRegion={setRegion}
          submit={props.onSubmit}
        />
      </FullScreenContainer>
    );
  } else {
    return (
      <Container>
        <FormContent
          band={band}
          bands={bands}
          cup={cup}
          cups={cups}
          region={region}
          setBand={setBand}
          setCup={setCup}
          setRegion={setRegion}
          submit={props.onSubmit}
        />
      </Container>
    );
  }
};

export default BoobPickerForm;
