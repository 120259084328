import React from 'react';
import styled from 'styled-components';
import ArrowLeft from 'react-icons/lib/go/triangle-left';
import ArrowRight from 'react-icons/lib/go/triangle-right';

import { media } from 'shared_components/styles';
import colors from '../../styles/colors';
import { Caption, SmallText } from '../../text/index';

const ButtonContainer = styled.div`
  flex: 0 0 50%;
  display: flex;
  ${({ disabled }) => disabled && 'opacity: 0.3; button { cursor: inherit; }'};
`;

const ButtonLabel = styled.div`
  ${media.phone`font-size:0.8rem`};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ right }) =>
    right ? 'margin-right: 10px; align-items: flex-end;text-align: right' : 'margin-left: 10px;'};
`;

const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 2.5rem;
  height: 1.875rem;
  border-radius: 1rem;
  background-color: ${colors.white};
  border: ${({ disabled }) => (disabled ? 'none' : `1px solid ${colors.black}`)};
  box-shadow: 0px 2px 5px 0px ${colors.black} 10.5%;
  transition: box-shadow 100ms ease-out;
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};

  :hover {
    box-shadow: none;
  }

  :focus {
    outline: none;
  }
`;

const MainLabel = styled(Caption)`
  font-weight: bold;
`;

const SecondaryLabel = styled(SmallText)`
  padding-left: ${({ right }) => (right ? '5px' : '0')};
  padding-right: ${({ left }) => (left ? '5px' : '0')};
  ::before {
    content: '(';
  }
  ::after {
    content: ')';
  }
`;

const Container = styled.div`
  margin: 1rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.phoneVerySmall`
    display:none;
  `}
  ${media.phoneSmall`
    margin: 0.6rem 0;
  `}
`;

const Buttons = ({ handleClickToScroll, disabled, disableLeft, disableRight }) => {
  return (
    <Container>
      <ButtonContainer disabled={disableLeft}>
        <IconContainer disabled={disabled} onClick={() => handleClickToScroll('back')}>
          <ArrowLeft />
        </IconContainer>
        <ButtonLabel>
          <MainLabel name="MORE_BELLY" />
          <SecondaryLabel name="LESS_CHEST" left />
        </ButtonLabel>
      </ButtonContainer>
      <ButtonContainer disabled={disableRight}>
        <ButtonLabel right>
          <MainLabel name="MORE_CHEST" />
          <SecondaryLabel name="LESS_BELLY" right />
        </ButtonLabel>
        <IconContainer disabled={disabled} onClick={() => handleClickToScroll()}>
          <ArrowRight />
        </IconContainer>
      </ButtonContainer>
    </Container>
  );
};

export default Buttons;
