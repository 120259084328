import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import colors from '../../styles/colors';
import { isIE } from '../../services/device';

import OneSize from './OneSize';
import Comparison from './Comparison';

/*
  COMFORT CLOTHES COMPONENT
  It displays the comfort based on an array of sizes and their comfort. If there are several sizes you can select one.
  Expected sizes input:
    [
      {
        "comfort": {
          "Chest": "ADJUSTED",
          "Waist": "LOOSE"
        },
        "name": "L"
      },
      {
        "comfort": {
          "Chest": "TIGHT",
          "Waist": "ADJUSTED"
        },
        "comparison": "TIGHTER",
        "name": "M"
      },
      {
        "comfort": {
          "Chest": "LOOSE",
          "Waist": "TOO_LOOSE"
        },
        "comparison": "LOOSER",
        "name": "XL"
      }
    ]
  The first size is the recommended size.
  If there is only one size, show the OneSize component.
  If there are two or three sizes:
    1. Determine the placement of the sizes with their "comparison" attribute.
    2. Display the comparison
    3. Allow for a size selection
*/

const ComfortClothes = ({
  language,
  gender,
  sizes,
  selectSize,
  selectedSize,
  clothType,
  theme,
  photoUrl,
  sizingChartHasOneSize,
  showSizingChart,
  sendTooltipDisplayedEvent
}) => (
  <ThemeProvider theme={theme}>
    {
      // The IE check is here b/c replacing the text in the svg does not work on IE so far.
      // We want the feature to go live asap so instead of spending to much time on IE we just show OneSize on IE.
      // It "works" in OneSize b/c the svgs still have the texts. So on IE we have the right comfort texts displayed, they are just always in english.
      // TODO: fix the svg text replacement on IE.
      sizes.length === 1 || isIE() ? (
        <OneSize
          size={sizes[0]}
          clothType={clothType}
          gender={gender}
          language={language}
          photoUrl={photoUrl}
          sizingChartHasOneSize={sizingChartHasOneSize}
        />
      ) : (
        <Comparison
          sizes={sizes}
          clothType={clothType}
          gender={gender}
          language={language}
          selectSize={selectSize}
          selectedSize={selectedSize}
          photoUrl={photoUrl}
          showSizingChart={showSizingChart}
          sendTooltipDisplayedEvent={sendTooltipDisplayedEvent}
        />
      )
    }
  </ThemeProvider>
);

ComfortClothes.defaultProps = {
  theme: {
    primaryColor: colors.black,
    backgroundColor: colors.lightBlueGrey
  }
};

ComfortClothes.propTypes = {
  language: PropTypes.string.isRequired,
  gender: PropTypes.oneOf(['male', 'female']),
  sizes: PropTypes.array.isRequired,
  selectSize: PropTypes.func.isRequired,
  selectedSize: PropTypes.object.isRequired,
  clothType: PropTypes.oneOf(['top', 'bottom', 'full', 'bosom']),
  theme: PropTypes.object,
  sizingChartHasOneSize: PropTypes.bool,
  sendTooltipDisplayedEvent: PropTypes.func
};

export default ComfortClothes;
