import React, { useState, useRef } from 'react';
import Async from 'library/Async';
import { Body, Caption } from 'shared_components';
import { Intent } from '@blueprintjs/core';
import styled from 'styled-components/macro';

import api from 'api';
import { OptionsRow, Content, Card } from 'library/layout';
import { Dialog, CancellableDialog } from 'library/dialogs';
import { AddPerson, AddGroup, Trash } from 'library/icons';
import EmployeeListing from './EmployeeListing';
import { ColoredButton, DangerButton } from 'library/buttons';
import EmployeeCreationForm from './EmployeeCreationForm';
import EmployeesImportForm from 'library/EmployeesImportForm';
import EditModal from './EditModal';
import { useSelectManyTableRows } from 'library/tables';
import { showToast } from 'services/toaster';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const NoEmployees = ({ openCreate, openImport }) => (
  <Container>
    <Body color="grey" name="NO_EMPLOYEES_IN_COMPANY" />
    <Row>
      <ColoredButton onClick={openCreate}>
        <AddPerson color="white" />
        <Caption name="EMPLOYEE_CREATION" color="white" />
      </ColoredButton>
      <Body style={{ paddingLeft: 10, paddingRight: 10 }} color="grey" name="OR" />
      <ColoredButton onClick={openImport}>
        <AddGroup color="white" />
        <Caption name="EMPLOYEES_IMPORT" color="white" />
      </ColoredButton>
    </Row>
  </Container>
);

const Employees = ({ match }) => {
  const companyId = match.params.company_id;

  const [employees, setEmployees] = useState([]);
  const fetchEmployees = async () => {
    const res = await api.employees.list({ company_id: companyId });
    setEmployees(res.sort((eA, eB) => eB.id - eA.id));
  };

  const asyncComponent = useRef(null);
  const refresh = () => asyncComponent.current && asyncComponent.current.refresh();

  const { selected, checkboxesColumn } = useSelectManyTableRows(employees);

  const [creationModalOpen, setCreationModalOpen] = useState(false);
  const toggleCreateModal = () => setCreationModalOpen(!creationModalOpen);
  const createEmployee = async params => {
    toggleCreateModal();
    try {
      await api.employees.create({ company_id: companyId, ...params });
      await refresh();
    } catch (e) {
      if (e.status === 409) {
        showToast({
          message: <Body name="EMPLOYEE_CONFLICT" />,
          intent: Intent.DANGER
        });
      } else {
        showToast({
          message: <Body name="UNKNOWN_ERROR" />,
          intent: Intent.DANGER
        });
      }
    }
  };

  const [importModalOpen, setImportModalOpen] = useState(false);
  const toggleImportModal = () => setImportModalOpen(!importModalOpen);

  const [deleteEmployeesModalOpen, setDeleteEmployeesModalOpen] = useState(false);
  const toggleDeleteEmployeesModal = () => setDeleteEmployeesModalOpen(!deleteEmployeesModalOpen);

  const [pendingDelete, setPendingDelete] = useState(null);
  const showDeleteModal = employee => setPendingDelete(employee);
  const closeDeleteModal = () => setPendingDelete(null);
  const deleteEmployee = async () => {
    closeDeleteModal();
    await api.employees.delete({
      employee_id: pendingDelete.id,
      company_id: companyId
    });
    await refresh();
  };

  const [pendingEdit, setPendingEdit] = useState(null);
  const showEditModal = employee => setPendingEdit(employee);
  const closeEditModal = () => setPendingEdit(false);
  const editEmployee = async params => {
    closeEditModal();
    await api.employees.update({
      ...params,
      company_id: companyId
    });
    await refresh();
  };

  const massDeleteEmployees = async () => {
    const employee_ids = selected.map(e => e.id);
    await api.employees.massDelete({ employee_ids });
    await refresh();
    setDeleteEmployeesModalOpen(false);
  };

  return (
    <Content>
      <Async fetchProps={fetchEmployees} ref={asyncComponent}>
        {() => (
          <>
            {employees.length ? (
              <>
                <OptionsRow>
                  <ColoredButton onClick={toggleCreateModal}>
                    <AddPerson color="white" />
                    <Caption name="EMPLOYEE_CREATION" color="white" />
                  </ColoredButton>
                  <ColoredButton onClick={toggleImportModal}>
                    <AddGroup color="white" />
                    <Caption name="EMPLOYEES_IMPORT" color="white" />
                  </ColoredButton>
                  <DangerButton
                    onClick={toggleDeleteEmployeesModal}
                    disabled={selected.length === 0}
                  >
                    <Trash color="white" />
                    <Caption name="DELETE_MASS_EMPLOYEES" color="white" />
                  </DangerButton>
                </OptionsRow>
                <Card>
                  <EmployeeListing
                    checkboxesColumn={checkboxesColumn}
                    data={employees}
                    onEdit={showEditModal}
                    onDelete={showDeleteModal}
                  />
                </Card>
              </>
            ) : (
              <NoEmployees openCreate={toggleCreateModal} openImport={toggleImportModal} />
            )}
            <Dialog isOpen={creationModalOpen} onClose={toggleCreateModal}>
              <EmployeeCreationForm
                client_employee_ids={employees.map(({ client_employee_id }) => client_employee_id)}
                onSubmit={createEmployee}
              />
            </Dialog>
            <Dialog width={'40rem'} isOpen={importModalOpen} onClose={toggleImportModal}>
              <EmployeesImportForm
                companyId={companyId}
                onSuccess={refresh}
                onFileSelected={toggleImportModal}
              />
            </Dialog>
            <CancellableDialog
              isOpen={deleteEmployeesModalOpen}
              onClose={toggleDeleteEmployeesModal}
              danger
              onSubmit={massDeleteEmployees}
            >
              <Body name="CONFIRM_MASS_DELETE_EMPLOYEES" />
            </CancellableDialog>
            <CancellableDialog
              danger
              isOpen={pendingDelete}
              onClose={closeDeleteModal}
              onSubmit={deleteEmployee}
            >
              <Body name="CONFIRM_DELETE_EMPLOYEE" values={pendingDelete} />
            </CancellableDialog>
            <EditModal
              isOpen={pendingEdit}
              employee={pendingEdit}
              onClose={closeEditModal}
              onSubmit={editEmployee}
            />
          </>
        )}
      </Async>
    </Content>
  );
};

export default Employees;
