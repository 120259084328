import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import Async from 'library/Async';
import { Body } from 'shared_components';

import api from 'api';
import CompanyHeader from './CompanyHeader';
import CompanyCampaigns from 'screens/campaigns/campaign-listing/CompanyCampaigns';
import Employees from 'screens/employees';
import { CancellableDialog } from 'library/dialogs';
import { TabRouter, Tab } from 'library/navigation/tab-router';
import { Campaign, Employee } from 'library/icons';
import { alphanum } from 'services/utils';

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

class CompanyDetails extends React.Component {
  state = {
    showDeleteDialog: false,
    tab: 0
  };

  getCompanyId = () => this.props.match.params.company_id;

  fetchCompanyData = async () => {
    const company = await api.companies.get(this.getCompanyId());
    return company;
  };

  deleteCompany = async () => {
    await api.companies.delete(this.getCompanyId());
    this.setState({ showDeleteDialog: false }, () => this.props.history.replace('/companies'));
  };

  renderDeleteModal = company => {
    const confirmationString = alphanum(company.client_company_id);
    return (
      <CancellableDialog
        onClose={() => this.setState({ showDeleteDialog: false })}
        onSubmit={this.deleteCompany}
        confirmationString={confirmationString}
        danger
      >
        <Body name="CONFIRM_DELETE_COMPANY" values={company} style={{ marginBottom: 30 }} />
      </CancellableDialog>
    );
  };

  render() {
    return (
      <Container>
        <Async fetchProps={this.fetchCompanyData}>
          {company => (
            <React.Fragment>
              <CompanyHeader
                company={company}
                isAdmin={this.props.isAdmin}
                onDelete={() => this.setState({ showDeleteDialog: true })}
              />
              <TabRouter>
                <Tab
                  name="CAMPAIGNS"
                  to="/companies/:company_id/campaigns"
                  component={CompanyCampaigns}
                  Icon={Campaign}
                />
                <Tab
                  name="EMPLOYEES"
                  to="/companies/:company_id/employees"
                  component={Employees}
                  Icon={Employee}
                />
              </TabRouter>
              {this.state.showDeleteDialog && this.renderDeleteModal(company)}
            </React.Fragment>
          )}
        </Async>
      </Container>
    );
  }
}

const mapper = state => {
  return { isAdmin: state.auth.isAdmin };
};
export default connect(mapper)(withRouter(CompanyDetails));
