import React from 'react';
import styled from 'styled-components/macro';
import { media, colors, Title, Caption, BodyMedium } from 'shared_components';

import LinkButton from 'library/buttons/LinkButton';
import { LightPage, BottomSubmitButton } from './common/styles';
import shirt from 'assets/images/icons/shirt.svg';
import identity from 'assets/images/icons/identity.svg';
import meter from 'assets/images/icons/meter.svg';
import morpho from 'assets/images/icons/morpho.svg';

// Import css files for react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Component } from 'react';
import Slider from 'react-slick';

const SwiperContainer = styled.div`
  width: 100%;
  height: max(50vh, 18rem);

  .slick-next {
    right: 0;
  }
  .slick-arrow {
    right: 0;
  }

  .slick-dots li {
    height: 10px;
    width: 10px;
    margin: 0 2px;
  }
  .slick-dots li button::before {
    height: 8px;
    width: 8px;
    margin: 0 2px;
    font-size: 8px;
    color: #c8c8c8;
    opacity: 0.7;
  }
  .slick-dots li.slick-active button::before {
    color: #34a5f8;
    opacity: 1;
  }
  margin-bottom: 4rem;
  ${media.phone`
    height: 60vh;
    margin-bottom: 1rem;
  `}
  ${media.phoneLarge`
    height: 60vh;
    margin-bottom: 3rem;
  `}
`;
class ExplanationCarousel extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <SwiperContainer>
        <Slider {...settings}>
          <Section>
            <SectionNumber>
              <Number>1</Number>
            </SectionNumber>
            <SectionIcon src={identity} alt="identity-icon" />
            <SectionTitle name="FUNNEL_EXPLANATION_INFO_TITLE" />
            <SectionContent name="FUNNEL_EXPLANATION_INFO_TEXT" />
          </Section>
          <Section>
            <SectionNumber>
              <Number>2</Number>
            </SectionNumber>
            <SectionIcon src={morpho} alt="shirt-icon" />
            <SectionTitle name="FUNNEL_EXPLANATION_MORPHOLOGY_TITLE" />
            <SectionContent name="FUNNEL_EXPLANATION_MORPHOLOGY_TEXT" />
          </Section>
          <Section>
            <SectionNumber>
              <Number>3</Number>
            </SectionNumber>
            <SectionIcon src={shirt} alt="shirt-icon" />
            <SectionTitle name="FUNNEL_EXPLANATION_REFERENCES_TITLE" />
            <SectionContent name="FUNNEL_EXPLANATION_REFERENCES_TEXT" />
          </Section>
          <Section>
            <SectionNumber>
              <Number>4</Number>
            </SectionNumber>
            <SectionIcon src={meter} alt="meter-icon" />
            <SectionTitle name="FUNNEL_EXPLANATION_SIZE_TITLE" />
            <SectionContent name="FUNNEL_EXPLANATION_SIZE_TEXT" />
          </Section>
        </Slider>
      </SwiperContainer>
    );
  }
}

const ExplanationCarouselContainer = styled.div`
  display: none;
  ${media.screenMedium`
    width:100%;
    display: block;
  `}
`;

const ExplanationContainer = styled.div`
  ${media.screenMedium`
    display: none;
  `}
`;
const MainTitle = styled(Title)`
  font-size: 1.5em;
  font-weight: 500;
  line-height: 2rem;
  text-align: center;
  margin: 5vh auto 5vh auto;
  }

  ${media.phone`
    margin: 2rem 0 0rem 0;
    font-size: 1.3rem;
  `}
  ${media.phoneVerySmall`
    margin: 2rem 0 0rem 0;
    font-size: 1.3rem;
  `}
  ${media.phoneSmall`
    margin: 2rem 0 0rem 0;
    font-size: 1.3rem;
  `}
  ${media.phoneMedium`
    margin: 10vh 0 4vh 0;
    font-size: min(4vh, 1.3rem);
  `}
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 2em;
  padding: 0 10.5%;
  display: flex;
  align-items: center;
  margin-bottom: 2em;

  ${media.phone`
    display: none;
  `}
`;

const ProgressBarItem = styled.div`
  height: 2em;
  width: 2em;
  border-radius: 50px;
  background-color: ${colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressBarNumber = styled.div`
  z-index: 2;
  height: 1.375rem;
  width: 1.375rem;
  border-radius: 50px;
  background-color: ${colors.mainBlue};
  color: white;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Pls don't ask why ...
const Number = styled.span`
  margin-top: 0.125em;
`;

// flex-grow and flex-basis instead of flex: 1 for IE 11
const ProgressBarLine = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  height: 1.25em;
  margin: 0 -0.5em;
  background-color: ${colors.lightGrey};
`;

const SectionContainer = styled.div`
  display: flex;
  margin-bottom: 5em;

  ${media.phone`
    flex-direction: column;
    margin-bottom: 2.5rem;
  `}
`;

const SectionNumber = styled(ProgressBarNumber)`
  display: none;

  ${media.screenMedium`
    display: flex;
    margin: 1.25rem 0;
  `}
`;

const Section = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  ${
    /* 1 */
    media.bigScreen`
    width: 11.75em;
  `
  }

  ${media.phone`
    margin-bottom: 1rem;
    flex: 1;
  `} 
  */
`;

const SectionIcon = styled.img`
  font-size: 2em;
  color: ${colors.mainBlue};
`;

const SectionTitle = styled(BodyMedium)`
  color: ${colors.mainBlue};
  line-height: 1em;
  text-align: center;
  margin: 1.25rem 0;

  ${
    /* 1 */
    media.bigScreen`
    width: 11.75em;
  `
  }
`;

const SectionContent = styled(Caption)`
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.3rem;
  text-align: center;
  color: ${colors.darkBlack};
  width: 80%;

  ${
    /* 1 */
    media.bigScreen`
    width: 11.75em;
  `
  }
  ${media.phone`
    margin-bottom: 1rem;

  `}
`;

const FunnelExplanation = ({ match }) => (
  <LightPage>
    <MainTitle name="FUNNEL_EXPLANATION_TITLE" />
    <ExplanationContainer>
      <ProgressBar>
        <ProgressBarItem>
          <ProgressBarNumber>
            <Number>1</Number>
          </ProgressBarNumber>
        </ProgressBarItem>
        <ProgressBarLine />
        <ProgressBarItem>
          <ProgressBarNumber>
            <Number>2</Number>
          </ProgressBarNumber>
        </ProgressBarItem>
        <ProgressBarLine />
        <ProgressBarItem>
          <ProgressBarNumber>
            <Number>3</Number>
          </ProgressBarNumber>
        </ProgressBarItem>
        <ProgressBarLine />
        <ProgressBarItem>
          <ProgressBarNumber>
            <Number>4</Number>
          </ProgressBarNumber>
        </ProgressBarItem>
      </ProgressBar>
      <SectionContainer>
        <Section>
          <SectionNumber>
            <Number>1</Number>
          </SectionNumber>
          <SectionIcon src={identity} alt="identity-icon" />
          <SectionTitle name="FUNNEL_EXPLANATION_INFO_TITLE" />
          <SectionContent name="FUNNEL_EXPLANATION_INFO_TEXT" />
        </Section>
        <Section>
          <SectionNumber>
            <Number>2</Number>
          </SectionNumber>
          <SectionIcon src={morpho} alt="shirt-icon" />
          <SectionTitle name="FUNNEL_EXPLANATION_MORPHOLOGY_TITLE" />
          <SectionContent name="FUNNEL_EXPLANATION_MORPHOLOGY_TEXT" />
        </Section>
        <Section>
          <SectionNumber>
            <Number>3</Number>
          </SectionNumber>
          <SectionIcon src={shirt} alt="shirt-icon" />
          <SectionTitle name="FUNNEL_EXPLANATION_REFERENCES_TITLE" />
          <SectionContent name="FUNNEL_EXPLANATION_REFERENCES_TEXT" />
        </Section>
        <Section>
          <SectionNumber>
            <Number>4</Number>
          </SectionNumber>
          <SectionIcon src={meter} alt="meter-icon" />
          <SectionTitle name="FUNNEL_EXPLANATION_SIZE_TITLE" />
          <SectionContent name="FUNNEL_EXPLANATION_SIZE_TEXT" />
        </Section>
      </SectionContainer>
    </ExplanationContainer>

    <ExplanationCarouselContainer>
      <ExplanationCarousel />
    </ExplanationCarouselContainer>
    <LinkButton
      buttonComponent={BottomSubmitButton}
      to={`/clothes_attribution/${match.params.campaign_employee_token}/physical_info`}
      style={{ margin: '1rem 0 1rem 0' }}
    >
      <Caption name="I_UNDERSTAND" color="white" />
    </LinkButton>
  </LightPage>
);

export default FunnelExplanation;
