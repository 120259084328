import React from 'react';

import api from 'api';
import { BodyShapeMale as BodyShapeMaleFitleComponent } from 'shared_components';
import styled from 'styled-components/';
import Async from 'library/Async';
import { media } from 'shared_components';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 2rem 0;
  ${media.phone`
    margin: 0;
  `}
`;

const BodyShapeMale = ({ theme, selectedShape, selectShape, details }) => {
  return (
    <Container>
      <Async
        fetchProps={async () =>
          await api.campaignEmployees.getPhysicalInfo({ campaign_employee_id: details.id })
        }
      >
        {({ h, w }) => (
          <BodyShapeMaleFitleComponent
            theme={theme}
            selectedShape={selectedShape}
            selectShape={selectShape}
            height={h}
            weight={w}
          />
        )}
      </Async>
    </Container>
  );
};

export default BodyShapeMale;
