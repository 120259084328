import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { SvgLoader } from 'react-svgmt';

import { Caption } from '../../text';
import { media } from 'shared_components/styles';
import { ProxyText } from './shoes-svg/proxys';
import { localizedString } from '../../services/i18n/translations';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const MeasureScreenSingleImage = styled.div`
  height: 30vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  & img {
    height: 100%;
  }
  ${media.bigScreen`height: 12.5em;`};
  ${media.phoneVerySmall`
    height: 22vh;
  `}
  ${media.phoneSmall`
    height: 22vh;
  `}
  ${media.phoneMedium`
    height: 22vh;
  `}
`;

const ListItem = styled.div`
  display: flex;
`;

const List = styled.div`
  ${media.bigScreen`
    padding-left: 1.5em;
    padding-right: 1.5em;
  `}
`;

const HEIGHT_OFFSET = 15;

const getShoeImageLinkFromKey = key =>
  `${process.env.REACT_APP_STATIC_URL}/plugin/data/shoes-image/${key}.svg`;

const FootLengthMeasurementExplanation = ({ theme }) => {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <MeasureScreenSingleImage
          style={{ borderStyle: 'solid', borderColor: '#ECECEC', borderWidth: '1px' }}
        >
          <SvgLoader
            path={getShoeImageLinkFromKey('SHAPE_MEASURE_LENGTH_V2')}
            alt={localizedString('FOOT_LENGTH_IMAGE_ALT')}
            width="88%"
            height="88%"
            overflow="visible"
          >
            <ProxyText
              id="#sheet tspan"
              text={localizedString('MEASURE_FOOT_LENGTH_SVG_SHEET')}
              heightOffset={HEIGHT_OFFSET}
              fontSize="1rem"
            />
            <ProxyText
              id="#wall tspan"
              text={localizedString('MEASURE_FOOT_LENGTH_SVG_WALL')}
              heightOffset={HEIGHT_OFFSET}
              fontSize="1rem"
            />
            <ProxyText
              id="#stroke tspan"
              text={localizedString('MEASURE_FOOT_LENGTH_SVG_STROKE')}
              heightOffset={HEIGHT_OFFSET}
              fontSize="1rem"
            />
          </SvgLoader>
        </MeasureScreenSingleImage>
        <List>
          <ListItem style={{ marginBottom: '1em' }}>
            <Caption text="1. " style={{ fontWeight: 600, marginRight: '0.3em' }}></Caption>
            <Caption
              className={`measure-foot-length-instructions`}
              name="MEASURE_FOOT_LENGTH_TEXT_STEP_1"
              style={{ textAlign: 'justify', lineHeight: '1em' }}
            />
          </ListItem>
          <ListItem style={{ marginBottom: '1em' }}>
            <Caption text="2. " style={{ fontWeight: 600, marginRight: '0.3em' }}></Caption>
            <Caption
              className={`measure-foot-length-instructions`}
              name="MEASURE_FOOT_LENGTH_TEXT_STEP_2"
              style={{ textAlign: 'justify', lineHeight: '1em' }}
            />
          </ListItem>
          <ListItem>
            <Caption text="3. " style={{ fontWeight: 600, marginRight: '0.3em' }}></Caption>
            <Caption
              className={`measure-foot-length-instructions`}
              name="MEASURE_FOOT_LENGTH_TEXT_STEP_3"
              style={{ textAlign: 'justify', lineHeight: '1em' }}
            />
          </ListItem>
        </List>
      </Container>
    </ThemeProvider>
  );
};

export default FootLengthMeasurementExplanation;
