import React from 'react';
import styled from 'styled-components/macro';
import { MainTitle, Title, Body, Caption } from 'shared_components';
import FaTrash from 'react-icons/lib/fa/trash';

import { DangerButton } from 'library/buttons';
import { Company } from 'library/icons';
import { TitleSection } from 'library/layout';

const DeleteButton = p => (
  <DangerButton {...p}>
    <FaTrash color="white" />
    <Caption name="COMPANY_DELETION" color="white" />
  </DangerButton>
);

const CenterContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const TitleContent = styled.div`
  display: flex;
  align-items: flex-end;
`;

const TitleText = styled.div`
  display: flex;
  align-items: baseline;
`;

const CompanyHeader = ({ company, onDelete, isAdmin }) => {
  const { name, client_company_id, description } = company;
  return (
    <TitleSection
      style={{ justifyContent: 'space-between' }}
      right={isAdmin && <DeleteButton onClick={onDelete} />}
    >
      <CenterContent>
        <TitleContent>
          {/* FYI Firefox does not support SVG height in rem
            https://bugzilla.mozilla.org/show_bug.cgi?id=1231147 */}
          <Company color="grey" size={'2em'} style={{ marginRight: '0.25rem' }} />
          <TitleText>
            <MainTitle text={name} />
            <Title
              text={'(' + client_company_id + ')'}
              color="grey"
              style={{ marginLeft: '0.25rem' }}
            />
          </TitleText>
        </TitleContent>
        {description && <Body color="grey" text={description} style={{ marginTop: '0.25rem' }} />}
      </CenterContent>
    </TitleSection>
  );
};

export default CompanyHeader;
