import React from 'react';
import { Body, Caption } from 'shared_components';
import styled from 'styled-components/macro';

import api from 'api';
import { CancellableDialog } from 'library/dialogs';
import Select from 'library/forms/select';
import Async from 'library/Async';
import { Tooltip } from '@blueprintjs/core';
import { isNullOrUndefined } from 'util';
import Callout from 'library/Callout';

const TextContainer = styled.div`
  margin-bottom: 10px;
`;

class EditPositionDialog extends React.Component {
  state = { position: null, error: null };

  fetchPositions = () => api.positions.list({ campaign_id: this.props.campaign_id });

  onSubmit = async () => {
    if (this.state.position) {
      await api.campaignEmployees.setPosition({
        campaign_employee_id: this.props.employee.id,
        position_id: this.state.position.id
      });
      this.props.onClose(true);
    } else {
      this.setState({ error: 'MISSING_FIELD' });
    }
  };

  render() {
    const { employee } = this.props;
    if (!employee) {
      return null;
    } else {
      return (
        <CancellableDialog
          isOpen={this.props.isOpen}
          onClose={() => this.props.onClose(false)}
          onSubmit={this.onSubmit}
        >
          <TextContainer>
            <Body name="SELECT_CAMPAIGN_EMPLOYEE_POSITION" values={employee} />
          </TextContainer>
          {employee.has_results && (
            <TextContainer>
              <Callout intent="danger">
                <Body name="MODIFY_POSITION_WARNING" />
              </Callout>
            </TextContainer>
          )}

          <Async fetchProps={this.fetchPositions}>
            {positions => (
              <Tooltip
                isOpen={!isNullOrUndefined(this.state.error)}
                intent="danger"
                position="right"
                content={<Caption name={this.state.error} color="white" />}
              >
                <Select
                  popoverProps={{ usePortal: false }}
                  value={this.state.position}
                  items={positions.filter(p => p.id !== employee.position_id)}
                  renderItem={position =>
                    position ? <Caption text={position.name} /> : <Caption name="SELECT_POSITION" />
                  }
                  onChange={position => {
                    this.setState({ position, error: null });
                  }}
                />
              </Tooltip>
            )}
          </Async>
        </CancellableDialog>
      );
    }
  }
}

export default EditPositionDialog;
