import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Card, Popover } from '@blueprintjs/core';
import FaClose from 'react-icons/lib/fa/close';
import { MEASUREMENTS_IN_COMFORT_SVGS, extractMeasurements } from 'shared_components';
import { CaptionMedium } from 'shared_components';

import { media, colors } from 'shared_components';
import { isNullOrUndefined } from 'services/utils';
import bottom from 'assets/images/shapes/bottom.png';
import top from 'assets/images/shapes/top.png';
import full from 'assets/images/shapes/full.png';

import { Validation, NoRecoCallout, SizeAdvice, PositionItemInfo } from './common';
import SizeInput from './inputs/SizeInput';
import QuantityInput from './inputs/QuantityInput';
import Recommendation from './clothes-recommendation';

const SHAPES_MAPPING = {
  top: top,
  bottom: bottom,
  full: full
};

const CloseIcon = styled(FaClose)`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  &:hover {
    filter: brightness(50%);
  }
`;

const Container = styled(Card)`
  width: 90%;
  border-radius: 5px;
  border: 0.5px solid #d8d8d8;
  box-shadow: 0 5px 10px 0 #eaeff2;
  padding: 0;
  margin-bottom: 1.5em;
  ${media.phone`
    width: 90%;
    margin-top: 2em;
    background: ${colors.black};
  `}
  ${media.phoneMedium`
    margin-top: 2em;
    background:  ${colors.black};
  `}
  ${media.phoneLarge`
    margin-top: 2em;
    background:  ${colors.black};
  `}
`;

const ImageContainer = styled.div`
  flex: 0 1 15%;
  margin-right: 1em;

  ${media.phone`
    flex: 0 1 20%;
    margin-left: 5%;
  `}
  ${media.phoneLarge`
    margin-left: 5%;
  `}
`;

const ProductImage = styled.img`
  cursor: pointer;
  object-fit: contain;
  height: ${props => (props.height ? props.height : '2.5rem')};
  width: ${props => (props.width ? props.width : '2.5rem')};
  background-color: white;
  vertical-align: middle;
  ${media.phone`
    background-color: ${colors.mainBlue};
  `}
  ${media.phoneLarge`
    background-color: ${colors.mainBlue};
  `}
`;

const DesktopContentContainer = styled.div`
  flex: 1;
`;

const Header = styled.div`
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  align-items: center;
  min-height: 3rem;
  background-color: ${colors.mainBlue};
  padding: 0.5em 1em;
  ${media.phone`
    height: 3rem;
    padding: 0em 0em;
    background-color: ${colors.mainBlue};
  `}
  ${media.phoneLarge`
    height: 3rem;
    padding: 0em 0em;
    background-color: ${colors.mainBlue};
  `}
`;

const Inner = styled.div`
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: ${colors.lighterGrey};
  padding: 1em;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  ${media.screenMedium`
    flex-direction: column;
  `}
  ${media.phone`
    flex-direction: column;
    background: inherit;
  `}
`;

const FlexRow = styled.div`
  display: flex;
  order: 1;
  justify-content: center;
  width: 80%;
  ${media.screenMedium`
    width:60%;
    margin: 0 auto;
    flex-direction: column;
    justify-content: flex-end;
  `};
  ${media.phone`
    width:100%;
    flex-direction: column;
    justify-content: flex-end;
  `};
`;

const ClothForm = ({
  clothResult,
  showTooltip,
  userMeasurements,
  updateResult,
  gender,
  language
}) => {
  const {
    product_name,
    reco,
    cloth_type,
    fixed_quantity,
    sizing_chart_sizes,
    sizing_chart_info,
    size_advices,
    enable_preferred_fit,
    product_image_url,
    validated,
    quantity
  } = clothResult;
  const chosenSizeId = clothResult.chosen_size_id;
  const recommendedSizeId = clothResult.recommended_size_id;

  const [imagePopoverOpened, setImagePopoverOpened] = useState(false);

  let validationTooltipState = 'OK';
  if (!clothResult.fixed_quantity && isNullOrUndefined(quantity)) {
    validationTooltipState = 'SELECT_QUANTITY';
  }

  const setQuantity = quantity => updateResult({ quantity });
  const setChosenSizeId = chosen_size_id => updateResult({ chosen_size_id });
  const toggleValidated = () => updateResult({ validated: !clothResult.validated });

  // We cannot show comfort comparison when no size is recommended
  const recoFound = recommendedSizeId;

  // This is a HACK to support "accessories" (products where we cannot do a recommendation. Ex: hats, gloves, ...).
  // The product is an accessory if ALL the measurements, in its sizing chart, are NOT in the comfort SVGs.
  const isAccessory = !extractMeasurements(sizing_chart_info).some(measurement =>
    MEASUREMENTS_IN_COMFORT_SVGS.includes(measurement)
  );

  let productImage = product_image_url;
  // We don't have shapes for accessories so if there is no product_image_url we don't show any.
  if (!productImage && !isAccessory) {
    productImage = SHAPES_MAPPING[cloth_type];
  }

  const showRecommendation = recoFound && !isAccessory && !validated;

  const togglePopover = () => setImagePopoverOpened(prev => !prev);

  const selectSizeFromName = (sizeName, isRecommended) => {
    const sizeId = sizing_chart_sizes.find(s => s.name === sizeName).id;
    updateResult({
      chosen_size_id: sizeId,
      recommended_size_id: isRecommended ? sizeId : recommendedSizeId
    });
  };

  return (
    <Container>
      <DesktopContentContainer>
        <Header>
          {productImage && (
            <Popover
              isOpen={imagePopoverOpened}
              onClose={() => setImagePopoverOpened(false)}
              content={
                <div>
                  <CloseIcon
                    onClick={() => setImagePopoverOpened(false)}
                    color={colors.grey}
                  ></CloseIcon>
                  <ProductImage src={productImage} alt={cloth_type} width="12rem" height="12rem" />
                </div>
              }
            >
              <ImageContainer onClick={togglePopover}>
                <ProductImage src={productImage} alt={cloth_type} />
              </ImageContainer>
            </Popover>
          )}
          <CaptionMedium text={product_name} color="white" />
        </Header>
        <PositionItemInfo>
          {!validated && (
            <SizeAdvice
              language={language}
              itemSizeAdvices={size_advices}
              hasMarginBottom={!showRecommendation}
            />
          )}
          {showRecommendation && (
            <Recommendation
              // This components only knows the size names as all its logic is based around the reco object.
              recoSizeName={sizing_chart_sizes.find(s => s.id === recommendedSizeId).name}
              chosenSizeName={sizing_chart_sizes.find(s => s.id === chosenSizeId).name}
              selectSize={selectSizeFromName}
              showTooltip={showTooltip}
              reco={reco}
              language={language}
              gender={gender}
              clothType={cloth_type}
              enablePreferredFit={enable_preferred_fit}
              sizingChartSizes={sizing_chart_sizes}
              sizingChartInfo={sizing_chart_info}
              userMeasurements={userMeasurements}
            />
          )}
        </PositionItemInfo>
        <Inner>
          <FlexRow>
            <SizeInput
              sizes={sizing_chart_sizes}
              selectedSizeId={chosenSizeId}
              // The reco does not make any sense for an accessory so we don't show the "recommended" bagde in the size selector.
              recommendedSizeId={!isAccessory ? recommendedSizeId : null}
              onChange={setChosenSizeId}
              validated={validated}
            />
            <QuantityInput
              fixed={fixed_quantity}
              quantity={quantity}
              disabled={validated}
              onChange={setQuantity}
            />
          </FlexRow>
          <Validation
            validated={validated}
            validationTooltip={validationTooltipState}
            onToggle={toggleValidated}
            isAccessory={isAccessory}
          />
          {!recoFound && <NoRecoCallout />}
        </Inner>
      </DesktopContentContainer>
    </Container>
  );
};

const languageMapper = state => ({ language: state.settings.language });

export default connect(languageMapper)(ClothForm);
