import { createGlobalStyle } from 'styled-components';

export const SLIDE_VERTICAL_DURATION_MS = 300;
const SLIDE_DOWN_DELAY_MS = SLIDE_VERTICAL_DURATION_MS + 200;
export const SLIDE_DOWN_TOTAL_MS = SLIDE_VERTICAL_DURATION_MS + SLIDE_DOWN_DELAY_MS;

const SLIDE_LEFT_DURATION_MS = 300;
export const SLIDE_LEFT_TOTAL_MS = SLIDE_LEFT_DURATION_MS + SLIDE_VERTICAL_DURATION_MS;

const SLIDE_FADE_DURATION_MS = 300;
export const SLIDE_FADE_TOTAL_MS = SLIDE_FADE_DURATION_MS + SLIDE_LEFT_TOTAL_MS;

export const Animations = createGlobalStyle`
  /* SLIDE UP */

  .reference-slide-up-appear {
    opacity: 0;
    transform: translateY(20%);
  }

  .reference-slide-up-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all ${SLIDE_VERTICAL_DURATION_MS}ms ease;
  }

  /* SLIDE DOWN */

  .reference-slide-down-appear {
    opacity: 0;
    transform: translateY(-5%);
  }

  .reference-slide-down-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all ${SLIDE_VERTICAL_DURATION_MS}ms ease ${SLIDE_DOWN_DELAY_MS}ms;
  }

  /* SLIDE RIGHT */

  .reference-slide-left-appear {
    opacity: 0;
    transform: translateX(-10%);
  }

  .reference-slide-left-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: all ${SLIDE_LEFT_DURATION_MS}ms ease ${SLIDE_VERTICAL_DURATION_MS}ms;
  }

  /* FADE IN */

  .reference-fade-in-appear {
    opacity: 0;
  }   

  .reference-fade-in-appear-active {
    opacity: 1;
    transition: opacity ${SLIDE_FADE_DURATION_MS}ms ease ${SLIDE_LEFT_TOTAL_MS}ms;
  }

  /* TITLE SLIDE UP */

  .reference-title-slide-up-appear {
    opacity: 0;
    transform: translateY(25%);
  }

  .reference-title-slide-up-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all ${SLIDE_VERTICAL_DURATION_MS}ms ease;
  }
`;
