import React, { PureComponent } from 'react';
import styled from 'styled-components/macro';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Body, Caption } from 'shared_components';
import { Tooltip } from '@blueprintjs/core';

import { Dialog } from 'library/dialogs';
import { StandardButton } from 'library/buttons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InvitationLink = styled(Body)`
  margin-top: 1em;
  max-width: 30em;
  word-wrap: break-word;
  display: inline-block;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

class CopyLinkDialog extends PureComponent {
  state = {
    showCopiedTooltip: false
  };

  onCopy = () => {
    this.setState({ showCopiedTooltip: true }, () => {
      setTimeout(() => this.setState({ showCopiedTooltip: false }), 1000);
    });
  };

  render() {
    return (
      <Dialog isOpen={this.props.accessLink} onClose={this.props.onClose}>
        <Container>
          <Row>
            <Caption name="ACCESS_LINK" />
            <Tooltip
              isOpen={this.state.showCopiedTooltip}
              content={<Caption name="COPIED" color="white" />}
            >
              <CopyToClipboard text={this.props.accessLink} onCopy={this.onCopy}>
                <StandardButton>
                  <Caption name="COPY_TO_CLIPBOARD" />
                </StandardButton>
              </CopyToClipboard>
            </Tooltip>
          </Row>
          <InvitationLink text={this.props.accessLink} />
        </Container>
      </Dialog>
    );
  }
}

export default CopyLinkDialog;
