import React, { useRef, useMemo, useEffect, useState } from 'react';
import { Caption, CaptionMedium, SmallText } from '../text/index';
import {
  MEASUREMENTS_TEXT_MAPPING,
  exactToRangeConstraints,
  rangeToExactConstraints,
  computeOutOfBounds
} from './util';
import { FixedCell, SizingChartCell } from './styles';
import styled from 'styled-components';
import { colors } from 'shared_components/styles';
// import { getTheme } from '../styles/theme';

const INDICATOR_HEAD_WIDTH = '1.75';
const INDICATOR_HEAD_HEIGHT = '1';

const IndicatorHead = styled.div`
  background-color: ${({ isOutOfBound }) => (isOutOfBound !== 0 ? colors.red : colors.black)};
  width: ${INDICATOR_HEAD_WIDTH}rem;
  height: ${INDICATOR_HEAD_HEIGHT}rem;
  box-shadow: 0 2px 4px 0 ${colors.grey};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 3px;

  &:before {
    content: '${({ isOutOfBound }) => (isOutOfBound === -1 ? '▲' : '')}';
    color: ${colors.red};
    position: absolute;
    left: -30%;
    font-size: 0.5625rem;
    top: 20%;
    transform: rotate(30deg);
  }

  &:after {
    content: '${({ isOutOfBound }) => (isOutOfBound === 1 ? '▲' : '')}';
    color: ${colors.red};
    position: absolute;
    right: -30%;
    font-size: 0.5625rem;
    top: 20%;
    transform: rotate(-30deg);
  }
`;

const IndicatorTail = styled.div`
  height: calc(${props => props.height}px - ${INDICATOR_HEAD_HEIGHT / 2}rem);
  width: ${({ isOutOfBound }) => (isOutOfBound ? 0 : '1px')};
  background-color: ${({ isOutOfBound }) => (isOutOfBound ? colors.white : colors.black)};
  box-shadow: 0 2px 4px 0 ${colors.grey};
  border-radius: 3px;
  border-right: ${({ isOutOfBound }) => (isOutOfBound ? `1px solid ${colors.red}` : null)};
  border-right-style: ${({ isOutOfBound }) => (isOutOfBound ? 'dashed' : null)};
`;

const IndicatorText = styled(SmallText)`
  font-size: 0.5625rem;
  color: ${colors.white};
`;

const IndicatorContainer = styled.div`
  position: absolute;
  left: calc(${props => props.indicatorRelativePosition}% - ${INDICATOR_HEAD_WIDTH}rem / 2);
  flex-direction: column;
  align-items: center;
  bottom: -1px;
  z-index: 1;
  display: flex;
  visibility: ${props => (props.showIndicator ? 'visible' : 'hidden')};
`;

const Indicator = ({
  indicatorRelativePosition,
  userMeasurement,
  outOfBounds,
  fixedCellRighPosition,
  rowHeight
}) => {
  const isOutOfBound = outOfBounds.min ? -1 : outOfBounds.max ? 1 : 0;
  const tailRef = useRef(null);

  let showIndicator = true;
  if (tailRef.current) {
    if (showIndicator && tailRef.current.getBoundingClientRect().left < fixedCellRighPosition - 1) {
      showIndicator = false;
    } else if (
      !showIndicator &&
      tailRef.current.getBoundingClientRect().left >= fixedCellRighPosition - 1
    ) {
      showIndicator = true;
    }
  }

  return (
    <IndicatorContainer
      showIndicator={showIndicator}
      indicatorRelativePosition={indicatorRelativePosition}
    >
      <IndicatorHead isOutOfBound={isOutOfBound}>
        <IndicatorText text={userMeasurement} />
      </IndicatorHead>
      <IndicatorTail isOutOfBound={isOutOfBound} ref={tailRef} height={rowHeight} />
    </IndicatorContainer>
  );
};

const SizingChartCellText = ({ type, constraint }) => {
  switch (type) {
    case 'RANGE':
      return <Caption text={`${+constraint.min.toFixed(1)} - ${+constraint.max.toFixed(1)}`} />;
    case 'EXACT':
      return <Caption text={+constraint.value.toFixed(1)} />;
    default:
      return '';
  }
};

const SizingChartCellWithIndicator = ({
  type,
  constraint,
  indicatorRelativePosition,
  userMeasurement,
  outOfBounds,
  fixedCellRighPosition
}) => {
  const cellRef = useRef();
  const [rowHeight, setRowHeight] = useState();
  useEffect(() => {
    if (cellRef.current) {
      setRowHeight(cellRef.current.getBoundingClientRect().height);
    }
  }, [cellRef]);

  return (
    <SizingChartCell ref={cellRef}>
      <Indicator
        indicatorRelativePosition={indicatorRelativePosition}
        userMeasurement={userMeasurement}
        outOfBounds={outOfBounds}
        fixedCellRighPosition={fixedCellRighPosition}
        rowHeight={rowHeight}
      />
      <SizingChartCellText type={type} constraint={constraint} />
    </SizingChartCell>
  );
};

const computeVisualIndicatorIndexAndPosition = (
  outOfBounds,
  constraints,
  userMeasurement,
  inputType,
  recommendedSizeIndex
) => {
  if (outOfBounds.min) {
    return [0, 0];
  }
  if (outOfBounds.max) {
    return [constraints.length - 1, 100];
  }
  let exactConstraints;
  let rangeConstraints;
  if (inputType === 'RANGE') {
    exactConstraints = rangeToExactConstraints(constraints);
    rangeConstraints = constraints;
  } else if (inputType === 'EXACT') {
    exactConstraints = constraints;
    rangeConstraints = exactToRangeConstraints(constraints);
  } else {
    return [null, null];
  }
  /* Find the Indicator index. (which cell to put the indicator in)
    ```
    Example for a multidimensional sizing chart:
    exactConstraints = [70, 80, 90, 70, 80, 90], recommendedSizeIndex = 3 (70), userMeasurement = 87cm
    bestConstraintsIndexes = [2, 5] (constraint 2 and 5 which both are 90cm)
    indicatorIndex = 2 (because 2 is closer to the recommendedSizeIndex than 5 (3 - 2 < 5 - 2))
    The indicator will be placed in the 3rd cell of the ConstraintsRow
    ```
  */
  // Get the constraints that are the closest to the userMeasurement.
  // This could result in multiple constraints if we have a multidimensional sizing chart or gap between size constraints
  let bestConstraintsIndexes = [];
  rangeConstraints.forEach((constraint, i) => {
    if (userMeasurement <= constraint.max && userMeasurement >= constraint.min) {
      bestConstraintsIndexes.push(i);
    }
  });

  if (bestConstraintsIndexes.length === 0) {
    const distances = exactConstraints.map(({ value }) => Math.abs(userMeasurement - value));
    let bestDistance = Infinity;
    distances.forEach((distance, i) => {
      if (distance < bestDistance) {
        bestConstraintsIndexes = [i];
        bestDistance = distances[i];
      } else if (distance === bestDistance) {
        bestConstraintsIndexes.push(i);
      }
    });
  }
  let indicatorIndex;
  if (bestConstraintsIndexes.length === 1) {
    // Uni-dimensional sizing chart
    indicatorIndex = bestConstraintsIndexes[0];
  } else {
    // Multidimensional sizing chart. We need to compute the index that is the closest to the recommended size index.
    indicatorIndex =
      bestConstraintsIndexes[
        bestConstraintsIndexes
          .map(index => Math.abs(recommendedSizeIndex - index))
          .reduce(
            (minIndex, distance, i, distances) => (distance < distances[minIndex] ? i : minIndex),
            0
          )
      ];
  }
  let indicatorRelativePosition;
  const indicatorConstraint = rangeConstraints[indicatorIndex];
  if (userMeasurement <= indicatorConstraint.min) {
    indicatorRelativePosition = 0;
  } else if (userMeasurement >= indicatorConstraint.max) {
    indicatorRelativePosition = 100;
  } else {
    indicatorRelativePosition =
      ((userMeasurement - indicatorConstraint.min) /
        (indicatorConstraint.max - indicatorConstraint.min)) *
      100;
  }

  return [indicatorIndex, indicatorRelativePosition];
};

const ConstraintsRow = ({
  constraints,
  inputType,
  measurementName,
  userMeasurement,
  recommendedSizeIndex
}) => {
  // These functions are expensive, we don't want to recompute them on each re-rendering (triggered by the scroll)
  const outOfBounds = useMemo(
    () => computeOutOfBounds(constraints, userMeasurement, inputType),
    [constraints, userMeasurement, inputType]
  );

  const [indicatorIndex, indicatorRelativePosition] = useMemo(
    () =>
      computeVisualIndicatorIndexAndPosition(
        outOfBounds,
        constraints,
        userMeasurement,
        inputType,
        recommendedSizeIndex
      ),
    [constraints, userMeasurement, inputType, recommendedSizeIndex, outOfBounds]
  );

  const measurementCellRef = useRef(null);

  return (
    <tr>
      <FixedCell ref={measurementCellRef}>
        <CaptionMedium name={MEASUREMENTS_TEXT_MAPPING[measurementName]} />
      </FixedCell>
      {constraints.map((constraint, constraintIndex) =>
        constraintIndex === indicatorIndex ? (
          <SizingChartCellWithIndicator
            key={constraintIndex}
            type={inputType}
            constraint={constraint}
            userMeasurement={userMeasurement}
            indicatorRelativePosition={indicatorRelativePosition}
            outOfBounds={outOfBounds}
            fixedCellRighPosition={
              measurementCellRef.current && measurementCellRef.current.getBoundingClientRect().right
            }
          />
        ) : (
          <SizingChartCell key={constraintIndex}>
            <SizingChartCellText type={inputType} constraint={constraint} />
          </SizingChartCell>
        )
      )}
    </tr>
  );
};

export default ConstraintsRow;
